import axios from '@/plugins/axios'
export const fetchIndex = () => {
  return axios.get('index', {
    params: { includes: 'summary' },
  })
}

export const fetchIndexProjects = () => {
  return axios.get('index/projects', {
    params: { includes: 'summary' },
  })
}

export const subscribe = (email) => {
  return axios.post('subscribe', {
    email,
  })
}
