import axios from '@/plugins/axios'

export const fetchUnread = (params) => {
  return axios.get('/notifications/unread', {
    params,
  })
}

export const updateAsRead = (data) => {
  return axios.post('/notifications/read', data)
}

export const fetchAll = (params) => {
  return axios.get('/notifications', {
    params,
  })
}
