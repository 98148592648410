import Vue from 'vue'
import axios from '@/plugins/axios'
const state = {
  data: [],
  loading: [],
}
const mutations = {
  SET(state, { index, data }) {
    Vue.set(state.data, index, data)
  },
  PUSH(state, data) {
    state.data.push(data)
  },
  SET_LOADING(state, { index, data }) {
    Vue.set(state.loading, index, data)
  },
  PUSH_LOADING(state, data) {
    state.loading.push(data)
  },
}
const actions = {
  update({ commit, state }, users) {
    users.forEach((user) => {
      const index = state.data.findIndex((item) => item.id === user.id)
      if (index > -1) {
        commit('SET', {
          index,
          data: user,
        })
      } else {
        commit('PUSH', user)
      }
    })
  },
  isFollowed({ state }, user_id) {
    const index = state.data.findIndex((item) => item.id === user_id)
    if (index > -1) return state.data[index].followed
    return false
  },
  async follow({ state, dispatch, commit }, user_id) {
    dispatch('updateLoading', { id: user_id, status: true })
    const response = await axios.post(`follow/${user_id}`)
    dispatch('updateLoading', { id: user_id, status: false })
    dispatch('update', [{ id: user_id, followed: true }])
    dispatch(
      'message/pushMessage',
      {
        type: 'info',
        content: response.message,
        timeout: 3000,
      },
      { root: true }
    )
  },
  async unfollow({ state, dispatch, commit }, user_id) {
    dispatch('updateLoading', { id: user_id, status: true })
    await axios.delete(`follow/${user_id}`)
    dispatch('updateLoading', { id: user_id, status: false })
    dispatch('update', [{ id: user_id, followed: false }])
  },
  updateLoading({ commit }, data) {
    const index = state.loading.findIndex((item) => item.id === data.id)
    if (index > -1) {
      commit('SET_LOADING', { index, data })
    } else {
      commit('PUSH_LOADING', data)
    }
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
