const state = {
  data: null,
  password: null,
}
const mutations = {
  SET(state, data) {
    state.data = data
  },
  SET_PASSWORD(state, password) {
    state.password = password
  },
}
export default {
  namespaced: true,
  state,
  mutations,
}
