import store from '@/store'
export default {
  path: '/auth',
  component: () => import('@/views/layouts/empty'),
  children: [
    {
      path: 'login',
      name: 'AuthLogin',
      redirect: { name: 'NewLogin' },
      component: () => import(/* webpackChunkName: 'auth' */'@/views/auth/login'),
    },
    {
      path: 'register',
      name: 'AuthRegister',
      redirect: { name: 'NewRegister' },
      component: () => import(/* webpackChunkName: 'blog' */'@/views/auth/register'),
    },
    {
      path: 'email/verify/:id?/:hash?',
      name: 'EmailVerify',
      component: () => import(/* webpackChunkName: 'blog' */'@/views/auth/emailVerify'),
      meta: {
        auth: true,
      },
    },
  ],
}
