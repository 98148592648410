import axios from '@/plugins/axios'

export const fetchIndex = (params) => {
  return axios.get('challenges', {
    params,
  })
}

export const fetchData = (id) => {
  return axios.get(`challenges/${id}`, {
    params: {
      includes: 'content',
    },
  })
}

/**
 *
 * @param id 挑战的 id
 * @param params 参数
 * @returns {*}
 */
export const fetchReplies = (id, params) => {
  console.log(params)
  return axios.get(`challenges/${id}/replies/`, {
    params,
  })
}

export const create = (data) => {
  return axios.post(`challenges`, data)
}

export const update = (id, data) => {
  return axios.put(`challenges/${id}`, data)
}

export const deleteData = (id) => {
  return axios.delete(`challenges/${id}`)
}

export const createOrUpdate = (data, id = 0) => {
  if (id > 0) {
    return update(id, data)
  } else {
    return create(data)
  }
}

export const reply = (id, data) => {
  return axios.post(`challenges/${id}/reply`, data)
}

export const checkReplyToBest = (id) => {
  return axios.post(`replies/${id}/best`)
}

export const like = (id) => {
  return axios.post(`challenges/${id}/like`)
}
