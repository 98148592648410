import axios from '@/plugins/axios'

export const fetchOrderInfo = (id) => {
  return axios.get(`order/${id}`)
}
export const payment = (token, data) => {
  return axios.post(`payment/${token}/pay`, data)
}

export const createOrder = (data) => {
  return axios.post(`order`, data)
}

export const updateOrder = (id, data) => {
  return axios.put(`order/${id}`, data)
}
export const paymentSingle = (token, data) => {
  return axios.post(`payment_single/${token}/pay`, data)
}

export const createApplyProjectOrder = (data) => {
  return axios.post('apply_project_order', data)
}
