export default {
  path: '/mentor-program',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: ':id/edit',
      name: 'mentorEdit',
      component: () => import(/* webpackChunkName: 'mentor' */'@/views/projects/create'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: 'create',
      name: 'mentorProgramCreate',
      component: () => import(/* webpackChunkName: 'mentor' */'@/views/projects/create'),
      meta: {
        auth: true,
      },
    },
    {
      path: ':id',
      name: 'mentorProgramShow',
      component: () => import(/* webpackChunkName: 'mentor' */'@/views/projects/show'),
      meta: {},
    },
  ],
}
