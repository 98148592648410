<template>
  <div>
    <index-top-banner></index-top-banner>
    <index-student></index-student>
    <index-company></index-company>
    <index-other></index-other>
    <index-our></index-our>
    <v-row justify="center" class="py-13" no-gutters>
      <v-col cols="12" md="8">
        <!--index-categories :categories="categories"></index-categories-->
        <div class="mx-n3 mx-md-0">
          <v-tabs
            show-arrows
            fixed-tabs
            background-color="primary"
            dark
            v-model="tab"
          >
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item">
            <v-row v-if="item === items[0]">
              <v-col
                cols="12"
                xl="6"
                class="mx-0"
                v-for="project in companyProjects"
                :key="`company-project-${project.id}`"
              >
                <project-item :item="project"></project-item>
              </v-col>
            </v-row>
            <v-row v-if="item === items[1]">
              <v-col
                cols="12"
                xl="6"
                class="mx-0"
                v-for="challenge in challenges"
                :key="`challenge-${challenge.id}`"
              >
                <challenge-item :challenge="challenge"></challenge-item>
              </v-col>
            </v-row>

            <v-row v-if="item === items[2]">
              <v-col
                cols="12"
                xl="6"
                class="mx-0"
                v-for="project in projects"
                :key="`index-project-${project.id}`"
              >
                <project-item :item="project"></project-item>
              </v-col>
            </v-row>
            <v-row v-if="item === items[3]">
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="3"
                class="mx-0"
                v-for="hire in hires"
                :key="`index-project-${hire.id}`"
              >
                <hire-item :item="hire"></hire-item>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <our-friend :friend-links="friendLinks"></our-friend>
  </div>
</template>
<script>
import IndexTopBanner from '@/components/Index/TopBanner/index'
import IndexStudent from '@/components/Index/Student'
// import IndexCategories from '@/components/Index/Categories'
import ChallengeItem from '@/components/Challenge/Item'
import IndexCompany from '@/components/Index/Company'
import IndexOther from '@/components/Index/Other'
import IndexOur from '@/components/Index/Our'
import { fetchIndex } from '@/api'
import ProjectItem from '@/components/Project/Item'
import HireItem from '@/components/Hire/Item'
import OurFriend from '@/components/Index/OurFriend'
import { mapActions } from 'vuex'
export default {
  components: {
    IndexTopBanner,
    // IndexCategories,
    ChallengeItem,
    IndexStudent,
    IndexCompany,
    IndexOther,
    ProjectItem,
    HireItem,
    IndexOur,
    OurFriend,
  },
  data() {
    return {
      tab: null,
      items: ['Company Projects', 'Company Challenges', 'Projects', 'Network'],
      challenges: [],
      categories: [],
      companyProjects: [],
      projects: [],
      hires: [],
      friendLinks: [],
      content: '',
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        const {
          categories,
          data,
          projects,
          company,
          hires,
          friend_links,
        } = await fetchIndex()
        this.categories = categories
        this.challenges = data
        this.companyProjects = company
        this.projects = projects
        this.hires = hires
        this.friendLinks = friend_links
        console.log('加载完毕', projects)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
