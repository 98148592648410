<template>
  <v-app>
    <template v-if="!isMobile">
      <common-header></common-header>
    </template>
    <template v-else>
      <mobile-drawer></mobile-drawer>
      <mobile-header></mobile-header>
      <!--v-app-bar
        app
        flat
        elevate-on-scroll
        hide-on-scroll
        class="grey lighten-5"
      >
        <v-app-bar-nav-icon v-if="isLoggedIn"></v-app-bar-nav-icon>
        <v-toolbar-title class="orange--text">
          <router-link to="/" style="text-decoration: none;"
            >Beeznests</router-link
          >
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tabs centered style="max-width: 660px;" v-if="!isMobile">
          <v-tab exact to="/" style="text-transform: none;">Home</v-tab>
          <v-tab exact to="/challenges" style="text-transform: none;"
            >Company Challenges</v-tab
          >
          <v-tab exact to="/projects" style="text-transform: none;"
            >One-time Projects</v-tab
          >
          <v-tab exact to="/hires" style="text-transform: none;"
            >Potential Hires</v-tab
          >
        </v-tabs>
        <v-spacer></v-spacer>
        <auth-bar></auth-bar>
      </v-app-bar-->
    </template>
    <v-main>
      <v-container
        fluid
        :fill-height="$route.meta.fill"
        :style="$route.name === 'PaymentUserCost' ? 'padding: 0' : ''"
      >
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </v-container>
    </v-main>
    <v-footer app absolute>
      <div class="mx-auto bg-white" v-if="!isMobile" style="width: 1024px;">
        <common-footer></common-footer>
      </div>

      <!--v-bottom-navigation color="purple lighten-1" grow app v-else>
        <v-btn :to="{ name: 'MobileIndex' }">
          <span>Home</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'challengeIndex' }">
          <span>Discussions</span>
          <v-icon>mdi-file-question</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'projectIndex' }">
          <span>Projects</span>
          <v-icon>mdi-book-open-variant</v-icon>
        </v-btn>
      </v-bottom-navigation-->
    </v-footer>
    <Message />
  </v-app>
</template>
<script>
import Message from '@/components/Message/Index'
import AuthBar from '@/components/AuthBar'
import GFooter from '@/components/Footer'
import { mapGetters } from 'vuex'
import commonHeader from '@/views/new-pages/common/header'
import commonFooter from '@/views/new-pages/common/footer'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
export default {
  components: {
    MobileDrawer,
    MobileHeader,
    Message,
    commonHeader,
    commonFooter,
  },
  computed: {
    // isMobile() {
    //   return this.$vuetify.breakpoint.mobile
    // },
    ...mapGetters(['isLoggedIn', 'userInfo', 'isMobile']),
  },
}
</script>
