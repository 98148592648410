<template>
  <div>
    <template v-if="!isMobile">
      <div class="tw-container tw-mx-auto tw-mt-6" style="max-width: 1150px;">
        <!--项目介绍-->
        <!--        <div class="tw-my-4 tw-mt-2">-->
        <!--          <div class="tw-p-4 tw-py-8 tw-bg-white tw-rounded-md">-->
        <!--            <div>-->
        <!--              <p-->
        <!--                class="tw-text-black tw-text-xl lg:tw-text-2xl tw-mb-4 tw-text-center"-->
        <!--              >-->
        <!--                A Step by Step Experience-->
        <!--              </p>-->
        <!--              <div class="tw-grid tw-grid-cols-6 tw-text-sm tw-text-center">-->
        <!--                <div class="tw-flex tw-flex-col">-->
        <!--                  <div-->
        <!--                    class="tw-inline-flex tw-items-center tw-justify-center tw-mb-4"-->
        <!--                  >-->
        <!--                    <v-divider style="border-color: transparent;"></v-divider>-->
        <!--                    <div-->
        <!--                      class="tw-font-bold tw-text-xl tw-bg-orange-400 tw-text-white tw-rounded-full tw-h-12 tw-w-12 tw-inline-flex tw-items-center tw-justify-center"-->
        <!--                    >-->
        <!--                      <i-->
        <!--                        class="fa fa-user-o"-->
        <!--                        style="transform: scale(1.3, 1.3);"-->
        <!--                        aria-hidden="true"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <v-divider></v-divider>-->
        <!--                  </div>-->
        <!--                  Apply for a Program-->
        <!--                </div>-->
        <!--                <div class="tw-flex tw-flex-col">-->
        <!--                  <div-->
        <!--                    class="tw-inline-flex tw-items-center tw-justify-center tw-mb-4"-->
        <!--                  >-->
        <!--                    <v-divider></v-divider>-->
        <!--                    <div-->
        <!--                      class="tw-font-bold tw-text-xl tw-bg-orange-400 tw-text-white tw-rounded-full tw-h-12 tw-w-12 tw-inline-flex tw-items-center tw-justify-center"-->
        <!--                    >-->
        <!--                      <i-->
        <!--                        class="fa fa-bullhorn"-->
        <!--                        style="transform: scale(1.3, 1.3);"-->
        <!--                        aria-hidden="true"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <v-divider></v-divider>-->
        <!--                  </div>-->
        <!--                  Get Trained by our Industry Experts-->
        <!--                </div>-->
        <!--                <div class="tw-flex tw-flex-col">-->
        <!--                  <div-->
        <!--                    class="tw-inline-flex tw-items-center tw-justify-center tw-mb-4"-->
        <!--                  >-->
        <!--                    <v-divider></v-divider>-->
        <!--                    <div-->
        <!--                      class="tw-font-bold tw-text-xl tw-bg-orange-400 tw-text-white tw-rounded-full tw-h-12 tw-w-12 tw-inline-flex tw-items-center tw-justify-center"-->
        <!--                    >-->
        <!--                      <i-->
        <!--                        class="fa fa-bullseye"-->
        <!--                        style="transform: scale(1.3, 1.3);"-->
        <!--                        aria-hidden="true"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <v-divider></v-divider>-->
        <!--                  </div>-->
        <!--                  Learn through Hands-on Experience-->
        <!--                </div>-->
        <!--                <div class="tw-flex tw-flex-col">-->
        <!--                  <div-->
        <!--                    class="tw-inline-flex tw-items-center tw-justify-center tw-mb-4"-->
        <!--                  >-->
        <!--                    <v-divider></v-divider>-->
        <!--                    <div-->
        <!--                      class="tw-font-bold tw-text-xl tw-bg-orange-400 tw-text-white tw-rounded-full tw-h-12 tw-w-12 tw-inline-flex tw-items-center tw-justify-center"-->
        <!--                    >-->
        <!--                      <i-->
        <!--                        class="fa fa-pencil-square-o"-->
        <!--                        style="transform: scale(1.3, 1.3);"-->
        <!--                        aria-hidden="true"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <v-divider></v-divider>-->
        <!--                  </div>-->
        <!--                  Complete the Program and Obtain a Certificate-->
        <!--                </div>-->
        <!--                <div class="tw-flex tw-flex-col">-->
        <!--                  <div-->
        <!--                    class="tw-inline-flex tw-items-center tw-justify-center tw-mb-4"-->
        <!--                  >-->
        <!--                    <v-divider></v-divider>-->
        <!--                    <div-->
        <!--                      class="tw-font-bold tw-text-xl tw-bg-orange-400 tw-text-white tw-rounded-full tw-h-12 tw-w-12 tw-inline-flex tw-items-center tw-justify-center"-->
        <!--                    >-->
        <!--                      <i-->
        <!--                        class="fa fa-star-o"-->
        <!--                        style="transform: scale(1.3, 1.3);"-->
        <!--                        aria-hidden="true"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <v-divider></v-divider>-->
        <!--                  </div>-->
        <!--                  Request to join your Mentor’s Academic Team-->
        <!--                </div>-->
        <!--                <div class="tw-flex tw-flex-col">-->
        <!--                  <div-->
        <!--                    class="tw-inline-flex tw-items-center tw-justify-center tw-mb-4"-->
        <!--                  >-->
        <!--                    <v-divider></v-divider>-->
        <!--                    <div-->
        <!--                      class="tw-font-bold tw-text-xl tw-bg-orange-400 tw-text-white tw-rounded-full tw-h-12 tw-w-12 tw-inline-flex tw-items-center tw-justify-center"-->
        <!--                    >-->
        <!--                      <i-->
        <!--                        class="fa fa-files-o"-->
        <!--                        style="transform: scale(1.3, 1.3);"-->
        <!--                        aria-hidden="true"-->
        <!--                      ></i>-->
        <!--                    </div>-->
        <!--                    <v-divider style="border-color: transparent;"></v-divider>-->
        <!--                  </div>-->
        <!--                  <p class="tw-px-1">Take on large-scaled complex projects</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="tw-flex tw-items-center tw-mt-15 tw-mb-6">
          <router-link
            to="/jobs/create"
            class="tw-shadow-md tw-font-bold tw-uppercase bg-primary tw-px-10 tw-py-4 tw-rounded tw-text-white tw-text-sm"
            style="color: white;"
            ><i class="fa fa-pencil-square-o tw-mr-1" aria-hidden="true"></i
            >Post a new job</router-link
          >
          <div
            class="tw-border tw-px-2 tw-py-1 tw-ml-12 tw-flex tw-items-center tw-flex-grow tw-rounded"
          >
            <input
              class="tw-outline-none tw-w-8/12"
              type="text"
              v-model="keyword"
              placeholder="Search"
            />
            <div class="tw-relative tw-w-2/12 tw-ml-auto">
              <select
                class="tw-block tw-appearance-none tw-w-full tw-bg-gray-200 tw-border tw-border-gray-200 tw-text-gray-700 tw-py-3 tw-px-3 tw-pr-8 tw-rounded tw-leading-tight tw-outline-none tw-focus:tw-bg-white tw-focus:tw-border-gray-500"
                id="grid-state"
                v-model="category_id"
              >
                <option value="0">All</option>
                <option
                  v-for="(item, i) in categories"
                  :key="`select-category-${i}`"
                  :value="item.id"
                  >{{ item.name }}</option
                >
              </select>
              <div
                class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700"
              >
                <svg
                  class="tw-fill-current tw-h-4 tw-w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <button
              @click="loadData(true)"
              class="tw-uppercase tw-ml-2 focus:tw-outline-none tw-leading-tight bg-orange-2 tw-px-4 tw-py-3 tw-rounded tw-text-sm"
            >
              <i class="fa fa-search tw-mr-1" aria-hidden="true"></i>Search
            </button>
          </div>
        </div>

        <div class="tw-mt-8 tw-flex tw-flex-wrap tw-justify-between">
          <div
            class="tw-p-5 tw-rounded-md tw-mb-6 tw-border"
            style="
              max-width: 356px;
              width: 356px;
              border-color: #ddd;
              box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.07);
            "
            v-for="(item, i) in data"
            :key="`project-${i}`"
          >
            <div class="tw-flex">
              <div class="tw-border tw-shadow tw-rounded">
                <router-link
                  class="tw-w-20 tw-h-20"
                  :to="{ name: 'userShow', params: { id: item.user.id } }"
                >
                  <img
                    :src="item.user.avatar"
                    class="tw-w-20 tw-h-20 tw-rounded"
                    style="min-width: 70px;"
                /></router-link>
              </div>
              <div
                class="tw-ml-3"
                :class="
                  item.type === 'mentor-program'
                    ? 'tw-flex tw-flex-col tw-justify-between'
                    : ''
                "
              >
                <div class="tw-flex tw-flex-row tw-items-center">
                  <span
                    class="tw-text-xl tw-truncate tw-font-bold"
                    style="max-width: 200px;"
                    ><router-link
                      :to="{ name: 'userShow', params: { id: item.user.id } }"
                      >{{ item.user.name }}</router-link
                    ></span
                  >
                  <template
                    v-if="item.user.organization && item.user.organization.id"
                  >
                    <user-badge
                      :organization="item.user.organization"
                    ></user-badge>
                  </template>
                  <div class="tw-w-6 tw-h-6 tw-ml-2 tw-rounded-full">
                    <img :src="item.user.country_flag" />
                  </div>
                </div>
                <div
                  class="tw-flex tw-flex-row tw-text-sm"
                  :class="item.type === 'mentor-program' ? '' : 'tw-mt-3'"
                >
                  <div
                    class="tw-rounded-full tw-px-2 text-gray-1 bg-gray-2 tw-flex-grow-0 tw-flex-shrink tw-flex tw-items-center"
                  >
                    <i class="fa fa-briefcase tw-mr-1" aria-hidden="true"></i>
                    <span>{{ item.user.type | userType }}</span>
                  </div>
                  <div
                    class="tw-rounded-full tw-ml-2 text-gray-1 tw-px-2 bg-gray-2 tw-flex-grow-0 tw-flex-shrink tw-flex tw-items-center"
                  >
                    <i class="fa fa-map-marker tw-mr-1" aria-hidden="true"></i
                    ><span>{{ item.user.country.name }}</span>
                  </div>
                </div>
                <div
                  v-if="item.type === 'mentor-program'"
                  class="tw-mb-1 tw-flex"
                >
                  <span
                    class="bg-gray-2 tw-px-2 py-1 tw-rounded-full text-gray-1 tw-text-sm"
                  >
                    Certificate provided
                  </span>
                  <!--                  <span-->
                  <!--                    class="tw-ml-1 bg-gray-2 tw-px-2 py-1 tw-rounded-full text-gray-1 tw-text-sm"-->
                  <!--                    v-if="item.work_with_academic_team"-->
                  <!--                  >-->
                  <!--                    Academic team-->
                  <!--                  </span>-->
                  <academic-team-label
                    v-if="item.work_with_academic_team"
                  ></academic-team-label>
                </div>
              </div>
            </div>
            <h3
              class="tw-my-3 tw-text-sm text-primary tw-font-medium tw-truncate"
            >
              <span class="tw-text-black"
                >Company Name: {{ item.company_name }}</span
              >
            </h3>
            <h3
              class="tw-my-3 tw-text-sm text-primary tw-font-medium tw-truncate"
            >
              <span class="tw-text-black"
                >{{
                  item.type === 'job'
                    ? 'Job Description:'
                    : 'Internship Description:'
                }}{{ item.position_title }}</span
              >
            </h3>
            <p
              class="text-gray-1 tw-text-sm tw-overflow-hidden tw-h-20 tw-max-h-20 tw-font-rubik"
              v-html="item.summary"
            ></p>
            <div class="tw-text-xs tw-grid tw-grid-cols-2 tw-mt-6 tw-gap-2">
              <div
                class="bg-gray-2 tw-rounded tw-p-2 text-gray-2"
                v-if="item.type !== 'bootcamp'"
              >
                <i class="fa fa-calendar text-primary"></i>
                <span class="tw-font-bold tw-ml-1">Deadline:</span
                >{{ item.closed_at | formatDate }}
              </div>
              <div class="bg-gray-2 tw-rounded tw-p-2">
                <i class="fa fa-trophy text-primary"></i>
                <span class="tw-font-bold tw-ml-1">Type:</span>
                {{ item.type }}
              </div>

              <div
                class="bg-gray-2 tw-rounded tw-p-2 tw-flex-grow-0 tw-flex-shrink tw-mt-2"
              >
                <i class="fa fa-clock-o text-primary"></i>
                <span class="tw-font-bold tw-ml-1">Reward:</span>
                <template v-if="item.cost">
                  {{ item.cost }}/{{
                    item.type === 'project' ? 'per project' : 'hour'
                  }}
                </template>
                <template v-else>TBD</template>
              </div>
            </div>
            <div class="tw-border-t tw-mt-6 tw-mb-4"></div>
            <div class="tw-flex tw-justify-between tw-text-sm">
              <router-link
                to="/"
                class="bg-gray-2 tw-rounded tw-p-2 tw-flex-grow-0 tw-flex-shrink tw-mt-2 tw-truncate"
              >
                <i class="fa fa-diamond" aria-hidden="true"></i>
                {{ item.category.name }}
              </router-link>
              <router-link
                :to="{ name: 'jobsShow', params: { id: item.id } }"
                class="bg-orange-2 tw-uppercase tw-rounded tw-p-2 tw-flex-grow-1 tw-flex-shrink-0 tw-ml-2 tw-mt-2 tw-flex tw-items-center"
              >
                <span>view more</span
                ><i class="fa fa-arrow-circle-right tw-ml-1"></i>
              </router-link>
            </div>
          </div>
          <template v-if="data.length < 12 && data.length % 3 === 2">
            <div
              class="tw-p-5 tw-mb-6"
              style="max-width: 356px; width: 356px;"
            ></div>
          </template>
        </div>
        <div>
          <div class="tw-flex tw-justify-center tw-mt-4">
            <div class="tw-my-6">
              <button
                @click="loadMore"
                class="tw-px-4 tw-py-2 tw-rounded tw-uppercase tw-outline-none focus:tw-outline-none"
                :class="
                  loading || noMoreData
                    ? 'tw-bg-gray-100 tw-border-gray-100'
                    : 'border-color-2 bg-orange-2'
                "
                :disabled="loading || noMoreData"
              >
                {{
                  noMoreData
                    ? 'no more data'
                    : loading
                    ? 'loading'
                    : 'load more'
                }}<i
                  class="tw-ml-1 fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                  v-if="!loading"
                ></i>
                <i
                  class="tw-ml-1 fa fa-spinner fa-spin"
                  aria-hidden="true"
                  v-else
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="m-top">
            <div class="tw-flex tw-flex-col tw-items-center tw-mt-8 tw-mx-4">
              <router-link
                to="/jobs/create"
                style="color: #fff;"
                class="tw-shadow-md tw-text-center tw-font-bold tw-uppercase bg-primary tw-px-10 tw-py-4 tw-rounded tw-text-white tw-text-sm tw-w-full"
                ><i class="fa fa-pencil-square-o tw-mr-1" aria-hidden="true"></i
                >Post a new job</router-link
              >
              <div class="tw-border tw-rounded tw-w-full tw-mt-4">
                <input
                  class="tw-outline-none tw-p-2"
                  type="text"
                  v-model="keyword"
                  placeholder="Search"
                />
              </div>
              <div class="tw-relative tw-w-full tw-mt-4">
                <select
                  class="tw-block tw-appearance-none tw-w-full tw-bg-gray-200 tw-border tw-border-gray-200 tw-text-gray-700 tw-py-3 tw-px-3 tw-pr-8 tw-rounded tw-leading-tight tw-outline-none tw-focus:tw-bg-white tw-focus:tw-border-gray-500"
                  v-model="category_id"
                >
                  <option value="0">All</option>
                  <option
                    v-for="(item, i) in categories"
                    :key="`select-category-${i}`"
                    :value="item.id"
                    >{{ item.name }}</option
                  >
                </select>
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700"
                >
                  <svg
                    class="tw-fill-current tw-h-4 tw-w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
              <div class="tw-w-full tw-flex tw-justify-center tw-mt-4">
                <button
                  @click="loadData(true)"
                  class="tw-uppercase tw-ml-2 focus:tw-outline-none tw-leading-tight bg-orange-2 tw-px-4 tw-py-3 tw-rounded tw-text-sm"
                >
                  <i class="fa fa-search tw-mr-1" aria-hidden="true"></i>Search
                </button>
              </div>
            </div>
          </div>
          <div class="tw-mt-12 tw-flex tw-flex-wrap tw-justify-between tw-mx-4">
            <div
              class="tw-p-5 tw-rounded-md tw-mb-6 tw-border"
              style="
                max-width: 100%;
                width: 100%;
                border-color: #ddd;
                box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.07);
              "
              v-for="(item, i) in data"
              :key="`project-${i}`"
            >
              <div class="tw-flex">
                <div class="tw-border tw-shadow tw-rounded">
                  <router-link
                    class="tw-w-20 tw-h-20"
                    :to="{ name: 'userShow', params: { id: item.user.id } }"
                  >
                    <img
                      :src="item.user.avatar"
                      class="tw-w-20 tw-h-20 tw-rounded"
                      style="min-width: 70px;"
                  /></router-link>
                </div>
                <div
                  class="tw-ml-3"
                  :class="
                    item.type === 'mentor-program'
                      ? 'tw-flex tw-flex-col tw-justify-between'
                      : ''
                  "
                >
                  <div class="tw-flex tw-flex-row tw-items-center">
                    <span
                      class="tw-text-xl tw-truncate tw-font-bold"
                      style="max-width: 200px;"
                      ><router-link
                        :to="{ name: 'userShow', params: { id: item.user.id } }"
                        >{{ item.user.name }}</router-link
                      ></span
                    >
                    <template
                      v-if="item.user.organization && item.user.organization.id"
                    >
                      <v-img
                        :src="`https://cdn.jsdelivr.net/gh/beeznests/static@master/dist/img/badge_${item.user.organization.badge}.png`"
                        max-height="18"
                        max-width="18"
                        contain
                        class="align-self-center mr-1 ml-1"
                        :alt="item.user.organization.show_text"
                        :title="item.user.organization.show_text"
                      ></v-img>
                    </template>
                    <div class="tw-w-6 tw-h-6 tw-ml-2 tw-rounded-full">
                      <img :src="item.user.country_flag" />
                    </div>
                  </div>
                  <div
                    class="tw-flex tw-flex-row tw-text-sm"
                    :class="item.type === 'mentor-program' ? '' : 'tw-mt-3'"
                  >
                    <div
                      class="tw-rounded-full tw-px-2 text-gray-1 bg-gray-2 tw-flex-grow-0 tw-flex-shrink tw-flex tw-items-center"
                    >
                      <i class="fa fa-briefcase tw-mr-1" aria-hidden="true"></i>
                      <span>{{ item.user.type | userType }}</span>
                    </div>
                    <div
                      class="tw-rounded-full tw-ml-2 text-gray-1 tw-px-2 bg-gray-2 tw-flex-grow-0 tw-flex-shrink tw-flex tw-items-center"
                    >
                      <i class="fa fa-map-marker tw-mr-1" aria-hidden="true"></i
                      ><span>{{ item.user.country.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="tw-my-3 tw-text-sm tw-font-medium">
                <span>{{
                  item.type === 'job'
                    ? 'Job Description:'
                    : 'Internship Description:'
                }}</span>
                <span class="text-primary tw-ml-2">{{
                  item.position_title
                }}</span>
              </h3>
              <p
                class="text-gray-1 tw-text-sm tw-overflow-hidden tw-h-20 tw-max-h-20 tw-font-rubik"
                v-html="item.summary"
              ></p>
              <div
                class="tw-text-xs tw-flex tw-flex-col tw-justify-between tw-flex-wrap tw-mt-6"
              >
                <div class="tw-flex">
                  <div
                    class="bg-gray-2 tw-rounded tw-p-2 text-gray-2 tw-mr-auto"
                  >
                    <i class="fa fa-calendar text-primary"></i>
                    <span class="tw-font-bold tw-ml-1">Deadline:</span>
                    {{ item.closed_at | formatDate }}
                  </div>
                </div>
                <div class="tw-flex tw-mt-2">
                  <!-- 金额的显示 非训练营项目-->
                  <div class="bg-gray-2 tw-rounded tw-p-2 tw-mr-auto">
                    <i class="fa fa-trophy text-primary"></i>
                    <span class="tw-font-bold tw-ml-1"
                      >Type: {{ item.type }}</span
                    >
                  </div>
                </div>
                <div class="tw-flex tw-mt-2">
                  <div class="bg-gray-2 tw-rounded tw-p-2 tw-mr-auto">
                    <i class="fa fa-clock-o text-primary"></i>
                    <span class="tw-font-bold tw-ml-1">Reward:</span>
                    <template v-if="item.cost">
                      {{ item.cost }}/{{
                        item.type === 'project' ? 'per project' : 'hour'
                      }}
                    </template>
                    <template v-else>TBD</template>
                  </div>
                </div>

                <!--                <div class="tw-flex tw-mt-2">-->
                <!--                  <div-->
                <!--                    class="bg-gray-2 tw-rounded tw-p-2 tw-flex-grow-0 tw-flex-shrink tw-mt-2"-->
                <!--                  >-->
                <!--                    <i class="fa fa-clock-o text-primary"></i>-->
                <!--                    <span class="tw-font-bold tw-ml-1">Duration:</span>-->
                <!--                    {{ item.time_line }}-->
                <!--                    {{ item.type === 'task' ? 'Days' : 'Months' }}-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="tw-border-t tw-mt-6 tw-mb-4"></div>
              <div class="tw-flex tw-justify-between tw-text-sm">
                <router-link
                  to="/"
                  class="bg-gray-2 tw-rounded tw-p-2 tw-flex-grow-0 tw-flex-shrink tw-mt-2 max-line-1 tw-mr-2"
                >
                  <i class="fa fa-diamond" aria-hidden="true"></i>
                  {{ item.category.name }}
                </router-link>
                <router-link
                  :to="{ name: 'jobsShow', params: { id: item.id } }"
                  class="bg-orange-2 tw-uppercase tw-rounded tw-p-2 tw-flex-none tw-mt-2 tw-flex tw-items-center"
                >
                  <span>view more</span
                  ><i class="fa fa-arrow-circle-right tw-ml-1"></i>
                </router-link>
              </div>
            </div>
          </div>
          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
// import CommonHeader from '@/views/new-pages/common/header'
import CommonFooter from '@/views/new-pages/common/footer'
import { fetchIndex } from '@/api/job'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import { mapGetters } from 'vuex'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
import UserBadge from '@/components/User/Badge'

import AcademicTeamLabel from '@/components/Project/AcademicTeamLabel'
export default {
  components: {
    MobileDrawer,
    MobileFooter,
    CommonFooter,
    // CommonHeader,
    MobileHeader,
    UserBadge,
    AcademicTeamLabel,
  },
  computed: {
    ...mapGetters(['isMobile']),
    type() {
      return this.$route.name === 'mentorIndex' ? 'mentor-program' : 'task'
    },
    isProject() {
      return this.$route.name === 'NewProjects'
    },
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
    this.filterType = this.$route.params.type || 'task'
    this.loadData(true)
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  watch: {
    '$route.query.category_id'(category_id) {
      this.category_id = '' + category_id
      this.loadData(true)
    },
    '$route.name'() {
      this.loadData(true)
    },
    filterType(type) {
      this.$router.push({
        name: this.$route.name,
        params: {
          type: type === 'task' ? null : type,
        },
      })
      this.loadData(true)
    },
  },
  data: () => ({
    data: [],
    page: 1,
    last_page: 1,
    noMoreData: false,
    loading: false,
    categories: [],
    category_id: 0,
    keyword: '',
    filterType: 'task',
  }),
  methods: {
    async loadMore() {
      if (this.noMoreData) {
        return
      }
      this.page++
      await this.loadData()
    },
    async loadData(reset = false) {
      if (reset) {
        this.page = 1
        this.data = []
        this.noMoreData = false
      }
      this.loading = true
      const requestData = {
        includes: 'summary',
        page: this.page,
        type: this.type,
      }
      this.keyword ? (requestData.position = this.keyword) : null
      this.category_id > 0 ? (requestData.category_id = this.category_id) : null
      if (this.type === 'task') {
        requestData.type = this.filterType
      }
      const res = await fetchIndex(requestData)
      this.data = reset ? res.data : this.data.concat(res.data)
      this.noMoreData = res.meta.last_page === this.page
      this.loading = false
      if (reset) {
        this.categories = res.categories
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.border-color-primary {
  border-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-5 {
  background-color: #fff9f1;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
.text-gray-2 {
  color: #031226;
}
.bg-main {
  background-color: #f5f9ff;
}
.bg-profile {
  background-image: url('~@/assets/images/profile-top.png');
}
.bg-blue-1 {
  background-color: #e1f6ff;
}
.text-blue-1 {
  color: #03a9f4;
}
.bg-blue-2 {
  background-color: #f0eff9;
}
.text-blue-2 {
  color: #1600db;
}
</style>
