<template>
  <v-card>
    <v-card-text>
      <v-list-item>
        <v-list-item-avatar>
          <router-link :to="{ name: 'userShow', params: { id: data.id } }">
            <v-avatar>
              <v-img :src="data.avatar" />
            </v-avatar>
          </router-link>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.name }}</v-list-item-title>
        </v-list-item-content>
        <v-row justify="end">
          <v-btn
            icon
            depressedclass="white--text"
            :loading="isLoading(data.id)"
            @click="isFollowed(data.id) ? unfollow(data.id) : follow(data.id)"
            :color="isFollowed(data.id) ? 'orange' : ''"
            ><v-icon class="mr-1">mdi-account-heart-outline</v-icon></v-btn
          >
        </v-row>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    isFollowed(id) {
      const index = this.$store.state.follow.data.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.data[index].followed
      return false
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    async follow(id) {
      await this.$store.dispatch('follow/follow', id)
      this.$emit('followed', id)
    },
    async unfollow(id) {
      await this.$store.dispatch('follow/unfollow', id)
      this.$emit('unfollow', id)
    },
  },
}
</script>
