import axios from '@/plugins/axios'
import ls from '@/plugins/ls'
export const logout = () => {
  return axios.delete('authorizations')
}

export const login = (data) => {
  return axios.post('authorizations', data)
}
export const register = (data) => {
  const code = ls.get('invite_code')
  if (code) {
    data.invite_code = code
  }
  return axios.post('register', data)
}

export const resetEmail = (data) => {
  return axios.post('password/email', data)
}

export const me = () => {
  return axios.get('user_info')
}

export const csrfCookie = () => {
  return axios.get('csrf-cookie')
}
