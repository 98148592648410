import ls from '@/plugins/ls'
import moment from 'moment'
import {
  login as loginRequest,
  register as registerRequest,
  logout as logoutRequest,
  me,
} from '@/api/auth'
import { get } from 'lodash'

const state = {
  loggedIn: ls.get('loggedIn', false),
  redirectTo: '/',
  backTo: '/', // 返回按钮跳转的页面, 始终为打开登录页时的来路页面
  userInfo: ls.get('userInfo', null),
  loginLoading: false,
  token: ls.get('token', null),
  expires_in: ls.get('expires_in', null),
  emailVerify: ls.get('email_verify', false),
}

const mutations = {
  SET_LOGGED(state, logged) {
    const isLogged = typeof logged === 'undefined' ? true : logged
    ls.set('loggedIn', isLogged)
    state.loggedIn = isLogged
  },
  SET_REDIRECT_TO(state, fullPath) {
    state.redirectTo = fullPath
  },
  SET_BACK_TO(state, fullPath) {
    state.backTo = fullPath
  },
  SET_USER_INFO(state, userInfo) {
    if (userInfo && !get(userInfo, 'identityable', false)) {
      userInfo.identityable = {}
    }
    ls.set('userInfo', userInfo)
    state.userInfo = userInfo
  },
  SET_TOKEN(state, token) {
    ls.set('token', token)
    state.token = token
  },
  TOGGLE_LOADING(state) {
    state.loginLoading = !state.loginLoading
  },
  SET_EXPIRES_IN(state, seconds) {
    if (seconds) {
      const expires_in = moment().add(seconds, 'seconds').format()
      state.expires_in = expires_in
      ls.set('expires_in', expires_in)
    } else {
      state.exipres_in = null
      ls.remove('expires_in')
    }
  },
  SET_EMAIL_VERIFY(state, verify) {
    if (verify) {
      ls.set('email_verify', verify)
      state.emailVerify = verify
    } else {
      state.emailVerify = null
      ls.remove('email_verify')
    }
  },
}

const actions = {
  saveToken({ commit }, { access_token, expires_in }) {
    commit('SET_TOKEN', access_token)
    commit('SET_EXPIRES_IN', expires_in)
  },
  async logout({ commit, dispatch }) {
    await logoutRequest()
    dispatch('clearUserInfo')
  },
  clearUserInfo({ commit }) {
    commit('SET_LOGGED', false)
    commit('SET_USER_INFO', null)
    commit('SET_TOKEN', null)
    commit('SET_EXPIRES_IN', null)
    commit('SET_EMAIL_VERIFY', false)
  },
  async login({ commit, dispatch }, loginData) {
    commit('TOGGLE_LOADING')
    try {
      const { data } = await loginRequest(loginData)
      if (data.id) {
        commit('SET_LOGGED')
        commit('SET_USER_INFO', data)
        commit('SET_EMAIL_VERIFY', data.email_verified_at)
        return true
      }
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('TOGGLE_LOADING')
    }
  },
  async register({ commit }, registerData) {
    commit('TOGGLE_LOADING')
    try {
      const { data } = await registerRequest(registerData)
      if (data.id) {
        commit('SET_LOGGED')
        commit('SET_USER_INFO', data)
        commit('SET_EMAIL_VERIFY', data.email_verified_at)
        return true
      }
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('TOGGLE_LOADING')
    }
  },
  async isLogged({ commit, dispatch }) {
    try {
      const { data } = await me()
      if (data.id) {
        commit('SET_LOGGED')
        commit('SET_USER_INFO', data)
        commit('SET_EMAIL_VERIFY', data.email_verified_at)
        return true
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        dispatch('clearUserInfo')
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
