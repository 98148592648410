<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="time"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="time"
      scrollable
      format="24hr"
      @click:minute="$refs.menu.save(time)"
      @click:hour="$refs.menu.save(time)"
    >
    </v-time-picker>
  </v-menu>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    value: {
      type: String,
      default() {
        return moment().format('HH:mm:ss')
      },
    },
    label: {
      type: String,
      default: 'Deadline',
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    time: {
      set(newTime) {
        this.$emit('input', newTime)
      },
      get() {
        return this.value
      },
    },
  },
}
</script>
