<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" lg="10" xl="8" class="mt-5">
      <v-row>
        <v-col cols="12" md="9">
          <v-card>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="article"
            ></v-skeleton-loader>
            <template v-else-if="data.id">
              <v-card-title>
                <h3 class="text-h4">
                  {{ data.position_title }}({{
                    data.type === 'internship' ? 'Internship' : 'Job'
                  }})
                </h3>
                <v-list-item class="px-0 overflow-hidden">
                  <v-list-item-avatar size="48">
                    <router-link
                      :to="{
                        name: 'userIndex',
                        params: { id: data.user.id },
                      }"
                      style="text-decoration: none;"
                    >
                      <v-avatar size="48">
                        <v-img
                          class="elevation-6"
                          :src="data.user.avatar"
                        ></v-img>
                      </v-avatar>
                    </router-link>
                  </v-list-item-avatar>

                  <v-list-item-content
                    class="flex-shrink-1"
                    style="overflow: hidden;"
                  >
                    <user-name
                      :name="data.user.name"
                      :type="data.user.type"
                      :id="data.user.id"
                      :organization="data.user.organization"
                    >
                      <template v-slot:before
                        ><v-img
                          :src="data.user.country_flag"
                          max-height="24"
                          max-width="24"
                          contain
                          class="align-self-center mr-1"
                        ></v-img
                      ></template>
                    </user-name>
                    <small class="body-2 mr-2 caption">{{
                      data.created_at | format
                    }}</small>
                  </v-list-item-content>
                </v-list-item>
              </v-card-title>
              <v-card-subtitle class="text-h6">
                Company: {{ data.company_name }}
              </v-card-subtitle>
              <v-card-subtitle class="text-h6">
                {{ data.type === 'internship' ? 'Internship' : 'Job' }}
                Description:
              </v-card-subtitle>

              <v-card-text v-html="$replaceEmpty(data.description)">
              </v-card-text>
              <template>
                <v-card-subtitle class="text-h6">
                  About the company:
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.about_company)">
                </v-card-text>
              </template>
              <template v-if="data.what_you_will_do">
                <v-card-subtitle class="text-h6">
                  What you will do:
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.what_you_will_do)">
                </v-card-text>
              </template>
              <template v-if="data.skills_experience">
                <v-card-subtitle class="text-h6">
                  <skill
                    :is-project="data.type !== 'mentor-program'"
                    :type="data.type"
                    :provide-solution="data.provide_solution"
                  ></skill>
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.skills_experience)">
                </v-card-text>
              </template>
              <template v-if="data.work_with">
                <v-card-subtitle class="text-h6">
                  <work-with-label
                    :is-project="data.type !== 'mentor-program'"
                    :type="data.type"
                    :provide-solution="data.provide_solution"
                  ></work-with-label>
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.work_with)">
                </v-card-text>
              </template>

              <v-card-text>
                <v-sheet class="pt-n4">
                  <span class="primary--text mr-1">Deadline:</span>
                  {{ data.closed_at | format }}
                </v-sheet>
                <v-sheet v-if="data.type === 'internship'">
                  <span class="primary--text mr-1"
                    >Duration of the Internship:</span
                  >
                  {{ data.internship_duration || '1' }} Months
                </v-sheet>
                <!--学术团队的价格-->
                <!--v-sheet v-else-if="data.type === 'academic-team'">
                  <span class="primary--text mr-1">Total budget:</span>
                  <template v-if="!data.is_government_subsidy">
                    ${{ data.cost_min * data.time_line }}
                  </template>
                  <template v-else-if="data.total_cost > 0">
                    ${{ data.total_cost }}
                  </template>
                  <template v-else> ${{ data.cost_max }}</template>
                </v-sheet-->

                <v-sheet>
                  <span class="primary--text mr-1">Remote: </span
                  >{{ data.remote === 'yes' ? 'Yes' : 'No' }}</v-sheet
                >
                <v-sheet class="pt-n4">
                  <span class="primary--text mr-1">College Availability:</span>
                  {{ data.college_availability }}
                </v-sheet>
                <v-sheet class="pt-n4">
                  <span class="primary--text mr-1">Company Name:</span>
                  {{ data.company_name }}
                </v-sheet>
              </v-card-text>
              <v-card-actions
                v-if="
                  (!isLoggedIn || userInfo.id !== data.user.id) &&
                  !data.is_closed &&
                  (data.status === 'applying' || data.status === 'published') &&
                  !data.is_applied
                "
              >
                <v-row justify="center" no-gutters>
                  <v-col cols="12">
                    <v-row justify="center" no-gutters>
                      <v-col cols="auto">
                        <v-btn
                          depressed
                          color="deep-orange"
                          class="white--text"
                          @click="applyNow"
                          >Apply Now</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
            </template>
            <v-alert v-else text outlined color="deep-orange" icon="mdi-cancel">
              Job not found
              <v-divider
                class="my-4 deep-orange"
                style="opacity: 0.22;"
              ></v-divider>
              <v-row align="center" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="orange"
                    class="mr-1"
                    outlined
                    @click="$router.push({ name: 'jobsIndex' })"
                  >
                    Go to jobs list
                  </v-btn>
                  <v-btn color="orange" outlined @click="$router.go(-1)">
                    Back
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
          <v-row no-gutters class="mt-5" v-if="data.id">
            <v-col cols="12" class="title mt-4">Current Applicants</v-col>
            <v-col cols="12">
              <Student
                v-for="user in applicants"
                :user="user"
                :key="user.id"
                :is-publisher="isLoggedIn && data.user_id === userInfo.id"
              />
              <v-skeleton-loader
                v-if="applicantsLoading"
                class="mx-auto"
                type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
          <v-card
            class="mx-auto"
            tile
            v-else-if="isLoggedIn && data.user.id === userInfo.id"
          >
            <v-card-title>Actions</v-card-title>
            <v-list rounded nav dense>
              <v-list-item-group
                v-if="isLoggedIn && userInfo.id === data.user.id"
                color="deep-orange"
              >
                <v-list-item @click="$router.push(`/jobs/${data.id}/edit`)">
                  <v-list-item-icon
                    ><v-icon
                      >mdi-file-document-edit-outline</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      Edit
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteJob">
                  <v-list-item-icon>
                    <v-icon v-if="!deleteLoading">mdi-trash-can-outline</v-icon>
                    <v-progress-circular
                      v-else
                      indeterminate
                      size="28"
                    ></v-progress-circular>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Delete
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group
                v-else-if="
                  isLoggedIn && !data.is_closed && data.status === 'applying'
                "
              >
                <v-list-item @click="showApply">
                  <v-list-item-icon
                    ><v-icon
                      >mdi-calendar-check-outline</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      Apply
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <user-info-card :user="data.user" v-else></user-info-card>
          <v-card
            class="mt-4"
            v-if="isLoggedIn && userInfo.id === data.user.id"
          >
            <v-alert>
              Don't forget, once you hire a student, you can leave them a
              performance review at any time. <br />
              This function will encourage students to do the best job they can
              so that you can leave them an amazing review!
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="userGotoPaymentDialog" max-width="290"
      ><v-card>
        <!--        <v-card-title class="headline grey lighten-2" primary-title>-->
        <!--          Privacy Policy-->
        <!--        </v-card-title>-->
        <v-card-title class="headline">You cannot apply</v-card-title>
        <v-card-text>
          All the exclusive job opportunities are only available for premium
          member.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="gotoPaymentPage">
            become premium member
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { apply, deleteJobRequest, fetchApplicants, fetchData } from '@/api/job'
import { mapGetters } from 'vuex'
import UserInfoCard from '@/components/User/Info'
import Skill from '@/components/Project/Label/skill'
import WorkWithLabel from '@/components/Project/Label/WorkWithLabel'
import Student from '@/views/jobs/Components/Student'

export default {
  components: {
    Student,
    WorkWithLabel,
    Skill,
    UserInfoCard,
  },
  data: () => ({
    data: {
      user: {
        country: {},
      },
    },
    pages: {
      current_page: 1,
      last_page: 1,
    },
    loading: false,
    applicantsLoading: false,
    applicants: [],
    userGotoPaymentDialog: false,
    deleteLoading: false,
  }),
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    ...mapGetters(['userInfo', 'isLoggedIn', 'isAdmin']),
  },
  async mounted() {
    await this.loadData()
    this.loadApplicants()
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        let includes =
          'is_applied,is_own,content,who,work_with,skills,about_mentor,policy,agenda,can_apply'
        const { data } = await fetchData(this.id, {
          includes,
        })
        this.data = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async applyNow() {
      if (!this.data.can_apply) {
        this.showUserPaymentDialog()
        return
      }
      await apply(this.id)
      this.$message('Apply Successful!', 'success')
      this.loadApplicants()
    },
    showUserPaymentDialog() {
      this.userGotoPaymentDialog = true
    },
    gotoPaymentPage() {
      this.$router.push({
        name: 'PaymentUserCost',
      })
    },
    async loadApplicants() {
      if (this.applicantsLoading) return
      this.applicantsLoading = true
      try {
        const { data } = await fetchApplicants(this.id)
        this.applicants = data
      } finally {
        this.applicantsLoading = false
      }
    },
    async deleteJob() {
      if (this.deleteLoading) {
        return
      }
      this.deleteLoading = true
      try {
        await deleteJobRequest(this.id)
        this.$router.push({ name: 'jobsIndex' })
        this.$message('Deleted!')
      } catch {
        void 0
      } finally {
        this.deleteLoading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.v-card__text {
  ::v-deep {
    p {
      a {
        text-decoration: underline;
      }
    }
  }
}
</style>
