export default {
  path: '/',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '403',
      name: 'unable',
      component: () => import(/* webpackChunkName: 'unable' */'@/views/Unable'),
      meta: {
        fill: true,
      },
    },
  ],
}
