import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'
import moment from 'moment'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Time-Zone'] = moment().utcOffset()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      return { status_code: response.status, ...response.data }
    }
    return response
  },
  async (error) => {
    if (
      error.response.data.message === 'Unauthenticated.' &&
      error.config.url !== 'user_info'
    ) {
      router.push({ name: 'AuthLogin' })
    }
    if (error.response.status === 404) {
      store.dispatch('message/pushMessage', {
        type: 'error',
        content: 'Net Work Error',
        timeout: 3000,
      })
    } else if (
      (error.response.status === 422 ||
        error.response.status === 403 ||
        error.response.status === 401) &&
      error.config.url !== 'user_info'
    ) {
      store.dispatch('message/pushMessage', {
        type: 'error',
        content: error.response.data.message,
        timeout: 5000,
      })
    } else {
      if (error.request.readyState === 4 && error.request.status === 0) {
        store.dispatch('message/pushMessage', {
          type: 'error',
          content: 'Request timeout, Please retry!',
          timeout: 3000,
        })
      }
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default service
