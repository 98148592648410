<template>
  <v-row @click="setting">
    <v-col cols="12" class="py-0">
      <v-hover v-slot:default="{ hover }">
        <v-row
          :class="`${
            showHover ? (hover ? 'grey lighten-4 pointer' : ' pointer') : ''
          } mx-n4`"
        >
          <v-col
            cols="12"
            md="3"
            class="user-no-select px-6 py-4 py-md-0"
            align-self="center"
            >{{ label }}</v-col
          >
          <v-col clos="12" md="9" class="d-flex py-4 px-6">
            <slot>{{ value }}</slot>
            <slot name="append-icon">
              <div class="ml-auto align-self-center" v-if="showIcon">
                <v-icon>{{ disabled ? 'mdi-lock' : icon }}</v-icon>
              </div>
            </slot>
          </v-col>
        </v-row>
      </v-hover>
    </v-col>
    <v-col class="py-0" v-if="divider">
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: [String, Array, Object, Number],
      default() {
        return ''
      },
    },
    showHover: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'mdi-chevron-right',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  methods: {
    setting() {
      this.$emit('update')
    },
  },
}
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
