<template>
  <v-col cols="12">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <div ref="container"></div>
        <loading
          v-if="loading || data.length <= 0"
          :loading="loading"
          no-data="Nothing"
        ></loading>
        <transition-group name="flip-list" tag="div" v-else>
          <template v-for="item in data">
            <v-sheet :key="`portfolio-${item.id}-title`">
              <div class="py-4 d-flex justify-space-between">
                <div class="title">{{ item.title }}</div>
                <v-btn
                  depressed
                  text
                  :to="{ name: 'portfolioShow', params: { id: item.id } }"
                  >Detail</v-btn
                >
              </div>
              <div
                class="images"
                v-if="item.boxes.length > 0"
                :style="{ height: item.boxes[0].height + 'px' }"
              >
                <template v-for="(image, index) in item.boxes">
                  <div
                    v-if="image.url !== ''"
                    :key="`p-${item.id}-image-preview-${index}`"
                    :style="{
                      position: 'absolute',
                      left: image.left + 'px',
                      top: image.top + 'px',
                      width: image.width + 'px',
                      height: image.height + 'px',
                    }"
                  >
                    <v-img
                      :src="item.attachments[index].url"
                      :width="image.width"
                      :height="image.height"
                      contain
                    ></v-img>
                  </div>
                </template>
              </div>
            </v-sheet>
          </template>
        </transition-group>
      </v-col>
      <v-col cols="12" xl="8" v-if="pages.last_page > 1">
        <v-pagination
          v-model="pages.current_page"
          :length="pages.last_page"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import { fetchUserIndex, destroy } from '@/api/portfolio'
import { mapGetters } from 'vuex'
import justifiedLayout from 'justified-layout'
import Loading from '@/components/Loading'
export default {
  components: {
    Loading,
  },
  mounted() {
    this.containerWidth = this.$refs.container.offsetWidth
    this.loadData(true)
  },
  computed: {
    ...mapGetters(['userInfo']),
    user_id() {
      return this.$route.params.id
    },
  },
  watch: {
    'pages.current_page'() {
      this.loadData()
    },
  },
  data: () => ({
    data: [],
    pages: {
      current_page: 1,
      last_page: 1,
      per_page: 5,
    },
    noMoreData: false,
    loading: false,
    containerWidth: 1280,
    deleteLoadings: [],
  }),
  methods: {
    async loadData(reset = false) {
      try {
        this.loading = true
        const requestData = {}
        requestData.per_page = this.pages.per_page
        if (reset) {
          requestData.page = 1
        } else {
          requestData.page = this.pages.current_page
        }
        const { meta, data } = await fetchUserIndex(this.user_id, requestData)
        data.forEach((item, index) => {
          if (item.attachments.length > 0) {
            data[index].boxes = this.counterSize(item.attachments)
          } else {
            data[index].boxes = []
          }
          data.deleteLoading = false
        })
        this.data = data
        this.pages.current_page = meta.current_page
        this.pages.last_page = meta.last_page
        if (requestData.page === meta.last_page) this.noMoreData = true
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    async deletePortfolio(id) {
      try {
        const index = this.data.findIndex((item) => item.id === id)
        this.$set(this.data[index], 'deleteLoading', true)
        await destroy(id)
        this.data.splice(index, 1)
      } catch (e) {
        const index = this.data.findIndex((item) => item.id === id)
        this.$set(this.data[index], 'deleteLoading', false)
      }
    },
    counterSize(data) {
      const { boxes } = justifiedLayout(data, {
        containerWidth: this.containerWidth,
        containerPadding: {
          top: 0,
          right: 0,
          bottom: 20,
          left: 0,
        },
        boxSpacing: {
          horizontal: 18,
          vertical: 18,
        },
        maxNumRows: 1,
        targetRowHeight: 180,
      })
      return boxes
    },
  },
}
</script>
<style lang="scss" scoped>
.images {
  height: 200px;
  position: relative;
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>
