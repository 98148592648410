<template>
  <div
    class="tw-ml-1 bg-gray-2 tw-px-2 py-1 tw-rounded-full text-gray-1 tw-text-sm tw-cursor-pointer tw-relative"
    style="overflow: visible;"
    @mouseover="show = true"
    @mouseleave="show = false"
  >
    Academic team
    <div
      class="tw-p-2 tw-bg-white tw-shadow-md tw-absolute tw-rounded tw-mt-1 tw-flex tw-duration-100 tw-transition"
      style="
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
        max-width: 320px;
        width: 320px;
      "
      v-show="show && showText"
    >
      <div class="" style="white-space: normal;">
        {{ showText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProjectType',
  props: {
    type: {
      type: String,
      default: 'task',
    },
  },
  computed: {
    showText() {
      return 'Upon completion of bootcamp, you will have the opportunity to apply for larger-scaled complex projects with your mentor if they select you.'
    },
  },
  data: () => ({
    show: false,
  }),
}
</script>
