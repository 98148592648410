<template>
  <v-row justify="center">
    <v-col cols="12" md="10" xl="8" lg="6">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="9">
          <div class="tw-w-full tw-max-w-7xl tw-mx-auto">
            <v-card>
              <v-card-title>
                Post a new job
              </v-card-title>
              <!--              <v-card-text>-->
              <!--                <v-alert type="warning">-->
              <!--                  Please note that the $100 posting fee is fully refundable.-->
              <!--                  Once you successfully hire a student through Beeznests, please-->
              <!--                  go back to your job posting page and click on the “hire”-->
              <!--                  button under certain students you want to hire. After we-->
              <!--                  verified the student is hired, we will offer a $100 refund in-->
              <!--                  3-5 business days.-->
              <!--                </v-alert>-->
              <!--              </v-card-text>-->
              <v-card-text>
                <form>
                  <label class="my-2">Type of Opportunity:</label>
                  <v-radio-group
                    v-model="data.type"
                    :readonly="isEdit"
                    :disabled="isEdit"
                  >
                    <v-radio value="internship">
                      <template v-slot:label>
                        <div>
                          Paid Internship: Full-Time (4 - 12 Months) Pay per
                          hour
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="job">
                      <template v-slot:label>
                        <div>
                          Paid Job: Full-Time paid job positions
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="project">
                      <template v-slot:label>
                        <div>
                          Paid Project
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <v-text-field
                    v-model="data.position_title"
                    :counter="255"
                    label="Position Title"
                    required
                  ></v-text-field>
                  <v-slider
                    v-model="data.internship_duration"
                    :label="`Duration of the ${showName}`"
                    min="1"
                    :max="durationMax"
                    thumb-label
                    required
                    v-if="data.type !== 'job'"
                  >
                    <template v-slot:append>
                      {{ data.internship_duration + showDurationText }}
                    </template>
                  </v-slider>
                  <v-text-field
                    v-model="data.company_name"
                    :counter="255"
                    label="Company Name"
                    required
                  ></v-text-field>

                  <v-select
                    v-model="data.college_availability"
                    multiple
                    :items="collegeAvailability"
                    label="College Availability"
                    required
                    item-text="name"
                    item-value="name"
                    hint="(only students from the college you choose will be able to apply)"
                    persistent-hint
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="checkItem(item)"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="
                              data.college_availability.indexOf(item.name) > -1
                            "
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                  <v-select
                    v-model="data.category_id"
                    :items="categories"
                    label="Category"
                    data-vv-name="category"
                    required
                    item-text="name"
                    item-value="id"
                    :loading="categoryLoading"
                  >
                  </v-select>
                  <date-picker
                    v-model="data.closed_at"
                    label="Application Deadline"
                  ></date-picker>
                  <!--                  <date-picker-->
                  <!--                    v-model="data.position_start_date"-->
                  <!--                    label="Position Start Date"-->
                  <!--                  ></date-picker>-->
                  <v-switch
                    v-model="data.remote"
                    class="mx-2"
                    :label="data.remote ? 'Remote' : 'Onsite'"
                  ></v-switch>
                  <v-col cols="12" sm="10" md="8" lg="4">
                    <v-text-field
                      label="Reward"
                      prepend-inner-icon="mdi-currency-usd"
                      v-model="data.cost"
                      :suffix="`.00/${isProject ? 'per project' : 'hour'}`"
                    ></v-text-field>
                  </v-col>
                  <v-sheet class="py-6">
                    <label>About the company:</label>
                  </v-sheet>
                  <froala v-model="data.about_company"></froala>
                  <v-sheet class="py-6">
                    <label>{{ showName }} Description</label>
                  </v-sheet>
                  <froala v-model="data.description"></froala>
                  <v-sheet class="py-6">
                    <label>What you will do</label>
                  </v-sheet>
                  <froala v-model="data.what_you_will_do"></froala>
                  <v-sheet class="py-6">
                    <label>Skills and experience you possess</label>
                  </v-sheet>
                  <froala v-model="data.skills_experience"></froala>
                  <v-sheet class="py-6">
                    <label>Who you'll work with</label>
                  </v-sheet>
                  <froala v-model="data.work_with"></froala>
                </form>
              </v-card-text>
              <v-card-actions>
                <v-btn class="ml-3" color="primary" depressed @click="save"
                  >save</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { fetchAll } from '@/api/category'
import Froala from '@/components/Froala'
import DatePicker from '@/components/DatePicker'
import { create, editJob, fetchData } from '@/api/job'

import moment from 'moment'
export default {
  components: {
    Froala,
    DatePicker,
  },
  data: () => ({
    data: {
      closed_at: moment().add(1, 'months').format('YYYY-MM-DD'),
      remote: 'yes',
      type: 'internship',
      internship_duration: 1,
      position_title: undefined,
      company_name: undefined,
      position_start_date: moment().format('YYYY-MM-DD'),
      about_company: undefined,
      description: undefined,
      what_you_will_do: undefined,
      skills_experience: undefined,
      work_with: undefined,
      category_id: undefined,
      college_availability: [],
      cost: null,
    },
    categories: [],
    categoryLoading: false,
    storeLoading: false,
    collegeAvailability: [
      // {
      //   name: 'ITD',
      //   description:
      //     'Institute of Technology Development of Canada is a tech based collage based in Vancouver',
      // },
      {
        name: 'CICCC',
        description:
          'Cornerstone International Community College of Canada is one of the most prominent private colleges in Vancouver, Canada. Specializing in tech and marketing management diploma programs',
      },
      {
        name: 'ILAC',
        description:
          'ILAC International College is a leading Private Career College that offers high-quality career focused programs in Toronto and Vancouver.',
      },
      {
        name: 'Tamwood international college',
        description: 'Tamwood international college',
      },
      // {
      //   name: 'UBC',
      //   description: '',
      // },
      {
        name: 'Beeznests',
        description:
          'Beeznests is located in Vancouver and offers professional training for college students',
      },
      // {
      //   name: 'etc',
      //   description: '',
      // },
    ],
  }),
  computed: {
    showName() {
      return 'Job/Internship'
    },
    isEdit() {
      return this.$route.params.id
    },
    showDurationText() {
      return this.data.type === 'project'
        ? 'Days'
        : this.data.internship_duration > 1
        ? 'Months'
        : 'Month'
    },
    durationMax() {
      return this.data.type === 'project' ? 360 : 12
    },
    isProject() {
      return this.data.type === 'project'
    },
  },
  mounted() {
    this.loadCategories()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    async loadData() {
      try {
        const { data } = await fetchData(this.$route.params.id)
        this.data = {
          closed_at: data.closed_at,
          remote: data.remote,
          type: data.type,
          internship_duration: data.internship_duration,
          position_title: data.position_title,
          company_name: data.company_name,
          position_start_date: data.position_start_date,
          about_company: data.about_company,
          description: data.description,
          what_you_will_do: data.what_you_will_do,
          skills_experience: data.skills_experience,
          work_with: data.work_with,
          category_id: data.category_id,
          college_availability: data.college_availability
            ? data.college_availability.split()
            : [],
          cost: data.cost,
        }
      } catch {
        void 0
      }
    },
    async loadCategories() {
      try {
        this.categoryLoading = 'info'
        const { data } = await fetchAll({})
        this.categories = data
      } catch (e) {
        console.log(e)
      } finally {
        this.categoryLoading = false
      }
    },
    async save() {
      if (this.storeLoading) return
      this.storeLoading = true
      try {
        const postData = Object.assign({}, this.data)
        postData.college_availability = postData.college_availability.toString()
        if (this.isEdit) {
          await editJob(this.$route.params.id, postData)
          this.$router.push({
            name: 'jobsShow',
            params: { id: this.$route.params.id },
          })
          return
        }
        const responseData = await create(postData)
        if (
          responseData.data &&
          responseData.data.order &&
          responseData.data.order.id
        ) {
          this.$router.push({
            name: 'Pay',
            params: { no: responseData.data.order.no },
          })
        } else {
          this.$router.push({ name: 'jobsIndex' })
        }
        // this.$router.push({ name: 'Pay', params: { no: data.no } })
        // this.$router.push({ name: 'jobsIndex' })
      } catch (e) {
        console.log(e)
      } finally {
        this.storeLoading = false
      }
    },
    checkItem(item) {
      const _index = this.data.college_availability.findIndex(
        (i) => i === item.name
      )
      if (_index > -1) {
        this.data.college_availability.splice(_index, 1)
      } else {
        this.data.college_availability.push(item.name)
      }
    },
  },
}
</script>
