<template>
  <div
    class="bg-gray-2 tw-rounded tw-p-2 tw-flex-grow-0 tw-flex-shrink tw-mt-2 tw-cursor-pointer tw-relative"
    style="overflow: visible;"
    @mouseover="show = true"
    @mouseleave="show = false"
  >
    <i class="fa fa-clock-o text-primary"></i>
    <span class="tw-font-bold tw-ml-1">Type:</span>
    {{
      type === 'task'
        ? 'Project'
        : type === 'internship'
        ? 'Internship'
        : 'Academic team'
    }}
    <div
      class="tw-p-2 tw-bg-white tw-shadow-md tw-absolute tw-rounded tw-mt-1 tw-flex tw-duration-100 tw-transition"
      style="
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
        max-width: 320px;
        width: 320px;
      "
      v-show="show && showText"
    >
      <div class="" style="white-space: normal;">
        {{ showText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProjectType',
  props: {
    type: {
      type: String,
      default: 'task',
    },
  },
  computed: {
    showText() {
      return this.type === 'task'
        ? ''
        : this.type === 'internship'
        ? ''
        : 'Only eligible for Team Leads to apply. To form an Academic Team, students must first participate in our mentorship programs and request the mentor to form a team as Team Lead.'
    },
  },
  data: () => ({
    show: false,
  }),
}
</script>
