<template>
  <div>
    <froala :tag="'textarea'" :config="config" v-model="content"></froala>
  </div>
</template>

<script>
import 'froala-editor/js/plugins.pkgd.min.js'
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min'
import 'froala-editor/js/third_party/font_awesome.min'
import 'froala-editor/js/third_party/spell_checker.min'
import 'froala-editor/js/plugins/image.min'
import 'froala-editor/js/plugins/fullscreen.min'
import 'froala-editor/js/plugins/colors.min'
import 'froala-editor/js/plugins/image_manager.min'
import 'froala-editor/js/plugins/file.min'
import 'froala-editor/js/plugins/files_manager.min'
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
import Vue from 'vue'
Vue.use(VueFroala)
const uploadPrefix = process.env.VUE_APP_BASE_URL
const licenseKey = process.env.VUE_APP_FROALA_KEY
export default {
  name: 'app',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  created() {
    this.content = this.value
  },
  data() {
    return {
      config: {
        key: licenseKey,
        events: {
          'froalaEditor.initialized': function () {
            console.log('initialized')
          },
          'image.beforeUpload': function (images) {
            // Do something here.
            // this is the editor instance.
          },
        },
        requestWithCredentials: true,

        imageUploadParam: 'upload_file',

        // Set the image upload URL.
        imageUploadURL: uploadPrefix + 'edit/upload',
        imageManagerLoadURL: uploadPrefix + 'edit/files',

        imageManagerDeleteURL: uploadPrefix + 'edit/delete',

        // Set request type.
        imageUploadMethod: 'POST',

        // Set max image size to 5MB.
        imageMaxSize: 5 * 1024 * 1024,

        // Allow to upload PNG and JPG.
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],

        // File
        fileUploadURL: uploadPrefix + 'edit/upload',
        fileUploadParam: 'upload_file',

        fontFamilySelection: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        emoticonsButtons: ['emoticonsBack', '|'],
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertVideo',
              'insertTable',
              'emoticons',
              'fontAwesome',
              'specialCharacters',
              'embedly',
              'insertFile',
              'insertHR',
            ],
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              'fullscreen',
              'print',
              'getPDF',
              'spellChecker',
              'selectAll',
              'html',
              'help',
            ],
            align: 'right',
            buttonsVisible: 2,
          },
        },
      },
      content: '',
    }
  },
  watch: {
    content(newContent) {
      this.$emit('input', newContent)
    },
    value(newValue) {
      this.content = newValue
    },
  },
}
</script>
