<template>
  <v-col cols="12">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" xl="8" class="mt-4">
            <div class="d-flex">
              <div class="title">Reviews as Employer</div>
              <div class="d-flex ml-4">
                <v-icon color="orange">mdi-star</v-icon>
                <span
                  class="align-self-center orange--text font-weight-bold text-h6"
                  >{{ rating }}</span
                >
              </div>
              <span class="ml-2 grey--text text--darken-3 align-self-center">
                ({{ totalReviews }})
              </span>
            </div>
          </v-col>
          <v-col cols="12" xl="8">
            <div class="d-flex justify-lg-space-between">
              <div>
                <div class="text-subtitle-1">Communication</div>
                <div class="d-flex ml-4">
                  <v-icon color="orange">mdi-star</v-icon>
                  <span
                    class="align-self-center orange--text font-weight-bold text-h6"
                    >{{ communication }}</span
                  >
                </div>
              </div>
              <div>
                <div class="text-subtitle-1">Teamwork</div>
                <div class="d-flex ml-4">
                  <v-icon color="orange">mdi-star</v-icon>
                  <span
                    class="align-self-center orange--text font-weight-bold text-h6"
                    >{{ teamwork }}</span
                  >
                </div>
              </div>
              <div>
                <div class="text-subtitle-1">Punctuality</div>
                <div class="d-flex ml-4">
                  <v-icon color="orange">mdi-star</v-icon>
                  <span
                    class="align-self-center orange--text font-weight-bold text-h6"
                    >{{ punctuality }}</span
                  >
                </div>
              </div>
              <div>
                <div class="text-subtitle-1">Problem-solving</div>
                <div class="d-flex ml-4">
                  <v-icon color="orange">mdi-star</v-icon>
                  <span
                    class="align-self-center orange--text font-weight-bold text-h6"
                    >{{ problem_solving }}</span
                  >
                </div>
              </div>
              <div>
                <div class="text-subtitle-1">Creativity</div>
                <div class="d-flex ml-4">
                  <v-icon color="orange">mdi-star</v-icon>
                  <span
                    class="align-self-center orange--text font-weight-bold text-h6"
                    >{{ creativity }}</span
                  >
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" xl="8">
            <template v-if="data.length > 0">
              <item
                v-for="(item, index) in data"
                :data="item"
                :key="`${item.id}`"
                :class="{ 'mt-5': index > 0 }"
              ></item>
            </template>
            <v-alert type="error" v-if="!loading && loadError" class="mt-5">
              Load data error.
            </v-alert>
            <v-alert type="info" v-if="!loading && noData" class="mt-5">
              Nothing found here.
            </v-alert>
            <v-skeleton-loader
              ref="skeleton"
              type="article, article, article, article, article"
              class="mx-auto mt-5"
              v-if="loading"
            ></v-skeleton-loader>
            <v-sheet v-intersect="onIntersect" v-if="!noMoreData"></v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import { fetchReviews } from '@/api/user'
import Item from '@/components/Review/Item'
export default {
  components: {
    Item,
  },
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    noData() {
      return this.data.length <= 0
    },
  },
  data: () => ({
    loading: false,
    loadError: false,
    pages: {
      current_page: 1,
      last_page: 1,
      per_page: 5,
    },
    data: [],
    noMoreData: false,
    totalReviews: 0,
    rating: 0,
    communication: 0,
    teamwork: 0,
    punctuality: 0,
    problem_solving: 0,
    creativity: 0,
  }),
  created() {
    this.loadData(true)
  },
  methods: {
    async loadData(reset = false) {
      try {
        this.loading = true
        const requestData = {
          user_id: this.id,
        }
        requestData.page = ++this.pages.current_page
        requestData.per_page = this.pages.per_page
        if (reset) {
          requestData.page = 1
        }
        const {
          data,
          meta,
          communication,
          creativity,
          problem_solving,
          punctuality,
          teamwork,
          rating,
          total,
        } = await fetchReviews(requestData)
        this.data = reset ? data : this.data.concat(data)
        this.pages = meta
        this.communication = communication
          ? parseFloat(communication).toFixed(1)
          : 0
        this.rating = rating ? parseFloat(rating) : 0
        this.creativity = creativity ? parseFloat(creativity).toFixed(1) : 0
        this.problem_solving = problem_solving
          ? parseFloat(problem_solving).toFixed(1)
          : 0
        this.punctuality = punctuality ? parseFloat(punctuality).toFixed(1) : 0
        this.teamwork = teamwork ? parseFloat(teamwork).toFixed(1) : 0
        this.totalReviews = total
        if (requestData.page === meta.last_page) this.noMoreData = true
      } catch (e) {
        this.loadError = true
      } finally {
        this.loading = false
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting && !this.noMoreData && !this.loading) {
        this.loadData()
      }
    },
  },
}
</script>
