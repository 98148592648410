<template>
  <v-card>
    <v-row justify="center" no-gutters class="py-4">
      <v-col cols="auto">
        <v-avatar>
          <v-img :src="user.avatar" />
        </v-avatar>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="pb-4">
      <v-col cols="auto">
        <v-chip small color="warning">
          {{ user.type | userType }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="pb-4 mx-4">
      <v-col cols="auto" class="text-center">
        <span v-if="user.type === 'company'"
          >{{ user.identityable.contact_name }}@</span
        >{{ user.name }}
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="pb-4">
      <template v-if="user.type === 'company'">
        <v-col cols="6" class="text-center d-flex justify-center">
          <v-icon>mdi-map-marker</v-icon>
          <span class="align-self-center">{{ user.country.name }}</span>
        </v-col>
        <v-col cols="6" class="text-center d-flex justify-center">
          <v-icon>mdi-briefcase</v-icon>
          <span class="align-self-center">{{
            user.identityable.is_profit ? 'Profit' : 'Non-Profit'
          }}</span>
        </v-col>
      </template>

      <v-col cols="auto" class="text-center d-flex justify-center" v-else>
        <v-icon>mdi-map-marker</v-icon>
        <span class="align-self-center">{{ user.country.name }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default() {
        return {
          identityable: {},
          country: {},
          name: '',
        }
      },
    },
  },
}
</script>
