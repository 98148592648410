var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-4"})],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('div',{staticClass:"py-4 d-flex justify-space-between"},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),(_vm.isLoggedIn && _vm.userInfo.id === _vm.data.user_id)?_c('div',[_c('v-btn',{staticClass:"white--text",attrs:{"icon":"","color":"error","to":{ name: 'portfolioEdit', params: { id: _vm.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"white--text",attrs:{"icon":"","color":"error","loading":_vm.deleteLoading},on:{"click":function($event){return _vm.deletePortfolio(_vm.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1),_c('div',{staticStyle:{"position":"relative"},style:({ height: _vm.containerHeight + 'px' })},[_vm._l((_vm.images),function(image,index){return [(image.url !== '')?_c('div',{key:`image-preview-${index}`,style:({
            position: 'absolute',
            left: image.left + 'px',
            top: image.top + 'px',
            width: image.width + 'px',
            height: image.height + 'px',
          })},[_c('v-img',{attrs:{"src":_vm.data.attachments[index].url,"width":image.width,"height":image.height,"contain":""}})],1):_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }