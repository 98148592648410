<template>
  <div class="tw-container tw-mx-auto">
    <template v-if="isSelf">
      <div class="tw-flex tw-justify-center" v-if="showTrainingHourForm">
        <v-card max-width="560">
          <v-card-title
            >How long you would like the training session to be (max 6
            hours)</v-card-title
          >
          <v-card-text>
            <v-form @submit.prevent="submitHowManyHours">
              <v-select
                :items="hourItems"
                label="Select"
                solo
                v-model="trainingHour"
              ></v-select>
              <v-btn type="primary" depressed :loading="saveTrainingHourLoading"
                >Submit</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </div>
      <div class="tw-mt-6">
        <v-card>
          <v-card-title>
            My students (Students who have paid for training )
          </v-card-title>
          <v-card-text>
            <div
              class="tw-grid tw-grid-cols-4 tw-gap-4"
              v-if="paidStudents.length > 0"
            >
              <student-item
                v-for="item in paidStudents"
                :item="item"
                :key="`can-hire-student-${item.id}`"
              ></student-item>
            </div>
            <div v-else>
              <v-alert class="text-body-2"
                >No students have paid you for training at the moment. You can
                find your potential students in the list below and you can
                contact them by email to invite them to join your training
                program. (Please use the link we provided
                <span class="orange--text">{{ fullUrl }}</span> for you to
                charge $80/hour per Candian student (Beeznests take $30 /hour
                per student) before the training, we will transfer all the money
                once the project is completed.<br />
                You will see the list of students who have paid you for
                training. When a student pays the training fee, they will
                automatically join your training plan. Please keep in touch with
                the students regarding their training start date or any other
                important information or changes to the training.
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="tw-mt-6">
        <v-card>
          <v-card-title>
            Available to hire
          </v-card-title>
          <v-card-text>
            <div class="tw-grid tw-grid-cols-4 tw-gap-4">
              <student-item
                v-for="item in students"
                :item="item"
                :key="`can-hire-student-${item.id}`"
              ></student-item>
            </div>
            <div class="tw-mt-4 tw-flex tw-justify-center tw-items-center">
              <v-btn @click="loadMoreCanHiredStudents">Load More</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <template v-else>
      <div class="tw-flex tw-justify-center tw-mt-8">
        <v-card max-width="520" class="tw-flex-grow">
          <v-card-title>
            Join the Team Lead’s training program
          </v-card-title>
          <v-card-text>
            <div class="tw-flex tw-flex-col">
              <div class="tw-text-red-500">
                Please confirm that you are invited to this page by the Team
                Lead, otherwise please do not make any payment！
              </div>
              <div class="tw-text-red-500">
                Once you pay, it means that you agree to join the Team Lead’s
                training program, and the fees you paid will not be refunded,
                please consider carefully before paying!
              </div>
            </div>

            <v-list dense>
              <v-list-item>
                <v-list-item-title>Fee</v-list-item-title>
                <v-list-item-subtitle>$80/Hour</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Duration</v-list-item-title>
                <v-list-item-subtitle>{{
                  receiveData.training_hour
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-title>Total</v-list-item-title>
                <v-list-item-subtitle
                  >${{ 80 * receiveData.training_hour }}</v-list-item-subtitle
                >
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions right>
            <v-btn
              class="ml-auto"
              depressed
              color="primary"
              @click="createStudentForTeamLeadOrder"
              :loading="createOrderLoading"
              :disabled="createOrderLoading"
              >Confirm payment</v-btn
            >
            <v-btn depressed text>Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>
  </div>
</template>
<script>
import {
  fetchReceiveData,
  saveTrainingHour,
  createOrder,
  fetchPaidStudents,
} from '@/api/teamLead'
import { fetchStudents } from '@/api/student'
import { mapGetters } from 'vuex'
import StudentItem from '@/components/TeamLead/StudentItem'
export default {
  components: {
    StudentItem,
  },
  computed: {
    ...mapGetters(['userInfo', 'isLoggedIn', 'isAdmin']),
    isSelf() {
      console.log(this.receiveData.user_id, this.userInfo)
      return this.receiveData.user_id === this.userInfo.id
    },
    hourItems() {
      return [
        {
          text: '1 Hour',
          value: 1,
        },
        {
          text: '2 Hours',
          value: 2,
        },
        {
          text: '3 Hours',
          value: 3,
        },
        {
          text: '4 Hours',
          value: 4,
        },
        {
          text: '5 Hours',
          value: 5,
        },
        {
          text: '6 Hours',
          value: 6,
        },
      ]
    },
    receive_id() {
      return this.$route.params.receive_id || null
    },
  },
  data: () => ({
    saveTrainingHourLoading: false,
    trainingHour: null,
    receiveData: {},
    showTrainingHourForm: false,
    students: [],
    paidStudents: [],
    fetchPaidStudentPage: 1,
    fetchStudentPage: 1,
    createOrderLoading: false,
    fullUrl: null,
  }),
  watch: {
    isSelf(newIsSelf) {
      if (newIsSelf) {
        this.getStudentsList(true)
        this.getPaidStudents(true)
      }
    },
  },
  mounted() {
    this.getReceiveData()
    this.fullUrl = location.href
  },
  methods: {
    async getReceiveData() {
      try {
        const { data } = await fetchReceiveData(this.receive_id)
        if (!data.training_hour) {
          this.showTrainingHourForm = true
        }
        this.receiveData = data
      } catch (e) {
        this.$message('Server Error!', 'error')
      }
    },
    async getStudentsList(reset = false) {
      try {
        const requestData = {
          type: 'student',
          category_id: this.receiveData.project.category_id,
        }
        if (reset) {
          this.fetchStudentPage = 1
        } else {
          this.fetchStudentPage++
        }
        requestData.page = this.fetchStudentPage
        const { data } = await fetchStudents(requestData)
        reset
          ? (this.students = data)
          : (this.students = this.students.concat(data))
      } catch (e) {
        this.$message('Server Error!', 'error')
      }
    },
    async loadMoreCanHiredStudents() {
      this.getStudentsList()
    },
    async getPaidStudents(reset = false) {
      try {
        const requestData = {}
        if (reset) {
          this.fetchPaidStudentPage = 1
        } else {
          this.fetchPaidStudentPage++
        }
        requestData.page = this.fetchPaidStudentPage
        const { data } = await fetchPaidStudents(this.receive_id, requestData)
        reset
          ? (this.paidStudents = data)
          : (this.paidStudents = this.paidStudents.concat(data))
      } catch (e) {
        this.$message('Server Error!', 'error')
      }
    },
    async loadMorePaidStudents() {},
    async submitHowManyHours() {
      try {
        if (this.saveTrainingHourLoading) return
        this.saveTrainingHourLoading = true
        const { data } = await saveTrainingHour(
          this.receive_id,
          this.trainingHour
        )
        this.showSaved()
        this.saveTrainingHourLoading = false
      } catch (e) {
        this.saveTrainingHourLoading = false
      }
    },
    showSaved() {
      this.showTrainingHourForm = false
      this.$message('Saved.')
    },
    async createStudentForTeamLeadOrder() {
      this.createOrderLoading = true
      try {
        const { data } = await createOrder(this.receive_id)
        console.log(data)
        this.$router.push({
          name: 'Pay',
          params: {
            no: data.no,
          },
        })
      } catch (e) {
        console.log(e)
        this.createOrderLoading = false
      }
    },
  },
}
</script>
