export default {
  path: '/portfolio',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '',
      name: 'userPortfolioIndex',
      component: () => import(/* webpackChunkName: 'portfolio' */'@/views/users/portfolio/index'),
    },
    {
      path: 'create',
      name: 'portfolioCreate',
      component: () => import(/* webpackChunkName: 'portfolio' */'@/views/users/portfolio/create'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'edit/:id',
      name: 'portfolioEdit',
      component: () => import(/* webpackChunkName: 'portfolio' */'@/views/users/portfolio/create'),
    },
    {
      path: ':id',
      name: 'portfolioShow',
      component: () => import(/* webpackChunkName: 'portfolio' */'@/views/users/portfolio/show'),
    },
  ],
}
