<template>
  <v-row justify="center" no-gutters class="ma-n3">
    <v-col cols="12">
      <v-sheet color="grey lighten-4">
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-row class="pa-5">
              <v-col class="d-flex">
                <v-avatar size="64">
                  <v-img :src="user.avatar" />
                </v-avatar>
                <div class="d-flex flex-column mr-auto ml-3 align-self-center">
                  <strong class="ml-1 flex-grow-0 flex-shrink-1">{{
                    user.name
                  }}</strong>
                  <v-sheet color="transparent">
                    <v-chip class="ml-1 text-center" small color="primary">{{
                      user.type | userType
                    }}</v-chip>
                  </v-sheet>
                </div>
              </v-col>
              <v-col align-self="center" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="info"
                  v-if="!isFollowed"
                  :loading="isLoading(user.id)"
                  @click="follow(user.id)"
                >
                  follow
                </v-btn>
                <v-btn
                  outlined
                  color="info"
                  v-else
                  :loading="isLoading(user.id)"
                  @click="unfollow(user.id)"
                >
                  unfollow
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-n3">
              <v-col>
                <v-tabs show-arrows>
                  <v-tab :to="{ name: 'userIndex', params: { id } }"
                    >Profile</v-tab
                  >
                  <v-tab :to="{ name: 'userPortfolios', params: { id } }"
                    >Portfolios</v-tab
                  >
                  <v-tab :to="{ name: 'userReviews', params: { id } }"
                    >reviews</v-tab
                  >
                  <v-tab :to="{ name: 'userProjects', params: { id } }"
                    >Projects</v-tab
                  >
                  <!--v-tab :to="{ name: 'userChallenges', params: { id } }"
                    >Discussions</v-tab
                  -->
                  <!--v-tab :to="{ name: 'userReplies', params: { id } }"
                    >replies</v-tab
                  -->
                  <v-tab :to="{ name: 'userReceives', params: { id } }"
                    >receives</v-tab
                  >
                  <v-tab :to="{ name: 'userFollowings', params: { id } }"
                    >followings</v-tab
                  >
                  <v-tab :to="{ name: 'userFollowers', params: { id } }"
                    >followers</v-tab
                  >
                </v-tabs>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { fetchInfo } from '@/api/user'
import _ from 'lodash'
export default {
  computed: {
    ...mapGetters(['userInfo']),
    id() {
      return parseFloat(this.$route.params.id) || 0
    },
    isFollowed: {
      get() {
        const isFollow = this.$store.state.follow.data.find(
          (item) => item.id === this.id
        )
        return isFollow && isFollow.followed
      },
      set(isFollowed) {
        this.$store.dispatch('follow/update', [
          {
            id: this.id,
            followed: isFollowed,
          },
        ])
      },
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.loadData()
      }
    },
    isFollowed(is) {
      console.log(is)
    },
  },
  mounted() {
    this.loadData()
  },
  data: () => ({
    user: {},
    transitionName: 'slide-right',
  }),
  methods: {
    async loadData() {
      try {
        const { data, isFollowed } = await fetchInfo(this.id)
        this.user = data
        this.$store.commit('user/SET', data)
        this.isFollowed = isFollowed
      } catch (e) {
        console.log(e)
      }
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    ...mapActions({
      follow: 'follow/follow',
      unfollow: 'follow/unfollow',
    }),
  },
}
</script>
