<template>
  <div v-if="isGovernmentSubsidy">
    <v-sheet>
      <span class="primary--text mr-1"
        >Student Wage per hour (min $15/hr to be eligible for wage
        subsidy):</span
      >
      {{ project.students_budget_each_hour }}
    </v-sheet>
    <v-sheet>
      <span class="primary--text mr-1">Number of Students to hire:</span
      >{{ project.students_count }}</v-sheet
    >
    <v-sheet>
      <span class="primary--text mr-1">Hours required per week</span>
      {{ project.students_work_time }}</v-sheet
    >
    <v-sheet>
      <span class="primary--text mr-1">Total student wage:</span>
      {{ project.students_budget }}</v-sheet
    >

    <v-sheet>
      <span class="primary--text mr-1">Total team lead wage:</span>
      {{ parseInt(project.cost_min).toFixed(2) }}</v-sheet
    >
    <v-sheet>
      <span class="primary--text mr-1">Total Academic team:</span>
      {{
        (
          parseInt(project.students_budget) + parseInt(project.cost_min)
        ).toFixed(2)
      }}
    </v-sheet>
    <v-sheet v-if="project.added_international_students">
      <span class="primary--text mr-1"
        >How many international students would you accept:</span
      >
      {{ project.international_students_count }}</v-sheet
    >
  </div>
  <div v-else>
    <template v-if="isProvideSolution">
      <v-sheet>
        <span class="primary--text mr-1"
          >Budget for Project Fee(any additional expenses to carry out a
          solution for your project):
        </span>
        <!--div class="text-md">
          This information will help the Team Lead create a strategy and
          breakdown costs for the solution proposal. (i.e. project fee may be
          the total budget for Ad expenses for a marketing project)
        </div-->
        ${{ project.actual_cost_max }}</v-sheet
      >
    </template>
    <v-sheet>
      <span class="primary--text mr-1">Budget for team lead:</span>
      ${{ parseInt(project.cost_min).toFixed(2) }}</v-sheet
    >
  </div>
</template>
<script>
export default {
  props: {
    isGovernmentSubsidy: {
      type: Boolean,
      default: false,
    },
    isProvideSolution: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
