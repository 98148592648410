<template>
  <v-list-item>
    <v-list-item-avatar>
      <router-link
        :to="{ name: 'userShow', params: { id: item.data.user_id } }"
      >
        <v-avatar>
          <v-img :src="item.data.user_avatar"></v-img>
        </v-avatar>
      </router-link>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <span class="grey--text mr-2">{{ item.data.user_name }}</span>
        <span class="mr-2">Confirmed completion of</span>
        <router-link
          :to="{ name: 'projectShow', params: { id: item.data.project_id } }"
          >{{ item.data.project_title }}</router-link
        >
      </v-list-item-title>
      <v-list-item-subtitle>
        <small class="ml-auto grey--text">
          {{ item.created_at | diffForHumans }}
        </small>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: {
    item: {
      data: {},
    },
  },
}
</script>
