<template>
  <v-row justify="center">
    <v-col cols="12" md="10" xl="8" lg="6">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="9">
          <div class="tw-w-full tw-max-w-7xl tw-mx-auto">
            <v-card>
              <v-card-title>
                Bootcamp Create
              </v-card-title>
              <v-card-text>
                <form>
                  <v-text-field
                    v-model="data.position"
                    :counter="255"
                    label="Position Type"
                    show-hint
                    hint="Please indicate if this is an internship position"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="data.title"
                    :counter="255"
                    label="Project/Company Name"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="data.category_id"
                    :items="categories"
                    label="Category"
                    data-vv-name="category"
                    required
                    item-text="name"
                    item-value="id"
                    :loading="categoryLoading"
                  >
                  </v-select>
                  <v-slider
                    v-model="data.time_line"
                    label="Duration of the project"
                    min="1"
                    max="12"
                    thumb-label
                    required
                  >
                    <template v-slot:append>
                      {{ data.time_line + 'Months' }}
                    </template>
                  </v-slider>
                  <v-switch
                    v-model="data.is_online"
                    class="mx-2"
                    :label="data.is_online ? 'Remote' : 'Onsite'"
                  ></v-switch>
                  <v-sheet class="py-6">
                    <label>About company:</label>
                  </v-sheet>
                  <froala v-model="data.about_mentor"></froala>
                  <v-sheet class="py-6">
                    <label>What you will do with the mentor</label>
                  </v-sheet>
                  <froala v-model="data.content"></froala>
                  <v-sheet class="py-6">
                    <label>The requirements for students</label>
                  </v-sheet>
                  <froala v-model="data.work_with"></froala>
                </form>
              </v-card-text>
              <v-card-actions>
                <v-btn class="ml-3" color="primary" depressed @click="save"
                  >save</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { fetchAll } from '@/api/category'
import Froala from '@/components/Froala'

import { create, fetchData, update } from '@/api/project'
import moment from 'moment'
export default {
  components: {
    Froala,
  },
  data: () => ({
    data: {
      cost_min: 0,
      cost_max: 0,
      closed_at: moment().add(1, 'months').format('YYYY-MM-DD'),
      is_online: false,
      type: 'bootcamp',
    },
    categories: [],
    categoryLoading: false,
    storeLoading: false,
  }),
  mounted() {
    this.loadCategories()
  },
  methods: {
    async loadCategories() {
      try {
        this.categoryLoading = 'info'
        const { data } = await fetchAll({})
        this.categories = data
      } catch (e) {
        console.log(e)
      } finally {
        this.categoryLoading = false
      }
    },
    async save() {
      if (this.storeLoading) return
      this.storeLoading = true
      try {
        const postData = Object.assign({}, this.data)
        const response = await create(postData)
        console.log(response)
      } catch (e) {
        console.log(e)
      } finally {
        this.storeLoading = false
      }
    },
  },
}
</script>
