import Vue from 'vue'
import { fetchAll } from '@/api/category'
const state = {
  data: [],
  loading: false,
}

const mutations = {
  SET(state, data) {
    state.data = data
  },
  LOADING(state, status) {
    state.loading = status
  },
}

const actions = {
  async getCategories({ commit, state }, reset = false) {
    if (state.data.length > 0 && !reset) {
      return
    }
    try {
      commit('LOADING', true)
      const { data } = await fetchAll({})
      commit('SET', data)
    } catch (e) {
      console.log(e)
    } finally {
      commit('LOADING', false)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
