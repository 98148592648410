<template>
  <div>
    <template v-if="!isMobile">
      <common-header></common-header>
      <div class="tw-container tw-mx-auto tw-mt-10">
        <div class="tw-flex tw-flex-row tw-mx-auto tw-justify-center">
          <div class="" style="max-width: 264px; width: 264px;">
            <div class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mx-4">
              <ul class="tw-font-bold tw-text-xs">
                <li class="tw-my-2">
                  <a
                    href="#"
                    class="bg-orange-2 tw-rounded-xl tw-shadow tw-w-full tw-inline-block tw-px-3 tw-py-3"
                  >
                    <i class="fa fa-user tw-mr-2"></i>
                    <span>{{ isSelf ? 'My ' : null }}Profile</span>
                  </a>
                </li>
                <li class="tw-my-2">
                  <router-link
                    :to="{ name: 'userProjects', params: { id: user_id } }"
                    class="tw-bg-transparent tw-rounded-xl tw-w-full tw-inline-block tw-px-3 tw-py-2"
                  >
                    <i class="fa fa-briefcase tw-mr-2" aria-hidden="true"></i>
                    <span>Projects</span>
                  </router-link>
                </li>
                <li class="tw-my-2">
                  <router-link
                    :to="{ name: 'userChallenges', params: { id: user_id } }"
                    class="tw-bg-transparent tw-rounded-xl tw-w-full tw-inline-block tw-px-3 tw-py-2"
                  >
                    <i class="fa fa-users tw-mr-2" aria-hidden="true"></i>
                    <span>Discussions</span>
                  </router-link>
                </li>
                <li class="tw-my-2">
                  <router-link
                    :to="{ name: 'userReviews', params: { id: user_id } }"
                    class="tw-bg-transparent tw-rounded-xl tw-w-full tw-inline-block tw-px-3 tw-py-2"
                  >
                    <i class="fa fa-quote-right tw-mr-2" aria-hidden="true"></i>
                    <span>Performance Reviews</span>
                  </router-link>
                </li>
              </ul>
              <div class="tw-mt-6" v-if="isSelf">
                <div
                  @click="logout"
                  class="tw-bg-black tw-cursor-pointer tw-text-sm tw-text-white tw-rounded tw-w-full tw-inline-block tw-px-3 tw-py-2 tw-text-center"
                >
                  <i class="fa fa-sign-out tw-mr-2" aria-hidden="true"></i>
                  <span>LOG OUT</span>
                </div>
              </div>
            </div>
            <div
              class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mx-4 tw-mt-4"
              v-if="!data.organization_id && isSelf"
            >
              Enter Promo Code
              <div class="tw-rounded tw-bg-white tw-p-1 tw-border">
                <input
                  class="tw-outline-none focus:tw-outline-none tw-w-full"
                  v-model="code"
                />
              </div>
              <div
                class="tw-my-1"
                :class="settingCodeError ? 'tw-text-red-500' : null"
              >
                {{ settingCodeMessage }}
              </div>
              <button
                @click="settingCode"
                class="tw-mt-2 tw-w-full tw-inline-block tw-outline-none focus:tw-outline-none active:tw-outline-none tw-rounded tw-py-1 tw-uppercase bg-gray-2"
              >
                <i
                  class="fa fa-spinner fa-spin text-gray-1 tw-mr-1"
                  v-if="settingCodeLoading"
                ></i>
                Submit
              </button>
            </div>
            <div
              class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mx-4 tw-mt-4"
              v-if="isSelf"
            >
              Invite your friends!
              <div class="tw-rounded tw-bg-white tw-p-1 tw-border">
                <input
                  class="tw-outline-none focus:tw-outline-none tw-w-full"
                  readonly
                  id="copy-txt"
                  v-model="data.invite_link"
                />
              </div>
              <div class="tw-my-1" v-if="hasCopied">
                {{ copiedMessage }}
              </div>
              <button
                @click="copyLink"
                class="tw-mt-2 tw-w-full tw-inline-block tw-outline-none focus:tw-outline-none active:tw-outline-none tw-rounded tw-py-1 tw-uppercase bg-gray-2"
              >
                Copy
              </button>
            </div>
          </div>
          <div class="" style="max-width: 581px; width: 581px;">
            <div
              class="bg-main bg-profile tw-bg-top tw-relative tw-pt-12 tw-bg-contain"
            >
              <div
                class="tw-shadow tw-bg-white tw-mx-5 tw-mt-12 tw-p-4 tw-rounded tw-flex tw-flex-row"
              >
                <div
                  class="tw-mr-2 tw-self-center"
                  style="min-width: 84px; max-width: 84px;"
                >
                  <img :src="data.avatar" class="tw-w-24 tw-h-24 tw-rounded" />
                  <div class="tw-text-xs">
                    <i
                      class="fa fa-star tw-text-xs text-primary"
                      v-for="i in 4"
                      :key="`profile-star-${i}`"
                    ></i>
                    <i
                      class="fa fa-star-half-o text-primary"
                      aria-hidden="true"
                    ></i>
                    <span class="text-primary"
                      >({{ data.counts.rate | toFixed }})</span
                    >
                  </div>
                </div>
                <div
                  class="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden"
                  style="max-width: calc(100% - 80px);"
                >
                  <div class="tw-flex tw-flex-row tw-justify-between">
                    <div class="tw-flex tw-flex-col" style="max-width: 50%;">
                      <h3
                        class="tw-text-2xl tw-font-bold tw-leading-none tw-truncate"
                      >
                        {{ data.name }}
                      </h3>
                      <div
                        class="text-gray-1 tw-text-xs tw-mt-1 tw-truncate"
                        v-if="isSelf"
                      >
                        <i
                          class="fa fa-envelope text-primary tw-mr-1"
                          aria-hidden="true"
                        ></i>
                        <span>{{ data.email }}</span>
                      </div>
                    </div>
                    <div class="tw-self-center tw-text-xs">
                      <a
                        v-if="data.identityable.linkedin"
                        :href="data.identityable.linkedin"
                        class="tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded"
                        >LinkedIn</a
                      >
                      <router-link
                        v-if="isSelf"
                        :to="{ name: 'userSettings' }"
                        class="tw-uppercase tw-ml-2 tw-px-4 tw-py-2 bg-primary tw-text-white tw-rounded"
                        ><i
                          class="fa fa-pencil-square-o mr-1"
                          aria-hidden="true"
                        ></i
                        >Edit Profile</router-link
                      >
                    </div>
                  </div>

                  <div
                    class="tw-flex-grow tw-items-end tw-grid tw-grid-cols-3 tw-space-x-4"
                  >
                    <div
                      class="bg-blue-1 tw-p-1 tw-flex tw-col-span-1 tw-rounded"
                    >
                      <div class="tw-h-10 tw-w-10 tw-self-center">
                        <img src="@/assets/images/hired.png" />
                      </div>
                      <div class="tw-flex tw-flex-col tw-ml-1">
                        <span class="tw-text-xs">Projects Hired</span>
                        <span class="tw-text-xl text-blue-1">{{
                          data.counts.receive
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="bg-orange-5 tw-p-1 tw-flex tw-col-span-1 tw-rounded"
                    >
                      <div class="tw-h-10 tw-w-10 tw-self-center">
                        <img src="@/assets/images/following.png" />
                      </div>
                      <div class="tw-flex tw-flex-col tw-ml-1">
                        <span class="tw-text-xs">Following</span>
                        <span class="tw-text-xl text-primary">{{
                          data.counts.following
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="bg-blue-2 tw-p-1 tw-flex tw-col-span-1 tw-rounded"
                    >
                      <div class="tw-h-10 tw-w-10 tw-self-center">
                        <img src="@/assets/images/follower.png" />
                      </div>
                      <div class="tw-flex tw-flex-col tw-ml-1">
                        <span class="tw-text-xs">Follower</span>
                        <span class="tw-text-xl text-blue-2">{{
                          data.counts.follower
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-mt-10 tw-mx-4 border-color-primary tw-border-l-2">
                <h3
                  class="tw-text-xl tw-font-extrabold tw-border-l-4 border-color-primary tw-pl-2"
                >
                  About me
                </h3>
                <p class="text-gray-1 tw-mx-4 tw-mt-4">
                  {{ data.is_mentor ? data.about_me : data.introduction }}
                </p>
                <template v-if="data.is_mentor">
                  <h3
                    class="tw-text-xl tw-font-extrabold tw-border-l-4 border-color-primary tw-pl-2 tw-mt-2"
                  >
                    Introduction
                  </h3>
                  <p class="text-gray-1 tw-mx-4 tw-mt-4">
                    {{ data.introduction }}
                  </p>
                </template>
                <div class="tw-flex tw-flex-row tw-flex-wrap tw-mx-4 tw-mt-4">
                  <div class="tw-w-1/2">
                    <div class="">
                      <i class="fa fa-flag text-primary" aria-hidden="true"></i>
                      <span class="tw-font-bold tw-ml-2">Languages</span>
                    </div>
                    <div
                      class="tw-flex tw-flex-row tw-flex-wrap tw-ml-4 tw-mt-2"
                    >
                      <div
                        v-for="(item, i) in data.languages"
                        :key="`user-language-${i}`"
                        class="tw-flex-shrink tw-flex-grow-0 tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2 tw-mr-2 tw-mb-2"
                      >
                        <!--div
                        class="tw-w-6 tw-h-6 tw-pl-2 tw-flex tw-items-center"
                      >
                        <img
                          class="tw-object-contain"
                          src="https://www.countryflags.io/ca/flat/64.png"
                        />
                      </div-->
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="tw-w-1/2">
                    <div class="">
                      <i
                        class="fa fa-globe text-primary"
                        aria-hidden="true"
                      ></i>
                      <span class="tw-font-bold tw-ml-2">Country</span>
                    </div>
                    <div
                      class="tw-ml-4 tw-mt-2 tw-space-x-2 tw-flex tw-flex-row"
                    >
                      <div
                        class="tw-flex-shrink tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2"
                      >
                        <div
                          class="tw-w-6 tw-h-6 tw-pl-2 tw-flex tw-items-center"
                        >
                          <img
                            class="tw-object-contain"
                            :src="data.country_flag"
                          />
                        </div>
                        <span>{{ data.country.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-w-1/2"
                    v-if="
                      data.type !== 'company' && data.identityable.education
                    "
                  >
                    <div class="">
                      <i
                        class="fa fa-globe text-primary"
                        aria-hidden="true"
                      ></i>
                      <span class="tw-font-bold tw-ml-2">Eduction</span>
                    </div>
                    <div
                      class="tw-ml-4 tw-mt-2 tw-space-x-2 tw-flex tw-flex-row"
                    >
                      <div
                        class="tw-flex-shrink tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2"
                      >
                        <span>{{ data.identityable.education }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="tw-w-full" v-if="data.type !== 'company'">
                    <div class="">
                      <i
                        class="fa fa-globe text-primary"
                        aria-hidden="true"
                      ></i>
                      <span class="tw-font-bold tw-ml-2">Skills</span>
                    </div>
                    <div
                      class="tw-ml-2 tw-mt-2 tw-space-x-2 tw-flex tw-flex-wrap tw-flex-row"
                    >
                      <div
                        v-for="(item, i) in data.categories"
                        :key="`user-categories-${i}`"
                        class="tw-ml-2 tw-mb-2 tw-flex-shrink tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2"
                      >
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tw-border-t tw-mx-6 tw-my-6"></div>
              <div class="tw-mx-4" v-if="data.type === 'company'">
                <h3
                  class="tw-font-bold tw-text-xl tw-font-extrabold tw-border-l-4 border-color-primary tw-pl-2"
                >
                  Information
                </h3>
                <div
                  class="tw-pt-4 tw-border-l-2 border-color-primary tw-pl-4 tw-pb-4"
                >
                  <template v-if="data.identityable.address">
                    <h4 class="tw-font-bold tw-text-base">Company Address</h4>
                    <div class="tw-text-sm text-gray-1 tw-p-2">
                      {{ data.identityable.address }}
                    </div>
                  </template>
                  <template v-if="data.identityable.is_profit !== null">
                    <h4 class="tw-font-bold tw-text-base">
                      Profit or Non-profit
                    </h4>
                    <div class="text-gray-1 tw-text-sm tw-p-2">
                      {{
                        data.identityable.is_profit ? 'Profit' : 'Non-Profit'
                      }}
                    </div>
                  </template>
                  <template v-if="data.identityable.employees">
                    <h4 class="tw-font-bold tw-text-base">Company Employees</h4>
                    <div class="text-gray-1 tw-text-sm tw-p-2">
                      {{ data.identityable.employees }}
                    </div>
                  </template>
                  <template v-if="data.identityable.website">
                    <h4 class="tw-font-bold tw-text-base">Website</h4>
                    <div class="text-gray-1 tw-text-sm tw-p-2">
                      {{ data.identityable.website }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="tw-mx-4" v-if="data.type !== 'company'">
                <div class="tw-flex tw-justify-between tw-mb-4">
                  <h3 class="tw-font-bold tw-text-xl tw-ml-4">
                    Experience
                  </h3>
                  <!--button
                    v-if="isSelf"
                    class="bg-orange-2 tw-px-2 tw-py-1 tw-rounded tw-outline-none focus:tw-outline-none"
                  >
                    Manage
                  </button-->
                </div>
                <template v-if="data.is_mentor">
                  <p class="tw-px-4">{{ data.experience }}</p>
                </template>
                <div
                  v-for="(item, i) in data.experiences"
                  :key="`experience-${i}`"
                  class="tw-border-l-2 border-color-primary tw-pl-4 tw-pb-4"
                >
                  <h4 class="tw-font-bold tw-text-lg">Google.inc</h4>
                  <div class="text-primary tw-text-sm tw-font-bold">
                    {{ item.date_start | formatMonth }} -
                    {{ item.date_end | formatMonth }}
                  </div>
                  <p class="text-gray-1 tw-text-sm tw-font-bold tw-mt-2">
                    {{ item.content }}
                  </p>
                </div>
              </div>
              <div
                class="tw-border-t tw-mx-6 tw-my-6"
                v-if="data.type !== 'company'"
              ></div>
              <div class="tw-mx-4" v-if="data.type !== 'company'">
                <div class="tw-flex tw-justify-between tw-mb-4">
                  <h3 class="tw-font-bold tw-text-xl tw-ml-4">
                    Portfolio
                  </h3>
                  <button
                    class="bg-orange-2 tw-px-2 tw-py-1 tw-rounded tw-outline-none focus:tw-outline-none"
                    @click="$router.push({ name: 'userPortfolioIndex' })"
                    v-if="isSelf"
                  >
                    Manage
                  </button>
                </div>
                <div
                  class="tw-flex tw-flex-row tw-flex-wrap tw-py-4 tw-justify-between"
                >
                  <div
                    class="tw-mb-4 tw-w-1/2 tw-inline-flex tw-items-center tw-justify-center tw-px-2"
                    v-for="(portfolio, i) in portfolios"
                    :key="`user-portfolio-${i}`"
                  >
                    <img
                      :src="portfolio.attachments[0].url"
                      alt=""
                      style="object-fit: cover; height: 260px;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" style="max-width: 302px; width: 302px;">
            <div class="tw-mx-4">
              <!--              <div class="bg-orange-4 tw-p-4 tw-rounded tw-border">-->
              <!--                <div class="tw-flex tw-justify-between">-->
              <!--                  <h2 class="tw-text-xl tw-font-bold">News</h2>-->
              <!--                  <span-->
              <!--                    class="tw-uppercase text-primary tw-font-bold tw-text-sm tw-self-end tw-cursor-pointer"-->
              <!--                    @click="$router.push('/blog/18')"-->
              <!--                    >View All></span-->
              <!--                  >-->
              <!--                </div>-->

              <!--                <div class="tw-flex flex-row">-->
              <!--                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>-->
              <!--                  <div-->
              <!--                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"-->
              <!--                  ></div>-->
              <!--                </div>-->
              <!--                <p class="tw-text-sm text-gray-1 tw-mt-4">-->
              <!--                  No matter how challenging these times are, businesses still-->
              <!--                  need to get their work done. Get your projects and tasks-->
              <!--                  completed for low to no cost. Create a job posting, hire a-->
              <!--                  post-secondary student from our Beeznests platform, and get-->
              <!--                  your fees covered by the government. Eligibility: registered-->
              <!--                  Canadian business hiring a post-secondary student with their-->
              <!--                  PR or Canadian citizenship.-->
              <!--                  <img-->
              <!--                    class="tw-my-2"-->
              <!--                    src="https://api.beeznests.com/storage/files/WechatIMG9909.jpeg"-->
              <!--                  />-->
              <!--                  It’s a win-win situation for you. Hires can be part-time/full-->
              <!--                  time and remote/on-site. Please share this post with your-->
              <!--                  entrepreneur friends and family. For more information, contact-->
              <!--                  ourhoneycomb@beeznests.com-->
              <!--                </p>-->
              <!--              </div>-->
              <div class="tw-mt-0">
                <img
                  src="https://api.beeznests.com/storage/portfolios/202211/06/22_1667742474_CDmXuyP0lI.png"
                />
              </div>
              <div class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mt-4">
                <div class="tw-flex tw-justify-between">
                  <h2 class="tw-text-xl tw-font-bold">Recent Badges</h2>
                  <span
                    class="tw-uppercase text-primary tw-font-bold tw-text-sm tw-self-end"
                    >View All></span
                  >
                </div>

                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <div>
                  <div class="tw-flex tw-flex-col">
                    <div
                      class="tw-flex tw-flex-row tw-mt-4"
                      v-for="i in 4"
                      :key="`recent-${i}`"
                    >
                      <img src="@/assets/images/level.png" />
                      <div
                        class="tw-ml-2 tw-flex tw-flex-col tw-justify-between"
                      >
                        <div>
                          <span class="tw-text-black tw-font-bold"
                            >Nike Smith</span
                          >
                          <span class="text-gray-1 tw-text-sm tw-ml-2"
                            >has completed</span
                          >
                        </div>
                        <span class="tw-font-bold">LEVEL 8</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mt-4">
                <div class="tw-flex tw-justify-between">
                  <h2 class="tw-text-xl tw-font-bold">Following</h2>
                  <span class="text-primary tw-font-bold"
                    >({{ data.followings_count }})</span
                  >
                </div>

                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <div>
                  <div class="tw-flex tw-flex-col">
                    <div
                      class="tw-flex tw-flex-row tw-mt-4"
                      v-for="(item, i) in followings"
                      :key="`following-${i}`"
                    >
                      <img
                        :src="item.avatar"
                        style="width: 50px; height: 50px; flex: 0 0 50px;"
                      />
                      <div
                        class="tw-ml-2 tw-flex tw-justify-between tw-items-center tw-flex-grow"
                      >
                        <div
                          class="tw-flex tw-flex-col"
                          :style="
                            isLoggedIn
                              ? 'max-width: 90px;'
                              : 'max-width: 190px;'
                          "
                        >
                          <span
                            class="tw-text-black tw-font-bold tw-truncate"
                            >{{ item.name }}</span
                          >
                          <span
                            class="text-gray-1 tw-text-sm tw-truncate"
                            v-if="item.categories && item.categories.length > 0"
                          >
                            {{ item.categories[0].name }}
                          </span>
                        </div>
                        <div class="tw-self-center" v-if="isLoggedIn">
                          <button
                            class="tw-outline-none focus:tw-outline-none tw-uppercase bg-gray-2 hover:bg-primary tw-text-sm tw-font-bold tw-py-2 tw-px-2 tw-rounded-sm"
                            @click="
                              isFollowed(item.id)
                                ? unfollow(item.id)
                                : follow(item.id)
                            "
                            :disabled="isLoading(item.id)"
                          >
                            {{ isFollowed(item.id) ? 'UNFOLLOW' : 'FOLLOW'
                            }}{{ isLoading(item.id) ? '...' : null }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="">
            <div
              class="bg-main bg-profile tw-bg-top tw-relative tw-pt-12 tw-bg-contain"
            >
              <div
                class="tw-shadow tw-bg-white tw-mx-4 tw-mt-12 tw-p-2 tw-rounded tw-flex tw-flex-col"
              >
                <div class="tw-flex tw-flex-row tw-items-center">
                  <!-- 头像 -->
                  <div
                    class="tw-mr-2 tw-self-center"
                    style="min-width: 84px; max-width: 84px;"
                  >
                    <img
                      :src="data.avatar"
                      class="tw-w-24 tw-h-24 tw-rounded"
                    />
                  </div>
                  <!-- 结束 -->
                  <div
                    class="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden"
                    style="max-width: calc(100% - 80px);"
                  >
                    <div class="tw-flex tw-flex-row tw-justify-between">
                      <div class="tw-flex tw-flex-col">
                        <h3
                          class="tw-text-2xl tw-font-bold tw-leading-none tw-truncate"
                        >
                          {{ data.name }}
                        </h3>
                        <div
                          class="text-gray-1 tw-text-xs tw-mt-1 tw-truncate"
                          v-if="isSelf"
                        >
                          <i
                            class="fa fa-envelope text-primary tw-mr-1"
                            aria-hidden="true"
                          ></i>
                          <span>{{ data.email }}</span>
                        </div>
                        <div class="tw-text-xs">
                          <i
                            class="fa fa-star tw-text-xs text-primary"
                            v-for="i in 4"
                            :key="`profile-star-${i}`"
                          ></i>
                          <i
                            class="fa fa-star-half-o text-primary"
                            aria-hidden="true"
                          ></i>
                          <span class="text-primary"
                            >({{ data.counts.rate | toFixed }})</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-flex-wrap tw-mt-4">
                  <div class="tw-w-1/2 tw-flex">
                    <div
                      class="bg-blue-1 tw-p-1 tw-flex tw-rounded tw-mr-auto"
                      style="width: 132px;"
                    >
                      <div
                        class="tw-self-center"
                        style="width: 46px; height: 46px;"
                      >
                        <img src="@/assets/images/hired.png" />
                      </div>
                      <div class="tw-flex tw-flex-col tw-ml-1">
                        <span class="tw-text-xs">Projects Hired</span>
                        <span class="tw-text-xl text-blue-1">{{
                          data.counts.receive
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="tw-w-1/2">
                    <div
                      class="bg-orange-5 tw-p-1 tw-flex tw-col-span-1 tw-rounded"
                    >
                      <div
                        class="tw-self-center"
                        style="width: 46px; height: 46px;"
                      >
                        <img src="@/assets/images/following.png" />
                      </div>
                      <div class="tw-flex tw-flex-col tw-ml-1">
                        <span class="tw-text-xs">Following</span>
                        <span class="tw-text-xl text-primary">{{
                          data.counts.following
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="tw-w-1/2 tw-mt-4">
                    <div
                      class="bg-blue-2 tw-p-1 tw-flex tw-col-span-1 tw-rounded"
                      style="width: 132px;"
                    >
                      <div
                        class="tw-self-center"
                        style="width: 46px; height: 46px;"
                      >
                        <img src="@/assets/images/follower.png" />
                      </div>
                      <div class="tw-flex tw-flex-col tw-ml-1">
                        <span class="tw-text-xs">Follower</span>
                        <span class="tw-text-xl text-blue-2">{{
                          data.counts.follower
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tw-mt-4 tw-mb-2">
                  <div class="tw-self-center tw-text-xs">
                    <a
                      v-if="data.identityable.linkedin"
                      :href="data.identityable.linkedin"
                      class="tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-rounded"
                      style="color: #fff;"
                      >LinkedIn</a
                    >
                    <router-link
                      v-if="isSelf"
                      :to="{ name: 'userSettings' }"
                      class="tw-uppercase tw-ml-2 tw-px-4 tw-py-2 bg-primary tw-text-white tw-rounded"
                      ><i
                        class="fa fa-pencil-square-o mr-1"
                        aria-hidden="true"
                      ></i
                      >Edit Profile</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="tw-mt-10 tw-mx-4 border-color-primary tw-border-l-2">
                <h3
                  class="tw-text-xl tw-font-extrabold tw-border-l-4 border-color-primary tw-pl-2"
                >
                  About me
                </h3>
                <p class="text-gray-1 tw-mx-4 tw-mt-4">
                  {{ data.introduction }}
                </p>
                <div class="tw-flex tw-flex-row tw-flex-wrap tw-mx-4 tw-mt-4">
                  <div class="tw-w-1/2">
                    <div class="">
                      <i class="fa fa-flag text-primary" aria-hidden="true"></i>
                      <span class="tw-font-bold tw-ml-2">Languages</span>
                    </div>
                    <div
                      class="tw-flex tw-flex-row tw-flex-wrap tw-ml-4 tw-mt-2"
                    >
                      <div
                        v-for="(item, i) in data.languages"
                        :key="`user-language-${i}`"
                        class="tw-flex-shrink tw-flex-grow-0 tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2 tw-mr-2 tw-mb-2"
                      >
                        <!--div
                        class="tw-w-6 tw-h-6 tw-pl-2 tw-flex tw-items-center"
                      >
                        <img
                          class="tw-object-contain"
                          src="https://www.countryflags.io/ca/flat/64.png"
                        />
                      </div-->
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="tw-w-1/2">
                    <div class="">
                      <i
                        class="fa fa-globe text-primary"
                        aria-hidden="true"
                      ></i>
                      <span class="tw-font-bold tw-ml-2">Country</span>
                    </div>
                    <div
                      class="tw-ml-4 tw-mt-2 tw-space-x-2 tw-flex tw-flex-row"
                    >
                      <div
                        class="tw-flex-shrink tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2"
                      >
                        <div
                          class="tw-w-6 tw-h-6 tw-pl-2 tw-flex tw-items-center"
                        >
                          <img
                            class="tw-object-contain"
                            :src="data.country_flag"
                          />
                        </div>
                        <span>{{ data.country.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-w-1/2"
                    v-if="
                      data.type !== 'company' && data.identityable.education
                    "
                  >
                    <div class="">
                      <i
                        class="fa fa-globe text-primary"
                        aria-hidden="true"
                      ></i>
                      <span class="tw-font-bold tw-ml-2">Eduction</span>
                    </div>
                    <div
                      class="tw-ml-4 tw-mt-2 tw-space-x-2 tw-flex tw-flex-row"
                    >
                      <div
                        class="tw-flex-shrink tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2"
                      >
                        <span>{{ data.identityable.education }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="tw-w-full" v-if="data.type !== 'company'">
                    <div class="">
                      <i
                        class="fa fa-globe text-primary"
                        aria-hidden="true"
                      ></i>
                      <span class="tw-font-bold tw-ml-2">Skills</span>
                    </div>
                    <div
                      class="tw-ml-2 tw-mt-2 tw-space-x-2 tw-flex tw-flex-wrap tw-flex-row"
                    >
                      <div
                        v-for="(item, i) in data.categories"
                        :key="`user-categories-${i}`"
                        class="tw-ml-2 tw-mb-2 tw-flex-shrink tw-bg-white tw-border tw-rounded-full tw-flex tw-flex-row tw-items-center tw-py-1 tw-px-2"
                      >
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tw-border-t tw-mx-6 tw-my-6"></div>
              <div class="tw-mx-4" v-if="data.type === 'company'">
                <h3
                  class="tw-font-bold tw-text-xl tw-font-extrabold tw-border-l-4 border-color-primary tw-pl-2"
                >
                  Information
                </h3>
                <div
                  class="tw-pt-4 tw-border-l-2 border-color-primary tw-pl-4 tw-pb-4"
                >
                  <template v-if="data.identityable.address">
                    <h4 class="tw-font-bold tw-text-base">Company Address</h4>
                    <div class="tw-text-sm text-gray-1 tw-p-2">
                      {{ data.identityable.address }}
                    </div>
                  </template>
                  <template v-if="data.identityable.is_profit !== null">
                    <h4 class="tw-font-bold tw-text-base">
                      Profit or Non-profit
                    </h4>
                    <div class="text-gray-1 tw-text-sm tw-p-2">
                      {{
                        data.identityable.is_profit ? 'Profit' : 'Non-Profit'
                      }}
                    </div>
                  </template>
                  <template v-if="data.identityable.employees">
                    <h4 class="tw-font-bold tw-text-base">Company Employees</h4>
                    <div class="text-gray-1 tw-text-sm tw-p-2">
                      {{ data.identityable.employees }}
                    </div>
                  </template>
                  <template v-if="data.identityable.website">
                    <h4 class="tw-font-bold tw-text-base">Website</h4>
                    <div class="text-gray-1 tw-text-sm tw-p-2">
                      {{ data.identityable.website }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="tw-mx-4" v-if="data.type !== 'company'">
                <div class="tw-flex tw-justify-between tw-mb-4">
                  <h3 class="tw-font-bold tw-text-xl tw-ml-4">
                    Experience
                  </h3>
                  <button
                    v-if="isSelf"
                    class="bg-orange-2 tw-px-2 tw-py-1 tw-rounded tw-outline-none focus:tw-outline-none"
                  >
                    Manage
                  </button>
                </div>

                <div
                  v-for="(item, i) in data.experiences"
                  :key="`experience-${i}`"
                  class="tw-border-l-2 border-color-primary tw-pl-4 tw-pb-4"
                >
                  <h4 class="tw-font-bold tw-text-lg">Google.inc</h4>
                  <div class="text-primary tw-text-sm tw-font-bold">
                    {{ item.date_start | formatMonth }} -
                    {{ item.date_end | formatMonth }}
                  </div>
                  <p
                    class="text-gray-1 tw-text-sm tw-font-bold tw-mt-2"
                    style="word-break: break-word;"
                  >
                    {{ item.content }}
                  </p>
                </div>
              </div>
              <div
                class="tw-border-t tw-mx-6 tw-my-6"
                v-if="data.type !== 'company'"
              ></div>
              <div class="tw-mx-4" v-if="data.type !== 'company'">
                <div class="tw-flex tw-justify-between tw-mb-4">
                  <h3 class="tw-font-bold tw-text-xl tw-ml-4">
                    Portfolio
                  </h3>
                  <button
                    class="bg-orange-2 tw-px-2 tw-py-1 tw-rounded tw-outline-none focus:tw-outline-none"
                    @click="$router.push({ name: 'userPortfolioIndex' })"
                    v-if="isSelf"
                  >
                    Manage
                  </button>
                </div>
                <div
                  class="tw-flex tw-flex-row tw-flex-wrap tw-py-4 tw-justify-between"
                >
                  <div
                    class="tw-mb-4 tw-w-1/2 tw-inline-flex tw-items-center tw-justify-center tw-px-2"
                    v-for="(portfolio, i) in portfolios"
                    :key="`user-portfolio-${i}`"
                  >
                    <img
                      :src="portfolio.attachments[0].url"
                      alt=""
                      style="object-fit: cover; height: 260px;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右侧的内容 -->
          <div class="tw-mt-12">
            <div class="tw-mx-4">
              <div class="bg-orange-4 tw-p-4 tw-rounded tw-border">
                <div class="tw-flex tw-justify-between">
                  <h2 class="tw-text-xl tw-font-bold">News</h2>
                  <span
                    class="tw-uppercase text-primary tw-font-bold tw-text-sm tw-self-end tw-cursor-pointer"
                    @click="$router.push('/blog/18')"
                    >View All></span
                  >
                </div>

                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <p class="tw-text-sm text-gray-1 tw-mt-4">
                  No matter how challenging these times are, businesses still
                  need to get their work done. Get your projects and tasks
                  completed for low to no cost. Create a job posting, hire a
                  post-secondary student from our Beeznests platform, and get
                  your fees covered by the government. Eligibility: registered
                  Canadian business hiring a post-secondary student with their
                  PR or Canadian citizenship.
                  <img
                    class="tw-my-2"
                    src="https://api.beeznests.com/storage/files/WechatIMG9909.jpeg"
                  />
                  It’s a win-win situation for you. Hires can be part-time/full
                  time and remote/on-site. Please share this post with your
                  entrepreneur friends and family. For more information, contact
                  ourhoneycomb@beeznests.com
                </p>
              </div>
              <!--              <div class="tw-mt-4">-->
              <!--                <img-->
              <!--                  src="https://api.beeznests.com/storage/portfolios/202010/24/93_1603533311_si6KcUHKsZ.png"-->
              <!--                />-->
              <!--              </div>-->
              <!--              <div class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mt-4">-->
              <!--                <div class="tw-flex tw-justify-between">-->
              <!--                  <h2 class="tw-text-xl tw-font-bold">Recent Badges</h2>-->
              <!--                  <span-->
              <!--                    class="tw-uppercase text-primary tw-font-bold tw-text-sm tw-self-end"-->
              <!--                    >View All></span-->
              <!--                  >-->
              <!--                </div>-->

              <!--                <div class="tw-flex flex-row">-->
              <!--                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>-->
              <!--                  <div-->
              <!--                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"-->
              <!--                  ></div>-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  <div class="tw-flex tw-flex-col">-->
              <!--                    <div-->
              <!--                      class="tw-flex tw-flex-row tw-mt-4"-->
              <!--                      v-for="i in 4"-->
              <!--                      :key="`recent-${i}`"-->
              <!--                    >-->
              <!--                      <img src="@/assets/images/level.png" />-->
              <!--                      <div-->
              <!--                        class="tw-ml-2 tw-flex tw-flex-col tw-justify-between"-->
              <!--                      >-->
              <!--                        <div>-->
              <!--                          <span class="tw-text-black tw-font-bold"-->
              <!--                            >Nike Smith</span-->
              <!--                          >-->
              <!--                          <span class="text-gray-1 tw-text-sm tw-ml-2"-->
              <!--                            >has completed</span-->
              <!--                          >-->
              <!--                        </div>-->
              <!--                        <span class="tw-font-bold">LEVEL 8</span>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mt-4">
                <div class="tw-flex tw-justify-between">
                  <h2 class="tw-text-xl tw-font-bold">Following</h2>
                  <span class="text-primary tw-font-bold"
                    >({{ data.followings_count }})</span
                  >
                </div>

                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <div>
                  <div class="tw-flex tw-flex-col">
                    <div
                      class="tw-flex tw-flex-row tw-mt-4"
                      v-for="(item, i) in followings"
                      :key="`following-${i}`"
                    >
                      <img
                        :src="item.avatar"
                        style="width: 50px; height: 50px; flex: 0 0 50px;"
                      />
                      <div
                        class="tw-ml-2 tw-flex tw-justify-between tw-items-center tw-flex-grow"
                      >
                        <div
                          class="tw-flex tw-flex-col"
                          :style="
                            isLoggedIn
                              ? 'max-width: 90px;'
                              : 'max-width: 190px;'
                          "
                        >
                          <span
                            class="tw-text-black tw-font-bold tw-truncate"
                            >{{ item.name }}</span
                          >
                          <span
                            class="text-gray-1 tw-text-sm tw-truncate"
                            v-if="item.categories && item.categories.length > 0"
                          >
                            {{ item.categories[0].name }}
                          </span>
                        </div>
                        <div class="tw-self-center" v-if="isLoggedIn">
                          <button
                            class="tw-outline-none focus:tw-outline-none tw-uppercase bg-gray-2 hover:bg-primary tw-text-sm tw-font-bold tw-py-2 tw-px-2 tw-rounded-sm"
                            @click="
                              isFollowed(item.id)
                                ? unfollow(item.id)
                                : follow(item.id)
                            "
                            :disabled="isLoading(item.id)"
                          >
                            {{ isFollowed(item.id) ? 'UNFOLLOW' : 'FOLLOW'
                            }}{{ isLoading(item.id) ? '...' : null }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 底部填写 code -->
            <div
              class="bg-orange-4 tw-p-4 tw-rounded tw-border tw-mx-4 tw-mt-4"
              v-if="!data.organization_id && isSelf"
            >
              Enter Promo Code
              <div class="tw-rounded tw-bg-white tw-p-1 tw-border">
                <input
                  class="tw-outline-none focus:tw-outline-none tw-w-full"
                  v-model="code"
                />
              </div>
              <div
                class="tw-my-1"
                :class="settingCodeError ? 'tw-text-red-500' : null"
              >
                {{ settingCodeMessage }}
              </div>
              <button
                @click="settingCode"
                class="tw-mt-2 tw-w-full tw-inline-block tw-outline-none focus:tw-outline-none active:tw-outline-none tw-rounded tw-py-1 tw-uppercase bg-gray-2"
              >
                <i
                  class="fa fa-spinner fa-spin text-gray-1 tw-mr-1"
                  v-if="settingCodeLoading"
                ></i>
                Submit
              </button>
            </div>
            <!-- 底部填写 code 结束 -->
          </div>
          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import CommonHeader from './common/header'
import CommonFooter from './common/footer'
import { mapActions, mapGetters } from 'vuex'
import { fetchInfo } from '@/api/user'
import { settingCode as settingCodeRequest } from '@/api/setting'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
export default {
  components: {
    MobileDrawer,
    MobileFooter,
    MobileHeader,
    CommonFooter,
    CommonHeader,
  },
  data: () => ({
    data: {
      counts: {},
      country: {},
      identityable: {},
    },
    isFollow: false,
    portfolios: [],
    followings: [],
    settingCodeLoading: false,
    settingCodeError: false,
    settingCodeMessage: null,
    code: null,
    hasCopied: false,
    copiedMessage: null,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!to.params.id && !vm.isLoggedIn) {
        vm.$router.push('/auth/login')
      }
    })
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
    if (this.user_id > 0) {
      this.loadData()
    }
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userInfo', 'isMobile']),
    user_id() {
      return this.$route.params.id || (this.isLoggedIn ? this.userInfo.id : 0)
    },
    isSelf() {
      return this.isLoggedIn && this.userInfo.id === this.user_id
    },
  },
  methods: {
    copyLink() {
      const copyDom = document.getElementById('copy-txt')
      copyDom.select()
      var successful = document.execCommand('copy')
      try {
        const msg = successful ? 'successful' : 'unsuccessful'
        this.hasCopied = true
        this.copiedMessage = 'The Referal link is copied!'
      } catch (err) {
        console.log('unable to copy')
      }
    },
    async settingCode() {
      this.settingCodeLoading = true
      try {
        await settingCodeRequest(this.code)
        this.settingCodeError = false
        this.settingCodeMessage = 'successful'
      } catch (e) {
        this.settingCodeError = true
        if (e.response && e.response.data && e.response.data.message) {
          this.settingCodeMessage = e.response.data.message
        } else {
          this.settingCodeMessage = 'Server Error!'
        }
      } finally {
        this.settingCodeLoading = false
      }
    },
    async loadData() {
      const {
        data,
        isFollowed,
        portfolios,
        followings,
        isFollows,
      } = await fetchInfo(this.user_id)
      this.data = data
      this.isFollow = isFollowed
      this.portfolios = portfolios
      this.followings = followings
      if (this.isLoggedIn) {
        this.$store.dispatch('follow/update', isFollows)
      }
    },
    isFollowed(id) {
      const index = this.$store.state.follow.data.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.data[index].followed
      return false
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    logout() {
      // const user_id = this.user_id
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({
          name: 'Index',
        })
      })
    },
    ...mapActions({
      follow: 'follow/follow',
      unfollow: 'follow/unfollow',
    }),
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.border-color-primary {
  border-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-5 {
  background-color: #fff9f1;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
.bg-main {
  background-color: #f5f9ff;
}
.bg-profile {
  background-image: url('~@/assets/images/profile-top.png');
}
.bg-blue-1 {
  background-color: #e1f6ff;
}
.text-blue-1 {
  color: #03a9f4;
}
.bg-blue-2 {
  background-color: #f0eff9;
}
.text-blue-2 {
  color: #1600db;
}
</style>
