<template>
  <div class="tw-mt-6 tw-mr-4" style="max-width: 186px; width: 186px;">
    <div
      class="tw-border tw-pt-4 tw-rounded-lg tw-text-center tw-overflow-hidden"
      style="border-color: #ddd;"
    >
      <div class="tw-flex tw-justify-center tw-relative">
        <div
          class="tw-w-20 tw-h-20 tw-bg-yellow-100 tw-rounded-full tw-border-2 tw-shadow tw-border-white tw-overflow-hidden"
        >
          <img :src="item.avatar" />
        </div>
        <div
          class="tw-absolute tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-border-2 tw-shadow tw-border-white tw-overflow-hidden"
          style="transform: translateX(14px); left: 50%; bottom: 4px;"
        >
          <img :src="item.country_flag" />
        </div>
      </div>
      <h1
        class="tw-font-bold tw-items-center tw-flex tw-justify-center tw-text-xl tw-my-1 tw-mx-2 tw-max-w-full"
      >
        <span class="tw-block tw-truncate">{{ item.name | noName }}</span>
        <img
          v-if="item.organization && item.organization.badge"
          class="tw-w-6 tw-h-6"
          :alt="item.organization.description"
          :title="item.organization.description"
          :src="`https://beeznests-cdn.fra1.cdn.digitaloceanspaces.com/img/badge_${item.organization.badge}.png`"
        />
      </h1>
      <div class="text-gray-1 tw-text-sm tw-h-4 text-w-full">
        <template v-if="item.categories && item.categories.length > 0">
          {{ item.categories[0].name }}
        </template>
        <template v-else>
          No Skills
        </template>
      </div>
      <div
        class="tw-my-1 tw-inline-block tw-text-xs tw-flex tw-items-center tw-justify-center"
      >
        <i
          class="fa fa-star text-primary"
          v-for="index in 4"
          :key="`student-${i}-star-${index}`"
        ></i>
        <i class="fa fa-star-half-o text-primary" aria-hidden="true"></i>
        <span class="text-primary tw-text-xs tw-font-bold tw-align-middle"
          >({{ item.counts.rate | toFixed }})</span
        >
      </div>
      <div
        class="text-gray-1 tw-px-4 tw-leading-tight tw-overflow-hidden tw-flex tw-justify-center tw-text-center tw-h-12 tw-text-xs"
        style="height: 45px;"
      >
        {{ item.introduction }}
      </div>
      <div class="tw-mt-4">
        <router-link
          :to="{ name: 'NewProfile', params: { id: item.id } }"
          class="tw-text-xs tw-block border-color-2 bg-gray-2 hover:tw-bg-yellow-300 hover:tw-bg-orange-300 tw-px-4 tw-py-2 tw-rounded-b tw-uppercase"
        >
          View Details
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item', 'i'],
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.border-color-gray {
  border-color: #ddd;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
</style>
