<template>
  <v-row justify="center" no-gutters>
    <!--v-col cols="12" lg="8">
      <v-breadcrumbs :items="items" divider="-"></v-breadcrumbs>
    </v-col-->
    <v-col cols="12" lg="8" class="mt-5">
      <v-row>
        <v-col cols="12" md="9">
          <loading
            v-if="loading || data.length <= 0"
            :loading="loading"
          ></loading>
          <v-card elevation="0" outlined class="pa-6" v-else>
            <v-card-title>
              <h3 class="text-h4">{{ data.title }}</h3>
              <v-list-item class="pl-0">
                <v-list-item-avatar size="48">
                  <v-avatar>
                    <v-img class="elevation-6" :src="data.user.avatar"></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <user-name
                    :name="data.user.name"
                    :type="data.user.type"
                    :id="data.user.id"
                    :organization="data.user.organization"
                  ></user-name>
                  <span class="caption"> {{ data.created_at | format }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-card-title>
            <v-card-text v-html="data.content"> </v-card-text>
            <v-sheet>
              <v-sheet v-if="data.has_meeting">
                <span class="primary--text mr-2">Webinar Link:</span>
                <a :href="data.meeting" target="_blank">{{ data.meeting }}</a>
              </v-sheet>
              <v-sheet v-if="data.has_meeting">
                <span class="primary--text mr-2">Webinar Date:</span>
                {{ data.meeting_at | format }}
              </v-sheet>
              <v-sheet v-if="data.has_meeting" class="mt-4">
                <v-alert text
                  >There is an associated webinar for the following company
                  challenge. We encourage you to join to get to know the owner
                  and challenge better!</v-alert
                >
              </v-sheet>
              <v-sheet v-if="data.closed_at">
                <span class="primary--text mr-2">Deadline:</span>
                {{ data.closed_at | format }}
              </v-sheet>
            </v-sheet>
          </v-card>
          <v-sheet class="mt-12 mb-1 title">
            All Replies({{ data.reply_count }})
          </v-sheet>
          <v-sheet class="mt-10" v-if="repliesLoading || replies.length <= 0">
            <loading
              :loading="repliesLoading"
              :noData="replies.length <= 0 ? 'No Replies' : ''"
            ></loading>
          </v-sheet>

          <template v-else>
            <transition-group name="flip-list">
              <template v-for="reply in replies">
                <challenge-reply-item
                  :data="reply"
                  @update="updateReply"
                  :challenge-user-id="data.user.id"
                  :key="`reply-${reply.id}`"
                  @best="handleBest(reply.id)"
                ></challenge-reply-item>
              </template>
            </transition-group>
            <v-pagination
              v-if="pages.last_page > 1"
              v-model="pages.current_page"
              :length="pages.last_page"
            ></v-pagination>
          </template>
        </v-col>
        <v-col cols="3">
          <v-sheet>
            <v-btn
              block
              rounded
              color="orange"
              class="white--text"
              v-if="!isLoggedIn"
              :to="{ name: 'AuthLogin' }"
            >
              Login
            </v-btn>
          </v-sheet>
          <v-sheet>
            <v-btn
              block
              rounded
              color="orange"
              class="white--text"
              @click="showReply"
              v-if="isLoggedIn"
            >
              Reply
            </v-btn>
          </v-sheet>
          <!--v-sheet class="mt-5">
            <v-btn
              block
              rounded
              color="orange"
              class="white--text"
              v-if="isLoggedIn"
            >
              Follow
            </v-btn>
          </v-sheet-->
          <template v-if="isLoggedIn && data.user.id === userInfo.id">
            <v-sheet class="mt-5">
              <v-btn
                block
                rounded
                color="orange"
                class="white--text"
                :to="{ name: 'challengeEdit', params: { id: data.id } }"
              >
                Edit
              </v-btn>
            </v-sheet>
            <v-sheet class="mt-5">
              <v-btn
                block
                rounded
                color="error"
                class="white--text"
                @click="deleteDialog = true"
              >
                Delete
              </v-btn>
            </v-sheet>
          </template>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="replyDialog" persistent fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="replyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Reply</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-sheet style="min-height: 3vh;"></v-sheet>
              <label class="mt-5">Reply Content</label>
              <froala v-model="content"></froala>
              <v-switch
                v-model="isHidden"
                class="mx-2"
                :label="
                  isHidden
                    ? 'Viewable only to me and publisher'
                    : 'Can be viewed by anyone'
                "
              ></v-switch>
              <v-btn
                color="info"
                :disabled="!form"
                @click="replySubmit"
                :loading="replySubmitLoading"
                >Reply</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline info lighten-2 white--text">
          Confirm
        </v-card-title>

        <v-card-text class="mt-4">
          Are you sure you want to delete it?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteChallenge"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { fetchData, fetchReplies, reply, deleteData } from '@/api/challenge'
import ChallengeReplyItem from '@/components/Challenge/ReplyItem'
import Loading from '@/components/Loading'
import Froala from '@/components/Froala'
import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  components: {
    Loading,
    ChallengeReplyItem,
    Froala,
  },
  data() {
    return {
      items: [
        {
          text: 'Index',
          disabled: false,
          href: '/',
        },
        {
          text: 'Challenges',
          disabled: false,
          href: '/challenges',
        },
        {
          text: 'Details',
          disabled: true,
          href: '/',
        },
      ],
      data: {
        user: {},
      },
      replies: [],
      loading: false,
      repliesLoading: false,
      pages: {
        current_page: 1,
        last_page: 0,
      },
      replyDialog: false,
      content: '',
      isHidden: false,
      replySubmitLoading: false,
      deleteLoading: false,
      deleteDialog: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    form() {
      return !!this.content
    },
    ...mapGetters(['userInfo', 'isLoggedIn']),
  },
  watch: {
    'pages.current_page'() {
      this.loadReplies()
    },
  },
  async mounted() {
    await this.loadDetails()
  },
  methods: {
    async loadDetails() {
      try {
        this.loading = true
        const { data } = await fetchData(this.id)
        this.data = data
        if (data.id) {
          await this.loadReplies()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async loadReplies() {
      try {
        this.repliesLoading = true
        const requestData = {
          page: this.pages.current_page,
        }
        const { data, meta, followed } = await fetchReplies(
          this.id,
          requestData
        )
        this.replies = data
        this.pages = meta
        if (followed) {
          this.$store.dispatch('follow/update', followed)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.repliesLoading = false
      }
    },
    showReply() {
      // 判断是否有权限
      if (!this.isLoggedIn || !this.userInfo) {
        this.$router.push({ name: 'AuthLogin' })
        return
      } else if (!this.userInfo.type) {
        this.$router.push({ name: 'userSettings' })
        this.$message('Please Complete Your Profile First')
        return
      }
      this.replyDialog = true
    },
    async replySubmit() {
      if (this.replySubmitLoading) return
      try {
        this.replySubmitLoading = true
        const postData = {
          content: this.content,
          is_hidden: this.isHidden,
        }
        const { data } = await reply(this.id, postData)
        // 把 data 数据加入内容
        this.replies = [data].concat(this.replies)
        this.replyDialog = false
        this.$message('Replied Successfully!')
      } catch (e) {
        console.log(e)
      } finally {
        this.replySubmitLoading = false
      }
    },
    updateReply({ id, type }) {
      if (type === 'liked') {
        const index = this.replies.findIndex((item) => item.id === id)
        this.$set(this.replies[index], 'is_liked', true)
        this.$set(
          this.replies[index],
          'likes_count',
          ++this.replies[index].likes_count
        )
      } else if (type === 'unliked') {
        const index = this.replies.findIndex((item) => item.id === id)
        this.$set(this.replies[index], 'is_liked', false)
        this.$set(
          this.replies[index],
          'likes_count',
          --this.replies[index].likes_count
        )
      }
    },
    handleBest(id) {
      const index = this.replies.findIndex((item) => item.id === id)
      const moveIndex = this.replies.findIndex((item) => !item.is_best)
      this.$set(this.replies[index], 'is_best', true)
      if (moveIndex !== index) {
        // 切换顺序
        const data = Object.assign({}, this.replies[index])
        _.remove(this.replies, (item, i) => i === index)
        this.replies.splice(moveIndex, 0, data)
      }
    },
    async deleteChallenge() {
      if (this.deleteLoading) return
      this.deleteLoading = true
      try {
        await deleteData(this.id)
        this.$message('Deleted Successfully!')
        this.$router.push({ name: 'challengeIndex' })
      } catch (e) {
        this.deleteLoading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.flip-list-move {
  transition: transform 1s;
}
</style>
