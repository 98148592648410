import Vue from 'vue'
import ax from 'axios'
import axios from '@/plugins/axios'
import {} from '@/api/'
/**
 * 文件数组
 * @type {Array} {id: uuid, data: '', status: ''}
 */
let data = []
let autoId = 0
const createId = () => ++autoId
const add = (file) => {
  const id = createId()
  data.push({
    id,
    data: file,
    url: '',
    fileId: 0,
    status: 'waiting',
    uploadId: null,
    progress: 0,
  })
  return id
}

const remove = async (id) => {
  cancel(id)
  const fileId = getAttr(id, 'fileId')
  update(id, 'status', 'deleting')
  await deleteFile(fileId)
  const index = getIndex(id)
  data.splice(index, 1)
}
const deleteFile = (fileId) => {
  if (fileId > 0) {
    return axios.post('edit/delete', {
      id: fileId,
    })
  }
}
const upload = (id) => {
  const index = getIndex(id)

  const fileData = index > -1 ? data[index] : false
  if (fileData) {
    const formData = new FormData()
    formData.append('file', fileData.data)
    const CancelToken = ax.CancelToken
    const source = CancelToken.source()
    update(id, 'uploadId', source)
    updateStatus(id, 'uploading')
    axios
      .post('portfolio/upload', formData, {
        cancelToken: source.token,
        onUploadProgress: function (e) {
          update(id, 'progress', ((e.loaded / e.total) * 100) | 0)
        },
        timeout: 60 * 60 * 1000,
      })
      .then((res) => {
        if (res.success) {
          update(id, 'url', res.url)
          update(id, 'fileId', res.attachment)
          updateStatus(id, 'complete')
        } else {
          updateStatus(id, 'fail')
        }
      })
      .catch((error) => {
        updateStatus(id, 'fail')
      })
  }
}

const cancel = (id) => {
  const index = getIndex(id)
  if (index >= 0 && data[index].status === 'uploading') {
    data[index].source.cancel()
  }
}
const update = (id, key, value) => {
  const index = getIndex(id)
  if (index >= 0) {
    Vue.set(data[index], key, value)
  }
}
const updateStatus = (id, status) => {
  update(id, 'status', status)
}

const getStatus = (id) => {
  const index = getIndex(id)
  if (index >= 0) {
    return data[index].status
  }
  return null
}
const getIndex = (id) => {
  return data.findIndex((item) => item.id === id)
}

const getAttr = (id, key) => {
  const index = getIndex(id)
  if (index > -1) return data[index][key]
  else return null
}
const pushCompleteData = (oldData) => {
  oldData.forEach((item) => {
    const id = createId()
    data.push({
      id,
      data: item.url,
      url: item.url,
      fileId: item.id,
      status: 'complete',
      uploadId: null,
      progress: 100,
    })
  })
}
const init = () => {
  data = []
  autoId = 0
}
export { add, remove, upload, getStatus, data, pushCompleteData, init }
