import axios from '@/plugins/axios'

export const fetchIndex = (params) => {
  return axios.get('hires', {
    params,
  })
}

export const fetchStudents = (params) => {
  return axios.get('students', {
    params,
  })
}
