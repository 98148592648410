<template>
  <div class="tw-font-rubik">
    <template v-if="!isMobile">
      <index-header></index-header>
      <div
        class="tw-h-32 w-full"
        style="background-color: rgba(254, 204, 23, 0.85);"
      ></div>
      <div
        class="tw-h-12 w-full"
        style="background-color: rgba(254, 204, 23, 0.85);"
      ></div>
      <div class="banner">
        <div class="top tw-pb-0" style="background-position: top center;">
          <h1
            class="tw-text-center"
            style="font-size: 50px; line-height: 1; padding-bottom: 24px;"
          >
            Student Freelance Platform
          </h1>
          <div
            class="tw-text-center tw-self-center tw-text-lg tw-mt-4 tw-pb-12"
          >
            Beeznests help companies access the best student candidates referred
            by our partner colleges for their paid internship/ project/ job
            opportunities.<br />
            We also help companies outsource projects/tasks to students and
            academic teams(mentor +students).
            <br />
            We offer remote academic support to the business community.
          </div>
          <div class="tw-flex tw-flex-col tw-justify-center">
            <!--h3
            class="tw-text-center tw-self-center tw-text-lg tw-mt-4"
            style="max-width: 449px; font-weight: 400;"
          >
            More than 98% of students manage to attain their dream job after
            completing 3 projects
          </h3-->
            <!--            <h3-->
            <!--              class="tw-text-center tw-self-center tw-text-lg tw-mt-4"-->
            <!--              style="max-width: 549px; font-weight: 400;"-->
            <!--            >-->
            <!--              Special Hiring model - Academic Team (Students work for free)-->
            <!--              <br />-->
            <!--              <br />-->
            <!--              Ensure high quality project-based work. Part-time and remote.<br />-->
            <!--              Academic teams consist of one industry expert/professor who works-->
            <!--              with trained students to tackle your business problems.-->
            <!--            </h3>-->
            <!--            <div class="tw-flex tw-justify-center">-->
            <!--              <video src="@/assets/introduction.mp4" controls></video>-->
            <!--            </div>-->
            <div class="tw-text-center tw-mt-8">
              <button
                class="tw-uppercase tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-text-sm tw-font-bold"
                style="font-weight: 500;"
                @click="$router.push('/new-register')"
                v-if="!isLoggedIn"
              >
                Join us <i class="fa fa-arrow-circle-o-right"></i>
              </button>
              <button
                class="tw-uppercase tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-text-sm tw-font-bold"
                style="font-weight: 500;"
                @click="
                  $router.push({ name: 'articleShow', params: { id: 17 } })
                "
                v-if="isLoggedIn"
              >
                How to use our platform
                <i class="fa fa-arrow-circle-o-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-justify-center" style="margin-top: 80px;">
          <img
            src="@/assets/images/banner-image.png"
            style="max-width: 1080px;"
          />
        </div>
      </div>
      <div class="tw-mt-20 tw-container tw-mx-auto">
        <div
          class="tw-flex tw-flex-row tw-justify-between tw-mx-auto"
          style="max-width: 750px; margin-top: 80px;"
        >
          <div class="tw-self-center" style="max-width: 340px; width: 340px;">
            <div class="tw-text-4xl">
              <span class="text-primary">College</span><br />
              Referral Job Board <br />
            </div>
            <span class="tw-text-base"
              >(Only for Paid internship/Project/ Full-Time Job)</span
            >
            <div
              class="bg-primary tw-mt-3"
              style="width: 80px; height: 3px;"
            ></div>
            <div
              class="tw-text-gray-500 tw-max-w-md tw-text-sm"
              style="margin-top: 20px; color: #8a92ab;"
            >
              <!--              Ensure high quality project-based work. Part-time. Academic teams-->
              <!--              consist of one industry expert/professor who works with trained-->
              <!--              students to tackle your business problems. Solution proposals can-->
              <!--              be provided upon request.-->
              Access our “Referred Student Database” by making a job post. Hire
              students who have been strongly recommended by our partner
              colleges/universities or Beeznests. These students have completed
              some professional training; students are prepared to work and are
              looking for paid positions.
            </div>
            <div class="tw-mt-8">
              <router-link
                :to="{ name: 'jobsIndex' }"
                class="tw-border border-color-2 bg-orange-2 tw-px-3 tw-text-sm tw-py-3 tw-rounded"
                style="font-weight: 500;"
                >View Jobs<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
              ></router-link>
              <router-link
                :to="{ name: 'jobsCreate' }"
                class="tw-border tw-px-6 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
                style="font-weight: 500; color: #8a92ab;"
                >Create Job<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
              ></router-link>
            </div>
          </div>
          <div class="">
            <img
              src="@/assets/images/join-our.png"
              style="width: 330px; height: 350px;"
            />
          </div>
        </div>
        <div
          class="tw-flex tw-flex-row tw-justify-between tw-mx-auto"
          style="width: 750px; margin-top: 80px;"
        >
          <div class="">
            <img
              src="@/assets/images/index-task.png"
              style="max-width: 337px; max-height: 337px;"
            />
          </div>
          <div class="tw-self-center">
            <div class="tw-text-4xl">
              <span class="text-primary">Project-Based</span><br />
              Work
            </div>
            <div
              class="bg-primary tw-mt-3"
              style="width: 80px; height: 3px;"
            ></div>
            <div
              class="tw-text-gray-500 tw-max-w-md tw-mt-8 tw-text-sm"
              style="color: #8a92ab;"
            >
              Two options.<br />
              Option 1: Post your short-term project (can be volunteer-based
              work), get help from 16,000 students in our community.<br />
              Option 2: Hire the Beeznests Academic Team for your project.<br />
              The academic team consists of one industry expert/professor who
              works with students to tackle your business problems. Solution
              proposals can be provided upon request.
            </div>
            <div class="tw-mt-8">
              <router-link
                :to="{ name: 'NewProjects' }"
                class="tw-border border-color-2 tw-text-xs bg-orange-2 tw-px-6 tw-py-3 tw-rounded hover-color tw-transition tw-ease-in-out tw-duration-150"
                style="font-weight: 500;"
                >View Projects<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
              ></router-link>
              <router-link
                :to="{ name: 'projectCreate' }"
                class="tw-border tw-px-6 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
                style="font-weight: 500; color: #8a92ab;"
                >Create Project<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
              ></router-link>
            </div>
          </div>
        </div>
        <!--博客-->
        <div
          class="tw-flex tw-flex-row tw-justify-between tw-mx-auto"
          style="margin-top: 80px; width: 750px;"
        >
          <div class="tw-self-center" style="width: 340px; max-width: 340px;">
            <div class="tw-text-4xl">
              <span class="text-primary">Special hiring model </span><br />
              Beeznests Academic Team
            </div>
            <div
              class="bg-primary tw-mt-3"
              style="width: 80px; height: 3px;"
            ></div>
            <div
              class="tw-text-gray-500 tw-text-sm"
              style="margin-top: 20px; color: #8a92ab;"
            >
              Ensure high-quality project-based work. Part-time. Academic teams
              consist of one industry expert/professor who works with trained
              students to tackle your business problems. Solution proposals can
              be provided upon request.
            </div>
            <div class="tw-mt-8">
              <router-link
                :to="{
                  name: 'NewProjects',
                  params: { type: 'academic-team' },
                }"
                class="tw-border border-color-2 bg-orange-2 tw-px-3 tw-py-3 tw-rounded tw-text-sm"
                style="font-weight: 500;"
                >View Jobs<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
              ></router-link>
              <router-link
                :to="{ name: 'projectCreate' }"
                style="color: #8a92ab; font-weight: 500;"
                class="tw-border tw-px-3 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
                >Post Job<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
              ></router-link>
            </div>
          </div>
          <div class="" style="margin-right: 32px;">
            <img
              src="@/assets/images/index-discussions.png"
              style="
                width: 354px;
                height: 333px;
                max-width: 354px;
                max-height: 333px;
              "
            />
          </div>
        </div>
        <!--博客结束-->

        <div
          class="tw-flex tw-flex-row tw-justify-between tw-mx-auto"
          style="margin-top: 80px; width: 750px;"
        >
          <div class="" style="margin-right: 32px;">
            <img
              src="@/assets/images/index-task.png"
              style="
                width: 354px;
                height: 333px;
                max-width: 354px;
                max-height: 333px;
              "
            />
          </div>
          <div class="tw-self-center" style="width: 340px; max-width: 340px;">
            <div class="tw-text-4xl">
              <span class="text-primary">Access </span><br />
              Our Bootcamps
            </div>
            <div
              class="bg-primary tw-mt-3"
              style="width: 80px; height: 3px;"
            ></div>
            <div
              class="tw-text-gray-500 tw-text-sm"
              style="margin-top: 20px; color: #8a92ab;"
            >
              Our Beeznests Bootcamps involve various opportunities. Get
              mentored by industry experts through short courses and hands-on
              projects in our mentorship program. Apply to be part of an
              Academic Team with your mentor and take on large-scaled paid
              projects outsourced by companies. Participate in our high end
              programs and job shadow CEOs of big name companies.
            </div>
            <div class="tw-mt-8">
              <router-link
                :to="{ name: 'mentorIndex' }"
                class="tw-border border-color-2 bg-orange-2 tw-px-3 tw-py-3 tw-rounded tw-text-sm"
                style="font-weight: 500;"
                >View Mentor Programs<i
                  class="fa fa-arrow-circle-o-right tw-ml-1"
                ></i
              ></router-link>
              <!--router-link
              :to="{ name: 'challengeCreate' }"
              style="color: #8a92ab; font-weight: 500;"
              class="tw-border tw-px-3 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
              >Create Discussion<i
                class="fa fa-arrow-circle-o-right tw-ml-1"
              ></i
            ></router-link-->
            </div>
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-items-center tw-flex-col"
        style="
          background-color: #fffbef;
          position: relative;
          margin-top: 88px;
          padding-top: 80px;
          padding-bottom: 80px;
        "
      >
        <!--div
        class="honeycomb"
        style="position: absolute; left: 3.5rem; top: -4.75rem;"
      >
        <img src="@/assets/images/honeycomb.png" style="width: 116px;" />
      </div-->
        <div
          class="honeycomb"
          style="position: absolute; right: 4.25rem; bottom: 5.25rem;"
        >
          <img src="@/assets/images/honeycomb2.png" style="width: 116px;" />
        </div>
        <h3 class="tw-text-4xl">
          Our <span class="text-primary">Beeznests</span> Story
        </h3>
        <div class="tw-mt-8 tw-max-w-lg">
          <img src="@/assets/images/index-story.png" />
        </div>
        <div
          class="tw-text-center tw-text-gray-500 tw-mt-6"
          style="color: #8a92ab; max-width: 512px;"
        >
          Our company mission is to bridge the gap between students and
          companies by overcoming a geographical boundary. By creating a
          platform that solely focuses on offering remote opportunities, we
          provide convenience and limitless opportunities for everyone.
          Certificates are handed out for every opportunity provided:
          project-based work, internships, and mentorship programs.
        </div>
        <div class="tw-mt-10">
          <router-link
            :to="{ name: 'NewOur' }"
            class="tw-px-3 tw-py-3 bg-orange-2 tw-rounded tw-text-sm tw-tracking-tighter"
            style="font-weight: 500;"
            >View More
            <i class="fa fa-arrow-circle-o-right tw-ml-1"></i>
          </router-link>
        </div>
      </div>
      <div
        class="tw-container tw-mx-auto"
        style="max-width: 832px; width: 832px; margin-top: 80px;"
      >
        <div class="tw-flex tw-justify-between">
          <h3 class="tw-text-4xl">
            <span class="text-primary">Featured</span> Interests
          </h3>
          <nav class="tw-flex tw-flex-shrink tw-flex-grow-0 tw-py-2 tw-text-sm">
            <span
              @click="showTab = 'project'"
              class="tw-cursor-pointer tw-border tw-px-4 tw-py-2 tw-rounded tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
              :class="
                showTab === 'project'
                  ? 'border-color-2 bg-orange-2 hover-box-shadow'
                  : ''
              "
              >projects</span
            >
            <span
              @click="showTab = 'discussion'"
              class="tw-cursor-pointer tw-border tw-px-2 tw-py-2 tw-rounded tw-ml-5 tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
              :class="
                showTab === 'discussion'
                  ? 'border-color-2 bg-orange-2 hover-box-shadow'
                  : ''
              "
              >discussions</span
            >
            <span
              @click="showTab = 'student'"
              class="tw-cursor-pointer tw-border tw-px-2 tw-py-2 tw-rounded tw-ml-5 tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
              :class="
                showTab === 'student'
                  ? 'border-color-2 bg-orange-2 hover-box-shadow'
                  : ''
              "
              >Student Freelancers</span
            >
          </nav>
        </div>
        <transition name="fade" mode="out-in">
          <!--任务-->
          <div key="project" v-if="showTab === 'project'">
            <div class="tw-mt-12">
              <div
                class="tw-rounded tw-border tw-flex tw-flex-row tw-justify-between tw-mb-5 project-box"
                style="padding: 14px;"
                v-for="(item, i) in projects"
                :key="`task-${i}`"
              >
                <div class="tw-flex tw-flex-grow-0 tw-flex-shrink">
                  <img
                    :src="item.user.avatar"
                    style="width: 60px; height: 60px;"
                    class="tw-rounded-lg"
                  />
                  <div
                    class="tw-flex tw-flex-col tw-justify-between"
                    style="
                      max-width: 220px;
                      width: 220px;
                      height: 60px;
                      overflow: hidden;
                      margin-left: 14px;
                    "
                  >
                    <div class="tw-truncate">
                      <span
                        class="tw-text-x"
                        style="line-height: 1; font-weight: 500;"
                        >{{ item.position }}</span
                      >
                      <span
                        class="tw-ml-4 tw-text-gray-600 tw-text-xs"
                        style="color: #8a92ab;"
                        >{{ item.user.name }}</span
                      >
                    </div>
                    <div class="text-primary">
                      {{ item.cost_min > 0 ? `$ ${item.cost_min}` : 'Free' }}
                    </div>
                    <div
                      class="tw-text-gray-600 tw-text-sm"
                      style="color: #8a92ab;"
                    >
                      <i class="fa fa-map-marker"></i>
                      {{ item.user.country.name }}
                    </div>
                  </div>
                </div>
                <div class="tw-self-center tw-space-x-2">
                  <span
                    class="tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-inline-flex tw-items-center"
                    style="background-color: #eff3f9; color: #8a92ab;"
                    ><i class="fa fa-briefcase" style="margin-right: 2px;"></i
                    >Remote</span
                  >
                  <span
                    class="tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-inline-flex tw-items-center"
                    style="background-color: #eff3f9; color: #8a92ab;"
                    ><i class="fa fa-fire" style="margin-right: 2px;"></i
                    >Urgent</span
                  >
                  <span
                    class="tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-inline-flex tw-items-center"
                    style="background-color: #eff3f9; color: #8a92ab;"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.881"
                      height="10.411"
                      viewBox="0 0 13.881 10.411"
                      style="margin-right: 2px;"
                    >
                      <path
                        d="M13.447,13.175H1.735V4.934A.434.434,0,0,0,1.3,4.5H.434A.434.434,0,0,0,0,4.934v9.109a.868.868,0,0,0,.868.868H13.447a.434.434,0,0,0,.434-.434v-.868A.434.434,0,0,0,13.447,13.175Zm-.868-7.808h-3.2a.651.651,0,0,0-.46,1.111l.878.878L7.808,9.346,5.819,7.357a.867.867,0,0,0-1.227,0L2.73,9.219a.434.434,0,0,0,0,.614l.613.613a.434.434,0,0,0,.614,0L5.205,9.2l1.989,1.989a.867.867,0,0,0,1.227,0l2.6-2.6.878.878A.651.651,0,0,0,13.013,9V5.8A.433.433,0,0,0,12.579,5.368Z"
                        transform="translate(0 -4.5)"
                        fill="#8a92ab"
                      /></svg
                    >Featured</span
                  >
                </div>
                <div class="tw-self-center">
                  <router-link
                    :to="{ name: 'projectShow', params: { id: item.id } }"
                    class="tw-border tw-py-3 tw-px-4 tw-rounded tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                    style="font-weight: 500;"
                    >APPLY NOW<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="tw-text-center tw-mt-4">
              <router-link
                :to="{ name: 'NewProjects' }"
                class="tw-uppercase text-orange-2 tw-text-xs tw-font-bold"
                >See more jobs<i class="fa fa-arrow-circle-o-right tw-ml-1"></i>
              </router-link>
            </div>
          </div>
          <!--任务结束-->
          <!--学生-->
          <div key="student" v-if="showTab === 'student'">
            <div class="tw-mt-12">
              <div
                class="tw-flex tw-flex-row tw-flex-wrap tw--mt-6 tw--mr-4 tw-justify-between"
              >
                <template v-for="(item, i) in students.slice(0, 8)">
                  <user-item
                    :item="item"
                    :i="i"
                    :key="`user-item-${i}`"
                  ></user-item>
                </template>
              </div>
            </div>
            <div class="tw-text-center tw-mt-4">
              <router-link
                :to="{ name: 'NewNetwork' }"
                class="tw-uppercase text-orange-2 tw-text-xs tw-font-bold"
                >See more network<i
                  class="fa fa-arrow-circle-o-right tw-ml-1"
                ></i>
              </router-link>
            </div>
          </div>
          <!--学生结束-->
          <!--讨论-->
          <div key="discussion" v-if="showTab === 'discussion'">
            <div class="tw-mt-12">
              <div
                class="tw-rounded tw-border tw-flex tw-flex-row tw-justify-between tw-mb-5 project-box"
                style="padding: 14px;"
                v-for="(item, i) in discussions.slice(0, 5)"
                :key="`discussion-${i}`"
              >
                <div class="tw-flex tw-flex-grow-0 tw-flex-shrink">
                  <img
                    :src="item.user.avatar"
                    style="width: 60px; height: 60px;"
                    class="tw-rounded-lg"
                  />
                  <div
                    class="tw-flex tw-flex-col tw-justify-between"
                    style="
                      max-width: 220px;
                      width: 220px;
                      height: 60px;
                      overflow: hidden;
                      margin-left: 14px;
                    "
                  >
                    <div class="tw-truncate">
                      <span
                        class="tw-text-x"
                        style="line-height: 1; font-weight: 500;"
                        >{{ item.title }}</span
                      >
                      <span
                        class="tw-ml-4 tw-text-gray-600 tw-text-xs"
                        style="color: #8a92ab;"
                        >{{ item.user.name }}</span
                      >
                    </div>
                    <div
                      class="tw-text-gray-600 tw-text-sm"
                      style="color: #8a92ab;"
                    >
                      <i class="fa fa-map-marker"></i>
                      {{ item.user.country.name }}
                    </div>
                  </div>
                </div>
                <div class="tw-self-center">
                  <router-link
                    :to="{ name: 'challengeShow', params: { id: item.id } }"
                    class="tw-border tw-py-3 tw-px-4 tw-rounded tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                    style="font-weight: 500;"
                    >View More<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
                  ></router-link>
                </div>
              </div>
              <div class="tw-text-center tw-mt-4">
                <router-link
                  :to="{ name: 'NewDiscussion' }"
                  class="tw-uppercase text-orange-2 tw-text-xs tw-font-bold"
                  >See more discussions<i
                    class="fa fa-arrow-circle-o-right tw-ml-1"
                  ></i>
                </router-link>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div
        class="tw-flex tw-items-center tw-justify-center"
        style="
          background-color: #fffbef;
          margin-top: 80px;
          padding-top: 50px;
          padding-bottom: 50px;
        "
      >
        <div style="margin-right: 80px;">
          <img src="@/assets/images/our-1.png" style="width: 260px;" />
        </div>
        <div style="max-width: 510px;">
          <h2 class="tw-text-4xl tw-text-center">
            What Our <span class="text-primary">Clients Say</span>
          </h2>
          <div
            class="tw-text-gray-500 tw-text-center tw-mt-10"
            style="font-style: italic; color: #8a92ab;"
          >
            Beeznets is safe haven for students and companies to interact and
            share opportunities. Their community showcases diversity,
            opportunity and hope for students to tackle real-world problems
            while aiding businesses by providing unique young talent from around
            the world) followed by name of person and Position/Company
          </div>
          <div
            style="margin-top: 20px; font-weight: 500;"
            class="tw-text-center"
          >
            Thamina Rahman Anny
          </div>
          <div
            style="color: #ff9800; line-height: 20px;"
            class="tw-text-center tw-text-xs"
          >
            Product Designer @cp
          </div>
          <div class="tw-text-center tw-space-x-1">
            <i
              class="fa fa-star text-primary tw-text-xs"
              v-for="index in 5"
              :key="`student-star-${index}`"
            ></i>
          </div>
          <div class="tw-flex tw-justify-center">
            <div class="tw-text-center tw-inline-flex tw-justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="16"
                viewBox="0 0 60 16"
              >
                <g transform="translate(-930 -5634)">
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(930 5637)"
                    fill="#fecc17"
                    opacity="0.502"
                  />
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(980 5637)"
                    fill="#fecc17"
                    opacity="0.502"
                  />
                  <g
                    transform="translate(952 5634)"
                    fill="rgba(37,38,85,0)"
                    stroke="#ff9800"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <circle cx="8" cy="8" r="8" stroke="none" />
                    <circle cx="8" cy="8" r="7" fill="none" />
                  </g>
                  <circle
                    cx="3"
                    cy="3"
                    r="3"
                    transform="translate(957 5639)"
                    fill="#ff9800"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div>
          <img
            src="@/assets/images/our-2.png"
            style="width: 260px; margin-left: 80px;"
          />
        </div>
      </div>
      <div class="tw-mx-auto" style="max-width: 1024px; margin-top: 90px;">
        <div class="tw-text-center tw-text-2xl tw-font-medium">
          Our Partners
        </div>
        <div class="tw-flex tw-flex-wrap tw-justify-center tw-mt-6">
          <a
            :href="i.link"
            v-for="i in friendLinks"
            :key="`logo-${i.id}`"
            class="tw-mx-4 tw-self-center"
          >
            <img
              :src="i.image"
              alt="logo"
              class="link-logo"
              style="max-width: 102px; max-height: 102px;"
            />
          </a>
        </div>
      </div>
      <!--      <div class="tw-mx-auto" style="max-width: 1024px; margin-top: 60px;">-->
      <!--        <h2 class="tw-text-center" style="font-size: 32px;">-->
      <!--          <span class="text-primary">Choose your</span> Membership Plan-->
      <!--        </h2>-->
      <!--        <div-->
      <!--          class="tw-mx-auto tw-grid tw-grid-cols-3 tw-gap-10 tw-mt-10"-->
      <!--          style="max-width: 880px;"-->
      <!--        >-->
      <!--          <div-->
      <!--            class="tw-border tw-relative plan-basic"-->
      <!--            style="border-radius: 13px; border: solid 1px #dddddd;"-->
      <!--          >-->
      <!--            <div-->
      <!--              class="top tw-absolute tw-inset-x-0 tw-top-0"-->
      <!--              style="-->
      <!--                border-radius: 13px;-->
      <!--                height: 176px;-->
      <!--                background-color: #fffaf3;-->
      <!--              "-->
      <!--            >-->
      <!--              <div-->
      <!--                class="tw-bg-black tw-pl-4 tw-absolute"-->
      <!--                style="-->
      <!--                  top: 39px;-->
      <!--                  width: 102px;-->
      <!--                  height: 36px;-->
      <!--                  background-color: #031226;-->
      <!--                  box-shadow: 0px 5px 15px 0px rgba(132, 132, 132, 0.24);-->
      <!--                  border-radius: 0px 21px 21px 0px;-->
      <!--                  color: #fff;-->
      <!--                  line-height: 36px;-->
      <!--                "-->
      <!--              >-->
      <!--                Basic-->
      <!--              </div>-->
      <!--              <div-->
      <!--                class="inset tw-absolute"-->
      <!--                style="left: 100px; top: 30px;"-->
      <!--              ></div>-->
      <!--            </div>-->
      <!--            <div class="tw-text-4xl tw-text-center" style="padding-top: 176px;">-->
      <!--              Free-->
      <!--            </div>-->
      <!--            <div class="tw-my-3 tw-text-center" style="color: #8a92ab;">-->
      <!--              SPECIAL OFFER-->
      <!--            </div>-->
      <!--            <div class="tw-mt-4" style="border-top: 1px solid #c5dcff;"></div>-->
      <!--            <div class="tw-px-4 tw-py-5">-->
      <!--              <div class="tw-pb-2" style="color: #ff9800;">Benefits:</div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Free discussion posting</span-->
      <!--                >-->
      <!--              </div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Blog mentions</span-->
      <!--                >-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="tw-flex tw-justify-center tw-pt-15">-->
      <!--              <router-link-->
      <!--                :to="{ name: 'MemberShip' }"-->
      <!--                class="tw-px-10 tw-py-3 hover-orange"-->
      <!--                style="-->
      <!--                  background-color: #ffffff;-->
      <!--                  border-radius: 4px;-->
      <!--                  border: solid 1px #dddddd;-->
      <!--                "-->
      <!--                >View More</router-link-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="tw-border tw-relative plan-standard"-->
      <!--            style="-->
      <!--              box-shadow: 0px 3px 20px 0px rgba(55, 55, 55, 0.05);-->
      <!--              border-radius: 10px;-->
      <!--              border: solid 1px #fecc17;-->
      <!--            "-->
      <!--          >-->
      <!--            <div-->
      <!--              class="top tw-absolute tw-inset-x-0 tw-top-0"-->
      <!--              style="-->
      <!--                border-radius: 13px;-->
      <!--                height: 176px;-->
      <!--                background-color: #fffaf3;-->
      <!--              "-->
      <!--            >-->
      <!--              <div-->
      <!--                class="tw-bg-black tw-pl-4 tw-absolute"-->
      <!--                style="-->
      <!--                  top: 39px;-->
      <!--                  width: 102px;-->
      <!--                  height: 36px;-->
      <!--                  background-color: #ff9800;-->
      <!--                  box-shadow: 0px 5px 15px 0px rgba(255, 87, 0, 0.24);-->
      <!--                  border-radius: 0px 21px 21px 0px;-->
      <!--                  color: #fff;-->
      <!--                  line-height: 36px;-->
      <!--                "-->
      <!--              >-->
      <!--                Premium-->
      <!--              </div>-->
      <!--              <div-->
      <!--                class="inset tw-absolute"-->
      <!--                style="left: 100px; top: 30px;"-->
      <!--              ></div>-->
      <!--            </div>-->
      <!--            <div class="tw-text-4xl tw-text-center" style="padding-top: 176px;">-->
      <!--              $150.00<span class="tw-text-base">/Year</span>-->
      <!--            </div>-->
      <!--            <div class="tw-my-3 tw-text-center" style="color: #8a92ab;">-->
      <!--              SPECIAL OFFER-->
      <!--            </div>-->

      <!--            <div class="tw-mt-4" style="border-top: 1px solid #c5dcff;"></div>-->
      <!--            <div class="tw-px-4 tw-py-5">-->
      <!--              <div class="tw-pb-2" style="color: #ff9800;">Benefits:</div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Free job posting</span-->
      <!--                >-->
      <!--              </div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Reserved 1 week ad space (x2)</span-->
      <!--                >-->
      <!--              </div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Some Promotional Benefits</span-->
      <!--                >-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="tw-flex tw-justify-center tw-pt-8">-->
      <!--              <router-link-->
      <!--                :to="{ name: 'MemberShip' }"-->
      <!--                class="tw-px-10 tw-py-3 hover-orange"-->
      <!--                style="-->
      <!--                  background-color: #ffffff;-->
      <!--                  border-radius: 4px;-->
      <!--                  border: solid 1px #dddddd;-->
      <!--                "-->
      <!--                >View More</router-link-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="tw-border tw-relative plan-premium"-->
      <!--            style="-->
      <!--              box-shadow: 0px 3px 20px 0px rgba(55, 55, 55, 0.05);-->
      <!--              border-radius: 10px;-->
      <!--              border: solid 1px #fecc17;-->
      <!--            "-->
      <!--          >-->
      <!--            <div-->
      <!--              class="top tw-absolute tw-inset-x-0 tw-top-0"-->
      <!--              style="-->
      <!--                border-radius: 13px;-->
      <!--                height: 176px;-->
      <!--                background-color: #fffaf3;-->
      <!--              "-->
      <!--            >-->
      <!--              <div-->
      <!--                class="tw-bg-black tw-pl-4 tw-absolute"-->
      <!--                style="-->
      <!--                  top: 39px;-->
      <!--                  width: 102px;-->
      <!--                  height: 36px;-->
      <!--                  background-color: #031226;-->
      <!--                  box-shadow: 0px 5px 15px 0px rgba(132, 132, 132, 0.24);-->
      <!--                  border-radius: 0px 21px 21px 0px;-->
      <!--                  color: #fff;-->
      <!--                  line-height: 36px;-->
      <!--                "-->
      <!--              >-->
      <!--                Enterprise-->
      <!--              </div>-->
      <!--              <div-->
      <!--                class="inset tw-absolute"-->
      <!--                style="left: 100px; top: 30px;"-->
      <!--              ></div>-->
      <!--            </div>-->
      <!--            <div class="tw-text-4xl tw-text-center" style="padding-top: 176px;">-->
      <!--              $325.00<span class="tw-text-base">/Year</span>-->
      <!--            </div>-->
      <!--            <div class="tw-my-3 tw-text-center" style="color: #8a92ab;">-->
      <!--              SPECIAL OFFER-->
      <!--            </div>-->

      <!--            <div class="tw-mt-4" style="border-top: 1px solid #c5dcff;"></div>-->
      <!--            <div class="tw-px-4 tw-pt-5">-->
      <!--              <div class="tw-pb-2" style="color: #ff9800;">Benefits:</div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Free job posting</span-->
      <!--                >-->
      <!--              </div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Reserved 2 week ad space (x2)</span-->
      <!--                >-->
      <!--              </div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Full Promotional Benefits</span-->
      <!--                >-->
      <!--              </div>-->
      <!--              <div class="tw-pb-2">-->
      <!--                <span style="color: #8a92ab;"-->
      <!--                  ><i-->
      <!--                    class="fa fa-check-circle tw-mr-1"-->
      <!--                    aria-hidden="true"-->
      <!--                    style="color: #ff9800;"-->
      <!--                  ></i-->
      <!--                  >Reserved Blog Space</span-->
      <!--                >-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="tw-flex tw-justify-center tw-pb-5 tw-pt-5">-->
      <!--              <router-link-->
      <!--                :to="{ name: 'MemberShip' }"-->
      <!--                class="tw-px-10 tw-py-3 hover-orange"-->
      <!--                style="-->
      <!--                  background-color: #ffffff;-->
      <!--                  border-radius: 4px;-->
      <!--                  border: solid 1px #dddddd;-->
      <!--                "-->
      <!--                >View More</router-link-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="banner">
            <div class="top tw-py-12">
              <h1
                class="tw-text-center tw-mb-5"
                style="
                  font-family: Rubik;
                  font-size: 2.25rem;
                  line-height: 3rem;
                "
              >
                Student Freelance Platform
              </h1>
              <!--              <h3-->
              <!--                class="tw-text-center tw-self-center tw-text-lg tw-mt-4 tw-mx-4"-->
              <!--                style="font-weight: 400;"-->
              <!--              >-->
              <!--                Special Hiring model - Academic Team (Students work for free)-->
              <!--                <br />-->
              <!--                <br />-->
              <!--                Ensure high quality project-based work. Part-time and remote.<br />-->
              <!--                Academic teams consist of one industry expert/professor who-->
              <!--                works with trained students to tackle your business problems.-->
              <!--              </h3>-->
              <div
                class="tw-text-center tw-self-center tw-text-lg tw-mt-4 tw-pb-12"
              >
                Beeznests help companies access the best student candidates
                referred by our partner colleges for their paid internship/
                project/ job opportunities.<br />
                We also help companies outsource projects/tasks to students and
                academic teams(mentor +students).
                <br />
                We offer remote academic support to the business community.
              </div>
              <!--              <div class="tw-flex tw-justify-center">-->
              <!--                <video src="@/assets/introduction.mp4" controls></video>-->
              <!--              </div>-->
              <div class="tw-text-center tw-mt-8">
                <button
                  class="tw-uppercase tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-text-sm tw-font-bold"
                  style="font-weight: 500;"
                  @click="$router.push('/new-register')"
                  v-if="!isLoggedIn"
                >
                  Sign Up for Free <i class="fa fa-arrow-circle-o-right"></i>
                </button>
                <button
                  class="tw-uppercase tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-text-sm tw-font-bold"
                  style="font-weight: 500;"
                  @click="
                    $router.push({ name: 'articleShow', params: { id: 17 } })
                  "
                  v-if="isLoggedIn"
                >
                  How to use our platform
                  <i class="fa fa-arrow-circle-o-right"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- task 开始-->
          <div class="tw-flex tw-flex-col tw-mx-4 tw-mt-8">
            <div class="tw-self-center">
              <div class="tw-text-4xl">
                <span class="text-primary">College</span><br />
                Referral Job Board
              </div>
              <span class="tw-text-base"
                >(Only for Paid internship/Project/ Full-Time Job)</span
              >
              <!--div
                class="bg-primary tw-mt-3"
                style="width: 80px; height: 3px;"
              ></div-->
              <div
                class="tw-text-gray-500 tw-max-w-md tw-mt-8 tw-text-sm"
                style="color: #8a92ab;"
              >
                Access our “Referred Student Database” by making a job post.
                Hire students who have been strongly recommended by our partner
                colleges/universities or Beeznests. These students have
                completed some professional training; students are prepared to
                work and are looking for paid positions.
              </div>
              <div class="tw-mt-8">
                <router-link
                  :to="{ name: 'jobsIndex' }"
                  class="tw-border border-color-2 tw-text-xs bg-orange-2 tw-px-6 tw-py-3 tw-rounded hover-color tw-transition tw-ease-in-out tw-duration-150"
                  style="font-weight: 500;"
                  >View Jobs<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
                ></router-link>
                <router-link
                  :to="{ name: 'jobsCreate' }"
                  class="tw-border tw-px-6 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
                  style="font-weight: 500; color: #8a92ab;"
                  >Create Job<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
                ></router-link>
              </div>
            </div>
            <div class="tw-mt-8">
              <img src="@/assets/images/index-task.png" />
            </div>
          </div>
          <!-- task 结束 -->
          <!-- NetWork -->
          <div class="tw-flex tw-flex-col tw-justify-between tw-mx-4 tw-mt-8">
            <div class="tw-self-center">
              <div class="tw-text-4xl">
                <span class="text-primary">Project-Based</span> Work
              </div>
              <!--              <div-->
              <!--                class="bg-primary tw-mt-3"-->
              <!--                style="width: 80px; height: 3px;"-->
              <!--              ></div>-->
              <div
                class="tw-text-gray-500 tw-max-w-md tw-text-sm"
                style="margin-top: 20px; color: #8a92ab;"
              >
                Two options.<br />
                Option 1: Post your short-term project (can be volunteer-based
                work), get help from 16,000 students in our community.<br />
                Option 2: Hire the Beeznests Academic Team for your project.<br />
                The academic team consists of one industry expert/professor who
                works with students to tackle your business problems. Solution
                proposals can be provided upon request.
              </div>
              <div class="tw-mt-8">
                <router-link
                  :to="{
                    name: 'NewProjects',
                    params: { type: 'academic-team' },
                  }"
                  class="tw-border border-color-2 bg-orange-2 tw-px-3 tw-text-sm tw-py-3 tw-rounded"
                  style="font-weight: 500;"
                  >View Projects<i
                    class="fa fa-arrow-circle-o-right tw-ml-1"
                  ></i
                ></router-link>
                <router-link
                  :to="{ name: 'NewRegister' }"
                  class="tw-border tw-px-3 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
                  style="font-weight: 500; color: #8a92ab;"
                  >Create Project<i
                    class="fa fa-arrow-circle-o-right tw-ml-1"
                  ></i
                ></router-link>
              </div>
            </div>
            <div class="tw-mt-8">
              <img src="@/assets/images/join-our.png" />
            </div>
          </div>
          <!-- Net 结束 -->
          <!-- 博客 -->
          <div class="tw-flex tw-flex-col tw-justify-between tw-mx-4 tw-mt-8">
            <div class="tw-self-center">
              <div class="tw-text-4xl">
                <span class="text-primary">Special hiring model</span><br />
                Beeznests Academic Team
              </div>
              <!--div
                class="bg-primary tw-mt-3"
                style="width: 80px; height: 3px;"
              ></div-->
              <div
                class="tw-text-gray-500 tw-text-sm"
                style="margin-top: 20px; color: #8a92ab;"
              >
                Ensure high-quality project-based work. Part-time. Academic
                teams consist of one industry expert/professor who works with
                trained students to tackle your business problems. Solution
                proposals can be provided upon request.
              </div>
              <div class="tw-mt-8">
                <router-link
                  :to="{ name: 'mentorIndex' }"
                  class="tw-border border-color-2 bg-orange-2 tw-px-3 tw-py-3 tw-rounded tw-text-sm"
                  style="font-weight: 500;"
                  >View Jobs<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
                ></router-link>
                <router-link
                  :to="{ name: 'projectCreate' }"
                  style="color: #8a92ab; font-weight: 500;"
                  class="tw-border tw-px-3 tw-py-3 tw-rounded tw-ml-5 tw-text-sm hover-color tw-transition tw-ease-in-out tw-duration-150"
                  >Post Job<i class="fa fa-arrow-circle-o-right tw-ml-1"></i
                ></router-link>
              </div>
            </div>
            <div class="tw-mt-8">
              <img src="@/assets/images/index-discussions.png" />
            </div>
          </div>
          <!-- 博客结束 -->

          <!--关于我们-->
          <div
            class="tw-flex tw-items-center tw-flex-col tw-mt-8 tw-pt-6 tw-pb-10"
            style="background-color: #fffbef; position: relative;"
          >
            <h3 class="tw-text-4xl">
              Our <span class="text-primary">Beeznests</span> Story
            </h3>
            <div class="tw-mt-8 tw-max-w-lg">
              <img src="@/assets/images/index-story.png" />
            </div>
            <div
              class="tw-text-center tw-text-gray-500 tw-mt-6 tw-mx-4"
              style="color: #8a92ab;"
            >
              Our company mission is to bridge the gap between students and
              companies by overcoming a geographical boundary. By creating a
              platform that solely focuses on offering remote opportunities, we
              provide convenience and limitless opportunities for everyone.
              Certificates are handed out for every opportunity provided:
              project-based work, internships, and mentorship programs.
            </div>
            <div class="tw-mt-10">
              <router-link
                :to="{ name: 'NewOur' }"
                class="tw-px-3 tw-py-3 bg-orange-2 tw-rounded tw-text-sm tw-tracking-tighter"
                style="font-weight: 500;"
                >View More
                <i class="fa fa-arrow-circle-o-right tw-ml-1"></i>
              </router-link>
            </div>
          </div>
          <!--关于我们结束-->
          <!-- 列表 -->
          <div class="tw-mt-12 tw-mx-4">
            <div class="tw-flex tw-justify-between tw-flex-col">
              <h3 class="tw-text-4xl">
                <span class="text-primary">Featured</span> Interests
              </h3>
              <nav class="tw-flex tw-text-sm tw-flex-wrap">
                <span
                  @click="showTab = 'project'"
                  class="tw-cursor-pointer tw-border tw-px-4 tw-py-2 tw-mt-5 tw-rounded tw-mr-5 tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                  :class="
                    showTab === 'project'
                      ? 'border-color-2 bg-orange-2 hover-box-shadow'
                      : ''
                  "
                  >tasks</span
                >
                <!--span
                  @click="showTab = 'discussion'"
                  class="tw-cursor-pointer tw-border tw-px-2 tw-py-2 tw-mt-5 tw-rounded tw-mr-5 tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                  :class="
                    showTab === 'discussion'
                      ? 'border-color-2 bg-orange-2 hover-box-shadow'
                      : ''
                  "
                  >discussions</span
                -->
                <span
                  @click="showTab = 'student'"
                  class="tw-cursor-pointer tw-border tw-px-2 tw-py-2 tw-mt-5 tw-rounded tw-mr-5 tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                  :class="
                    showTab === 'student'
                      ? 'border-color-2 bg-orange-2 hover-box-shadow'
                      : ''
                  "
                  >Student Freelancers</span
                >
              </nav>
            </div>
            <transition name="fade" mode="out-in">
              <!--任务-->
              <div key="project" v-if="showTab === 'project'">
                <div
                  class="tw-mt-10 tw-flex tw-flex-row"
                  style="overflow-x: auto;"
                >
                  <div
                    class="tw-rounded tw-border tw-flex tw-flex-col tw-justify-between tw-px-4 tw-pt-4 tw-pb-7 tw-mb-5 project-box tw-mr-3"
                    style="max-width: 264px; width: 264px; flex: 0 0 100%;"
                    v-for="(item, i) in projects"
                    :key="`task-${i}`"
                  >
                    <div class="tw-flex tw-flex-col tw-items-center">
                      <img
                        :src="item.user.avatar"
                        style="width: 60px; height: 60px;"
                        class="tw-rounded-lg tw-self-center"
                      />
                      <div class="tw-flex tw-flex-col tw-justify-around">
                        <span
                          class="tw-text-x tw-self-center"
                          style="font-weight: 500;"
                          >{{ item.position }}</span
                        >
                        <span
                          class="tw-text-gray-600 tw-text-xs tw-self-center"
                          style="color: #8a92ab;"
                          >{{ item.user.name }}</span
                        >
                        <div class="tw-flex tw-items-center">
                          <div class="text-primary">
                            {{
                              item.cost_min > 0 ? `$ ${item.cost_min}` : 'Free'
                            }}
                          </div>
                          <div
                            class="tw-text-gray-600 tw-text-sm tw-ml-4"
                            style="color: #8a92ab;"
                          >
                            <i class="fa fa-map-marker"></i>
                            {{ item.user.country.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tw-self-center tw-space-x-2 tw-my-4">
                      <span
                        class="tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-inline-flex tw-items-center"
                        style="background-color: #eff3f9; color: #8a92ab;"
                        ><i
                          class="fa fa-briefcase"
                          style="margin-right: 2px;"
                        ></i
                        >Remote</span
                      >
                      <span
                        class="tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-inline-flex tw-items-center"
                        style="background-color: #eff3f9; color: #8a92ab;"
                        ><i class="fa fa-fire" style="margin-right: 2px;"></i
                        >Urgent</span
                      >
                      <span
                        class="tw-rounded-full tw-py-1 tw-px-2 tw-text-xs tw-inline-flex tw-items-center"
                        style="background-color: #eff3f9; color: #8a92ab;"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.881"
                          height="10.411"
                          viewBox="0 0 13.881 10.411"
                          style="margin-right: 2px;"
                        >
                          <path
                            d="M13.447,13.175H1.735V4.934A.434.434,0,0,0,1.3,4.5H.434A.434.434,0,0,0,0,4.934v9.109a.868.868,0,0,0,.868.868H13.447a.434.434,0,0,0,.434-.434v-.868A.434.434,0,0,0,13.447,13.175Zm-.868-7.808h-3.2a.651.651,0,0,0-.46,1.111l.878.878L7.808,9.346,5.819,7.357a.867.867,0,0,0-1.227,0L2.73,9.219a.434.434,0,0,0,0,.614l.613.613a.434.434,0,0,0,.614,0L5.205,9.2l1.989,1.989a.867.867,0,0,0,1.227,0l2.6-2.6.878.878A.651.651,0,0,0,13.013,9V5.8A.433.433,0,0,0,12.579,5.368Z"
                            transform="translate(0 -4.5)"
                            fill="#8a92ab"
                          /></svg
                        >Featured</span
                      >
                    </div>
                    <div class="tw-self-center tw-mt-4">
                      <router-link
                        :to="{ name: 'projectShow', params: { id: item.id } }"
                        class="tw-border tw-py-3 tw-px-4 tw-rounded tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                        style="font-weight: 500;"
                        >APPLY NOW<i
                          class="fa fa-arrow-circle-o-right tw-ml-1"
                        ></i
                      ></router-link>
                    </div>
                  </div>
                </div>
                <div class="tw-text-center tw-mt-4">
                  <router-link
                    :to="{ name: 'NewProjects' }"
                    class="tw-uppercase text-orange-2 tw-text-xs tw-font-bold"
                    >See more jobs<i
                      class="fa fa-arrow-circle-o-right tw-ml-1"
                    ></i>
                  </router-link>
                </div>
              </div>
              <!--任务结束-->
              <!--学生-->
              <div key="student" v-if="showTab === 'student'">
                <div
                  class="tw-mt-10 tw-flex tw-flex-row"
                  style="overflow-x: auto;"
                >
                  <div
                    class="tw-flex tw-flex-row tw--mt-6 tw--mr-4 tw-justify-between"
                  >
                    <template v-for="(item, i) in students.slice(0, 8)">
                      <user-item
                        :item="item"
                        :i="i"
                        :key="`user-item-${i}`"
                      ></user-item>
                    </template>
                  </div>
                </div>
                <div class="tw-text-center tw-mt-4">
                  <router-link
                    :to="{ name: 'NewNetwork' }"
                    class="tw-uppercase text-orange-2 tw-text-xs tw-font-bold"
                    >See more network<i
                      class="fa fa-arrow-circle-o-right tw-ml-1"
                    ></i>
                  </router-link>
                </div>
              </div>
              <!--学生结束-->
              <!--讨论-->
              <div key="discussion" v-if="showTab === 'discussion'">
                <div class="tw-mt-12">
                  <div
                    class="tw-rounded tw-border tw-flex tw-flex-row tw-justify-between tw-mb-5 project-box"
                    style="padding: 14px;"
                    v-for="(item, i) in discussions.slice(0, 5)"
                    :key="`discussion-${i}`"
                  >
                    <div class="tw-flex tw-flex-grow-0 tw-flex-shrink">
                      <img
                        :src="item.user.avatar"
                        style="width: 60px; height: 60px;"
                        class="tw-rounded-lg"
                      />
                      <div
                        class="tw-flex tw-flex-col tw-justify-between"
                        style="
                          max-width: 220px;
                          width: 220px;
                          height: 60px;
                          overflow: hidden;
                          margin-left: 14px;
                        "
                      >
                        <div class="tw-truncate">
                          <span
                            class="tw-text-x"
                            style="line-height: 1; font-weight: 500;"
                            >{{ item.title }}</span
                          >
                          <span
                            class="tw-ml-4 tw-text-gray-600 tw-text-xs"
                            style="color: #8a92ab;"
                            >{{ item.user.name }}</span
                          >
                        </div>
                        <div
                          class="tw-text-gray-600 tw-text-sm"
                          style="color: #8a92ab;"
                        >
                          <i class="fa fa-map-marker"></i>
                          {{ item.user.country.name }}
                        </div>
                      </div>
                    </div>
                    <div class="tw-self-center">
                      <router-link
                        :to="{ name: 'challengeShow', params: { id: item.id } }"
                        class="tw-border tw-py-3 tw-px-4 tw-rounded tw-uppercase tw-text-xs hover-color tw-transition tw-ease-in-out tw-duration-150"
                        style="font-weight: 500;"
                        >View More<i
                          class="fa fa-arrow-circle-o-right tw-ml-1"
                        ></i
                      ></router-link>
                    </div>
                  </div>
                  <div class="tw-text-center tw-mt-4">
                    <router-link
                      :to="{ name: 'NewDiscussion' }"
                      class="tw-uppercase text-orange-2 tw-text-xs tw-font-bold"
                      >See more discussions<i
                        class="fa fa-arrow-circle-o-right tw-ml-1"
                      ></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <!-- 列表结束 -->
          <!-- 客户评价 -->
          <div
            class="tw-flex tw-items-center tw-justify-center"
            style="
              background-color: #fffbef;
              margin-top: 60px;
              padding-top: 40px;
              padding-bottom: 40px;
            "
          >
            <div style="max-width: 510px;">
              <h2 class="tw-text-4xl tw-text-center">
                What Our <span class="text-primary">Clients Say</span>
              </h2>
              <div
                class="tw-text-gray-500 tw-text-center tw-mt-6 tw-mx-4"
                style="font-style: italic; color: #8a92ab;"
              >
                Beeznets is safe haven for students and companies to interact
                and share opportunities. Their community showcases diversity,
                opportunity and hope for students to tackle real-world problems
                while aiding businesses by providing unique young talent from
                around the world) followed by name of person and
                Position/Company
              </div>
              <div
                style="margin-top: 20px; font-weight: 500;"
                class="tw-text-center"
              >
                Thamina Rahman Anny
              </div>
              <div
                style="color: #ff9800; line-height: 20px;"
                class="tw-text-center tw-text-xs"
              >
                Product Designer @cp
              </div>
              <div class="tw-text-center tw-space-x-1">
                <i
                  class="fa fa-star text-primary tw-text-xs"
                  v-for="index in 5"
                  :key="`student-star-${index}`"
                ></i>
              </div>
              <div class="tw-flex tw-justify-center">
                <div class="tw-text-center tw-inline-flex tw-justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="16"
                    viewBox="0 0 60 16"
                  >
                    <g transform="translate(-930 -5634)">
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        transform="translate(930 5637)"
                        fill="#fecc17"
                        opacity="0.502"
                      />
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        transform="translate(980 5637)"
                        fill="#fecc17"
                        opacity="0.502"
                      />
                      <g
                        transform="translate(952 5634)"
                        fill="rgba(37,38,85,0)"
                        stroke="#ff9800"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7" fill="none" />
                      </g>
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(957 5639)"
                        fill="#ff9800"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!-- 客户评价结束 -->
          <!-- 订阅计划 -->
          <!--          <div class="tw-mx-4" style="margin-top: 60px;">-->
          <!--            <h2 class="tw-text-center" style="font-size: 32px;">-->
          <!--              <span class="text-primary">Choose your</span> Membership Plan-->
          <!--            </h2>-->
          <!--            <div class="tw-mx-auto tw-mt-10 tw-flex" style="overflow-x: auto;">-->
          <!--              <div-->
          <!--                class="tw-border tw-relative plan-basic tw-mr-3"-->
          <!--                style="-->
          <!--                  box-shadow: 0 3px 20px 0 rgba(55, 55, 55, 0);-->
          <!--                  border-radius: 13px;-->
          <!--                  border: solid 1px #dddddd;-->
          <!--                  flex: 0 0 100%;-->
          <!--                "-->
          <!--                v-show="mobilePlanIndex === 0"-->
          <!--              >-->
          <!--                <div-->
          <!--                  class="top tw-absolute tw-inset-x-0 tw-top-0"-->
          <!--                  style="-->
          <!--                    border-radius: 13px;-->
          <!--                    height: 176px;-->
          <!--                    background-color: #fffaf3;-->
          <!--                  "-->
          <!--                >-->
          <!--                  <div-->
          <!--                    class="tw-bg-black tw-pl-4 tw-absolute"-->
          <!--                    style="-->
          <!--                      top: 39px;-->
          <!--                      width: 102px;-->
          <!--                      height: 36px;-->
          <!--                      background-color: #031226;-->
          <!--                      box-shadow: 0px 5px 15px 0px rgba(132, 132, 132, 0.24);-->
          <!--                      border-radius: 0px 21px 21px 0px;-->
          <!--                      color: #fff;-->
          <!--                      line-height: 36px;-->
          <!--                    "-->
          <!--                  >-->
          <!--                    Basic-->
          <!--                  </div>-->
          <!--                  <div-->
          <!--                    class="inset tw-absolute"-->
          <!--                    style="left: 100px; top: 30px;"-->
          <!--                  ></div>-->
          <!--                </div>-->
          <!--                <div-->
          <!--                  class="tw-text-4xl tw-text-center"-->
          <!--                  style="padding-top: 176px;"-->
          <!--                >-->
          <!--                  Free-->
          <!--                </div>-->
          <!--                <div class="tw-my-3 tw-text-center" style="color: #8a92ab;">-->
          <!--                  SPECIAL OFFER-->
          <!--                </div>-->
          <!--                <div-->
          <!--                  class="tw-mt-4"-->
          <!--                  style="border-top: 1px solid #c5dcff;"-->
          <!--                ></div>-->
          <!--                <div class="tw-px-4 tw-py-5">-->
          <!--                  <div class="tw-pb-2" style="color: #ff9800;">Benefits:</div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Blog mentions</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="tw-flex tw-justify-center tw-pt-15">-->
          <!--                  <router-link-->
          <!--                    :to="{ name: 'MemberShip' }"-->
          <!--                    class="tw-px-10 tw-py-3 hover-orange"-->
          <!--                    style="-->
          <!--                      background-color: #ffffff;-->
          <!--                      border-radius: 4px;-->
          <!--                      border: solid 1px #dddddd;-->
          <!--                    "-->
          <!--                    >View More</router-link-->
          <!--                  >-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div-->
          <!--                class="tw-border tw-relative plan-standard tw-mr-3"-->
          <!--                style="-->
          <!--                  box-shadow: 0px 3px 20px 0px rgba(55, 55, 55, 0.05);-->
          <!--                  border-radius: 10px;-->
          <!--                  border: solid 1px #fecc17;-->
          <!--                  flex: 0 0 100%;-->
          <!--                "-->
          <!--              >-->
          <!--                <div-->
          <!--                  class="top tw-absolute tw-inset-x-0 tw-top-0"-->
          <!--                  style="-->
          <!--                    border-radius: 13px;-->
          <!--                    height: 176px;-->
          <!--                    background-color: #fffaf3;-->
          <!--                  "-->
          <!--                >-->
          <!--                  <div-->
          <!--                    class="tw-bg-black tw-pl-4 tw-absolute"-->
          <!--                    style="-->
          <!--                      top: 39px;-->
          <!--                      width: 102px;-->
          <!--                      height: 36px;-->
          <!--                      background-color: #ff9800;-->
          <!--                      box-shadow: 0px 5px 15px 0px rgba(255, 87, 0, 0.24);-->
          <!--                      border-radius: 0px 21px 21px 0px;-->
          <!--                      color: #fff;-->
          <!--                      line-height: 36px;-->
          <!--                    "-->
          <!--                  >-->
          <!--                    Premium-->
          <!--                  </div>-->
          <!--                  <div-->
          <!--                    class="inset tw-absolute"-->
          <!--                    style="left: 100px; top: 30px;"-->
          <!--                  ></div>-->
          <!--                </div>-->
          <!--                <div-->
          <!--                  class="tw-text-4xl tw-text-center"-->
          <!--                  style="padding-top: 176px;"-->
          <!--                >-->
          <!--                  $150.00<span class="tw-text-base">/Year</span>-->
          <!--                </div>-->
          <!--                <div class="tw-my-3 tw-text-center" style="color: #8a92ab;">-->
          <!--                  SPECIAL OFFER-->
          <!--                </div>-->

          <!--                <div-->
          <!--                  class="tw-mt-4"-->
          <!--                  style="border-top: 1px solid #c5dcff;"-->
          <!--                ></div>-->
          <!--                <div class="tw-px-4 tw-py-5">-->
          <!--                  <div class="tw-pb-2" style="color: #ff9800;">Benefits:</div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Free job posting</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Reserved 1 week ad space (x2)</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Some Promotional Benefits</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="tw-flex tw-justify-center tw-pt-8">-->
          <!--                  <router-link-->
          <!--                    :to="{ name: 'MemberShip' }"-->
          <!--                    class="tw-px-10 tw-py-3 hover-orange"-->
          <!--                    style="-->
          <!--                      background-color: #ffffff;-->
          <!--                      border-radius: 4px;-->
          <!--                      border: solid 1px #dddddd;-->
          <!--                    "-->
          <!--                    >View More</router-link-->
          <!--                  >-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div-->
          <!--                class="tw-border tw-relative plan-premium tw-mr-3"-->
          <!--                style="-->
          <!--                  box-shadow: 0 3px 20px 0 rgba(55, 55, 55, 0.05);-->
          <!--                  border-radius: 10px;-->
          <!--                  border: solid 1px #fecc17;-->
          <!--                  flex: 0 0 100%;-->
          <!--                "-->
          <!--              >-->
          <!--                <div-->
          <!--                  class="top tw-absolute tw-inset-x-0 tw-top-0"-->
          <!--                  style="-->
          <!--                    border-radius: 13px;-->
          <!--                    height: 176px;-->
          <!--                    background-color: #fffaf3;-->
          <!--                  "-->
          <!--                >-->
          <!--                  <div-->
          <!--                    class="tw-bg-black tw-pl-4 tw-absolute"-->
          <!--                    style="-->
          <!--                      top: 39px;-->
          <!--                      width: 102px;-->
          <!--                      height: 36px;-->
          <!--                      background-color: #031226;-->
          <!--                      box-shadow: 0px 5px 15px 0px rgba(132, 132, 132, 0.24);-->
          <!--                      border-radius: 0px 21px 21px 0px;-->
          <!--                      color: #fff;-->
          <!--                      line-height: 36px;-->
          <!--                    "-->
          <!--                  >-->
          <!--                    Enterprise-->
          <!--                  </div>-->
          <!--                  <div-->
          <!--                    class="inset tw-absolute"-->
          <!--                    style="left: 100px; top: 30px;"-->
          <!--                  ></div>-->
          <!--                </div>-->
          <!--                <div-->
          <!--                  class="tw-text-4xl tw-text-center"-->
          <!--                  style="padding-top: 176px;"-->
          <!--                >-->
          <!--                  $325.00<span class="tw-text-base">/Year</span>-->
          <!--                </div>-->
          <!--                <div class="tw-my-3 tw-text-center" style="color: #8a92ab;">-->
          <!--                  SPECIAL OFFER-->
          <!--                </div>-->

          <!--                <div-->
          <!--                  class="tw-mt-4"-->
          <!--                  style="border-top: 1px solid #c5dcff;"-->
          <!--                ></div>-->
          <!--                <div class="tw-px-4 tw-pt-5">-->
          <!--                  <div class="tw-pb-2" style="color: #ff9800;">Benefits:</div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Free job posting</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Reserved 2 week ad space (x2)</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Full Promotional Benefits</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                  <div class="tw-pb-2">-->
          <!--                    <span style="color: #8a92ab;"-->
          <!--                      ><i-->
          <!--                        class="fa fa-check-circle tw-mr-1"-->
          <!--                        aria-hidden="true"-->
          <!--                        style="color: #ff9800;"-->
          <!--                      ></i-->
          <!--                      >Reserved Blog Space</span-->
          <!--                    >-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="tw-flex tw-justify-center tw-pb-5 tw-pt-5">-->
          <!--                  <router-link-->
          <!--                    :to="{ name: 'MemberShip' }"-->
          <!--                    class="tw-px-10 tw-py-3 hover-orange"-->
          <!--                    style="-->
          <!--                      background-color: #ffffff;-->
          <!--                      border-radius: 4px;-->
          <!--                      border: solid 1px #dddddd;-->
          <!--                    "-->
          <!--                    >View More</router-link-->
          <!--                  >-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!-- 订阅计划结束 -->
          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import { fetchIndexProjects, fetchIndex } from '@/api'
import { mapState, mapGetters } from 'vuex'
import IndexHeader from '@/views/new-pages/common/IndexHeader'
import CommonFooter from '@/views/new-pages/common/footer'
import UserItem from '@/views/new-pages/components/user'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
export default {
  components: {
    MobileFooter,
    IndexHeader,
    UserItem,
    CommonFooter,
    MobileHeader,
    MobileDrawer,
  },
  data: () => ({
    showCategory: false,
    projects: [],
    discussions: [],
    students: [],
    showTab: 'project',
    friendLinks: [],
    mobilePlanIndex: 0,
  }),
  computed: {
    ...mapGetters(['isLoggedIn', 'isMobile']),
    ...mapState({
      categories: (state) =>
        state.category.data.filter((i) => i.parent_id === 0),
    }),
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
    if (this.categories.length <= 0) {
      this.$store.dispatch('category/getCategories')
    }
    this.loadProjects()
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  methods: {
    async loadProjects() {
      const { data } = await fetchIndexProjects()
      this.projects = data
      const response = await fetchIndex()
      this.discussions = response.data
      this.students = response.hires
      this.friendLinks = response.friend_links
    },
    mobilePlanLeft() {
      let index = this.mobilePlanIndex
      if (--index > -1) {
        this.mobilePlanIndex--
      }
    },
    mobilePlanRight() {
      let index = this.mobilePlanIndex
      if (++index < 3) {
        this.mobilePlanIndex++
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tw-container {
  max-width: 1150px !important;
}
.text-primary {
  color: #ff9800;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.hover-color:hover {
  background-color: #fecc17;
  border-color: #fecc17;
  color: #000 !important;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.banner {
  background-color: #fecc17;
  .top {
    background: url('../../assets/images/banner-top.png') no-repeat;
  }
}
.text-gray-1 {
  color: #8a92ab;
}
.project-box {
  &:hover {
    box-shadow: 0 3px 20px 0 rgba(55, 55, 55, 0.08);
    border: solid 1px #fecc17;
  }
  border-color: #ddd;
}
.hover-box-shadow {
  box-shadow: 0 3px 6px 0 rgba(244, 199, 42, 0.36);
}
.hover-orange {
  &:hover {
    box-shadow: 0 3px 20px 0 rgba(55, 55, 55, 0.08);
    border: solid 1px #fecc17 !important;
    background-color: #fecc17 !important;
    color: #fff;
  }
}
.plan-basic {
  .top {
    .inset {
      width: 80px;
      height: 104px;
      background: url('~@/assets/images/plan-basic.png') center center;
      background-size: cover;
    }
  }
}
.plan-standard {
  .top {
    .inset {
      width: 80px;
      height: 104px;
      background: url('~@/assets/images/plan-standard.png') center center
        no-repeat;
      background-size: contain;
    }
  }
}
.plan-premium {
  .top {
    .inset {
      width: 80px;
      height: 104px;
      background: url('~@/assets/images/plan-premium.png') center center
        no-repeat;
      background-size: contain;
    }
  }
}
.link-logo {
  &:hover {
    // filter: none;
  }
  // filter: grayscale(100%);
  // opacity: 0.6;
}
</style>
