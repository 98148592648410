<template>
  <div>
    <v-autocomplete
      v-model="newValue"
      :items="countries"
      label="Country"
      item-text="name"
      item-value="id"
      @change="handleChange"
      :loading="loading"
    ></v-autocomplete>
  </div>
</template>
<script>
import { countries } from '@/api/setting'
import _ from 'lodash'
export default {
  props: ['value'],
  data: () => ({
    countries: [],
    newValue: null,
    loading: false,
  }),
  async mounted() {
    await this.loadCountries()
    this.newValue = _.cloneDeep(this.value)
  },
  methods: {
    async loadCountries() {
      this.loading = true
      const { data } = await countries()
      this.countries = data
      this.loading = false
    },
    handleChange() {
      const index = this.countries.findIndex((i) => i.id === this.newValue)
      const v = _.pick(this.countries[index], ['id', 'name', 'emoji'])
      console.log(v)
      this.$emit('input', v)
    },
  },
}
</script>
