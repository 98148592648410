import axios from '@/plugins/axios'
export const create = (data) => {
  return axios.post('links', data)
}

export const createMany = (data) => {
  return axios.post('links/many', data)
}

export const show = (slug, params) => {
  return axios.get(`links/${slug}`, { params })
}

export const fetch = (params) => {
  return axios.get('links/', { params })
}

export const destroy = (id) => {
  return axios.delete(`links/${id}`)
}

export const update = (id, data) => {
  return axios.put(`links/${id}`, data)
}

export const updateMany = (data) => {
  return axios.put('links', data)
}

export const fetchAll = (params) => {
  return axios.get('all_links/', { params })
}
