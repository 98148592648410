<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8">
      <v-card outlined>
        <v-toolbar flat>
          <v-toolbar-title>Preview reference letter</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p style="text-align: center;">
            [Your Company Logo]
          </p>
          <p>
            <span>{{ date }}</span>
          </p>
          <p>
            It gives me great pleasure to provide a letter of reference for
            <i>{{ student_name }}</i
            >.
          </p>
          <p></p>
          <p>
            <i>{{ student_short_name }}</i> worked as a
            {{ projectPosition }} for {{ companyName }}. Throughout his/her work
            term, he/she displayed excellent levels of commitment and dedication
            towards all his/her duties. His/her attention to detail and ability
            to communicate on a regular basis has deeply impressed me, resulting
            in an effective professional relationship built off trust and high
            work ethics.
          </p>
          <p>
            <i>{{ student_short_name }}’s</i> kindhearted nature and willingness
            to go out of his/her way to contribute to the project has also been
            outstanding. His/her actions speak volumes about his/her hard work
            and determination. Due to all these reasons, I strongly believe that
            wherever {{ student_short_name }} goes, he/she will be an asset to
            the team.
          </p>
          <p>
            As an employer, I recommend <i>{{ student_short_name }}</i
            >.
          </p>
          <p>
            Sincerely,
          </p>
          <p>
            {{ signature }}
          </p>
          <p>
            {{ position }}
          </p>
          <p>
            {{ company }}
          </p>
        </v-card-text>
      </v-card>
      <v-card class="mt-4">
        <v-toolbar flat>
          <v-toolbar-title flat>Input</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form class="tw-max-w-md" ref="form" v-model="valid">
            <v-text-field
              label="Date"
              v-model="field_date"
              :rules="[(v) => !!v || 'Date is required']"
            >
            </v-text-field>
            <v-text-field
              label="Signature"
              v-model="field_signature"
              :rules="[(v) => !!v || 'Signature is required']"
            >
            </v-text-field>
            <v-text-field
              label="Position"
              v-model="field_position"
              :rules="[(v) => !!v || 'Position is required']"
            >
            </v-text-field>
            <v-text-field
              label="Company"
              v-model="field_company"
              :rules="[(v) => !!v || 'Company is required']"
            >
            </v-text-field>
            <v-file-input
              accept="image/png, image/jpeg, image/gif, image/bmp, image/jpg"
              label="Company Logo"
              v-model="file"
              messages="Please upload your company logo onto the reference letter template. Students may wish to share this letter on their social media, and it will provide higher visibility for your business."
            ></v-file-input>
            <v-btn
              depressed
              color="orange"
              class="white--text mt-4"
              :loading="sendLoading"
              @click="send"
              >Send</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { fetchReceiveData, sendRecommendation, fetchData } from '@/api/project'
export default {
  computed: {
    date() {
      return this.field_date || '[Date]'
    },
    student_name() {
      return this.receive.user.name || '[Student’s Name]'
    },
    student_short_name() {
      return this.student_name === '[Student’s Name]'
        ? this.student_name
        : this.student_name.split(' ')[0]
    },
    signature() {
      return this.field_signature || '[Your Name]'
    },
    position() {
      return this.field_position || '[Your Position]'
    },
    company() {
      return this.field_company || '[Your Company Name]'
    },
    companyName() {
      return this.project.user.name || '[Company Name]'
    },
    projectPosition() {
      return this.project.position || '[Position]'
    },
  },
  data: () => ({
    field_date: null,
    field_signature: null,
    field_name: null,
    field_position: null,
    field_company: null,
    projectId: 0,
    receiveId: 0,
    receive: {
      user: {},
    },
    project: {
      position: null,
      user: {
        name: null,
      },
    },
    sendLoading: false,
    valid: true,
    file: null,
  }),
  mounted() {
    this.projectId = this.$route.params.id
    this.receiveId = this.$route.params.receive_id
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        const { data } = await fetchReceiveData(this.receiveId, {})
        this.receive = data
      } catch (e) {
        console.log(e)
      }
      try {
        const { data } = await fetchData(this.projectId, {})
        this.project = data
      } catch (e) {
        console.log(e)
      }
    },
    async send() {
      this.$refs.form.validate()
      if (!this.valid) return
      try {
        this.sendLoading = true
        const data = new FormData()
        data.append('date', this.field_date)
        data.append('company', this.field_company)
        data.append('position', this.field_position)
        data.append('signature', this.field_signature)
        data.append('file', this.file)
        const res = await sendRecommendation(this.receiveId, data)
        this.$message(
          'The reference letter has been sent to students successfully.'
        )
        this.$router.back()
      } catch (e) {
        console.log(e)
      } finally {
        this.sendLoading = false
      }
    },
    fileChange(e) {
      this.file = e
    },
  },
}
</script>
