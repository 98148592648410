<template>
  <a
    :href="
      link ||
      'https://docs.google.com/document/d/1kmmaQLHh9ZEanH6yhjygx3Jl4ZN67or32Vju0X_qNHI/edit'
    "
    target="_blank"
    class="link"
    >{{ text || 'Faq' }}</a
  >
</template>
<script>
export default {
  props: ['link', 'text'],
}
</script>
<style lang="scss" scoped>
.link {
  text-decoration: none;
  padding: 0 4px;
}
</style>
