<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" md="4" lg="4">
      <v-card v-if="!isLoggedIn">
        <v-card-title>Oops!</v-card-title>
        <v-card-text
          >The page can only be viewed by members of Beeznests.<br />
          Please sign on our students freelance platform for all the
          opportunities.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            depressed
            color="orange"
            class="white--text"
            style="text-transform: none;"
            :to="{ name: 'AuthRegister' }"
            >Sign Up</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="orange"
            class="white--text"
            style="text-transform: none;"
            :to="{ name: 'Index' }"
            >Return Home</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-row justify="center" v-else-if="loadLoading">
        <v-progress-circular
          :width="3"
          color="red"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-card v-else-if="loadError">
        <v-card-title>Oops!</v-card-title>
        <v-card-text>Something went wrong!</v-card-text>
      </v-card>
      <v-card v-else>
        <v-toolbar flat>
          <v-toolbar-title>Redirect In {{ timeout }} Seconds</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <a :href="link.link">{{ link.link }}</a>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { show } from '@/api/link'
import { mapGetters } from 'vuex'
export default {
  computed: {
    slug() {
      return this.$route.params.slug || ''
    },
    ...mapGetters(['isLoggedIn']),
  },
  watch: {
    '$route.params.slug'() {
      this.loadData()
    },
  },
  data: () => ({
    link: {
      name: '',
      link: '',
      slug: '',
    },
    loadLoading: false,
    timeout: 3,
    loadError: false,
  }),
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      if (!this.isLoggedIn) {
        return
      }
      this.loadLoading = true
      try {
        const { data } = await show(this.slug, {})
        this.link = data
        const timer = setInterval(() => {
          this.timeout--
          if (this.timeout <= 1) {
            clearInterval(timer)
            window.location.href = this.link.link
          }
        }, 1000)
        this.loadLoading = false
      } catch (e) {
        this.loadError = true
        this.loadLoading = false
      }
    },
  },
}
</script>
