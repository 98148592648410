export default {
  path: '/',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: 'pay/:no',
      name: 'Pay',
      component: () => import(/* webpackChunkName: 'pay' */'@/views/Home'),
      meta: {
        fill: true,
      },
    },
    {
      path: 'payment/:amount',
      name: 'Payment',
      component: () => import(/* webpackChunkName: 'pay' */'@/views/membership/pay'),
      meta: {
        fill: true,
      },
    },
    {
      path: 'payment/user/cost',
      name: 'PaymentUserCost',
      component: () => import(/* webpackChunkName: 'pay' */'@/views/settings/payment'),
      meta: {
        fill: true,
        auth: true,
        emailVerify: true,
      },
    },
  ],
}
