import axios from '@/plugins/axios'

export const updateSetting = (data) => {
  return axios.post('settings/update', data)
}
export const settingType = (type) => {
  return axios.post('settings/update_type', { type })
}

export const setting = (data) => {
  return axios.post('settings/base_info', data)
}
export const settingExtra = (data) => {
  return axios.post('settings/extra', data)
}

export const settingPassword = (data) => {
  return axios.post('settings/password', data)
}
/**
 *
 * @param ids  id 数组
 */
export const categories = (ids) => {
  return axios.post('settings/categories', { category_ids: ids })
}
/**
 * 获取语言
 */

export const languages = () => {
  return axios.get('settings/languages')
}

export const countries = () => {
  return axios.get('settings/countries')
}

export const uploadResume = (data) => {
  return axios.post('upload_resume', data)
}
export const deleteResume = () => {
  return axios.delete('resume')
}

export const settingCode = (code) => {
  return axios.post(`settings/code/${code}`)
}

export const settingSubscribe = () => {
  return axios.post('settings/subscribe')
}

export const verifyMentorCode = (code) => {
  return axios.post('verify_mentor_code', {
    code,
  })
}

export const getPaymentUserCostNo = (cost) => {
  return axios.get(`settings/user_cost`, { params: { cost } })
}

export const setFreeStudent = () => {
  return axios.post('/settings/set_free_student')
}
