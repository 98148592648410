<template>
  <div>
    <template v-if="!isMobile">
      <common-header></common-header>
      <div class="tw-container tw-mx-auto tw-mt-10">
        <div class="tw-flex tw-justify-center">
          <div class="tw-mr-8" style="max-width: 750px;">
            <div class="tw-flex tw-items-center tw-mb-4 tw-mx-6">
              <router-link
                to="/challenges/create"
                class="tw-shadow-md tw-font-bold tw-uppercase bg-primary tw-px-10 tw-py-4 tw-rounded tw-text-white tw-text-sm"
                ><i class="fa fa-pencil-square-o tw-mr-1" aria-hidden="true"></i
                >Post a new discussion</router-link
              >
              <div
                class="tw-border tw-px-2 tw-py-1 tw-ml-12 tw-flex tw-items-center tw-flex-grow tw-rounded"
              >
                <input
                  class="tw-outline-none tw-w-8/12"
                  type="text"
                  v-model="keyword"
                  placeholder="Search"
                />
                <button
                  @click="loadData(true)"
                  class="tw-uppercase tw-ml-auto focus:tw-outline-none tw-leading-tight bg-orange-2 tw-px-4 tw-py-3 tw-rounded tw-text-sm"
                >
                  <i class="fa fa-search tw-mr-1" aria-hidden="true"></i>Search
                </button>
              </div>
            </div>
            <div class="tw-mx-4">
              <div
                class="tw-flex tw-justify-between tw-items-center tw-mb-4 tw-mx-2"
              >
                <h2 class="tw-font-bold tw-ml-4 tw-mb-2">Popular Posts</h2>
              </div>

              <div class="bg-gray-2 tw-py-2 tw-rounded tw-mx-2">
                <div class="tw-flex tw-flex-row tw-mx-4 tw-mt-4">
                  <div
                    class="tw-flex-shrink tw-mr-4 tw-relative"
                    :class="{ 'tw-cursor-pointer': currentShow > 0 }"
                    @click="currentShow > 0 ? currentShow-- : null"
                  >
                    <div
                      class="tw-absolute"
                      :class="{ 'tw-text-gray-300': currentShow <= 0 }"
                      style="top: 50%; transform: translateY(-50%);"
                    >
                      <i class="fa fa-chevron-left"></i>
                    </div>
                  </div>
                  <div
                    class="tw-flex-grow tw-p-4 tw-border tw-rounded tw-flex tw-flex-row tw-bg-white"
                  >
                    <div class="tw-self-center">
                      <div
                        class="bg-primary tw-w-16 tw-h-16 tw-rounded-full tw-p-4"
                      >
                        <img src="@/assets/images/page-1.png" />
                      </div>
                    </div>
                    <div class="tw-ml-2">
                      <div v-for="(item, index) in topItems" :key="item.title">
                        <template v-if="currentShow === index">
                          <h2 class="tw-font-bold">
                            {{ item.title }}
                          </h2>
                          <p class="text-gray-1 max-line-3 tw-text-sm">
                            {{ item.content }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-flex-shrink tw-mr-4 tw-relative"
                    :class="{
                      'tw-cursor-pointer': currentShow < topItems.length - 1,
                    }"
                    @click="
                      currentShow < topItems.length - 1 ? currentShow++ : null
                    "
                  >
                    <div
                      class="tw-absolute"
                      style="top: 50%; transform: translateY(-50%);"
                      :class="{
                        'tw-text-gray-300': currentShow >= topItems.length - 1,
                      }"
                    >
                      <i class="fa fa-chevron-right tw-ml-1"></i>
                    </div>
                  </div>
                </div>
                <div class="tw-space-x-1 tw-text-center tw-mt-2">
                  <span
                    v-for="(i, index) in topItems"
                    :key="`top-items-button-${index}-${i.title}`"
                    class="tw-cursor-pointer tw-w-3 tw-h-3 bg-primary tw-rounded-full tw-inline-block"
                    :class="index === currentShow ? ' tw-opacity-75' : ''"
                    @click="currentShow = index"
                  ></span>
                </div>
              </div>
            </div>
            <div
              class="tw-border border-gray-2 tw-rounded tw-p-4 tw-mx-6 tw-mb-10 tw-mt-10"
              v-for="(item, i) in data"
              :key="`post-${i}`"
            >
              <h2 class="tw-font-bold tw-text-xl">
                {{ item.title }}
              </h2>
              <div class="tw-text-sm tw-my-2">
                by <span class="text-primary">{{ item.user.name }}</span>
                <i class="fa fa-clock-o text-primary tw-ml-1"></i> Posted
                {{ item.created_at | diffForHumans }}
              </div>
              <!--div>
              <img
                src="https://api.beeznests.com/storage/portfolios/202010/24/93_1603512873_Vechc5w7AM.png"
                class="tw-rounded"
              />
            </div-->
              <p
                class="text-gray-1 tw-pb-2 tw-mt-2 tw-break-words"
                v-html="item.summary"
              ></p>
              <div class="tw-border-t-2 tw-mt-2"></div>
              <div class="tw-flex tw-flex-row tw-mt-4 tw-space-x-4">
                <div class="tw-cursor-pointer" @click="like(item.id, i)">
                  <i
                    class="fa fa-thumbs-up text-gray-1 tw-mr-1"
                    v-if="!item.like_loading"
                  ></i>
                  <i
                    class="fa fa-spinner fa-spin text-gray-1 tw-mr-1"
                    v-else
                  ></i>
                  <span class="text-gray-1">({{ item.likers_count }})</span>
                </div>
                <router-link
                  :to="{ name: 'challengeShow', params: { id: item.id } }"
                >
                  <i
                    class="fa fa-commenting-o text-gray-1 tw-mr-1"
                    aria-hidden="true"
                  ></i>
                  <span class="text-gray-1">Comment</span>
                </router-link>
                <!--div>
                <i
                  class="fa fa-share text-gray-1 tw-mr-1"
                  aria-hidden="true"
                ></i>
                <span class="text-gray-1">Share</span>
              </div-->
                <router-link
                  :to="{ name: 'challengeShow', params: { id: item.id } }"
                  class="text-primary"
                  style="margin-left: auto;"
                >
                  More
                  <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                </router-link>
              </div>
            </div>
            <div class="tw-flex flex-row tw-mx-6 tw-justify-between tw-mt-6">
              <!--button
              @click="loadPage(page - 1)"
              :disabled="!links.prev"
              class="focus:tw-outline-none tw-outline-none hover:tw-bg-yellow-300 hover:tw-text-white disabled:tw-bg-white tw-cursor-pointer tw-mr-4 tw-font-blod tw-flex tw-items-center tw-w-12 tw-h-12 tw-justify-center tw-rounded-full text-gray-1"
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button
              @click="loadPage(page + 1)"
              :disabled="!links.next"
              class="focus:tw-outline-none tw-outline-none hover:tw-bg-yellow-300 hover:tw-text-white tw-cursor-pointer tw-mr-4 tw-font-blod tw-flex tw-items-center tw-w-12 tw-h-12 tw-justify-center tw-rounded-full text-gray-1"
            >
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button-->
              <button
                @click="loadMore"
                class="tw-px-4 tw-py-2 tw-rounded tw-uppercase tw-outline-none focus:tw-outline-none"
                :class="
                  loading || noMoreData
                    ? 'tw-bg-gray-200 tw-border-gray-200'
                    : 'border-color-2 bg-orange-2'
                "
                :disabled="loading || noMoreData"
              >
                {{
                  noMoreData
                    ? 'no more data'
                    : loading
                    ? 'loading'
                    : 'load more'
                }}<i
                  class="tw-ml-1 fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                  v-if="!loading"
                ></i>
                <i
                  class="tw-ml-1 fa fa-spinner fa-spin"
                  aria-hidden="true"
                  v-else
                ></i>
              </button>
            </div>
          </div>
          <div class="" style="max-width: 300px; width: 300px;">
            <div class="">
              <div class="tw-rounded" v-if="blogs.length > 0">
                <h2
                  class="tw-font-bold bg-primary tw-rounded-t tw-text-white tw-py-3 tw-text-center"
                >
                  Popular Events
                </h2>
                <div
                  class="tw-p-4 bg-orange-4 tw-border-b tw-border-l tw-border-r tw-rounded-b"
                >
                  <div
                    class="tw-mb-4 tw-flex"
                    v-for="(item, i) in blogs"
                    :key="`discussion-popular-${i}`"
                  >
                    <div
                      class="tw-h-20 tw-w-20"
                      style="
                        max-width: 56px;
                        width: 56px;
                        max-height: 56px;
                        height: 56px;
                      "
                    >
                      <img
                        :src="item.image"
                        class="tw-w-20 tw-h-20"
                        style="
                          max-width: 56px;
                          width: 56px;
                          max-height: 56px;
                          height: 56px;
                          object-fit: fill;
                        "
                      />
                    </div>
                    <div
                      class="tw-ml-2 tw-flex-1 tw-flex tw-flex-col tw-content-between tw-overflow-hidden"
                    >
                      <h4 class="tw-font-bold tw-truncate tw-leading-none">
                        {{ item.title }}
                      </h4>
                      <p
                        class="text-gray-1 tw-text-xs tw-leading-none tw-mt-auto max-line-2"
                      >
                        {{ item.summary }}
                      </p>
                      <span
                        class="text-primary tw-mt-auto tw-text-xs tw-font-italic tw-leading-none"
                        style="font-style: italic;"
                        >Posted on {{ item.created_at | formatDate }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-mt-4">
                <img
                  src="https://api.beeznests.com/storage/portfolios/202010/24/93_1603542218_fMHlOXSgCc.png"
                />
              </div>
              <div class="bg-orange-4 tw-rounded tw-border tw-mt-6 tw-pt-4">
                <h2 class="tw-text-2xl tw-font-bold tw-text-center text-mb-4">
                  Invite Friends
                </h2>
                <div
                  class="tw-mt-4 tw-flex tw-items-center tw-border tw-py-2 tw-mx-6 tw-rounded tw-bg-white"
                >
                  <input
                    class="tw-appearance-none tw-bg-transparent tw-border-none tw-w-full tw-text-gray-700 tw-mr-3 tw-py-1 tw-px-2 tw-leading-tight tw-outline-none"
                    type="text"
                    placeholder="Email Address"
                    v-model="inviteMail"
                  />
                  <span class="flex-shrink-0 text-sm tw-mr-4">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
                <div
                  class="tw-my-1 tw-mx-6"
                  v-if="inviteMessage"
                  :class="{ 'tw-text-red-500': inviteError }"
                >
                  {{ inviteMessage }}
                </div>
                <div class="tw-my-4 tw-mx-6">
                  <button
                    @click="invite"
                    class="tw-shadow tw-outline-none focus:tw-outline-none tw-w-full tw-py-2 tw-rounded bg-orange-2 tw-uppercase disabled:tw-opacity-75"
                    :disabled="inviteLoading"
                  >
                    <i class="fa fa-spin fa-spinner" v-if="inviteLoading"></i>
                    Invite now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-header></mobile-header>
        <mobile-drawer></mobile-drawer>
        <v-main>
          <div class="tw-mx-4 tw-mt-6">
            <div
              class="tw-flex tw-justify-between tw-items-center tw-mb-4 tw-mx-2"
            >
              <h2 class="tw-font-bold tw-ml-4 tw-mb-2">Popular Posts</h2>
            </div>

            <div class="bg-gray-2 tw-py-2 tw-rounded">
              <div class="tw-flex tw-flex-row tw-mx-2 tw-mt-4">
                <div
                  class="tw-flex-shrink tw-mr-4 tw-relative"
                  :class="{ 'tw-cursor-pointer': currentShow > 0 }"
                  @click="currentShow > 0 ? currentShow-- : null"
                >
                  <div
                    class="tw-absolute"
                    :class="{ 'tw-text-gray-300': currentShow <= 0 }"
                    style="top: 50%; transform: translateY(-50%);"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </div>
                </div>
                <div
                  class="tw-flex-grow tw-p-2 tw-border tw-rounded tw-flex tw-flex-col tw-bg-white"
                >
                  <!--div class="tw-self-center">
                    <div
                      class="bg-primary tw-w-16 tw-h-16 tw-rounded-full tw-p-4"
                    >
                      <img src="@/assets/images/page-1.png" />
                    </div>
                  </div-->
                  <div class="tw-ml-2">
                    <div v-for="(item, index) in topItems" :key="item.title">
                      <template v-if="currentShow === index">
                        <h2 class="tw-font-bold">
                          {{ item.title }}
                        </h2>
                        <p class="text-gray-1 max-line-3 tw-text-sm mb-0">
                          {{ item.content }}
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  class="tw-flex-shrink tw-mr-4 tw-relative"
                  :class="{
                    'tw-cursor-pointer': currentShow < topItems.length - 1,
                  }"
                  @click="
                    currentShow < topItems.length - 1 ? currentShow++ : null
                  "
                >
                  <div
                    class="tw-absolute"
                    style="top: 50%; transform: translateY(-50%);"
                    :class="{
                      'tw-text-gray-300': currentShow >= topItems.length - 1,
                    }"
                  >
                    <i class="fa fa-chevron-right tw-ml-1"></i>
                  </div>
                </div>
              </div>
              <div class="tw-space-x-1 tw-text-center tw-mt-2">
                <span
                  v-for="(i, index) in topItems"
                  :key="`top-items-button-${index}-${i.title}`"
                  class="tw-cursor-pointer tw-w-3 tw-h-3 bg-primary tw-rounded-full tw-inline-block"
                  :class="index === currentShow ? ' tw-opacity-75' : ''"
                  @click="currentShow = index"
                ></span>
              </div>
            </div>
          </div>
          <div
            class="tw-border border-gray-2 tw-rounded tw-p-4 tw-mx-4 tw-mb-5 tw-mt-5"
            v-for="(item, i) in data"
            :key="`post-${i}`"
          >
            <h2 class="tw-font-bold tw-text-xl max-line-1">
              {{ item.title }}
            </h2>
            <div class="tw-text-sm tw-my-2">
              by <span class="text-primary">{{ item.user.name }}</span>
              <i class="fa fa-clock-o text-primary tw-ml-1"></i> Posted
              {{ item.created_at | diffForHumans }}
            </div>
            <!--div>
            <img
              src="https://api.beeznests.com/storage/portfolios/202010/24/93_1603512873_Vechc5w7AM.png"
              class="tw-rounded"
            />
          </div-->
            <p
              class="text-gray-1 tw-pb-2 tw-mt-2 tw-break-words"
              v-html="item.summary"
            ></p>
            <div class="tw-border-t-2 tw-mt-2"></div>
            <div class="tw-flex tw-flex-row tw-mt-4 tw-space-x-4">
              <div class="tw-cursor-pointer" @click="like(item.id, i)">
                <i
                  class="fa fa-thumbs-up text-gray-1 tw-mr-1"
                  v-if="!item.like_loading"
                ></i>
                <i class="fa fa-spinner fa-spin text-gray-1 tw-mr-1" v-else></i>
                <span class="text-gray-1">({{ item.likers_count }})</span>
              </div>
              <router-link
                :to="{ name: 'challengeShow', params: { id: item.id } }"
              >
                <i
                  class="fa fa-commenting-o text-gray-1 tw-mr-1"
                  aria-hidden="true"
                ></i>
                <span class="text-gray-1">Comment</span>
              </router-link>
              <!--div>
              <i
                class="fa fa-share text-gray-1 tw-mr-1"
                aria-hidden="true"
              ></i>
              <span class="text-gray-1">Share</span>
            </div-->
              <router-link
                :to="{ name: 'challengeShow', params: { id: item.id } }"
                class="text-primary"
                style="margin-left: auto;"
              >
                More
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
              </router-link>
            </div>
          </div>
          <div class="tw-flex flex-row tw-mx-6 tw-justify-center tw-mt-4">
            <button
              @click="loadMore"
              class="tw-px-4 tw-py-2 tw-rounded tw-uppercase tw-outline-none focus:tw-outline-none"
              :class="
                loading || noMoreData
                  ? 'tw-bg-gray-200 tw-border-gray-200'
                  : 'border-color-2 bg-orange-2'
              "
              :disabled="loading || noMoreData"
            >
              {{
                noMoreData ? 'no more data' : loading ? 'loading' : 'load more'
              }}<i
                class="tw-ml-1 fa fa-arrow-circle-o-right"
                aria-hidden="true"
                v-if="!loading"
              ></i>
              <i
                class="tw-ml-1 fa fa-spinner fa-spin"
                aria-hidden="true"
                v-else
              ></i>
            </button>
          </div>
          <div class="tw-mx-4 tw-mt-8">
            <div class="">
              <div class="tw-rounded" v-if="blogs.length > 0">
                <h2
                  class="tw-font-bold bg-primary tw-rounded-t tw-text-white tw-py-3 tw-text-center"
                >
                  Popular Events
                </h2>
                <div
                  class="tw-p-2 bg-orange-4 tw-border-b tw-border-l tw-border-r tw-rounded-b"
                >
                  <div
                    class="tw-mb-4 tw-flex tw-flex-col"
                    v-for="(item, i) in blogs"
                    :key="`discussion-popular-${i}`"
                  >
                    <div
                      style="max-width: 100%; width: 100%; max-height: 110px;"
                    >
                      <img
                        :src="item.image"
                        class="tw-rounded"
                        style="
                          max-width: 100%;
                          width: 100%;
                          max-height: 110px;
                          object-fit: fill;
                        "
                      />
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-content-between tw-overflow-hidden"
                    >
                      <h4
                        class="tw-font-bold tw-truncate tw-leading-none tw-my-2"
                      >
                        {{ item.title }}
                      </h4>
                      <div
                        class="text-gray-1 tw-text-xs tw-leading-none tw-mt-auto max-line-2 tw-mb-1"
                      >
                        {{ item.summary }}
                      </div>
                      <span
                        class="text-primary tw-mt-auto tw-text-xs tw-font-italic tw-leading-none tw-mt-2"
                        style="font-style: italic;"
                        >Posted on {{ item.created_at | formatDate }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-mt-4">
                <img
                  src="https://api.beeznests.com/storage/portfolios/202010/24/93_1603542218_fMHlOXSgCc.png"
                />
              </div>
              <div class="bg-orange-4 tw-rounded tw-border tw-mt-6 tw-pt-4">
                <h2 class="tw-text-2xl tw-font-bold tw-text-center text-mb-4">
                  Invite Friends
                </h2>
                <div
                  class="tw-mt-4 tw-flex tw-items-center tw-border tw-py-2 tw-mx-6 tw-rounded tw-bg-white"
                >
                  <input
                    class="tw-appearance-none tw-bg-transparent tw-border-none tw-w-full tw-text-gray-700 tw-mr-3 tw-py-1 tw-px-2 tw-leading-tight tw-outline-none"
                    type="text"
                    placeholder="Email Address"
                    v-model="inviteMail"
                  />
                  <span class="flex-shrink-0 text-sm tw-mr-4">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
                <div
                  class="tw-my-1 tw-mx-6"
                  v-if="inviteMessage"
                  :class="{ 'tw-text-red-500': inviteError }"
                >
                  {{ inviteMessage }}
                </div>
                <div class="tw-my-4 tw-mx-6">
                  <button
                    @click="invite"
                    class="tw-shadow tw-outline-none focus:tw-outline-none tw-w-full tw-py-2 tw-rounded bg-orange-2 tw-uppercase disabled:tw-opacity-75"
                    :disabled="inviteLoading"
                  >
                    <i class="fa fa-spin fa-spinner" v-if="inviteLoading"></i>
                    Invite now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import CommonHeader from './common/header'
import CommonFooter from './common/footer'
import { fetchIndex, like } from '@/api/challenge'
import { fetchCategory } from '@/api/article'
import { inviteFriend } from '@/api/user'
import { mapGetters } from 'vuex'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
export default {
  components: {
    MobileDrawer,
    CommonFooter,
    CommonHeader,
    MobileHeader,
    MobileFooter,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
    this.loadData(true)
    this.loadBlog()
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  data: () => ({
    data: [],
    page: 1,
    last_page: 1,
    noMoreData: false,
    loading: false,
    categories: [],
    category_id: 0,
    keyword: '',
    links: {},
    blogs: [],
    currentShow: 0,
    topItems: [
      {
        title: 'Please be respectful',
        content:
          'Discussion topics should be kept professional. (e.g.  current events, networking communities, career or educational advice, etc)',
      },
      {
        title: 'Keep yourself safe',
        content:
          'Physical distancing is important but so is social engagement. You can stay active in the community and engage in conversation while ensuring proper safety measures are kept.',
      },
    ],
    inviteMail: null,
    inviteError: false,
    inviteLoading: false,
    inviteMessage: null,
  }),
  methods: {
    async invite() {
      this.inviteLoading = true
      this.inviteError = false
      try {
        await inviteFriend(this.inviteMail)
        this.inviteMessage = 'Successful!'
      } catch (e) {
        this.inviteError = true
        if (e.response && e.response.data && e.response.data.message) {
          this.inviteMessage = e.response.data.message
        } else {
          this.inviteMessage = 'Server Error!'
        }
      } finally {
        this.inviteLoading = false
      }
    },
    async like(id, index) {
      this.$set(this.data[index], 'like_loading', true)
      const { status, count } = await like(id)
      this.$set(this.data[index], 'likers_count', count)
      this.$set(this.data[index], 'hasLiked', status)
      this.$set(this.data[index], 'like_loading', false)
    },
    async loadMore() {
      if (this.noMoreData) {
        return
      }
      this.page += 1
      await this.loadData()
    },
    async loadPage(page) {
      this.page = page
      this.data = []
      if (this.noMoreData && this.page > 1) {
        return
      }
      this.loading = true
      const requestData = {
        page,
        per_page: 6,
      }
      const res = await fetchIndex(requestData)
      this.data = res.data
      this.noMoreData = res.meta.last_page === this.page
      this.loading = false
      this.links = res.links
    },
    async loadData(reset = false) {
      if (reset) {
        this.page = 1
        this.data = []
        this.noMoreData = false
      }
      this.loading = true
      const requestData = {
        includes: 'summary,content',
        per_page: 6,
      }
      this.keyword ? (requestData.title = this.keyword) : null
      this.category_id > 0 ? (requestData.category_id = this.category_id) : null
      requestData.page = this.page
      const res = await fetchIndex(requestData)
      this.data = reset ? res.data : this.data.concat(res.data)
      this.noMoreData = res.meta.last_page === this.page
      this.loading = false
      this.links = res.links
      if (reset) {
        this.categories = res.categories
      }
    },
    async loadBlog() {
      const res = await fetchCategory(2, {})
      this.blogs = res.data
    },
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-5 {
  background-color: #fff9f1;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
.tw-border,
.tw-border-t-2 {
  border-color: #ddd;
}
</style>
