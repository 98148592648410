<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="4" class="mt-12">
        <v-card>
          <v-card-title>
            Change Password
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Old Password"
              type="password"
              v-model="oldPassword"
            ></v-text-field>
            <v-text-field
              label="New Password"
              type="password"
              v-model="password"
            ></v-text-field>
            <v-text-field
              label="Confirm New Password"
              type="password"
              v-model="rePassword"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              :loading="loading"
              color="orange"
              class="white--text"
              @click="changePassword"
              >Change</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settingPassword } from '@/api/setting'
export default {
  data: () => ({
    newPassword: null,
    password: null,
    rePassword: null,
    loading: false,
  }),
  methods: {
    async changePassword() {
      this.loading = true
      try {
        await settingPassword({
          new_password: this.password,
          old_password: this.oldPassword,
          new_password_confirmation: this.rePassword,
        })
        this.$message('Your password has been changed!')
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
  },
}
</script>
