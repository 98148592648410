<template>
  <div>
    <template v-if="!isMobile">
      <common-header></common-header>
      <div class="tw-container tw-mx-auto tw-mt-10">
        <div class="tw-flex tw-flex-row tw-justify-center">
          <div class="tw-mr-12" style="max-width: 580px; width: 580px;">
            <div
              class="tw-border border-gray-2 tw-rounded tw-p-6 tw-mx-2 tw-mb-10 border-gray-3"
              v-for="item in data"
              :key="`post-${item.id}`"
            >
              <div
                v-if="item.image"
                @click="
                  $router.push({ name: 'articleShow', params: { id: item.id } })
                "
              >
                <img :src="item.image" class="tw-rounded" />
              </div>
              <h2 class="tw-font-normal tw-text-xl tw-py-4">
                {{ item.title }}
              </h2>
              <p class="text-gray-1 tw-pb-4 tw-text-sm">
                {{ item.summary }}
              </p>
              <router-link
                :to="{ name: 'articleShow', params: { id: item.id } }"
                class="text-primary tw-text-xs tw-py-4 tw-font-weight"
                >Read More<i class="fa fa-chevron-right tw-ml-1"></i
              ></router-link>
              <div class="tw-border-t-4 tw-mt-4 border-gray-3"></div>
              <div class="tw-flex tw-flex-row tw-space-x-4 tw-mt-4 tw-text-xs">
                <div>
                  <i class="fa fa-user-o text-primary tw-mr-1"></i>
                  <span class="text-gray-1">{{
                    item.user ? item.user.name : 'Beeznests'
                  }}</span>
                </div>
                <div>
                  <i
                    class="fa fa-calendar text-primary tw-mr-1"
                    aria-hidden="true"
                  ></i>
                  <span class="text-gray-1">{{
                    item.created_at | formatDate
                  }}</span>
                </div>
                <div>
                  <i
                    class="fa fa-heart-o text-primary tw-mr-1"
                    aria-hidden="true"
                  ></i>
                  <span class="text-gray-1">50 Likes</span>
                </div>
                <div>
                  <i
                    class="fa fa-comments-o text-primary tw-mr-1"
                    aria-hidden="true"
                  ></i>
                  <span class="text-gray-1">0 Comments</span>
                </div>
              </div>
            </div>
          </div>
          <div class="" style="max-width: 240px;">
            <div class="">
              <div class="tw-rounded tw-border tw-p-6 tw-shadow-md">
                <h2 class="tw-text-xl tw-font-normal">Search</h2>
                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <div class="tw-mt-2 tw-mb-2 tw-border-b tw-flex">
                  <input
                    type="text"
                    class="tw-w-full tw-py-2 tw-px-1 tw-outline-none focus:outline-none tw-border-b-2 tw-text-xs"
                    placeholder="Search Now..."
                    v-model="keyword"
                  />
                  <button
                    @click="search"
                    class="tw-uppercase tw-outline-none focus:tw-outline-none active:tw-outline-none tw-px-2 tw-text-sm tw-text-gray"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div class="tw-rounded tw-border tw-p-6 tw-mt-8 tw-shadow-md">
                <h2 class="tw-text-xl tw-font-normal">Recent Post</h2>
                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <div>
                  <div
                    class="tw-flex tw-flex-row tw-my-4"
                    v-for="(item, i) in recommend"
                    :key="`recent-post-${i}`"
                  >
                    <div class="tw-rounded">
                      <img
                        :src="item.image"
                        class="tw-rounded tw-h-full"
                        style="
                          width: 48px;
                          height: 48px;
                          max-width: 48px;
                          max-height: 48px;
                          object-fit: cover;
                        "
                      />
                    </div>
                    <div class="tw-flex tw-flex-col tw-ml-4">
                      <h3
                        class="tw-font-normal tw-text-sm tw-leading-tight"
                        style="max-height: 30px; overflow: hidden;"
                      >
                        {{ item.title }}
                      </h3>
                      <div>
                        <i
                          class="fa fa-calendar text-primary tw-mr-1"
                          aria-hidden="true"
                        ></i>
                        <span class="text-gray-1 tw-text-xs">{{
                          item.created_at | formatMonth
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-rounded tw-border tw-p-6 tw-mt-8 tw-shadow-md">
                <h2 class="tw-text-xl tw-font-normal">Blog Categories</h2>
                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <ul>
                  <li
                    class="tw-my-4 tw-flex tw-flex-row tw-justify-between tw-text-sm text-gray-1"
                    :class="i < 1 ? 'text-primary' : ''"
                    v-for="(item, i) in categories"
                    :key="`post-category-${i}`"
                  >
                    <span>{{ item.name }}</span>
                    <span>({{ item.id }})</span>
                  </li>
                </ul>
              </div>
              <div class="tw-rounded tw-border tw-p-6 tw-mt-8 tw-shadow-md">
                <h2 class="tw-text-xl tw-font-normal">Archive Posts</h2>
                <div class="tw-flex flex-row">
                  <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                  <div
                    class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                  ></div>
                </div>
                <ul>
                  <li
                    class="tw-my-4 tw-text-sm text-gray-1"
                    :class="i < 1 ? 'text-primary' : ''"
                    v-for="(item, i) in archives"
                    :key="`post-archive-${i}`"
                  >
                    <i
                      class="fa fa-folder-o text-primary tw-mr-2"
                      aria-hidden="true"
                    ></i>
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <!--div class="tw-rounded tw-border tw-p-6 tw-mt-8 tw-shadow-md">
              <h2 class="tw-text-xl tw-font-normal">Meta</h2>
              <div class="tw-flex flex-row">
                <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                <div
                  class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                ></div>
              </div>
              <ul>
                <li
                  class="tw-my-4 tw-text-sm text-gray-1"
                  :class="i < 1 ? 'text-primary' : ''"
                  v-for="i in 3"
                  :key="`post-meta-${i}`"
                >
                  <span>LinkdedIn</span>
                </li>
              </ul>
            </div-->
            </div>
          </div>
        </div>
      </div>
      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="tw-mt-6 tw-mx-4">
            <div class="tw-rounded tw-border tw-p-6 tw-shadow-md">
              <h2 class="tw-text-xl tw-font-normal">Search</h2>
              <div class="tw-flex flex-row">
                <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                <div
                  class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                ></div>
              </div>
              <div class="tw-mt-2 tw-mb-2 tw-border-b tw-flex">
                <input
                  type="text"
                  class="tw-w-full tw-py-2 tw-px-1 tw-outline-none focus:outline-none tw-border-b-2 tw-text-xs"
                  placeholder="Search Now..."
                  v-model="keyword"
                />
                <button
                  @click="search"
                  class="tw-uppercase tw-outline-none focus:tw-outline-none active:tw-outline-none tw-px-2 tw-text-sm tw-text-gray"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="tw-rounded tw-border tw-p-6 tw-mt-8 tw-shadow-md">
              <h2 class="tw-text-xl tw-font-normal">Recent Post</h2>
              <div class="tw-flex flex-row">
                <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                <div
                  class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                ></div>
              </div>
              <div>
                <div
                  class="tw-flex tw-flex-row tw-my-4"
                  v-for="(item, i) in recommend"
                  :key="`recent-post-${i}`"
                >
                  <div class="tw-rounded">
                    <img
                      :src="item.image"
                      class="tw-rounded tw-h-full"
                      style="
                        width: 48px;
                        height: 48px;
                        max-width: 48px;
                        max-height: 48px;
                        object-fit: cover;
                      "
                    />
                  </div>
                  <div class="tw-flex tw-flex-col tw-ml-4">
                    <h3
                      class="tw-font-normal tw-text-sm tw-leading-tight"
                      style="max-height: 30px; overflow: hidden;"
                    >
                      {{ item.title }}
                    </h3>
                    <div>
                      <i
                        class="fa fa-calendar text-primary tw-mr-1"
                        aria-hidden="true"
                      ></i>
                      <span class="text-gray-1 tw-text-xs">{{
                        item.created_at | formatMonth
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-rounded tw-border tw-p-6 tw-mt-8 tw-shadow-md">
              <h2 class="tw-text-xl tw-font-normal">Blog Categories</h2>
              <div class="tw-flex flex-row">
                <div class="tw-w-8 bg-primary" style="height: 3px;"></div>
                <div
                  class="tw-border-t tw-h-1 tw-flex-shrink tw-flex-grow"
                ></div>
              </div>
              <ul class="pl-0">
                <li
                  class="tw-my-4 tw-flex tw-flex-row tw-justify-between tw-text-sm text-gray-1"
                  :class="i < 1 ? 'text-primary' : ''"
                  v-for="(item, i) in categories"
                  :key="`post-category-${i}`"
                >
                  <span>{{ item.name }}</span>
                  <span>({{ item.id }})</span>
                </li>
              </ul>
            </div>
            <div class="tw-mt-12">
              <div
                class="tw-border border-gray-2 tw-rounded tw-p-4 tw-mb-10 border-gray-3"
                v-for="item in data"
                :key="`post-${item.id}`"
              >
                <div
                  v-if="item.image"
                  @click="
                    $router.push({
                      name: 'articleShow',
                      params: { id: item.id },
                    })
                  "
                >
                  <img :src="item.image" class="tw-rounded" />
                </div>
                <h2 class="tw-font-normal tw-text-xl tw-py-4">
                  {{ item.title }}
                </h2>
                <p class="text-gray-1 tw-pb-4 tw-text-sm">
                  {{ item.summary }}
                </p>
                <router-link
                  :to="{ name: 'articleShow', params: { id: item.id } }"
                  class="text-primary tw-text-xs tw-py-4 tw-font-weight"
                  >Read More<i class="fa fa-chevron-right tw-ml-1"></i
                ></router-link>
                <div class="tw-border-t-4 tw-mt-4 border-gray-3"></div>
                <div class="tw-grid tw-grid-cols-2 tw-mt-4 tw-text-xs">
                  <div>
                    <i class="fa fa-user-o text-primary tw-mr-1"></i>
                    <span class="text-gray-1">{{
                      item.user ? item.user.name : 'Beeznests'
                    }}</span>
                  </div>
                  <div>
                    <i
                      class="fa fa-calendar text-primary tw-mr-1"
                      aria-hidden="true"
                    ></i>
                    <span class="text-gray-1">{{
                      item.created_at | formatDate
                    }}</span>
                  </div>
                  <div>
                    <i
                      class="fa fa-heart-o text-primary tw-mr-1"
                      aria-hidden="true"
                    ></i>
                    <span class="text-gray-1">50 Likes</span>
                  </div>
                  <div>
                    <i
                      class="fa fa-comments-o text-primary tw-mr-1"
                      aria-hidden="true"
                    ></i>
                    <span class="text-gray-1">0 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import CommonHeader from './common/header'
import CommonFooter from './common/footer'
import { fetchIndex } from '@/api/article'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
import { mapGetters } from 'vuex'
export default {
  components: {
    MobileFooter,
    CommonFooter,
    CommonHeader,
    MobileHeader,
    MobileDrawer,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
    this.loadData(true)
  },
  destroyed() {
    //document.documentElement.style.fontSize = '16px'
  },
  data: () => ({
    categories: [
      {
        name: 'Construction',
        value: 17,
      },
      {
        name: 'UI design',
        value: 9,
      },
      {
        name: 'Photography',
        value: 32,
      },
      {
        name: 'Industry',
        value: 19,
      },
    ],
    archives: [
      {
        name: '2020',
        value: 17,
      },
    ],
    recommend: [],
    data: [],
    page: 1,
    last_page: 1,
    noMoreData: false,
    loading: false,
    keyword: null,
  }),
  methods: {
    search() {
      this.loadData(true)
    },
    async loadData(reset = false) {
      if (reset) {
        this.page = 1
        this.data = []
        this.noMoreData = false
      }
      this.loading = true
      const requestData = {}
      if (this.keyword) {
        requestData.keyword = this.keyword
      }
      const res = await fetchIndex(requestData)
      this.data = reset ? res.data : this.data.concat(res.data)
      this.noMoreData = res.meta.last_page === this.page
      this.loading = false
      if (res.categories) {
        this.categories = res.categories
      }
      if (res.recommend) {
        this.recommend = res.recommend
      }
    },
    async loadMore() {
      if (this.noMoreData) {
        return
      }
      this.page += 1
      await this.loadData()
    },
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.border-gray-3 {
  border-color: #ddd;
}
.text-gray-1 {
  color: #8a92ab;
}
</style>
