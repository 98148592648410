const state = {
  viewUser: {},
  loading: false,
}

const mutations = {
  SET(state, info) {
    state.viewUser = info
  },
  LOADING(state, status) {
    state.loading = status
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
