export default {
  path: '/settings',
  component: () => import('@/views/layouts/root'),
  meta: {
    auth: true,
    emailVerify: true,
  },
  redirect: { name: 'userSettings' },
  children: [
    {
      path: 'student',
      name: 'studentSettings',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/student'),
    },
    {
      path: 'index',
      name: 'userSettings',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/index'),
    },
    {
      path: 'password',
      name: 'userPassword',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/password'),
      meta: {
        auth: true,
        emailVerify: true,
      },
    },
    {
      path: 'subscribe',
      name: 'settingSubscribe',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/subscribe'),
      meta: {
        auth: true,
        emailVerify: true,
      },
    },
    {
      path: 'company',
      name: 'companySettings',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/company'),
    },
    {
      path: 'person',
      name: 'personSettings',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/person'),
    },
    {
      path: 'next',
      name: 'nextSettings',
      component: () => import(/* webpackChunkName: 'settings' */'@/views/settings/next'),
    },
  ],
}
