import axios from '@/plugins/axios'
export const create = (data) => {
  return axios.post('experiences', data)
}

export const fetchData = (id, params) => {
  return axios.get(`experiences/${id}`, { params })
}

export const fetchIndex = (params) => {
  return axios.get('experiences/', { params })
}

export const destroy = (id) => {
  return axios.delete(`experiences/${id}`)
}

export const updateExperience = (id, data) => {
  return axios.put(`experiences/${id}`, data)
}
