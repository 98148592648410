<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-row justify="center">
        <!--v-col cols="12" md="3" lg="1" xl="1">
          <v-card class="pa-2">
            <v-list dense nav>
              <v-list-item
                v-for="item in items"
                :key="item.text"
                link
                @click="$router.push(item.route)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col-->
        <v-col cols="12" md="9" lg="11">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        icon: 'mdi-bell-ring-outline',
        text: 'About Us',
        route: {
          name: 'articleShow',
          params: {
            id: 1,
          },
        },
      },
      {
        icon: 'mdi-bell',
        text: 'FAQ',
        route: {
          name: 'articleShow',
          params: {
            id: 2,
          },
        },
      },
    ],
  }),
}
</script>
