<template>
  <v-col cols="12" md="8" lg="6">
    <v-card flat outlined>
      <v-toolbar flat>
        <v-toolbar-title>Company Information</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <field-row
          label="Company Name"
          :value="userInfo.identityable.name"
          @update="showEdit('identityable.name')"
        >
        </field-row>
        <field-row
          label="Company Address"
          :value="userInfo.identityable.address"
          @update="showEdit('identityable.address')"
        >
        </field-row>
        <field-row
          label="Profit or Non-Profit"
          :value="userInfo.identityable.is_profit"
          @update="showEdit('identityable.is_profit')"
        >
          {{ userInfo.identityable.is_profit ? 'Profit' : 'Non-Profit' }}
        </field-row>
        <field-row
          label="Company Employees"
          :value="userInfo.identityable.employees"
          @update="showEdit('identityable.employees')"
        >
        </field-row>
        <field-row
          label="Linkedin"
          :value="userInfo.identityable.linkedin"
          @update="showEdit('identityable.linkedin')"
        ></field-row>
        <field-row
          label="Website"
          :value="userInfo.identityable.website"
          @update="showEdit('identityable.website')"
          :divider="false"
        ></field-row>
      </v-card-text>
    </v-card>
    <v-card flat outlined class="mt-5">
      <v-toolbar flat>
        <v-toolbar-title>Contact Info</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <field-row
          label="Contact Name"
          :value="userInfo.identityable.contact_name"
          @update="showEdit('identityable.contact_name')"
        >
        </field-row>
        <field-row
          label="Contact Role"
          :value="userInfo.identityable.contact_role"
          @update="showEdit('identityable.contact_role')"
        >
        </field-row>
        <field-row
          label="Contact Email"
          :value="userInfo.identityable.email"
          @update="showEdit('identityable.email')"
        >
        </field-row>
        <field-row
          label="Contact Phone Number"
          :value="userInfo.identityable.phone"
          @update="showEdit('identityable.phone')"
          :divider="false"
        >
        </field-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import FieldRow from '@/components/Settings/FieldRow'
import { mapGetters } from 'vuex'
export default {
  components: {
    FieldRow,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {},
  methods: {
    showEdit(field) {
      this.$store.commit('setting/SET', field)
    },
  },
}
</script>
