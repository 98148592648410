<template>
  <div class="mt-n3 fill-height">
    <v-row justify="center" class="grey lighten-4 pt-15 pb-8">
      <v-col cols="12" md="6" class="text-center mx-3" align-self="center">
        <h3 class="text-h5 text-lg-h3">Student Freelance Platform</h3>
        <div class="text-subtitle-1 mt-5">
          The first student exclusive freelance platform - outsource all your
          remote tasks /projects to students here
        </div>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="grey lighten-4 pb-5 pb-sm-8 pb-md-10 pb-lg-12 top-banner"
    >
      <v-col cols="12" class="text-center pt-3 pb-5 py-sm-8 py-md-10 py-lg-12">
        <v-btn
          v-if="!isLoggedIn"
          color="orange"
          class="white--text mt-2"
          depressed
          :to="{ name: 'AuthRegister' }"
          >sign up</v-btn
        >
        <faq-link
          class="mt-2 ml-2 white--text mx-2 v-btn v-btn--depressed v-btn--router theme--light v-size--default orange"
          text="How to use our platform"
        ></faq-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {},
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
}
</script>
<style lang="scss" scoped></style>
