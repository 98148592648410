<template>
  <v-col cols="12" md="8" lg="6">
    <skills class="mb-5"></skills>
    <v-card flat outlined>
      <v-toolbar flat>
        <v-toolbar-title>More</v-toolbar-title>
      </v-toolbar>
      <v-alert
        icon="mdi-alert-outline"
        prominent
        text
        type="warning"
        color="yellow darken-3"
        class="mt-5"
      >
        Your Profile will be available to potential employers, so please don't
        leave anything blank. If you leave something blank, you risk your
        chances of getting the project or challenge you are applying for! Once
        updated, check out yourself on our Network page!
      </v-alert>
      <v-card-text>
        <field-row
          label="Education"
          :value="userInfo.identityable.education"
          @update="showEdit('identityable.education')"
        ></field-row>
        <field-row
          label="Linkedin"
          :value="userInfo.identityable.linkedin"
          @update="showEdit('identityable.linkedin')"
          :divider="false"
        ></field-row>
      </v-card-text>
    </v-card>
    <!--v-card flat outlined class="mt-5">
      <v-toolbar flat>
        <v-toolbar-title>Experience</v-toolbar-title>
      </v-toolbar>
      <v-alert
        icon="mdi-alert-outline"
        prominent
        text
        type="warning"
        color="yellow darken-3"
        class="mt-5"
      >
        All volunteer/professional experience should follow the format of:
        [Organization Name - Position Title - Dates Applicable] Brief
        explanation of your responsibilities
      </v-alert>
      <v-card-text>
        <field-row
          label="Professional"
          :value="userInfo.identityable.professional_experience"
          @update="showEdit('identityable.professional_experience')"
        ></field-row>
        <field-row
          label="Volunteer"
          :value="userInfo.identityable.volunteer_experience"
          @update="showEdit('identityable.volunteer_experience')"
        ></field-row>
      </v-card-text>
    </v-card-->
    <experience class="mt-5"></experience>
    <resume class="mt-5"></resume>
  </v-col>
</template>
<script>
import Skills from '@/components/Settings/Skills'
import FieldRow from '@/components/Settings/FieldRow'
import Resume from '@/components/Settings/Resume'
import Experience from '@/components/Settings/Experience'
import { mapGetters } from 'vuex'
import { settingType } from '@/api/setting'

export default {
  components: {
    Skills,
    FieldRow,
    Resume,
    Experience,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  async mounted() {},
  methods: {
    showEdit(field) {
      this.$store.commit('setting/SET', field)
    },
  },
}
</script>
