<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="type"
              light
              right
              background-color="orange"
              color="white"
            >
              <v-tab>Students</v-tab>
              <v-tab>Individual</v-tab>
              <v-tab>Companies</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              item-text="name"
              item-value="id"
              :items="categories"
              label="Select Category"
              solo
              v-model="category"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row :justify="loading ? 'center' : null">
          <v-col cols="12" v-if="loading">
            <v-row align="center" justify="center" class="fill-height">
              <v-sheet>
                <v-progress-circular
                  indeterminate
                  color="deep-orange lighten-2"
                ></v-progress-circular>
              </v-sheet>
            </v-row>
          </v-col>
          <v-col
            cols="10"
            sm="6"
            md="4"
            xl="3"
            v-for="item in students"
            :key="`student-${item.id}`"
            v-else
          >
            <v-card>
              <v-card-text>
                <v-row class="mx-auto" justify="center">
                  <v-avatar>
                    <v-img :src="item.avatar" />
                  </v-avatar>
                </v-row>
              </v-card-text>
              <v-card-title class="justify-center pt-0">
                <v-img
                  :src="item.country_flag"
                  max-height="24"
                  max-width="24"
                  contain
                  class="align-self-center mr-1"
                ></v-img>
                <span
                  class="font-weight-light text-center max-line-1"
                  style="max-width: 200px; overflow: hidden;"
                  >{{ item.name | noName }}</span
                >
                <v-tooltip
                  bottom
                  v-if="item.organization && item.organization.id"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      :src="`https://beeznests-cdn.fra1.cdn.digitaloceanspaces.com/img/badge_${item.organization.badge}.png`"
                      max-height="18"
                      max-width="18"
                      contain
                      class="align-self-center mr-1 ml-1"
                      v-on="on"
                      v-bind="attrs"
                    ></v-img>
                  </template>
                  <span>{{ item.organization.description }}</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <v-row align="center" class="mx-0" justify="center">
                  <v-rating
                    :value="parseFloat(item.counts.rate)"
                    dense
                    half-increments
                    readonly
                    size="22"
                  ></v-rating>
                  <div class="grey--text ml-4">
                    {{ item.counts.rate | toFixed }} ({{
                      item.counts.rate_count
                    }})
                  </div>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-sheet
                  :style="
                    isMobile
                      ? ''
                      : 'height: 88px;overflow: hidden;text-overflow: ellipsis;'
                  "
                  >{{ item.introduction }}</v-sheet
                >
                <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  v-if="type < 2"
                >
                  <template v-if="item.categories.length > 0">
                    <v-chip
                      v-for="category in item.categories"
                      :key="`student-${item.id}-categroy-${category.id}`"
                      @click="changeCategory(category.id)"
                      >{{ category.name }}</v-chip
                    >
                  </template>
                  <v-chip label v-else>
                    No Skills
                  </v-chip>
                </v-chip-group>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  icon
                  :loading="isLoading(item.id)"
                  @click="
                    isFollowed(item.id) ? unfollow(item.id) : follow(item.id)
                  "
                  :color="isFollowed(item.id) ? 'primary' : ''"
                >
                  <v-icon class="mr-1"
                    >mdi-account-heart-outline</v-icon
                  > </v-btn
                ><small>{{ item.followers_count }}</small>
                <v-btn
                  class="ml-auto"
                  color="orange"
                  text
                  :to="{ name: 'userShow', params: { id: item.id } }"
                >
                  View More
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="pages.last_page > 1">
      <v-col cols="12">
        <v-pagination
          v-model="pages.current_page"
          :length="pages.last_page"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { fetchIndex } from '@/api/student'
const typeMap = ['student', 'person', 'company']
import { mapActions } from 'vuex'
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    this.loadIndex()
  },
  data() {
    return {
      loading: false,
      students: [],
      categories: [],
      pages: {
        last_page: 1,
        current_page: 1,
      },
      category: null,
      type: 0,
    }
  },
  watch: {
    type() {
      this.category = null
      this.loadIndex(true)
    },
    category(id) {
      if (id) {
        this.loadIndex(true)
      }
    },
    'pages.current_page'() {
      this.loadIndex()
    },
  },
  methods: {
    changeCategory(id) {
      this.category = id
    },
    async loadIndex(reset = false) {
      this.loading = true
      try {
        const requestParams = {}
        if (this.category) {
          requestParams.category_id = this.category
        }
        if (reset) {
          this.pages = {
            current_page: 1,
            last_page: 1,
          }
        }
        requestParams.page = this.pages.current_page
        requestParams.type = typeMap[this.type]
        const { data, meta, categories, followed } = await fetchIndex(
          requestParams
        )
        this.students = data
        this.categories = categories
        this.pages = meta
        if (followed) {
          this.$store.dispatch('follow/update', followed)
        }
      } catch (e) {
        this.$store.dispatch('message/pushMessage', e.response.data.message)
      } finally {
        this.loading = false
      }
    },
    isFollowed(id) {
      const index = this.$store.state.follow.data.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.data[index].followed
      return false
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    ...mapActions({
      follow: 'follow/follow',
      unfollow: 'follow/unfollow',
    }),
  },
}
</script>
