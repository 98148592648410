<template>
  <v-card
    :class="loading || error ? 'fill-height' : ''"
    flat
    class="mx-auto"
    style="max-width: 1024px;"
  >
    <loading
      :loading="loading"
      no-data="Not Found"
      v-if="loading || error"
    ></loading>
    <div v-html="data.content" v-else></div>
  </v-card>
</template>
<script>
import { fetchData } from '@/api/article'
import Loading from '@/components/Loading'
export default {
  components: {
    Loading,
  },
  computed: {
    id() {
      return parseFloat(this.$route.params.id) || 0
    },
  },
  watch: {
    id() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  data: () => ({
    loading: false,
    error: false,
    data: {
      category: {},
    },
  }),
  methods: {
    async loadData() {
      this.loading = true
      try {
        const { data } = await fetchData(this.id)
        this.data = data
        this.loading = false
        this.error = false
      } catch (e) {
        this.error = true
        this.loading = false
      }
    },
  },
}
</script>
