<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card flat>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Experiences</v-toolbar-title>
      </v-toolbar>
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="9" lg="8" class="mt-8">
          <v-card :loading="saveLoading || loadLoading">
            <v-toolbar flat>
              <v-toolbar-title
                >{{ id > 0 ? 'Edit' : 'Add' }} Experiences</v-toolbar-title
              >
            </v-toolbar>

            <v-card-text v-if="!loadLoading">
              <v-form ref="form" v-model="form">
                <v-text-field
                  label="Organization Name"
                  style="max-width: 290px;"
                  v-model="data.organization_name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-row no-gutters>
                  <v-col cols="12">
                    <label>Dates Applicable</label>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-menu
                      ref="startDate"
                      v-model="startDateModal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.date_start"
                          label="Start date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="startPicker"
                        type="month"
                        v-model="data.date_start"
                        :max="
                          data.date_end ||
                          new Date().toISOString().substr(0, 10)
                        "
                        @change="changeStartDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="auto" class="mx-4 align-self-center"> - </v-col>
                  <v-col cols="12" md="auto">
                    <v-menu
                      ref="endDate"
                      v-model="endDateModal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.date_end"
                          label="End date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="endPicker"
                        type="month"
                        v-model="data.date_end"
                        @change="changeEndDate"
                        :min="data.date_start"
                        :max="new Date().toISOString().substr(0, 10)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-text-field
                  label="Position Title"
                  style="max-width: 290px;"
                  v-model="data.position_title"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-textarea
                  class="mt-4"
                  outlined
                  :rules="[rules.required]"
                  v-model="data.content"
                  label="Brief Bullet Point description of your role/responsibility"
                ></v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                depressed
                color="deep-orange"
                class="white--text"
                @click="save"
                :disabled="!form"
                :loading="saveLoading"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { create, updateExperience, fetchData } from '@/api/experience'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number || String,
      default: 0,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(v) {
        this.$emit('show', v)
      },
    },
  },
  watch: {
    startDateModal(val) {
      val &&
        !this.data.date_start &&
        setTimeout(() => (this.$refs.startPicker.activePicker = 'YEAR'))
    },
    endDateModal(val) {
      val &&
        !this.data.date_end &&
        setTimeout(() => (this.$refs.endPicker.activePicker = 'YEAR'))
    },
    show(show) {
      if (show && this.id > 0) {
        this.loadData()
      }
    },
  },
  async mounted() {},
  data: () => ({
    widgets: false,
    notifications: true,
    modal: false,
    startDateModal: false,
    endDateModal: false,
    saveLoading: false,
    loadLoading: false,
    form: false,
    data: {
      date_start: '',
      date_end: '',
      position_title: '',
      organization_name: '',
      content: '',
    },
    rules: {
      email: (v) => !!(v || '').match(/@/) || 'Please enter a valid email',
      length: (len) => (v) =>
        (v || '').length >= len || `Invalid character length, required ${len}`,
      password: (v) =>
        !!(v || '').match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
        ) ||
        'Password must contain an upper case letter, a numeric character, and a special character',
      required: (v) => !!v || 'This field is required',
    },
  }),
  methods: {
    changeStartDate(date) {
      this.$refs.startDate.save(date)
    },
    changeEndDate(date) {
      this.$refs.endDate.save(date)
    },
    async loadData() {
      this.loadLoading = true
      try {
        const { data } = await fetchData(this.id)
        this.data = data
        this.loadLoading = false
      } catch (e) {
        this.loadLoading = false
      }
    },
    async save() {
      if (!this.form) return
      this.saveLoading = true
      if (this.id > 0) {
        try {
          const res = await updateExperience(this.id, this.data)
          this.saveLoading = false
          this.$emit('reload')
          this.$refs.form.reset()
          this.$emit('show', false)
        } catch (e) {
          this.saveLoading = false
          if (e.response && e.response.data && e.response.data.message) {
            //this.$message(e.response.data.message)
          }
        }
      } else {
        try {
          await create(this.data)
          this.saveLoading = false
          this.$refs.form.reset()
          this.$emit('reload')
          this.$emit('show', false)
        } catch (e) {
          this.saveLoading = false
          if (e.response && e.response.data && e.response.data.message) {
            //this.$message(e.response.data.message)
          }
        }
      }
    },
  },
}
</script>
