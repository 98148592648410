export default {
  path: '/jobs',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: 'create',
      name: 'jobsCreate',
      component: () => import(/* webpackChunkName: 'jobs' */'@/views/jobs/create'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: '',
      name: 'jobsIndex',
      component: () => import(/* webpackChunkName: 'jobs' */'@/views/jobs/index'),
      meta: {
        // auth: true,
        // settings: true,
      },
    },
    {
      path: ':id/edit',
      name: 'jobsEdit',
      component: () => import(/* webpackChunkName: 'jobs' */'@/views/jobs/create'),
    },
    {
      path: ':id',
      name: 'jobsShow',
      component: () => import(/* webpackChunkName: 'jobs' */'@/views/jobs/show'),
    },
  ],
}
