import Vue from 'vue'
import VueRouter from 'vue-router'
import authRouter from './modules/auth'
import challengeRouter from './modules/challenge'
import projectRouter from './modules/projects'
import hireRouter from '@/router/modules/hires'
import userRouter from './modules/user'
import settingRouter from './modules/setting'
import notificationRouter from '@/router/modules/notification'
import articleRouter from '@/router/modules/article'
import portfolioRouter from '@/router/modules/portfolio'
import payRouter from '@/router/modules/pay'
import organizationRouter from '@/router/modules/organization'
import linkRouter from '@/router/modules/link'
import otherRouter from '@/router/modules/other'
import newRouter from '@/router/modules/new'
import promotionRouter from '@/router/modules/promotion'
import MentorProgramRouter from '@/router/modules/mentor'
import BootcampRouter from '@/router/modules/bootcamp'
import indexRouter from './modules/index'
import TeamLeadRouter from './modules/teamLead'
import jobsRouter from './modules/jobs'
// import vuetify from '../plugins/vuetify'
import store from '@/store'
Vue.use(VueRouter)
const routes = [
  settingRouter,
  authRouter,
  challengeRouter,
  projectRouter,
  hireRouter,
  userRouter,
  notificationRouter,
  articleRouter,
  portfolioRouter,
  organizationRouter,
  payRouter,
  linkRouter,
  otherRouter,
  newRouter,
  promotionRouter,
  MentorProgramRouter,
  BootcampRouter,
  TeamLeadRouter,
  jobsRouter,
  indexRouter,
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: '/',
  routes,
})
router.beforeEach((to, from, next) => {
  store.commit('device/SET_DRAWER', false)
  if (
    // 不是去登录页
    to.fullPath.indexOf('/auth') === -1 &&
    // 不是去设置页
    to.fullPath.indexOf('/settings') === -1 &&
    store.getters.isLoggedIn &&
    // 资料不完整
    !store.getters.completeProfile
  ) {
    const type = store.state.auth.userInfo.type
    store.commit(
      'alert/SET',
      'If you are a Beeznests mentor or College team lead, please choose either individual or company status to move forward'
      // 'Please complete your profile as detailed as possible. Your profile plays a large role in why students or companies wish to work with you.'
    )
    if (!store.state.setting.redirectTo.fullPath) {
      store.commit('setting/SET_REDIRECT_TO', to)
    }
    next('/settings')
  } else if (to.fullPath.indexOf('/auth') === 0) {
    // 去登录页
    if (store.getters.isLoggedIn && store.getters.emailVerify) {
      next({ path: from.fullPath })
    } else {
      // 记录返回页面
      if (from.fullPath.indexOf('/auth') !== 0) {
        store.commit('auth/SET_BACK_TO', from.fullPath)
        // 记录跳转页
        if (store.getters.authRedirectTo === '/') {
          // 如果跳转的页面是默认的话，记录一下从哪个页面来的
          store.commit('auth/SET_REDIRECT_TO', from.fullPath)
        }
      }
      next()
    }
  } else if (
    to.matched.some((record) => record.meta.admin) &&
    (!store.getters.isLoggedIn || !store.getters.isAdmin)
  ) {
    store.commit('auth/SET_BACK_TO', from.fullPath)
    if (store.getters.authRedirectTo === '/') {
      // 如果跳转的页面是默认的话，记录一下从哪个页面来的
      store.commit('auth/SET_REDIRECT_TO', to.fullPath)
    }
    if (!store.getters.isLoggedIn) {
      next({ name: 'NewLogin' })
    } else {
      next({ name: 'unable' })
    }
  } else if (
    to.matched.some((record) => record.meta.auth) &&
    !store.getters.isLoggedIn
  ) {
    store.commit('auth/SET_BACK_TO', from.fullPath)
    if (store.getters.authRedirectTo === '/') {
      // 如果跳转的页面是默认的话，记录一下从哪个页面来的
      store.commit('auth/SET_REDIRECT_TO', to.fullPath)
    }
    next({ name: 'NewLogin' })
  } else if (
    to.matched.some((record) => record.meta.auth) &&
    store.getters.isLoggedIn &&
    !store.getters.emailVerify
  ) {
    store.commit('auth/SET_BACK_TO', from.fullPath)
    if (store.getters.authRedirectTo === '/') {
      // 如果跳转的页面是默认的话，记录一下从哪个页面来的
      store.commit('auth/SET_REDIRECT_TO', to.fullPath)
    }
    next({ name: 'EmailVerify' })
  } else if (
    to.matched.some((record) => record.meta.settings) &&
    !to.matched.some((record) => record.meta.notPayCost) &&
    store.getters.userInfo.is_need_to_pay_cost
  ) {
    next({ name: 'PaymentUserCost' })
  } else {
    if (to.fullPath === '/' && to.name === undefined) {
      next({ name: 'Index' })
    } else {
      next()
    }
  }
})
export default router
