import vuetify from '../../plugins/vuetify'
const state = {
  isMobile: false,
  drawer: false,
}

const mutations = {
  SET(state, status) {
    state.isMobile = status
  },
  TOGGLE(state) {
    state.drawer = !state.drawer
  },
  SET_DRAWER(state, status) {
    state.drawer = status
  },
}

const actions = {
  setMobile({ commit }) {
    commit('SET', !!vuetify.framework.breakpoint.mobile)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
