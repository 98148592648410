<template>
  <div
    class="tw-relative tw-flex tw-cursor-pointer align-self-center"
    style="overflow: visible;"
    @mouseover="show"
    @mouseleave="showText = false"
  >
    <v-img
      :src="`https://cdn.jsdelivr.net/gh/beeznests/static@master/dist/img/badge_${organization.badge}.png`"
      max-height="18"
      max-width="18"
      contain
      class="mr-1 ml-1"
    ></v-img>
    <div
      class="tw-p-2 tw-bg-white tw-shadow tw-absolute tw-rounded tw-mt-1 tw-flex"
      style="top: 100%; left: 50%; transform: translateX(-50%); z-index: 99;"
      v-show="showText && organization.show_text"
    >
      <div class="" style="white-space: nowrap;">
        {{ organization.show_text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['organization'],
  data: () => ({
    showText: false,
    top: 0,
    left: 0,
  }),
  methods: {
    show(e) {
      this.showText = true
      // const { top, left, width, height } = e.fromElement.getBoundingClientRect()
      // this.top = top + height
      // this.left = left + width
    },
  },
}
</script>
