<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" md="10" lg="8">
      <v-toolbar flat>
        <v-toolbar-title>Promotion manage</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small @click="fetchIndex(true)" class="mr-2">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon small @click="showCreate">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card flat v-if="loading">
        <v-row>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="6" md="3">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card>
      <template v-else-if="data.length > 0">
        <v-row>
          <v-col
            cols="12"
            md="3"
            lg="3"
            v-for="(item, index) in data"
            :key="`organization-${item.id}`"
          >
            <v-card>
              <v-card-title class="text-center">
                <span>{{ item.name }}</span>
                <v-spacer> </v-spacer>
                <v-btn icon small @click="edit(index)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="deleteData(index)"
                  :loading="item.deleteLoading"
                  :disabled="item.is_closed"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                {{ item.description }}
              </v-card-text>
              <v-divider></v-divider>
              <v-list dense nav>
                <v-list-item>
                  <v-list-item-action>Cost</v-list-item-action>
                  <v-list-item-title>${{ item.cost }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-pagination
              v-model="meta.current_page"
              :length="meta.last_page"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>
    </v-col>
    <v-dialog
      class="elevation-0"
      v-model="createDialog"
      max-width="600px"
      :overlay-opacity="0.1"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Promotion</span>
          <v-spacer> </v-spacer>
          <v-btn icon @click.stop="createDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Promotion Name"
            v-model="activeData.name"
          ></v-text-field>
          <v-text-field
            label="Promotion Cost"
            v-model="activeData.cost"
          ></v-text-field>
          <v-textarea
            dense
            auto-grow
            rows="1"
            label="Promotion Description"
            v-model="activeData.description"
          ></v-textarea>
          <v-switch
            v-model="activeData.is_closed"
            :label="activeData.is_closed ? 'Unavailable' : 'Available'"
            >activation</v-switch
          >
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            :loading="saveLoading"
            @click="
              activeData.id <= 0 ? createOrganization() : updateOrganization()
            "
            >Save</v-btn
          >
          <v-btn color="grey darken-1" text @click.stop="createDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { show, create, update, destroy, fetch } from '@/api/promotion'

export default {
  data: () => ({
    createDialog: false,
    saveLoading: false,
    loading: false,
    activeData: {
      name: undefined,
      code: undefined,
      id: 0,
    },
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }),
  mounted() {
    this.fetchIndex()
  },
  watch: {
    'meta.current_page'() {
      this.fetchIndex()
    },
  },
  methods: {
    showCreate() {
      this.activeData = {
        name: undefined,
        code: undefined,
        id: 0,
      }
      this.showCreateDialog()
    },
    showCreateDialog() {
      this.createDialog = true
    },
    edit(index) {
      this.activeData = Object.assign({}, this.data[index])
      this.editIndex = index
      this.showCreateDialog()
    },
    async deleteData(index) {
      this.$set(this.data[index], 'deleteLoading', true)
      try {
        await destroy(this.data[index].id)
        this.data.splice(index, 1)
      } catch (e) {
        this.$set(this.data[index], 'deleteLoading', false)
      }
    },
    async updateOrganization() {
      this.saveLoading = true
      try {
        const postData = Object.assign({}, this.activeData)
        const { data } = await update(this.activeData.id, postData)
        const index = this.editIndex
        this.$set(this.data[index], 'name', data.name)
        this.$set(this.data[index], 'cost', data.cost)
        this.$set(this.data[index], 'description', data.description)
        this.createDialog = false
        this.saveLoading = false
      } catch (e) {
        this.saveLoading = false
      }
    },
    async createOrganization() {
      this.saveLoading = true
      try {
        const postData = Object.assign({}, this.activeData)
        const { data } = await create(postData)
        if (data.id > 0) {
          this.data.push(data)
        }
        this.createDialog = false
        this.saveLoading = false
      } catch (e) {
        this.saveLoading = false
      }
    },
    async fetchIndex(reset = false) {
      this.loading = true
      const params = {
        page: this.meta.current_page,
      }
      if (reset) {
        this.data = []
        params.page = 1
      }
      try {
        const { data, meta } = await fetch(params)
        console.log(data, meta)
        this.data = data
        this.meta = meta
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
}
</script>
