<template>
  <v-app-bar app flat dense>
    <div
      class="tw-grid tw-w-full"
      style="display: grid; grid-template-columns: repeat(3, 1fr);"
    >
      <v-app-bar-nav-icon
        @click="$store.commit('device/TOGGLE')"
      ></v-app-bar-nav-icon>
      <div class="tw-flex tw-justify-center tw-self-center">
        <router-link to="/">
          <img src="@/assets/images/logo.png" style="max-height: 38px;" />
        </router-link>
      </div>

      <template v-if="!isLoggedIn">
        <div class="ml-auto tw-self-center">
          <v-btn
            depressed
            text
            dense
            small
            @click="$router.push({ name: 'NewRegister' })"
            >Join</v-btn
          >
        </div>
      </template>
      <template v-else>
        <div class="ml-auto tw-self-center">
          <auth-bar></auth-bar>
        </div>
      </template>
    </div>
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex'
import AuthBar from '@/components/AuthBar'
export default {
  components: { AuthBar },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
}
</script>
