<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-subtitle>
          <v-list-item class="px-0">
            <v-list-item-avatar size="48">
              <router-link
                :to="{
                  name: 'userIndex',
                  params: { id: receiver.user.id },
                }"
              >
                <v-avatar>
                  <v-img
                    class="elevation-6"
                    :src="receiver.user.avatar"
                  ></v-img>
                </v-avatar>
              </router-link>
            </v-list-item-avatar>
            <v-list-item-content style="overflow: visible;">
              <div class="d-flex">
                <v-img
                  :src="receiver.user.country_flag"
                  max-height="24"
                  max-width="24"
                  contain
                  class="align-self-center mr-1"
                ></v-img>
                <user-name
                  :name="receiver.user.name"
                  :type="receiver.user.type"
                  :id="receiver.user.id"
                  :organization="receiver.user.organization"
                ></user-name>
              </div>
              <div class="d-flex">
                <v-chip x-small color="warning">{{
                  parseFloat(receiver.user.counts.rate).toFixed(1)
                }}</v-chip>
                <v-rating
                  background-color="orange lighten-3"
                  color="orange"
                  :size="16"
                  disabled
                  dense
                  :value="parseFloat(receiver.user.counts.rate)"
                ></v-rating>
                <span class="grey--text"
                  >({{ receiver.user.counts.rate_count }} reviews)</span
                >
              </div>
            </v-list-item-content>
            <div class="align-center justify-end hidden-xs-only">
              <v-chip class="ma-2" color="deep-orange" text-color="white">
                {{ receiver.status | Up }}
              </v-chip>
            </div>
            <!--div
              class="align-center justify-end"
              v-if="
                $vuetify.breakpoint.width > 720 &&
                ($vuetify.breakpoint.width < 1264 ||
                  $vuetify.breakpoint.width > 1499)
              "
            >
              <v-btn
                depressed
                v-if="
                  (isProjectPublisher || isCreate) && receiver.user.resume_file
                "
                @click="$routerTo(receiver.user.resume_file)"
              >
                View Resume
              </v-btn>
              <v-chip class="ma-2" color="deep-orange" text-color="white">
                {{ receiver.status | Up }}
              </v-chip>
              <v-btn
                color="primary"
                v-if="
                  receiver.status === 'applied' &&
                  isProjectPublisher &&
                  project.status === 'applying'
                "
                @click="checkout(receiver.id, receiver.cost)"
                >Hire</v-btn
              >
              <v-btn
                v-if="isCreate && receiver.status === 'processing'"
                color="primary"
                @click="complete(receiver.id)"
                >Complete</v-btn
              >
              <v-btn
                v-if="isProjectPublisher && receiver.status === 'completed'"
                color="primary"
                @click="finished"
                >Finish</v-btn
              >
              <v-btn
                v-if="
                  isProjectPublisher &&
                  (receiver.status === 'finished' ||
                    receiver.status === 'processing')
                "
                class="ml-2"
                color="primary"
                @click="review"
                >Review</v-btn
              >
            </div-->
          </v-list-item>
        </v-card-subtitle>
        <v-card-text>
          <div class="align-center justify-end hidden-sm-and-up">
            <v-chip class="ma-2" color="deep-orange" text-color="white">
              {{ receiver.status | Up }}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-text
          v-if="
            isLoggedIn &&
            (receiver.user.id === userInfo.id || isProjectPublisher)
          "
        >
          <v-chip class="ma-2" color="orange" label outlined>
            <v-icon left>mdi-email</v-icon>
            {{ receiver.user.email }}
          </v-chip>
          <v-chip
            class="ma-2"
            color="orange"
            label
            outlined
            v-if="receiver.user.identityable.linkedin"
          >
            <v-icon left>mdi-linkedin</v-icon>
            <a :href="receiver.user.identityable.linkedin">{{
              receiver.user.identityable.linkedin
            }}</a>
          </v-chip>
          <v-chip
            class="ma-2"
            color="orange"
            label
            outlined
            v-if="receiver.user.counts.portfolio > 0"
          >
            <v-icon left>mdi-folder-image</v-icon>
            <router-link
              :to="{ name: 'userPortfolios', params: { id: receiver.user.id } }"
            >
              Portfolios
            </router-link>
          </v-chip>
          <div
            v-for="(q, index) in receiver.answer"
            :key="`receiver-answer-${index}`"
          >
            <v-card-text>Q{{ ++index }}.{{ q.question }}</v-card-text>
            <v-card-text>{{ q.content }}</v-card-text>
          </div>
          <show-cost
            :project="project"
            :receiver="receiver"
            v-if="isProjectPublisher || isCreate"
          ></show-cost>
        </v-card-text>
        <template v-if="isProjectPublisher || isCreate">
          <v-divider></v-divider>
          <v-card-actions>
            <strong
              class="orange--text tw-text-lg"
              v-if="project.type !== 'academic-team'"
              >${{ parseInt(receiver.cost ? receiver.cost : 0) }}</strong
            >
            <v-spacer></v-spacer>
            <v-btn
              depressed
              v-if="(isProjectPublisher || isCreate) && receiver.file"
              :href="receiver.file"
              class="align-self-center"
              target="_blank"
            >
              View Proposal
            </v-btn>
            <v-btn
              depressed
              v-if="
                (isProjectPublisher || isCreate) && receiver.user.resume_file
              "
              :href="receiver.user.resume_file"
              class="align-self-center"
              target="_blank"
            >
              View Resume
            </v-btn>
            <v-btn
              v-if="
                isProjectPublisher &&
                receiver.status === 'finished' &&
                receiver.has_send_recommend
              "
              depressed
              @click="$routerTo(receiver.has_send_recommend)"
              >View reference letter</v-btn
            >
            <v-btn
              color="primary"
              depressed
              v-if="
                receiver.status === 'applied' &&
                isProjectPublisher &&
                project.status === 'applying'
              "
              @click="checkout(receiver.id, receiver.cost)"
              >Hire</v-btn
            >
            <v-btn
              color="primary"
              depressed
              v-if="
                isCreate &&
                project.type === 'academic-team' &&
                receiver.status === 'processing'
              "
              :to="{
                name: 'TeamLeadPayment',
                params: { receive_id: receiver.id },
              }"
              >Access Student Database</v-btn
            >
            <v-btn
              v-if="isCreate && receiver.status === 'processing'"
              color="primary"
              depressed
              @click="complete(receiver.id)"
              >Complete</v-btn
            >
            <v-btn
              v-if="
                isProjectPublisher &&
                (receiver.status === 'completed' ||
                  receiver.status === 'processing')
              "
              color="primary"
              depressed
              @click="finished"
              >Finish</v-btn
            >
            <v-btn
              v-if="
                isProjectPublisher &&
                (receiver.status === 'finished' ||
                  receiver.status === 'processing')
              "
              depressed
              color="primary"
              @click="review"
              >Review</v-btn
            >
            <v-btn
              v-if="
                isProjectPublisher &&
                receiver.status === 'finished' &&
                !receiver.has_send_recommend
              "
              depressed
              color="primary"
              @click="
                $router.push({
                  name: 'receiveRecommendation',
                  params: { id: project.id, receive_id: receiver.id },
                })
              "
              >Send reference letter</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import ShowCost from '@/components/Receive/ShowCost'
export default {
  props: ['receiver', 'project'],
  components: {
    ShowCost,
  },
  computed: {
    ...mapGetters(['userInfo', 'isLoggedIn']),
    isCreate() {
      return (
        this.isLoggedIn &&
        (this.receiver.user.id === this.userInfo.id || this.userInfo.id <= 2)
      )
    },
    isProjectPublisher() {
      return (
        this.isLoggedIn &&
        (this.project.user.id === this.userInfo.id || this.userInfo.id <= 2)
      )
    },
  },
  methods: {
    review() {
      this.$emit('review')
    },
    /**
     * 选择用户
     */
    checkout() {
      this.$emit('checkout')
    },
    complete() {
      this.$emit('complete')
    },
    finished() {
      this.$emit('finished')
    },
    locationTo(href) {
      window.location.href = href
    },
  },
}
</script>
