<template>
  <v-list-item>
    <v-list-item-avatar>
      <router-link
        :to="{ name: 'userShow', params: { id: item.data.user_id } }"
      >
        <v-avatar>
          <v-img :src="item.data.user_avatar"></v-img>
        </v-avatar>
      </router-link>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <span class="grey--text mr-2">{{ item.data.user_name }}</span>
        <span class="mr-2">{{ item.data.status | Up }} for</span>
        <router-link
          :to="{ name: 'projectShow', params: { id: item.data.project_id } }"
          >{{ item.data.project_title }}</router-link
        >
      </v-list-item-title>
      <small class="ml-auto grey--text">
        {{ item.created_at | diffForHumans }}
      </small>
      <v-list-item-subtitle>
        <v-chip v-if="item.data.receive_cost > 0" label outlined
          >Cost: ${{ item.data.receive_cost }}</v-chip
        >
        <v-card
          outlined
          v-for="(answer, i) in item.data.answer"
          :key="`project-${item.data.project_id}-question-${i}`"
          :class="{ 'mt-3': i > 0 || item.data.receive_cost > 0 }"
          class="pb-3"
        >
          <v-card-title class="body-1">{{ answer.question }}</v-card-title>
          <v-card-text>{{ answer.content }}</v-card-text>
        </v-card>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: {
    item: {
      data: {},
    },
  },
}
</script>
