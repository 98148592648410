<template>
  <v-app>
    <v-app-bar app v-if="isLoggedIn && !emailVerify" flat dense>
      <!--v-app-bar-nav-icon></v-app-bar-nav-icon-->
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed @click="logout">Logout</v-btn>
    </v-app-bar>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app> </v-footer>
    <Message />
  </v-app>
</template>
<script>
import Message from '@/components/Message/Index'
import { mapGetters } from 'vuex'
export default {
  components: { Message },
  computed: {
    ...mapGetters(['isLoggedIn', 'emailVerify']),
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.go(-1)
    },
  },
}
</script>
