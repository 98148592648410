export default {
  path: '/promotions',
  component: () => import('@/views/layouts/root'),
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      name: 'promotionIndex',
      component: () => import(/* webpackChunkName: 'promotions' */'@/views/promotions/index'),
    },
  ],
}
