<template>
  <v-row justify="center">
    <v-col cols="12" md="9" lg="8">
      <div class="d-flex justify-center">
        <div
          class="mx-2 mx-md-10 elevation-1 pa-3 rounded d-flex align-self-center"
          v-for="link in friendLinks"
          :key="`friend-link-${link.id}`"
        >
          <img :src="link.image" class="link-image" alt="" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    friendLinks: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
<style scoped>
.link-image {
  height: 80px;
  max-height: 80px;
}
</style>
