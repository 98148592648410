import axios from '@/plugins/axios'

export const getCategories = () => {
  return axios.get('/user/categories')
}

export const fetchInfo = (id) => {
  return axios.get(`/user/${id}`)
}

export const fetchReceives = (params) => {
  return axios.get('receives', {
    params,
  })
}

export const fetchReviews = (params) => {
  return axios.get('reviews', {
    params,
  })
}

export const fetchFollowings = (id, params) => {
  return axios.get(`followings/${id}`, {
    params,
  })
}

export const fetchFollowers = (id, params) => {
  return axios.get(`followers/${id}`, {
    params,
  })
}

export const inviteFriend = (email) => {
  return axios.post(`invite_friend/`, {
    email,
  })
}
