<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" md="10" lg="8">
      <v-toolbar flat>
        <v-toolbar-title>Links Manage</v-toolbar-title>
        <v-select
          label="Select Week"
          :items="[
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
          ]"
          v-model="week"
          outlined
          dense
          hide-details
          single-line
          style="max-width: 180px;"
          prefix="Week"
          class="ml-4"
          @change="loadWeekData"
        >
        </v-select>
        <v-spacer> </v-spacer>
        <v-btn icon @click="loadWeekData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon class="ml-2" @click="showCreateDialog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-2"
              @click="uploadFile"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>Upload By txt</span>
        </v-tooltip>
        <v-btn icon class="ml-2" @click="showEmailData">
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn icon class="ml-2" @click="showLinkedInData">
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <input
          type="file"
          style="width: 0; height: 0;"
          ref="uploadInput"
          accept="text"
          @change="readFile"
        />
      </v-toolbar>
      <v-card>
        <template v-if="loadWeekLoading">
          <v-skeleton-loader type="list-item-two-line" class="mx-auto">
          </v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line" class="mx-auto">
          </v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line" class="mx-auto">
          </v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line" class="mx-auto">
          </v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line" class="mx-auto">
          </v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line" class="mx-auto">
          </v-skeleton-loader>
        </template>

        <template v-else-if="listData.length > 0">
          <v-toolbar flat>
            <v-toolbar-title>Week {{ week }} links</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on">
                  <v-icon>mdi-sort</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeSort('view_counts')">
                  <v-list-item-title>View counts</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeSort('created')">
                  <v-list-item-title>Created date</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-slide-y-transition class="py-0" group tag="v-list">
            <v-list-item
              v-for="(item, index) in listData"
              :key="`week-show-list-${item.id}`"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.link }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <span class="grey--text text-caption">
                    <v-icon color="grey" small>mdi-eye</v-icon>({{
                      item.view_counts
                    }})
                  </span>
                  <v-btn icon @click="edit(index)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="deleteData(index)"
                    :loading="item.deleteLoading"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    :href="`/links/show/${item.slug}`"
                    target="_blank"
                  >
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-slide-y-transition>
        </template>
        <template v-else-if="listData.length <= 0">
          <v-alert>
            No more data
          </v-alert>
        </template>
      </v-card>
    </v-col>
    <v-dialog v-model="createDialog" max-width="600">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title
            >{{ activeData.id ? 'Edit' : 'Add' }} link</v-toolbar-title
          >
          <v-spacer> </v-spacer>
          <v-btn icon @click="createDialog = !createDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field label="Name" v-model="activeData.name"></v-text-field>
            <v-text-field label="Link" v-model="activeData.link"></v-text-field>
            <v-card-actions>
              <v-btn
                depressed
                color="orange"
                class="white--text"
                v-if="activeData.id > 0"
                @click="update"
                :loading="updateLoading"
                >Save</v-btn
              >
              <v-btn
                depressed
                color="orange"
                class="white--text"
                v-else
                @click="save"
                :loading="saveLoading"
                >Submit</v-btn
              >
              <v-btn depressed text @click="createDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadDialog" fullscreen persistent overlay-opacity="3">
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>Edit</v-toolbar-title>
              <v-spacer> </v-spacer>
              <v-btn icon @click="uploadDialog = !uploadDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <template v-for="(text, index) in textArray">
                  <v-list :key="`upload-${index}`">
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          label="Name"
                          v-model="text.name"
                          :key="`text-name-${index}`"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                        label="Link"
                        v-model="text.link"
                        :key="`text-link-${index}`"
                      ></v-text-field>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </template>
                <v-card-actions>
                  <v-btn depressed @click="saveMany" :loading="saveManyLoading"
                    >Submit</v-btn
                  >
                  <v-btn text>Reset</v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="emailDialog" max-width="650">
      <v-card>
        <v-card-text>
          <v-textarea v-model="emailData" rows="12"> </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="linkedInDialog" max-width="650">
      <v-card>
        <v-card-text>
          <v-textarea v-model="linkedInData" rows="12"> </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="-1"
      :value="showSnackbar"
      absolute
      centered
      color="deep-orange accent-4"
      elevation="24"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import { trim } from 'lodash'
import { create, update, fetch, destroy } from '@/api/link'

export default {
  data: () => ({
    createDialog: false,
    uploadDialog: false,
    textArray: [],
    week: 0,
    listData: [],
    loadWeekLoading: false,
    showSnackbar: false,
    message: null,
    saveManyLoading: false,
    emailDialog: false,
    emailData: '',
    activeData: {
      name: undefined,
      link: undefined,
    },
    updateLoading: false,
    updateIndex: null,
    saveLoading: false,
    linkedInDialog: false,
    linkedInData: '',
    sort: 'view_counts',
  }),
  methods: {
    changeSort(type) {
      this.sort = type
      this.loadWeekData()
    },
    async loadWeekData() {
      try {
        this.loadWeekLoading = true
        const paramsQuery = {
          week: this.week,
        }
        if (this.sort) {
          paramsQuery.sort = this.sort
        }
        const { data } = await fetch(paramsQuery)
        this.listData = data
        this.loadWeekLoading = false
      } catch (e) {
        this.loadWeekLoading = false
        console.log(e)
      }
    },
    showCreateDialog() {
      if (this.week <= 0) {
        this.showWeekError()
        return
      }
      this.activeData = {
        name: undefined,
        link: undefined,
      }
      this.createDialog = true
    },
    uploadFile() {
      if (this.week <= 0) {
        this.showWeekError()
        return
      }
      this.$refs.uploadInput.click()
    },
    readFile(e) {
      console.log(e)
      const reader = new FileReader()
      reader.onload = (evt) => {
        const text = evt.target.result.split(/(\r|\n|\r\n)/)
        const textArray = []
        text.map((item) => {
          const txt = item.replace(/(\r|\n|\r\n)/, '')
          return txt
            ? textArray.push(txt.split('|').map((item) => trim(item)))
            : null
        })
        this.textArray = this.checkTextArray(textArray)
        this.uploadDialog = true
      }
      reader.readAsText(e.target.files[0])
    },
    // 校对文本数据
    checkTextArray(array) {
      const newArray = []
      array.forEach((item, index) => {
        if (
          item.length === 1 &&
          array[index - 1] &&
          array[index - 1][1] === ''
        ) {
          array[index - 1][1] = item[0]
          array[index] = []
          newArray.push({
            name: array[index - 1][0],
            link: item[0],
          })
        } else if (item.length > 1) {
          newArray.push({
            name: item[0],
            link: item[1],
          })
        }
      })
      return newArray.filter((item) => !!item.link)
    },
    async saveMany() {
      this.saveManyLoading = true
      try {
        await create({ data: this.textArray, week: this.week })
        this.uploadDialog = false
        this.saveManyLoading = false
        this.loadWeekData()
      } catch (e) {
        this.saveManyLoading = false
        console.log(e)
      }
    },
    showEmailData() {
      if (this.listData.length <= 0 && this.week <= 0) {
        this.showWeekError()
      } else if (this.listData.length <= 0) {
        this.message = `Week ${this.week} links is empty, please upload or add!`
        this.showSnackbar = true
      } else {
        this.emailDialog = true
        this.listData.forEach((item) => {
          this.emailData += `<a href="https://www.beeznests.com/links/show/${item.slug}">${item.name}</a><br />\r\n`
        })
      }
    },
    showLinkedInData() {
      if (this.listData.length <= 0 && this.week <= 0) {
        this.showWeekError()
      } else if (this.listData.length <= 0) {
        this.message = `Week ${this.week} links is empty, please upload or add!`
        this.showSnackbar = true
      } else {
        this.linkedInDialog = true
        this.listData.forEach((item) => {
          this.linkedInData += `${item.name} | https://www.beeznests.com/links/show/${item.slug}\r\n`
        })
      }
    },
    edit(index) {
      this.activeData = Object.assign({}, this.listData[index])
      this.createDialog = true
      this.updateIndex = index
    },
    showWeekError() {
      this.message = 'Please select week!'
      this.showSnackbar = true
    },
    async update() {
      this.updateLoading = true
      try {
        const { data } = await update(this.activeData.id, {
          name: this.activeData.name,
          link: this.activeData.link,
        })
        if (data.id) {
          this.$set(this.listData, this.updateIndex, data)
          this.updateLoading = false
          this.createDialog = false
        }
      } catch (e) {
        this.updateLoading = false
      }
    },
    async save() {
      this.saveLoading = true
      try {
        const { data } = await create({
          name: this.activeData.name,
          link: this.activeData.link,
          week: this.week,
        })
        if (data.id) {
          this.listData.push(data)
          this.saveLoading = false
          this.createDialog = false
        }
      } catch (e) {
        this.saveLoading = false
      }
    },
    async deleteData(index) {
      this.$set(this.listData[index], 'deleteLoading', true)
      try {
        await destroy(this.listData[index].id)
        this.$set(this.listData[index], 'deleteLoading', false)
        this.listData.splice(index, 1)
      } catch (e) {
        this.$set(this.listData[index], 'deleteLoading', false)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.flip-list-move {
  transition: transform 1s;
}
</style>
