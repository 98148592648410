// import store from '@/store'
export default {
  path: '/users',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '',
      name: 'users',
      component: () => import(/* webpackChunkName: 'users' */'@/views/users/index'),
    },
    {
      path: ':id',
      name: 'userShow',
      component: () => import(/* webpackChunkName: 'users' */'@/views/users/show'),
      redirect: { name: 'NewProfile' },
      children: [
        {
          path: 'index',
          name: 'userIndex',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/index'),
        },
        {
          path: 'portfolios',
          name: 'userPortfolios',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/portfolios'),
        },
        {
          path: 'projects',
          name: 'userProjects',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/project'),
        },
        {
          path: 'challenges',
          name: 'userChallenges',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/challenge'),
        },
        {
          path: 'replies',
          name: 'userReplies',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/reply'),
        },
        {
          path: 'receives',
          name: 'userReceives',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/receive'),
        },
        {
          path: 'reviews',
          name: 'userReviews',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/review'),
        },
        {
          path: 'followings',
          name: 'userFollowings',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/following'),
        },
        {
          path: 'followers',
          name: 'userFollowers',
          component: () => import(/* webpackChunkName: 'users' */'@/views/users/follower'),
        },
      ],
    },
  ],
}
