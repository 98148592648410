const getters = {
  isLoggedIn: (state) => state.auth.loggedIn,
  authRedirectTo: (state) => state.auth.redirectTo,
  authBackTo: (state) => state.auth.backTo,
  loginLoading: (state) => state.auth.loginLoading,
  userInfo: (state) => state.auth.userInfo,
  token: (state) => state.auth.token,
  expiresIn: (state) => state.auth.expires_in,
  emailVerify: (state) => state.auth.emailVerify,
  viewUser: (state) => state.user.viewUser,
  isAdmin: (state) => state.auth.userInfo.is_admin,
  completeProfile: (state) => {
    if (state.auth.loggedIn && state.auth.userInfo.type !== 'company') {
      return !!(
        state.auth.userInfo.name &&
        state.auth.userInfo.introduction &&
        state.auth.userInfo.resume_file &&
        state.auth.userInfo.categories.length > 0
      )
    } else {
      return true
    }
  },
  isMobile: (state) => state.device.isMobile,
  drawer: (state) => state.device.drawer,
}
export default getters
