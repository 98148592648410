import axios from '@/plugins/axios'

export const saveTrainingHour = (id, hour) => {
  return axios.post(`/receive/${id}/save_training_hour`, {
    training_hour: hour,
  })
}

export const fetchReceiveData = (id) => {
  return axios.get(`receive/${id}/?with=project`)
}

export const createOrder = (receive_id) => {
  return axios.post(`order/receive`, {
    receive_id,
  })
}

export const fetchPaidStudents = (id, params) => {
  return axios.get(`receive/${id}/students`, { params })
}
