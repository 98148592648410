<template>
  <v-col cols="12" md="8" lg="6">
    <skills class="mb-5"></skills>
    <v-card flat outlined class="mt-5">
      <v-toolbar flat>
        <v-toolbar-title>Info</v-toolbar-title>
      </v-toolbar>
      <v-alert
        icon="mdi-alert-outline"
        prominent
        text
        type="warning"
        color="yellow darken-3"
        class="mt-5"
      >
        All volunteer/professional experience should follow the format of:
        [Organization Name - Position Title - Dates Applicable] Brief
        explanation of your responsibilities
      </v-alert>
      <v-card-text>
        <field-row
          label="Linkedin"
          :value="userInfo.identityable.linkedin"
          @update="showEdit('identityable.linkedin')"
          :divider="false"
        ></field-row>
        <field-row
          label="Professional"
          :value="userInfo.identityable.professional_experience"
          @update="showEdit('identityable.professional_experience')"
        ></field-row>
        <field-row
          label="Volunteer"
          :value="userInfo.identityable.volunteer_experience"
          @update="showEdit('identityable.volunteer_experience')"
        ></field-row>
      </v-card-text>
    </v-card>
    <resume class="mt-5"></resume>
  </v-col>
</template>
<script>
import FieldRow from '@/components/Settings/FieldRow'
import Skills from '@/components/Settings/Skills'
import Resume from '@/components/Settings/Resume'
import { mapGetters } from 'vuex'
export default {
  components: {
    Skills,
    FieldRow,
    Resume,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {},
  methods: {
    showEdit(field) {
      this.$store.commit('setting/SET', field)
    },
  },
}
</script>
