<template>
  <v-row justify="center">
    <v-col md="4" lg="6">
      <v-card class="pa-4" v-if="paidSuccess">
        <v-toolbar-title class="text-center"
          >Deposit Successful</v-toolbar-title
        >
        <v-card-text class="d-flex justify-center flex-column">
          <v-icon size="36" color="green">mdi-check</v-icon>
          <div class="text-center">
            <v-btn depressed @click="$router.back()">Back</v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="pa-4" v-if="!paidSuccess">
        <v-card-text class="mt-2">
          <template v-for="(type, i) in Object.keys(items)">
            <!--            <v-toolbar-title-->
            <!--              class="tw-mb-4"-->
            <!--              :key="`member-${type}`"-->
            <!--              v-if="type === 'membership'"-->
            <!--              >Pick a Membership</v-toolbar-title-->
            <!--            >-->
            <v-toolbar-title
              class="tw-mb-4 tw-mt-6"
              :key="`member-${type}`"
              v-if="type === 'sponsorship'"
              >Pick a Membership</v-toolbar-title
            >
            <div
              class="tw-flex tw-flex-wrap phone:tw-justify-center"
              :key="`items-${i}`"
            >
              <template v-for="(item, index) in items[type]">
                <div
                  v-if="
                    (isLoggedIn && userInfo.is_admin && item.name === 'TEST') ||
                    item.name !== 'TEST'
                  "
                  :key="`membership-${index}`"
                  @click="check(type, index)"
                  class="signup-plan tw-relative tw-bg-white tw-rounded-lg md:tw-mr-4 tw-mb-4 tw-flex md:tw-flex-col tw-items-center tw-justify-around md:tw-justify-center"
                  :class="item.checked ? 'tw-border-blue' : ''"
                >
                  <div
                    class="check-wrap tw-absolute tw-bg-blue"
                    :class="item.checked ? 'tw-block' : 'tw-hidden'"
                    style="top: 10px; right: 10px;"
                  ></div>
                  <h4
                    class="tw-font-semibold tw-text-grey tw-leading-normal tw-text-2xl tw-inline-flex tw-items-center tw-tracking-normal tw-line-through md:tw--mb-6"
                  >
                    <span class="" style="padding-right: 2px;">$</span>
                    {{ item.oldPrice }}
                  </h4>
                  <h4
                    class="tw-font-semibold tw-mt-3 tw-text-blue tw-leading-normal tw-text-3xl tw-inline-flex tw-items-center md:tw-mb-4 tw-tracking-normal"
                  >
                    <span class="tw-text-lg" style="padding-right: 2px;"
                      >$</span
                    >
                    {{ item.price }}
                  </h4>
                  <div class="tw-w-1/3 md:tw-w-auto">
                    <h3
                      class="tw-text-xs tw-font-bold tw-uppercase tw-text-black tw-tracking-normal tw-mb-auto"
                    >
                      {{ item.name }}
                    </h3>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <div class="tw-max-w-2xl">
            <v-text-field
              v-if="!isLoggedIn"
              v-model="orderEmail"
              placeholder="Your Email"
              :error="emailError"
              :messages="
                emailError
                  ? 'Email address is required'
                  : 'This email will be the only voucher for your payment. Please fill in it carefully!'
              "
            ></v-text-field>
          </div>
          <div id="card-element" class="mt-4"></div>
          <v-alert v-if="cardError" class="mt-2 red--text">
            {{ cardError }}
          </v-alert>
        </v-card-text>

        <v-card-actions class="mt-6">
          <v-btn
            depressed
            color="deep-orange"
            class="white--text"
            @click="pay"
            :loading="paidLoading"
            >Pay</v-btn
          >
          <v-btn depressed @click="$router.go(-1)">back</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { loadStripe } from '@stripe/stripe-js'
import { paymentSingle } from '@/api/pay'
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  data: () => ({
    stripe: null,
    cardElement: null,
    cardError: null,
    checkOrderLoading: false,
    orderException: false,
    payInfo: {},
    paidSuccess: false,
    paidLoading: false,
    orderEmail: null,
    emailError: false,
    items: {
      membership: [
        // {
        //   price: 10,
        //   oldPrice: 30,
        //   name: 'TEST',
        //   checked: false,
        // },
        // {
        //   price: 150,
        //   oldPrice: 300,
        //   name: 'PREMIUM',
        //   checked: false,
        // },
        // {
        //   price: 325,
        //   oldPrice: 500,
        //   name: 'ENTERPRISE',
        //   checked: false,
        // },
      ],
      // sponsorship: [
      //   {
      //     price: 1000,
      //     oldPrice: 1500,
      //     name: 'BRONZE',
      //     checked: false,
      //   },
      //   {
      //     price: 1500,
      //     oldPrice: 2200,
      //     name: 'SILVER',
      //     checked: false,
      //   },
      //   {
      //     price: 2500,
      //     oldPrice: 3300,
      //     name: 'PLATINUM',
      //     checked: false,
      //   },
      // ],
      sponsorship: [
        {
          price: 1500,
          oldPrice: 2500,
          name: 'Basic',
          checked: false,
        },
        {
          price: 2500,
          oldPrice: 5000,
          name: 'Premium',
          checked: false,
        },
        {
          price: 6000,
          oldPrice: 9000,
          name: 'Enterprise',
          checked: false,
        },
      ],
    },
    checked: null,
  }),
  computed: {
    no() {
      return this.$route.params.no || 0
    },
    ...mapGetters(['userInfo', 'isLoggedIn']),
  },
  mounted() {
    const amount = this.$route.params.amount || 0
    if (amount > 0) {
      Object.keys(this.items).forEach((t) => {
        this.items[t].forEach((i, key) => {
          if (i.price === parseInt(amount)) {
            this.$set(this.items[t][key], 'checked', true)
          }
        })
      })
    }
  },
  async created() {
    try {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
      const elements = this.stripe.elements()
      const cardElement = elements.create('card', { style })

      cardElement.mount('#card-element')
      var _self = this
      cardElement.addEventListener('change', (event) => {
        if (event.error) {
          _self.cardError = event.error.message
        } else {
          _self.cardError = ''
        }
      })
      this.cardElement = cardElement
    } catch (e) {
      this.orderException = true
    }
  },
  methods: {
    check(type, index) {
      if (this.items[type][index].checked) {
        this.$set(this.items[type][index], 'checked', false)
      } else {
        Object.keys(this.items).forEach((t) => {
          this.items[t].forEach((i, key) => {
            this.$set(this.items[t][key], 'checked', false)
          })
        })
        this.$set(this.items[type][index], 'checked', true)
      }
    },
    getPaymentInfo() {
      const info = {}
      Object.keys(this.items).forEach((t) => {
        this.items[t].forEach((i, key) => {
          if (i.checked) {
            info.type = t
            info.name = i.name
            info.amount = i.price
          }
        })
      })
      return info
    },
    async pay() {
      this.paidLoading = true
      this.emailError = false
      const info =
        this.items.membership.some((v) => v.checked) ||
        this.items.sponsorship.some((v) => v.checked)
      if (!info) {
        this.cardError = 'Please select payment amount'
        this.paidLoading = false
        return
      }

      let email = this.isLoggedIn ? this.userInfo.email : this.orderEmail
      if (!email) {
        // this.cardError = 'Email address is required'
        this.emailError = true
        this.paidLoading = false
        return
      }
      const payInfo = this.getPaymentInfo()
      console.log(payInfo)
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
        'card',
        this.cardElement,
        {
          billing_details: {
            email,
          },
          metadata: {
            type: payInfo.type,
            name: payInfo.name,
          },
        }
      )

      if (error) {
        this.cardError = error.message
        this.paidLoading = false
        // 错误信息
      } else {
        // 判断是否选择了支付信息

        // 向服务端传送 token 进行支付。
        try {
          const data = await paymentSingle(paymentMethod.id, {
            email,
            ...payInfo,
          })
          this.paidLoading = false
          if (data.error <= 0) {
            this.paidSuccess = true
          } else {
            this.cardError = data.message
          }
        } catch (e) {
          this.paidLoading = false
          if (e.response && e.response.data && e.response.data.message) {
            this.cardError = e.response.data.message
          }
        }
      }
    },
  },
}
</script>
<style lang="css">
.StripeElement {
  box-sizing: border-box;
  height: 3.0769rem;
  padding: 0.7692rem 0.9231rem;
  border: 0.0769rem solid transparent;
  border-radius: 0.3077rem;
  background-color: white;
  box-shadow: 0 0.0769rem 0.2308rem 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 0.0769rem 0.2308rem 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
<style lang="scss" scoped>
.tw-text-blue {
  --text-opacity: 1;
  color: #328af1;
  color: rgba(50, 138, 241, var(--text-opacity));
}
.signup-plan {
  width: 152px;
  height: 168px;
  border: 1px solid rgba(36, 37, 38, 0.08);
  cursor: pointer;
  position: relative;
  &.tw-border-blue {
    border: 2px solid #328af1;
  }
}
.tw-bg-blue {
  --bg-opacity: 1;
  background-color: #328af1;
  background-color: rgba(50, 138, 241, var(--bg-opacity));
}
.check-wrap {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  -webkit-animation: wrap 0.3s ease-in-out forwards;
  animation: wrap 0.3s ease-in-out forwards;
  transform: scale(0);
}
.check-wrap:after,
.check-wrap:before {
  content: '';
  position: absolute;
  background-color: #fff;
  width: 0;
  height: 3px;
  transform-origin: left;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.check-wrap:before {
  top: 10px;
  left: 6px;
  transform: rotate(45deg);
  -webkit-animation-name: left;
  animation-name: left;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.check-wrap:after {
  top: 15px;
  left: 9px;
  transform: rotate(-45deg);
  -webkit-animation-name: right;
  animation-name: right;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes left {
  0% {
    width: 0;
  }
  to {
    width: 5px;
  }
}
@keyframes left {
  0% {
    width: 0;
  }
  to {
    width: 5px;
  }
}
@-webkit-keyframes right {
  0% {
    width: 0;
  }
  to {
    width: 12px;
  }
}
@keyframes right {
  0% {
    width: 0;
  }
  to {
    width: 12px;
  }
}
@-webkit-keyframes buttonLeft {
  0% {
    width: 0;
  }
  to {
    width: 13px;
  }
}
@keyframes buttonLeft {
  0% {
    width: 0;
  }
  to {
    width: 13px;
  }
}
@-webkit-keyframes buttonRight {
  0% {
    width: 0;
  }
  to {
    width: 30px;
  }
}
@keyframes buttonRight {
  0% {
    width: 0;
  }
  to {
    width: 30px;
  }
}
</style>
