<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8">
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="pa-2">
            <v-list nav dense>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :to="item.route"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        icon: 'mdi-bell-ring-outline',
        text: 'New Notifications',
        route: {
          name: 'notificationUnread',
        },
      },
      {
        icon: 'mdi-bell',
        text: 'All Notifications',
        route: {
          name: 'notificationIndex',
        },
      },
      // {
      //   icon: 'mdi-alert-circle-outline',
      //   text: 'System Notifications',
      //   route: {
      //     name: 'notificationSystem',
      //   },
      // },
    ],
  }),
  methods: {},
}
</script>
