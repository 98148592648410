<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8">
      <v-card outlined>
        <v-toolbar flat>
          <v-toolbar-title>Review</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <span class="pl-2 grey--text text--darken-2 font-weight-light"
                >Communication</span
              >
              <v-rating
                v-model="communication"
                color="yellow darken-3"
                background-color="grey darken-1"
                hover
                size="36"
                label="Custom length"
              ></v-rating>
            </v-col>
            <v-col cols="auto">
              <span class="pl-2 grey--text text--darken-2 font-weight-light"
                >Teamwork</span
              >
              <v-rating
                v-model="teamwork"
                color="yellow darken-3"
                background-color="grey darken-1"
                hover
                size="36"
                label="Custom length"
              ></v-rating>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span class="pl-2 grey--text text--darken-2 font-weight-light"
                >Punctuality</span
              >
              <v-rating
                v-model="punctuality"
                color="yellow darken-3"
                background-color="grey darken-1"
                hover
                size="36"
                label="Custom length"
              ></v-rating>
            </v-col>
            <v-col cols="auto">
              <span class="pl-2 grey--text text--darken-2 font-weight-light"
                >Problem-solving</span
              >
              <v-rating
                v-model="problem_solving"
                color="yellow darken-3"
                background-color="grey darken-1"
                hover
                size="36"
                label="Custom length"
              ></v-rating>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <span class="pl-2 grey--text text--darken-2 font-weight-light">
                Creativity
              </span>
              <v-rating
                v-model="creativity"
                color="yellow darken-3"
                background-color="grey darken-1"
                hover
                size="36"
                label="Custom length"
              ></v-rating>
            </v-col>
          </v-row>

          <v-row>
            <v-col clos="12" md="6" class="pb-0">
              <v-textarea
                label="Additional Comments"
                :rules="rules"
                v-model="value"
                rows="3"
                outlined
                placeholder="Please feel free to add any comments you wish to send to the student, the more personalised feedback the better chance the student will know how to improve your project with the time that is left!"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            class="ml-3"
            @click="submit"
            :loading="loading"
            >Submit</v-btn
          >
          <v-btn depressed class="ml-3" @click="$router.go(-1)">Back</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { review } from '@/api/project'
export default {
  computed: {
    id() {
      return this.$route.params.id || 0
    },
  },
  data: () => ({
    rating: 1,
    rules: [() => true],
    value: '',
    loading: false,
    creativity: 5,
    communication: 5,
    teamwork: 5,
    punctuality: 5,
    problem_solving: 5,
  }),
  methods: {
    async submit() {
      this.loading = true
      try {
        const postData = {
          reviewable_type: 'App\\Receive',
          reviewable_id: this.id,
          content: this.value,
          extra: [],
          //rate: this.rating,
          communication: this.communication,
          teamwork: this.teamwork,
          punctuality: this.punctuality,
          problem_solving: this.problem_solving,
          creativity: this.creativity,
        }
        const { message } = await review(postData)
        this.$message(message)
        this.$router.go(-1)
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$router.go(-1)
        }
        this.loading = false
      }
    },
  },
}
</script>
