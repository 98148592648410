<template>
  <v-row justify="center" class="py-13 mx-0">
    <v-col cols="12" md="9" lg="8">
      <v-row>
        <v-col cols="12" md="6" align-self="center">
          <h3 class="text-h6 text-md-h4 text-center">Discussion</h3>
          <div class="text--secondary mt-5">
            ‍🐝 Students - connect with start-ups by pitching your ideas and
            solutions.
          </div>
          <div class="text--secondary mt-2">
            🍯 Companies - Gain internationally diverse perspectives and
            solutions to your problems. Leader board coming soon!
          </div>
          <div class="mt-10 d-flex flex-wrap justify-center justify-md-start">
            <v-btn
              class="white--text mt-4 mx-2"
              color="orange"
              depressed
              :to="{ name: 'challengeCreate' }"
              >Post Discussion</v-btn
            >
            <v-btn
              depressed
              class="white--text mt-4 mx-2"
              color="orange"
              :to="{ name: 'challengeIndex' }"
              >View Discussions</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mx-n3">
          <v-img src="~@/assets/images/index_2.jpeg" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  computed: {
    isOneLine() {
      return this.$vuetify.breakpoint.width > 500
    },
  },
}
</script>
