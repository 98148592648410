import axios from '@/plugins/axios'

export const create = (data) => {
  return axios.post('jobs', data)
}

export const fetchIndex = (params) => {
  return axios.get('jobs', { params })
}

export const fetchData = (id, params) => {
  return axios.get(`jobs/${id}`, {
    params,
  })
}

export const apply = (id) => {
  return axios.post(`jobs/${id}/apply`)
}

export const fetchApplicants = (id, params) => {
  return axios.get(`jobs/${id}/applicants`, {
    params,
  })
}

export const deleteJobRequest = (id) => {
  return axios.delete(`jobs/${id}`)
}

export const editJob = (id, data) => {
  return axios.post(`jobs/${id}/update`, data)
}
