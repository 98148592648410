<template>
  <v-card :loading="loginLoading ? 'warning' : false">
    <v-toolbar color="deep-orange" dark flat>
      <v-toolbar-title>Register</v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="mr-1">Already have an account?</span>
      <router-link
        to="/auth/login"
        class="white--text"
        style="text-decoration: none;"
      >
        Log in here
      </router-link>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="valid">
        <v-text-field
          label="*Email"
          name="login"
          prepend-icon="mdi-email"
          type="text"
          persistent-hint
          v-model="email"
          :rules="emailRules"
        ></v-text-field>
        <v-text-field
          id="password"
          label="*Password"
          name="password"
          prepend-icon="mdi-lock"
          persistent-hint
          :rules="passwordRules"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          v-model="password"
          @click:append="show = !show"
        ></v-text-field>
        <v-text-field
          id="code"
          label="Partnership code"
          name="code"
          prepend-icon="mdi-card-account-details"
          persistent-hint
          :rules="codeRules"
          append-icon="mdi-help"
          type="text"
          v-model="code"
          @click:append="showCodeHelp = !showCodeHelp"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="pl-5 pb-3">
      <v-btn
        color="primary"
        @click="submitRegister"
        :disabled="!valid"
        depressed
        >Register</v-btn
      >
      <v-btn text :to="authBackTo">back</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-dialog v-model="showCodeHelp" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>What is code?</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          If you’re associated with one of our partnering universities, please
          copy the partnership code here. This will generate a badge that will
          be visible on your profile.
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
import ls from '@/plugins/ls'
export default {
  name: 'AuthRegister',
  computed: {
    ...mapGetters(['authBackTo', 'authRedirectTo', 'loginLoading']),
  },
  data: () => ({
    valid: false,
    show: false,

    emailRules: [
      (v) =>
        !!(v || '').match(emailReg) || 'Email must be a valid email address.',
    ],
    passwordRules: [
      (v) =>
        (!!(v || '') && v.length >= 8) ||
        'Password must be at least 8 characters.',
    ],
    email: null,
    password: null,
    showCodeHelp: false,
    code: null,
  }),
  methods: {
    async submitRegister() {
      const isRegistered = await this.$store.dispatch('auth/register', {
        email: this.email,
        password: this.password,
        code: this.code,
      })
      if (isRegistered) {
        this.$router.push(this.authRedirectTo)
      }
    },
  },
  created() {
    if (this.$route.query.code) {
      ls.set('invite_code', this.$route.query.code)
    }
  },
}
</script>
