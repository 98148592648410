var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{ref:"container"}),(_vm.loading || _vm.data.length <= 0)?_c('loading',{attrs:{"loading":_vm.loading,"no-data":"Nothing"}}):_c('transition-group',{attrs:{"name":"flip-list","tag":"div"}},[_vm._l((_vm.data),function(item){return [_c('v-sheet',{key:`portfolio-${item.id}-title`},[_c('div',{staticClass:"py-4 d-flex justify-space-between"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('v-btn',{attrs:{"depressed":"","text":"","to":{ name: 'portfolioShow', params: { id: item.id } }}},[_vm._v("Detail")])],1),(item.boxes.length > 0)?_c('div',{staticClass:"images",style:({ height: item.boxes[0].height + 'px' })},[_vm._l((item.boxes),function(image,index){return [(image.url !== '')?_c('div',{key:`p-${item.id}-image-preview-${index}`,style:({
                    position: 'absolute',
                    left: image.left + 'px',
                    top: image.top + 'px',
                    width: image.width + 'px',
                    height: image.height + 'px',
                  })},[_c('v-img',{attrs:{"src":item.attachments[index].url,"width":image.width,"height":image.height,"contain":""}})],1):_vm._e()]})],2):_vm._e()])]})],2)],1),(_vm.pages.last_page > 1)?_c('v-col',{attrs:{"cols":"12","xl":"8"}},[_c('v-pagination',{attrs:{"length":_vm.pages.last_page},model:{value:(_vm.pages.current_page),callback:function ($$v) {_vm.$set(_vm.pages, "current_page", $$v)},expression:"pages.current_page"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }