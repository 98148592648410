export default {
  path: '/team-lead',
  component: () => import('@/views/layouts/root'),
  // redirect: { name: 'TeamLeadPayment' },
  children: [
    {
      name: 'TeamLeadPayment',
      path: ':receive_id/payment',
      component: () => import(/* webpackChunkName: 'team-lead' */'@/views/projects/team-lead/payment'),
    },
  ],
}
