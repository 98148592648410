export default {
  path: '/blog',
  component: () => import(/* webpackChunkName: 'base' */ '@/views/layouts/root'),
  children: [
    {
      path: 'links',
      name: 'blogLinks',
      component: () => import(/* webpackChunkName: 'blog' */'@/views/blog/links'),
      meta: {
        auth: true,
      },
    },
    {
      path: '',
      name: 'blogIndex',
      component: () => import(/* webpackChunkName: 'blog' */'@/views/blog/root'),
      redirect: {
        name: 'articleShow',
        params: {
          id: 1,
        },
      },
      children: [
        {
          path: ':id',
          component: () => import(/* webpackChunkName: 'blog' */'@/views/blog/show'),
          name: 'articleShow',
        },
      ],
    },
  ],
}
