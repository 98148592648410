<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" lg="10" xl="8" class="mt-5">
      <v-row>
        <v-col cols="12" md="9">
          <v-card>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="article"
            ></v-skeleton-loader>
            <template v-else-if="data.id">
              <v-card-title>
                <h3 class="text-h4">
                  {{ data.title
                  }}<template
                    v-if="
                      data.type !== 'mentor-program' && data.type !== 'task'
                    "
                    >({{
                      data.type === 'internship'
                        ? 'Internship'
                        : 'Academic team'
                    }})</template
                  >
                </h3>
                <v-list-item class="px-0 overflow-hidden">
                  <v-list-item-avatar size="48">
                    <router-link
                      :to="{
                        name: 'userIndex',
                        params: { id: data.user.id },
                      }"
                      style="text-decoration: none;"
                    >
                      <v-avatar size="48">
                        <v-img
                          class="elevation-6"
                          :src="data.user.avatar"
                        ></v-img>
                      </v-avatar>
                    </router-link>
                  </v-list-item-avatar>

                  <v-list-item-content
                    class="flex-shrink-1"
                    style="overflow: hidden;"
                  >
                    <user-name
                      :name="data.user.name"
                      :type="data.user.type"
                      :id="data.user.id"
                      :organization="data.user.organization"
                    >
                      <template v-slot:before
                        ><v-img
                          :src="data.user.country_flag"
                          max-height="24"
                          max-width="24"
                          contain
                          class="align-self-center mr-1"
                        ></v-img
                      ></template>
                    </user-name>
                    <small class="body-2 mr-2 caption">{{
                      data.created_at | format
                    }}</small>
                  </v-list-item-content>
                  <div class="align-center pl-2">
                    <v-chip color="orange" class="white--text">{{
                      data | projectStatus
                    }}</v-chip>
                  </div>
                </v-list-item>
              </v-card-title>
              <template v-if="data.type === 'mentor-program'">
                <v-card-subtitle class="text-h6">
                  About the mentor (BIO)</v-card-subtitle
                >
                <v-card-text v-html="data.about_mentor"> </v-card-text>
              </template>
              <v-card-subtitle class="text-h6">
                <description-label
                  :is-project="data.type !== 'mentor-program'"
                  :type="data.type"
                  :provide-solution="data.provide_solution"
                ></description-label>
              </v-card-subtitle>
              <v-card-text v-html="$replaceEmpty(data.content)"> </v-card-text>
              <template
                v-if="data.who  && (data.type === 'academic-team' && !data.provide_solution)"
              >
                <v-card-subtitle class="text-h6">
                  <will-do
                    :is-project="data.type !== 'mentor-program'"
                    :type="data.type"
                    :provide-solution="data.provide_solution"
                  ></will-do>
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.who)"> </v-card-text>
              </template>
              <template v-else-if="data.who">
                <v-card-subtitle class="text-h6">
                  <will-do
                    :is-project="data.type !== 'mentor-program'"
                    :type="data.type"
                    :provide-solution="data.provide_solution"
                  ></will-do>
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.who)"> </v-card-text>
              </template>
              <template v-if="data.skills">
                <v-card-subtitle class="text-h6">
                  <skill
                    :is-project="data.type !== 'mentor-program'"
                    :type="data.type"
                    :provide-solution="data.provide_solution"
                  ></skill>
                </v-card-subtitle>
                <v-card-text v-html="data.skills"> </v-card-text>
              </template>
              <template v-if="data.work_with">
                <v-card-subtitle class="text-h6">
                  <work-with-label
                    :is-project="data.type !== 'mentor-program'"
                    :type="data.type"
                    :provide-solution="data.provide_solution"
                  ></work-with-label>
                </v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.work_with)">
                </v-card-text>
              </template>
              <template v-if="data.type === 'mentor-program' && data.agenda">
                <v-card-subtitle class="text-h6"
                  >Course Outline</v-card-subtitle
                >
                <v-card-text v-html="$replaceEmpty(data.agenda)"> </v-card-text>
              </template>
              <template v-if="data.type === 'mentor-program' && data.policy">
                <v-card-subtitle class="text-h6">Policy</v-card-subtitle>
                <v-card-text v-html="$replaceEmpty(data.policy)"> </v-card-text>
              </template>
              <template
                v-if="data.type === 'mentor-program' && data.like_to_receive"
              >
                <v-card-subtitle class="text-h6"
                  >What type of projects would you like to
                  receive?</v-card-subtitle
                >
                <v-card-text v-html="$replaceEmpty(data.like_to_receive)">
                </v-card-text>
              </template>
              <template v-if="data.experience">
                <v-card-subtitle class="text-h6"
                  >Why is this experience (Even if it is unpaid) valuable for
                  students?
                </v-card-subtitle>
                <v-card-text v-html="data.experience"> </v-card-text>
              </template>
              <template v-if="data.practical_skills">
                <v-card-subtitle class="text-h6"
                  >What key practical skills students will learn from you? (your
                  answer will help us better promote your opportunity to our
                  students）
                </v-card-subtitle>
                <v-card-text v-html="data.practical_skills"> </v-card-text>
              </template>
              <v-card-text>
                <v-sheet class="pt-n4">
                  <span class="primary--text mr-1">Deadline:</span>
                  {{ data.closed_at | format }}
                </v-sheet>
                <v-sheet v-if="data.type === 'task'">
                  <span class="primary--text mr-1">Reward:</span>
                  <template v-if="data.cost_min <= 0"> Free</template>
                  <template v-else>${{ data.cost_min }}</template>
                </v-sheet>
                <v-sheet v-else-if="data.type === 'mentor-program'">
                  <span class="primary--text mr-1">Cost for each seat:</span>
                  <template v-if="data.cost_min">
                    ${{ data.cost_min }}</template
                  >
                  <template v-else> Free</template>
                </v-sheet>
                <!--学术团队的价格-->
                <!--v-sheet v-else-if="data.type === 'academic-team'">
                  <span class="primary--text mr-1">Total budget:</span>
                  <template v-if="!data.is_government_subsidy">
                    ${{ data.cost_min * data.time_line }}
                  </template>
                  <template v-else-if="data.total_cost > 0">
                    ${{ data.total_cost }}
                  </template>
                  <template v-else> ${{ data.cost_max }}</template>
                </v-sheet-->
                <v-sheet v-else-if="data.type === 'academic-team'"></v-sheet>
                <v-sheet v-else>
                  <span class="primary--text mr-1">Reward:</span>
                  <template v-if="data.reward"> ${{ data.reward }}</template>
                  <template v-else> Free</template>
                </v-sheet>
                <v-sheet v-if="data.type === 'mentor-program'">
                  <span class="primary--text mr-1">Seats available:</span>
                  {{ data.seats }}
                </v-sheet>
                <template v-if="data.type === 'mentor-program'">
                  <v-sheet>
                    <span class="primary--text mr-1"
                      >Total number of hours you wish to commit for the program
                      (we suggest 4-5 hours per week）:</span
                    >
                    {{ data.total_hours }}
                  </v-sheet>
                  <!--v-sheet>
                    <span class="primary--text mr-1"
                      >Number of hours for the course:</span
                    >
                    {{ data.course_hours }}
                  </v-sheet>
                  <v-sheet>
                    <span class="primary--text mr-1"
                      >Number of hours for the hands on experience:</span
                    >
                    {{ data.experience_hours }}
                  </v-sheet-->
                </template>
                <v-sheet>
                  <span class="primary--text mr-1">Type: </span
                  >{{ data.is_online ? 'Remote' : 'Onsite' }}</v-sheet
                >
                <v-sheet class="pt-n4" v-if="data.time_line">
                  <span class="primary--text mr-1"
                    >Duration of the project:</span
                  >
                  {{ data.time_line }}
                  {{ data.type === 'task' ? 'days' : 'months' }}
                </v-sheet>

                <v-sheet class="pt-n4" v-if="data.type === 'academic-team'">
                  <span class="primary--text mr-1"
                    >If the solution proposal is required:</span
                  >
                  {{ data.provide_solution ? 'Yes' : 'No' }}
                </v-sheet>
                <!--学术团队项目问题显示-->
                <academic-team-show-field
                  v-if="data.type === 'academic-team'"
                  :is-government-subsidy="data.is_government_subsidy"
                  :is-provide-solution="data.provide_solution"
                  :project="data"
                ></academic-team-show-field>
                <v-sheet
                  class="pt-n4"
                  v-if="data.type === 'academic-team' && data.is_applied"
                >
                  <span class="primary--text mr-1">Email Address:</span>
                  {{ data.user.email }}
                </v-sheet>
              </v-card-text>
              <v-card-actions
                v-if="
                  (!isLoggedIn || userInfo.id !== data.user.id) &&
                  !data.is_closed &&
                  data.status === 'applying' &&
                  !data.is_applied
                "
              >
                <v-row justify="center" no-gutters>
                  <v-col cols="12">
                    <v-row justify="center" no-gutters>
                      <v-col cols="auto">
                        <v-btn
                          depressed
                          color="deep-orange"
                          class="white--text"
                          @click="applyNow"
                          >Apply Now</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <v-row justify="center" no-gutters>
                      <v-col cols="auto">
                        <v-alert text color="blue"
                          >When clicked, your application is sent through to the
                          company and you will be notified if you have been
                          accepted.</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
            </template>
            <v-alert v-else text outlined color="deep-orange" icon="mdi-cancel">
              Project not found
              <v-divider
                class="my-4 deep-orange"
                style="opacity: 0.22;"
              ></v-divider>
              <v-row align="center" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="orange"
                    class="mr-1"
                    outlined
                    @click="$router.push({ name: 'projectIndex' })"
                  >
                    Go to projects list
                  </v-btn>
                  <v-btn color="orange" outlined @click="$router.go(-1)">
                    Back
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
          <v-row no-gutters class="mt-5" v-if="data.id">
            <v-col cols="12" class="title mt-4">Current Applicants</v-col>
            <v-col cols="12">
              <v-alert
                v-if="
                  receivers.length > 0 &&
                  isLoggedIn &&
                  userInfo.id === data.user.id
                "
                icon="mdi-alert-outline"
                prominent
                text
                type="warning"
                color="yellow darken-3"
                class="mt-5"
              >
                <template
                  v-if="
                    data.type === 'academic-team' && data.is_government_subsidy
                  "
                >
                  1.Before proceeding to hire a candidate for your project, we
                  highly suggest conducting your own interviews and screening
                  processes as you would normally. <br />2.Ensure both parties
                  agree with the final Team Lead Wage, before proceeding to
                  hire. This portion will be deposited on the website directly.
                  <br />3.Please communicate with the Team Lead the payment plan
                  for any Project Fees that may be incurred during the
                  project.<br />
                  4.Beeznests charges 7% on top of the Academic Team’s full
                  salary only. Beeznests does not charge for any Project
                  Fees.<br />
                  5.Please pay for all student wages through your payroll
                  system.
                </template>
                <template
                  v-else-if="
                    data.type === 'academic-team' && !data.is_government_subsidy
                  "
                >
                  1.Before proceeding to hire a candidate for your project, we
                  highly suggest conducting your own interviews and screening
                  processes as you would normally. <br />2.Ensure both parties
                  agree with the final Academic Team Wage, before proceeding to
                  hire. This portion will be deposited on the website
                  directly.<br />
                  3.Please communicate with the Team Lead the payment plan for
                  any Project Fees that may be incurred during the project.
                  <br />4. There will be one-time service fee of $100.
                </template>
                <template v-else>
                  Before proceeding to hire a candidate for your project, we
                  highly suggest conducting your own interviews and screening
                  processes as you would normally.
                </template>
              </v-alert>
              <v-alert
                v-if="
                  receivers.some(
                    (receive) =>
                      isLoggedIn &&
                      receive.status === 'processing' &&
                      receive.user_id === userInfo.id
                  )
                "
                icon="mdi-alert-outline"
                prominent
                text
                type="warning"
                color="yellow darken-3"
                class="mt-5"
              >
                Once you’ve completed a project, make sure to click the Complete
                Project button to notify the project owner.
              </v-alert>
              <v-alert
                v-if="
                  data.status === 'finished' &&
                  isLoggedIn &&
                  userInfo.id === data.id
                "
                icon="mdi-alert-outline"
                prominent
                text
                type="warning"
                color="yellow darken-3"
                class="mt-5"
              >
                If your posted project has been completed,, please make sure to
                hit the Finished Project Button and to leave the user a
                performance review.
              </v-alert>
              <receive-item
                v-for="receiver in receivers"
                :receiver="receiver"
                :project="data"
                :key="receiver.id"
                @checkout="checkout(receiver.id, receiver.cost)"
                @complete="complete(receiver.id)"
                @finished="handleFinished(receiver.id)"
                @review="handleReview(receiver.id)"
              ></receive-item>
              <v-skeleton-loader
                v-if="receiversLoading"
                class="mx-auto"
                type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"
              ></v-skeleton-loader>
            </v-col>
            <v-col
              cols="12"
              v-intersect="{
                handler: debounceLoadReceivers,
                options: {
                  threshold: [0, 0.5, 1.0],
                },
              }"
            ></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
          <v-card
            class="mx-auto"
            tile
            v-else-if="isLoggedIn && data.user.id === userInfo.id"
          >
            <v-card-title>Actions</v-card-title>
            <v-list rounded nav dense>
              <v-list-item-group
                v-if="isLoggedIn && userInfo.id === data.user.id"
                color="deep-orange"
              >
                <v-list-item
                  @click="
                    $router.push(
                      `/${
                        data.type === 'mentor-program'
                          ? 'mentor-program'
                          : 'projects'
                      }/${data.id}/edit`
                    )
                  "
                >
                  <v-list-item-icon
                    ><v-icon
                      >mdi-file-document-edit-outline</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      Edit
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="closedProject" v-if="!data.is_closed">
                  <v-list-item-icon>
                    <v-icon v-if="!closedLoading">mdi-lock-clock</v-icon>
                    <v-progress-circular
                      v-else
                      size="28"
                      indeterminate
                    ></v-progress-circular>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Close Application
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteProject">
                  <v-list-item-icon>
                    <v-icon v-if="!deleteLoading">mdi-trash-can-outline</v-icon>
                    <v-progress-circular
                      v-else
                      indeterminate
                      size="28"
                    ></v-progress-circular>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Delete
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group
                v-else-if="
                  isLoggedIn && !data.is_closed && data.status === 'applying'
                "
              >
                <v-list-item @click="showApply">
                  <v-list-item-icon
                    ><v-icon
                      >mdi-calendar-check-outline</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title>
                      Apply
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <user-info-card :user="data.user" v-else></user-info-card>
          <v-card
            class="mt-4"
            v-if="isLoggedIn && userInfo.id === data.user.id"
          >
            <v-alert>
              Don't forget, once you hire a student, you can leave them a
              performance review at any time. <br />
              This function will encourage students to do the best job they can
              so that you can leave them an amazing review!
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="pickDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Warning
        </v-card-title>

        <v-card-text class="mt-4">
          Are you sure you wish to proceed with hiring this candidate? Once you
          confirm, the money you deposited on the platform will be released to
          this student after the project is completed (if this is a paid
          project). When your task is completed by the applicant, please select
          the "Finish" button to confirm your transaction. If you have any
          questions, please reach out to jin.liu@beeznests.com
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="pickUser"
            :loading="checkoutLoading"
          >
            Yes
          </v-btn>
          <v-btn color="primary" text @click="pickDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 是否为政府补助项目的弹框-->
    <!--v-dialog v-model="isGovernmentSubsidyDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Notice
        </v-card-title>
        <v-card-text class="mt-6">
          <template v-if="!isCheckedIsGovernmentSubsidy">
            <p class="tw-text-lg">
              Are you applying for government wage subsidy for this project?
            </p>
            <v-btn
              class="mr-2"
              @click="
                isGovernmentSubsidy = true
                isCheckedIsGovernmentSubsidy = true
              "
              >Yes</v-btn
            >
            <v-btn
              @click="
                checkoutDialog = true
                isGovernmentSubsidyDialog = false
                isCheckedIsGovernmentSubsidy = true
              "
              >No</v-btn
            >
          </template>
          <template v-else-if="isGovernmentSubsidy">
            <v-text-field
              v-model="studentsFee"
              label="The total wage for students:"
              :rules="[(value) => !!value || 'Required.']"
            ></v-text-field>
            <v-text-field
              v-model="leadFee"
              label="The wage for team lead:"
              :rules="[(value) => !!value || 'Required.']"
            ></v-text-field>
            <v-sheet class="mt-4">
              <v-btn class="mr-2" @click="checkIsFillFee">Next</v-btn>
              <v-btn @click="isCheckedIsGovernmentSubsidy = false" text
                >Back</v-btn
              >
            </v-sheet>
          </template>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog-->
    <!-- 支付对话框-->
    <v-dialog v-model="checkoutDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Notice
        </v-card-title>

        <v-card-text class="mt-4">
          <v-alert text type="error">
            <template
              v-if="data.type === 'academic-team' && data.is_government_subsidy"
            >
              Please pay the Student Wage portion of the Academic Team through
              your company payroll system. For the remainder Team Lead portion,
              pay here. Once your payment has been confirmed, the Team Lead will
              immediately start training and hiring students in preparation for
              your project. Upon completion of your project, we will release the
              payments to the Team
            </template>
            <template
              v-else-if="
                data.type === 'academic-team' && !data.is_government_subsidy
              "
              >Please deposit full payments for Academic Teams here. Once your
              payment has been confirmed, the Team Lead will immediately start
              training and hiring students in preparation for your project. Upon
              completion of your project, we will release the payments to the
              Team.
            </template>
            <template v-else>
              Are you sure you wish to proceed with hiring this candidate? Once
              you confirm, the money you deposited on the platform will be
              released to this student after the project is completed (if this
              is a paid project). When your task is completed by the applicant,
              please select the "Finish" button to confirm your transaction.
            </template>
            <br />If you have any questions, please contact
            ourhoneycomb@gmail.com
          </v-alert>
          <v-list tile dense>
            <v-subheader>Payment Breakdown</v-subheader>
            <template v-if="data.type !== 'academic-team'">
              <v-list-item>
                <v-list-item-title>Project Fee</v-list-item-title>
                <v-list-item-subtitle
                  >${{ hireProjectFee }}</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item v-if="serviceFee > 0">
                <v-list-item-title>Service Fee</v-list-item-title>
                <v-list-item-subtitle>
                  ${{ hireServiceFee }}</v-list-item-subtitle
                >
              </v-list-item>
            </template>
            <template
              v-else-if="
                data.type === 'academic-team' && data.is_government_subsidy
              "
            >
              <v-list-item>
                <v-list-item-title>Total Student Wage:</v-list-item-title>
                <v-list-item-subtitle
                  >${{ data.students_budget }}</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Total Team Lead Wage:</v-list-item-title>
                <v-list-item-subtitle
                  >${{ checkoutAmount }}</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Service Fee:</v-list-item-title>
                <v-list-item-subtitle
                  >${{ hireServiceFee }}</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title>GST:</v-list-item-title>
                <v-list-item-subtitle>${{ hireGstFee }}</v-list-item-subtitle>
              </v-list-item>
              <!--              <v-list-item>-->
              <!--                <v-list-item-title>Website maintenance (4%):</v-list-item-title>-->
              <!--                <v-list-item-subtitle-->
              <!--                  >${{ hireMaintenanceFee }}</v-list-item-subtitle-->
              <!--                >-->
              <!--              </v-list-item>-->
              <!--              <v-list-item>-->
              <!--                <v-list-item-title>Beeznests Fees:</v-list-item-title>-->
              <!--                <v-list-item-subtitle-->
              <!--                  >${{ hireServiceFee }}</v-list-item-subtitle-->
              <!--                >-->
              <!--              </v-list-item>-->
              <!--              <v-list-item>-->
              <!--                <v-list-item-title>GST:</v-list-item-title>-->
              <!--                <v-list-item-subtitle-->
              <!--                  >${{ collegeGstFee }}</v-list-item-subtitle-->
              <!--                >-->
              <!--              </v-list-item>-->
            </template>
            <template
              v-else-if="
                data.type === 'academic-team' && !data.is_government_subsidy
              "
            >
              <v-list-item>
                <v-list-item-title>Team Lead Wage:</v-list-item-title>
                <v-list-item-subtitle
                  >${{ checkoutAmount }}</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Service Fee:</v-list-item-title>
                <v-list-item-subtitle
                  >${{ collegeHireServiceFee }}</v-list-item-subtitle
                >
              </v-list-item>
              <!--v-list-item>
                <v-list-item-title>Website maintenance:</v-list-item-title>
                <v-list-item-subtitle
                  >${{ hireMaintenanceFee }}</v-list-item-subtitle
                >
              </v-list-item-->
              <!--              <v-list-item>-->
              <!--                <v-list-item-title>Beeznests Fees:</v-list-item-title>-->
              <!--                <v-list-item-subtitle-->
              <!--                  >${{ hireServiceFee }}</v-list-item-subtitle-->
              <!--                >-->
              <!--              </v-list-item>-->
              <!--              <v-list-item>-->
              <!--                <v-list-item-title>GST:</v-list-item-title>-->
              <!--                <v-list-item-subtitle> 5% </v-list-item-subtitle>-->
              <!--              </v-list-item>-->
            </template>
            <!--            <v-list-item v-if="maintenanceFee > 0">-->
            <!--              <v-list-item-title>Maintenance Fee</v-list-item-title>-->
            <!--              <v-list-item-subtitle>-->
            <!--                ${{ hireMaintenanceFee }}</v-list-item-subtitle-->
            <!--              >-->
            <!--            </v-list-item>-->
            <v-divider></v-divider>
            <v-list-item v-if="fee > 0 || checkoutAmount > 0">
              <v-list-item-title>Total Cost</v-list-item-title>
              <v-list-item-subtitle
                >${{
                  data.type === 'academic-team' && !data.is_government_subsidy
                    ? collegeHireTotalFee
                    : hireTotalFee
                }}</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>

          <!--          <v-alert class="pt-2 pa-0 mb-0 grey&#45;&#45;text text-caption">-->
          <!--            The Service & Maintenance & Transaction Fee is only charged once for-->
          <!--            each project post-->
          <!--          </v-alert>-->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            class="white--text"
            depressed
            @click="createOrder"
            :loading="checkoutLoading"
          >
            Pay
          </v-btn>
          <v-btn color="primary" text @click="checkoutDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--支付对话框结束-->
    <!--完成项目-->
    <v-dialog v-model="completeDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Confirm
        </v-card-title>

        <v-card-text class="mt-4">
          Are you sure you want to complete the project?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="completeReceive"
            :loading="completeLoading"
          >
            Confirm
          </v-btn>
          <v-btn color="primary" text @click="completeDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--申请任务-->
    <v-dialog v-model="applyDialog" max-width="500" v-if="isLoggedIn">
      <v-card>
        <v-card-title
          class="headline lighten-2 white--text"
          :class="!userInfo.type ? 'error' : 'info'"
        >
          {{ !userInfo.type ? 'Warning' : 'Confirm' }}
        </v-card-title>

        <v-card-text class="mt-4">
          <template
            v-if="
              !userInfo.type ||
              (userInfo.type !== 'company' && !userInfo.resume_file)
            "
          >
            Please Complete Your Profile First
          </template>
          <template v-else>
            Are you ready to apply？
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !userInfo.type ||
              (userInfo.type !== 'company' && !userInfo.resume_file)
            "
            color="primary"
            text
            @click="$router.push({ name: 'userSettings' })"
            :loading="isApplyLoading"
          >
            Settings
          </v-btn>
          <v-btn
            v-else
            color="primary"
            text
            @click="apply"
            :loading="isApplyLoading"
          >
            Confirm
          </v-btn>
          <v-btn color="primary" text @click="applyDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--完成任务-->
    <v-dialog v-model="finishedDialog" max-width="500" v-if="isLoggedIn">
      <v-card>
        <v-card-title class="headline info lighten-2 white--text">
          Confirm
        </v-card-title>

        <v-card-text class="mt-4">
          Are you sure this project is finished? Once you confirm, the money you
          deposited on the platform will be released to this student.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="finished"
            :loading="finishedLoading"
          >
            Confirm
          </v-btn>
          <v-btn color="primary" text @click="finishedDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showApplyMentorProgramDialog" max-width="500">
      <v-card>
        <v-card-title class="headline info lighten-2 white--text">
          Confirm
        </v-card-title>
        <v-card-text class="mt-4">
          <!--          <v-alert type="info" text-->
          <!--            >This is a paid mentorship program. Please either pay to secure your-->
          <!--            seat or enter the invitation code to apply.</v-alert-->
          <!--          >-->
          <v-alert type="info" text>
            This is a paid bootcamp program. Please pay to secure your seat to
            apply.
          </v-alert>
          <v-list tile dense>
            <v-subheader>Payment Breakdown</v-subheader>
            <v-list-item>
              <v-list-item-title>Cost</v-list-item-title>
              <v-list-item-subtitle>${{ data.cost_min }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Service Fee</v-list-item-title>
              <v-list-item-subtitle>${{ serviceFee }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Maintenance Fee</v-list-item-title>
              <v-list-item-subtitle>${{ maintenanceFee }}</v-list-item-subtitle>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Checkout price (total):</v-list-item-title>
              <v-list-item-subtitle
                >${{ applyProjectFeeTotal }}</v-list-item-subtitle
              >
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--v-btn
            elevation="0"
            color="primary"
            @click="
              () => {
                showEnterInvitationCodeDialog = true
                showApplyMentorProgramDialog = false
              }
            "
            >enter invitation code</v-btn
          -->
          <v-btn
            elevation="0"
            color="primary"
            @click="createApplyOrder"
            :loading="createApplyOrderLoading"
            >pay</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="showApplyMentorProgramDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEnterInvitationCodeDialog" max-width="500">
      <v-card>
        <v-card-title class="headline info lighten-2 white--text">
          Enter your invitation code
        </v-card-title>
        <v-card-text class="mt-4">
          <v-text-field
            label="Invitation code"
            v-model="password"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="apply" :loading="isApplyLoading">
            Confirm
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                showApplyMentorProgramDialog = false
                showEnterInvitationCodeDialog = false
              }
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {
  fetchData,
  fetchReceiver,
  paymentUrl,
  complete,
  apply as applyProject,
  finished as finishedProject,
  closed,
  destroy,
  checkApplyPassword,
} from '@/api/project'
import { mapGetters } from 'vuex'
import { debounce, pick } from 'lodash'
import ReceiveItem from '@/components/Receive/Item'
import UserInfoCard from '@/components/User/Info'
import { createOrder, createApplyProjectOrder } from '@/api/pay'
import Skill from '@/components/Project/Label/skill'
import WorkWithLabel from '@/components/Project/Label/WorkWithLabel'
import WillDo from '@/components/Project/Label/WillDo'
import DescriptionLabel from '@/components/Project/Label/DescriptionLabel'
import BigNumber from 'bignumber.js'
import AcademicTeamShowField from '@/components/Project/AcademicTeamShowLabel'
export default {
  components: {
    DescriptionLabel,
    WillDo,
    WorkWithLabel,
    Skill,
    ReceiveItem,
    UserInfoCard,
    AcademicTeamShowField,
  },
  data: () => ({
    data: {
      user: {
        country: {},
      },
    },
    receivers: [],
    pages: {
      current_page: 1,
      last_page: 1,
    },
    receiversLoading: false,
    loading: false,
    isFirstLoad: true,
    checkoutDialog: false,
    checkoutAmount: 0,
    checkoutId: 0,
    checkoutLoading: false,
    completeId: 0,
    completeLoading: false,
    completeDialog: false,
    applyDialog: false,
    isApplyLoading: false,
    closedLoading: false,
    pickDialog: false,
    finishedId: 0,
    finishedDialog: false,
    finishedLoading: false,
    deleteLoading: false,
    fee: 4.99,
    showApplyMentorProgramDialog: false, // 团队项目申请弹框
    showEnterInvitationCodeDialog: false,
    password: null,
    createApplyOrderLoading: false,
    applyOrderIsPaid: false,
    isGovernmentSubsidyDialog: false,
    isGovernmentSubsidy: false, // 是否为政府补助
    isCheckedIsGovernmentSubsidy: false, // 有没有选择是否为政府补助项目
    studentsFee: null, // 政府补助项目的学生费用
    leadFee: null, //政府补助项目的导师费用
  }),
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    ...mapGetters(['userInfo', 'isLoggedIn', 'isAdmin']),
    serviceFee() {
      return new BigNumber(this.data.cost_min).multipliedBy(0.03).toFixed(2)
    },
    maintenanceFee() {
      return new BigNumber(this.data.cost_min).multipliedBy(0.04).toFixed(2)
    },
    applyProjectFeeTotal() {
      return new BigNumber(this.data.cost_min)
        .plus(this.maintenanceFee)
        .plus(this.serviceFee)
        .toFixed(2)
    },
    // 雇佣支付
    hireServiceFee() {
      if (this.data.type === 'academic-team') {
        return 100.0
        // return new BigNumber(this.data.students_budget)
        //   .plus(this.checkoutAmount)
        //   .multipliedBy(0.03)
      }
      return new BigNumber(this.checkoutAmount).multipliedBy(0.03).toFixed(2)
    },
    hireMaintenanceFee() {
      if (this.data.type === 'academic-team') {
        return 0
        // return new BigNumber(this.data.students_budget)
        //   .plus(this.checkoutAmount)
        //   .multipliedBy(0.04)
      }
      return new BigNumber(this.checkoutAmount).multipliedBy(0.04).toFixed(2)
    },
    collegeHireServiceFee() {
      return 100.0 // new BigNumber(this.data.time_line).multipliedBy(200).toFixed(2)
    },
    hireProjectFee() {
      return this.checkoutAmount
    },
    hireTotalFee() {
      const total = this.checkoutAmount
      return new BigNumber(total)
        .plus(this.hireMaintenanceFee)
        .plus(this.hireServiceFee)
        .toFixed(2)
      // return new BigNumber(totalFee).multipliedBy(1.05).toFixed(2)
    },
    collegeHireTotalFee() {
      const total = this.checkoutAmount
      return new BigNumber(total).plus(this.collegeHireServiceFee).toFixed(2)
      // return new BigNumber(totalFee).multipliedBy(1.05).toFixed(2)
    },
    // hireGstFee() {
    //   const totalFee = new BigNumber(this.checkoutAmount)
    //     .plus(this.hireMaintenanceFee)
    //     .plus(this.hireServiceFee)
    //     .toFixed(2)
    //   return new BigNumber(this.hireTotalFee).plus(-totalFee)
    // },
    // collegeGstFee() {
    //   const totalFee = new BigNumber(this.checkoutAmount)
    //     .plus(this.collegeHireServiceFee)
    //     .toFixed(2)
    //   return new BigNumber(this.collegeHireTotalFee).plus(-totalFee)
    // },
  },
  async mounted() {
    await this.loadData()
    await this.loadReceiver(true)
  },
  created() {
    this.debounceLoadReceivers = debounce(this.loadMoreReceivers, 800)
  },
  methods: {
    applyNow() {
      if (
        this.data.type === 'academic-team' &&
        this.isLoggedIn &&
        !this.userInfo.is_mentor
      ) {
        this.$message('Only eligible for Beeznests mentor to apply', 'error')
        return
      }
      if (
        this.isLoggedIn &&
        this.userInfo.is_mentor &&
        !this.userInfo.resume_file
      ) {
        this.$message('Please upload your resume', 'error')
        this.$router.push({ name: 'userSettings' })
        return
      }
      if (
        this.isLoggedIn &&
        (this.userInfo.type === 'company' || this.userInfo.resume_file)
      ) {
        if (this.userInfo.is_need_to_pay_cost) {
          this.$router.push({
            name: 'PaymentUserCost',
          })
          return
        }
        this.showApply()
      } else if (
        this.isLoggedIn &&
        this.userInfo.type !== 'company' &&
        !this.userInfo.resume_file
      ) {
        this.$message('Please upload your resume', 'error')
        this.$router.push({ name: 'userSettings' })
      } else {
        // 记录跳转地址
        this.$store.commit(
          'setting/SET_REDIRECT_TO',
          pick(this.$route, [
            'name',
            'fullPath',
            'meta',
            'params',
            'path',
            'query',
          ])
        )
        this.$router.push({ name: 'AuthRegister' })
      }
    },
    async loadData() {
      try {
        this.loading = true
        let includes =
          'is_applied,is_own,content,who,work_with,skills,about_mentor,policy,agenda'
        const { data } = await fetchData(this.id, {
          includes,
        })
        this.data = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async loadReceiver(reset = false) {
      if (!this.data.id) return
      try {
        this.receiversLoading = true
        const requestData = {
          id: this.id,
        }
        if (reset) {
          requestData.page = 1
        } else {
          requestData.page = ++this.pages.current_page
        }
        const { data, meta } = await fetchReceiver(this.id, requestData)
        this.receivers = reset ? data : this.receivers.concat(data)
        this.pages = meta
      } catch (e) {
        console.log(e)
      } finally {
        this.receiversLoading = false
      }
    },
    async loadMoreReceivers() {
      if (this.isFirstLoad) {
        this.isFirstLoad = false
      } else if (
        !this.receiversLoading &&
        this.pages.current_page <= this.pages.last_page
      ) {
        await this.loadReceiver()
      }
    },
    // 项目操作方法
    checkout(id, amount) {
      this.checkoutId = id
      // 学术团队项目 需要支付
      if (this.data.type === 'academic-team') {
        this.checkoutAmount = amount
        // this.fee = 0

        // this.checkoutDialog = true
        this.showCheckoutDialog()
        return
      }
      if (this.isAdmin) {
        this.pickDialog = true
        return
      }

      if (
        !this.data.is_hired ||
        this.data.cost_min <= 0 ||
        this.userInfo.can_free_hired ||
        (this.data.type !== 'task' && this.data.type !== 'academic-team')
      ) {
        this.pickDialog = true
        return
      } else if (this.data.cost_min > 0) {
        this.checkoutAmount = amount
        this.fee = 0
      }
      this.showCheckoutDialog()
    },
    // 创建订单
    async createOrder() {
      try {
        const { data } = await createOrder({
          project_id: this.data.id,
          receiver_id: this.checkoutId,
          lead_fee: this.leadFee,
          students_fee: this.studentsFee,
        })
        this.$router.push({ name: 'Pay', params: { no: data.no } })
      } catch (e) {
        console.log(e)
      }
    },
    // 创建申请项目订单
    async createApplyOrder() {
      if (this.createApplyOrderLoading) return
      this.createApplyOrderLoading = true
      try {
        const { data } = await createApplyProjectOrder({
          project_id: this.data.id,
        })
        console.log(data)
        this.createApplyOrderLoading = false
        if (data.is_paid) {
          this.applyOrderIsPaid = true
          this.apply()
          return
        }
        this.$router.push({ name: 'Pay', params: { no: data.no } })
      } catch (e) {
        this.createApplyOrderLoading = false
        console.log(e)
      }
    },
    async getPaymentUrl() {
      if (this.checkoutLoading || this.checkoutId <= 0) return
      try {
        this.checkoutLoading = true
        const { message } = await paymentUrl(this.checkoutId)
        if (message) {
          this.$message(message)
          this.pickDialog = false
          // this.$set(this.data, 'status', 'processing')
          this.setReceiveStatus(this.checkoutId, 'processing')
          this.$set(this.data, 'is_hired', true)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.checkoutLoading = false
      }
    },
    /////
    complete(id) {
      this.completeDialog = true
      this.completeId = id
    },
    async completeReceive() {
      if (this.completeLoading) return
      try {
        this.completeLoading = true
        const { message, receive_status, project_status } = await complete(
          this.completeId
        )
        this.$store.dispatch('message/pushMessage', {
          type: 'info',
          content: message,
          timeout: 3000,
        })
        // this.$set(this.data, 'status', project_status)
        this.setReceiveStatus(this.completeId, receive_status)
        this.completeDialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.completeLoading = false
      }
    },
    showApply() {
      if (this.data.type === 'mentor-program') {
        this.showApplyMentorProgram()
      } else if (
        this.data.type === 'academic-team' // &&
        // (this.data.provide_solution || this.data.questions.length > 0)
      ) {
        // 学术团队项目
        this.$store.commit('project/SET', this.data)
        this.$router.push({ name: 'projectApply', params: { id: this.id } })
      } else {
        this.applyDialog = true
      }
    },
    /**
     * 用户申请教授发的项目
     */
    showApplyMentorProgram() {
      // 修改如果训练营费用为0 不弹出确认框
      if (this.data.cost_min <= 0) {
        this.applyOrderIsPaid = true
        this.apply()
      } else {
        this.showApplyMentorProgramDialog = true
      }
      // this.createApplyOrder()
    },
    async apply() {
      //
      if (this.data.questions.length <= 0) {
        // 没有问题 申请项目
        await this.handleApplyProject(this.data.cost_min)
      } else {
        // 如果有问题 跳转到问题填写页面
        // 导师项目需要有密码或者支付后才可以申请
        if (this.data.type === 'mentor-program' && !this.applyOrderIsPaid) {
          // 验证一下密码是否正确
          try {
            await checkApplyPassword(this.id, {
              password: this.password,
            })
            this.$store.commit('project/SET_PASSWORD', this.password)
          } catch (e) {
            console.log(e)
            return
          }
        }
        this.$store.commit('project/SET', this.data)
        this.$router.push({ name: 'projectApply', params: { id: this.id } })
      }
    },
    // 申请项目
    async handleApplyProject(cost) {
      this.isApplyLoading = true
      try {
        const res = await applyProject(this.data.id, {
          cost: parseInt(cost),
          password: this.password,
        })
        if (res.message) {
          this.$store.dispatch('message/pushMessage', {
            type: 'info',
            content: res.message,
            timeout: 3000,
          })
          this.applyDialog = false
        }
        this.isApplyLoading = false
        this.showApplyMentorProgramDialog = false
        this.showEnterInvitationCodeDialog = false
      } catch (e) {
        this.isApplyLoading = false
        this.applyDialog = false
      }
    },
    async closedProject() {
      try {
        this.closedLoading = true
        await closed(this.id)
        this.$set(this.data, 'is_closed', true)
      } catch (e) {
        this.closedLoading = false
      }
    },
    async pickUser() {
      try {
        await this.getPaymentUrl()
        this.$set(this.data, 'is_hired', true)
      } catch (e) {
        console.log(e)
      }
    },
    handleFinished(id) {
      this.finishedDialog = true
      this.finishedId = id
    },
    async finished() {
      this.finishedLoading = true
      try {
        const {
          message,
          receive_status,
          project_status,
        } = await finishedProject(this.finishedId)
        if (message) {
          this.$store.dispatch('message/pushMessage', {
            type: 'info',
            content: message,
            timeout: 3000,
          })
          this.setProjectStatus(project_status)
          this.setReceiveStatus(this.finishedId, receive_status)
        }
        this.finishedDialog = false
        this.finishedLoading = false
      } catch (e) {
        this.finishedLoading = false
        this.finishedDialog = false
      }
    },
    setReceiveStatus(id, status) {
      const index = this.receivers.findIndex((item) => item.id === id)
      if (index >= 0) {
        this.$set(this.receivers[index], 'status', status)
      }
    },
    setProjectStatus(status) {
      this.$set(this.data, 'status', status)
    },
    handleReview(id) {
      this.$router.push({ name: 'receiveReview', params: { id } })
    },
    async deleteProject() {
      this.deleteLoading = true
      try {
        const { message } = await destroy(this.id)
        if (message) {
          this.$message(message)
          this.$router.replace({ name: 'projectIndex' })
        }
      } catch (e) {
        this.deleteLoading = false
      }
    },
    // 需要支付的雇佣，显示弹框
    showCheckoutDialog() {
      //先显示 提问的弹框
      this.showIsGovernmentSubsidy()
    },
    // 显示选择是否为政府补助的弹框
    showIsGovernmentSubsidy() {
      // this.checkoutDialog = true
      // this.isCheckedIsGovernmentSubsidy = false
      // this.isGovernmentSubsidyDialog = true
      this.checkoutDialog = true
    },
    // 检查政府补助项目是否正确填写了费用
    checkIsFillFee() {
      if (!this.studentsFee && this.studentsFee !== 0) {
        this.$message('Please fill in the fees paid to students', 'error')
        return
      }
      if (!this.leadFee && this.leadFee !== 0) {
        this.$message('Please fill in the fees paid to lead', 'error')
        return
      }
      this.isGovernmentSubsidyDialog = false
      this.checkoutDialog = true
    },
  },
}
</script>
<style scoped lang="scss">
.v-card__text {
  ::v-deep {
    p {
      a {
        text-decoration: underline;
      }
    }
  }
}
</style>
