<template>
  <v-card>
    <v-toolbar flat>
      <v-card-title>
        Upload Avatar
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('hide')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-avatar :size="120">
            <v-img :src="saveValue || value" />
          </v-avatar>
          <avatar-cropper
            @uploaded="handleUploaded"
            trigger="#pick-avatar"
            :upload-url="uploadUrl"
            upload-form-name="upload_file"
            :withCredentials="true"
            @uploading="handleUploading"
            @error="handlerError"
            :labels="{ submit: 'Submit', cancel: 'Cancel' }"
          />
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn depressed id="pick-avatar" :loading="uploading"
            >Select an image</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-alert type="error" v-if="uploadError">
            Upload error, Please retry!
            {{ uploadErrorMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import AvatarCropper from '@/components/Settings/AvatarCropper'
const uploadUrl = process.env.VUE_APP_BASE_URL + 'upload'
export default {
  components: { AvatarCropper },
  computed: {
    value: {
      get() {
        return this.$store.getters.userInfo.avatar
      },
      set(v) {
        this.saveValue = v
      },
    },
  },
  data: () => ({
    saveValue: null,
    uploadUrl: uploadUrl,
    uploading: false,
    uploadError: false,
    updateLoading: false,
    uploadErrorMessage: '',
  }),
  methods: {
    handleUploaded(resp) {
      if (resp.success) {
        this.value = resp.file_path
      }
      this.uploading = false
      this.$emit('update', resp.file_path)
      this.$emit('hide')
    },
    handlerError(message, type, xhr) {
      if (type === 'upload') {
        if (xhr.status === 401) {
          this.$store.dispatch('auth/logout')
          this.$router.push('auth/login')
        }
      }
      this.uploadError = true
      this.uploadErrorMessage = message
      this.uploading = false
    },
    handleUploading() {
      this.uploadError = false
      this.uploading = true
    },
  },
}
</script>
