export default {
  path: '/notifications',
  component: () => import('@/views/layouts/root'),
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      component: () => import('@/views/notifications/root'),
      redirect: {
        name: 'notificationUnread',
      },
      children: [
        {
          path: 'index',
          component: () => import(/* webpackChunkName: 'index' */'@/views/notifications/index'),
          name: 'notificationIndex',
        },
        {
          path: 'system',
          component: () => import(/* webpackChunkName: 'index' */'@/views/notifications/index'),
          name: 'notificationSystem',
        },
        {
          path: 'new',
          component: () => import(/* webpackChunkName: 'index' */'@/views/notifications/new'),
          name: 'notificationUnread',
        },
      ],
    },
  ],
}
