// import store from '@/store'
export default {
  path: '/challenges',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '',
      name: 'challengeIndex',
      component: () => import(/* webpackChunkName: 'challenge' */'@/views/challenges/index'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'create',
      name: 'challengeCreate',
      component: () => import(/* webpackChunkName: 'challenge' */'@/views/challenges/create'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: ':id/edit',
      name: 'challengeEdit',
      component: () => import(/* webpackChunkName: 'challenge' */'@/views/challenges/create'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: ':id',
      name: 'challengeShow',
      component: () => import(/* webpackChunkName: 'challenge' */'@/views/challenges/show'),
    },
  ],
}
