export default {
  path: '/',
  component: () => import('@/views/layouts/app'),
  children: [
    {
      path: '',
      name: 'Index',
      component: () => import(/* webpackChunkName: 'new-page' */'@/views/new-pages/index'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-network',
      name: 'NewNetwork',
      component: () => import(/* webpackChunkName: 'new-page' */'@/views/new-pages/network'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-blog',
      name: 'NewBlog',
      component: () => import(/* webpackChunkName: 'new-page' */'@/views/new-pages/blog'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-profile/:id?',
      name: 'NewProfile',
      component: () => import(/* webpackChunkName: 'new-page' */'@/views/new-pages/profile'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-discussion',
      name: 'NewDiscussion',
      component: () => import(/* webpackChunkName: 'new-page' */'@/views/new-pages/discussion'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-projects/:type?',
      name: 'NewProjects',
      component: () => import(/* webpackChunkName: 'project' */'@/views/new-pages/project'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'mentor-programs',
      name: 'mentorIndex',
      component: () => import(/* webpackChunkName: 'project' */'@/views/new-pages/project'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-our',
      name: 'NewOur',
      component: () => import(/* webpackChunkName: 'our' */'@/views/new-pages/our'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'new-login',
      name: 'NewLogin',
      component: () => import(/* webpackChunkName: 'auth' */'@/views/new-pages/login'),
    },
    {
      path: 'new-register',
      name: 'NewRegister',
      component: () => import(/* webpackChunkName: 'register' */'@/views/new-pages/register'),
    },
    {
      path: 'new-links',
      name: 'NewLinks',
      component: () => import(/* webpackChunkName: 'links' */'@/views/new-pages/links'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'forgot_password',
      name: 'ForgotPassword',
      component: () => import(/* webpackChunkName: 'auth' */'@/views/new-pages/forgotPassword'),
    },
  ],
}
