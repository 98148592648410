import axios from '@/plugins/axios'

export const fetchData = (id) => {
  return axios.get(`/articles/${id}`)
}

export const fetchIndex = (params) => {
  return axios.get(`articles`, {
    params,
  })
}

export const fetchCategory = (id, params) => {
  return axios.get(`articles/category/${id}`, {
    params,
  })
}
