<template>
  <ValidationObserver ref="observer">
    <form>
      <template v-if="is_project">
        <v-row>
          <v-col>
            <label class="my-2">Type of Opportunity:</label>
            <v-radio-group
              v-model="data.type"
              :readonly="isEdit"
              :disabled="isEdit"
            >
              <v-radio value="task">
                <template v-slot:label>
                  <div>
                    Project-based work (Part-time. Micro-internship. 1-120 days.
                    Pay per project. Paid projects will be promoted to students,
                    as well as to our team leads (professors or industry
                    experts) who may apply if they accept your rate.)
                  </div>
                </template>
              </v-radio>
              <!--              <v-radio value="internship">-->
              <!--                <template v-slot:label>-->
              <!--                  <div>-->
              <!--                    Internship (Full-time. 4, 8 or 12 months. Pay per hour.)-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--              </v-radio>-->
              <v-radio value="academic-team">
                <template v-slot:label>
                  <div>
                    Academic Team (Part-time, group hire consisting of industry
                    expert（team lead) + trained students. Higher quality
                    guaranteed. Only eligible for Team Lead to apply.)
                  </div>
                </template>
              </v-radio>
              <div class="tw-pl-9">
                <a
                  class="tw-text-sm link orange px-2 py-1 rounded white--text"
                  target="_blank"
                  href="https://docs.google.com/document/d/1JmPBdMLQ519XUYNmcqzBe1d_0VzJyeojeqSFvktkGAU/edit"
                  >What is Academic Team Project?</a
                >
              </div>
              <!--              <div class="tw-pl-9 tw-mt-4" v-if="isSelectCollegeHire">-->
              <!--                <v-btn-toggle-->
              <!--                  v-model="isCollegeHire"-->
              <!--                  borderless-->
              <!--                  rounded-->
              <!--                  dense-->
              <!--                  color="orange"-->
              <!--                  mandatory-->
              <!--                >-->
              <!--                  <v-btn :value="true">-->
              <!--                    <span>Hiring International students</span>-->
              <!--                  </v-btn>-->
              <!--                  <v-btn :value="false">-->
              <!--                    <span>Hiring canadian students</span>-->
              <!--                  </v-btn>-->
              <!--                </v-btn-toggle>-->
              <!--              </div>-->
            </v-radio-group>
          </v-col>
        </v-row>
      </template>
      <ValidationProvider
        v-slot="{ errors }"
        name="Position Type"
        rules="required|max:255"
      >
        <v-text-field
          v-model="data.position"
          :counter="255"
          :error-messages="errors"
          label="Position Type"
          show-hint
          :hint="
            data.type === 'academic-team' && !data.is_government_subsidy
              ? 'like : Team Lead - Marketing'
              : 'Please indicate if this project is for professors only'
          "
          required
          :prefix="
            data.type === 'academic-team' && !data.is_government_subsidy
              ? 'Team Lead - '
              : null
          "
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="Project/Company Name"
        rules="required|max:255"
      >
        <v-text-field
          v-model="data.title"
          :counter="255"
          :error-messages="errors"
          label="Project/Company Name"
          required
        ></v-text-field>
      </ValidationProvider>

      <ValidationProvider v-slot="{ errors }" name="category" rules="required">
        <v-select
          v-model="data.category_id"
          :items="categories"
          :error-messages="errors"
          label="Category"
          data-vv-name="category"
          required
          item-text="name"
          item-value="id"
          :loading="categoryLoading"
        >
        </v-select>
      </ValidationProvider>
      <v-slider
        v-model="data.time_line"
        :label="
          is_project
            ? 'Duration of the project/intern'
            : 'Duration of the project'
        "
        min="1"
        :max="!is_project || data.type !== 'task' ? 12 : 120"
        thumb-label
        required
      >
        <template v-slot:append>
          {{
            data.time_line +
            (!is_project || data.type !== 'task' ? ' Months' : ' Days')
          }}
        </template>
      </v-slider>
      <v-row>
        <v-col cols="12">
          <date-picker
            v-model="closed_at"
            label="Application Deadline"
          ></date-picker>
        </v-col>
      </v-row>

      <v-row v-if="!is_project">
        <v-col>
          <ValidationProvider v-slot="{ errors }" name="Cost" rules="required">
            <v-text-field
              v-model="data.cost_min"
              label="Cost for each seat"
              prepend-inner-icon="mdi-currency-usd"
              suffix=".00"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="!is_project">
        <v-col>
          <ValidationProvider
            v-slot="{ errors }"
            name="Number of seats"
            rules="required"
          >
            <v-text-field
              v-model="data.seats"
              label="Number of seats available per program"
              :error-messages="errors"
              type="number"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="!is_project">
        <v-col>
          <ValidationProvider
            v-slot="{ errors }"
            name="Total number of hours you wish to commit for the mentorship program"
            rules="required"
          >
            <v-text-field
              v-model="data.total_hours"
              label="Total number of hours you wish to commit for the mentorship program? (we suggest  4-5  hours per week）"
              :error-messages="errors"
              type="text"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <!--v-row v-if="!is_project">
        <v-col>
          <ValidationProvider
            v-slot="{ errors }"
            name="Number of hours for the hands on experience"
            rules="required"
          >
            <v-text-field
              v-model="data.experience_hours"
              label="Number of hours for the hands on experience"
              :error-messages="errors"
              type="text"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="!is_project">
        <v-col>
          <ValidationProvider
            v-slot="{ errors }"
            name="Number of hours for the course"
            rules="required"
          >
            <v-text-field
              v-model="data.course_hours"
              label="Number of hours for the course"
              :error-messages="errors"
              type="text"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row-->
      <v-row v-if="is_project && data.type !== 'academic-team'">
        <v-col cols="12" md="2">
          <v-switch
            v-model="data.is_free"
            class="mx-2"
            :label="data.is_free ? 'Unpaid' : 'Paid'"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6" v-if="data.is_free">
          <v-alert type="info">
            To get the most applicants, we recommend offering a paid project,
            even if the compensation is small. If the project is for professors,
            we recommended paid projects only
          </v-alert>
        </v-col>

        <v-col cols="auto">
          <ValidationProvider
            v-if="!data.is_free && data.type === 'task'"
            v-slot="{ errors }"
            name="Cost"
            rules="required|cost:10"
          >
            <v-text-field
              v-model="data.cost_min"
              label="Reward"
              prepend-inner-icon="mdi-currency-usd"
              suffix=".00"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-if="!data.is_free && data.type !== 'task'"
            v-slot="{ errors }"
            name="Reward"
            rules="required"
          >
            <v-text-field
              v-model="data.reward"
              label="Reward"
              prepend-inner-icon="mdi-currency-usd"
              :error-messages="errors"
              hint="Example $10/hour"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-alert v-if="!data.is_free && data.type === 'task'" text>
        The Project fee is the amount each student will receive after completing
        your task. Your money will be safely deposited on our site and the
        transaction will be held.
      </v-alert>
      <!-- 学术团队项目的问题-->
      <template v-if="is_project && data.type === 'academic-team'">
        <!--学术团队 第一个选项新增的问题-->
        <template v-if="!isCollegeHire">
          <div class="tw-text-md mx-2">
            Would it be possible/okay if the Team Lead added international
            students to your project for free?
          </div>
          <v-switch
            class="mx-2"
            v-model="data.added_international_students"
            :label="data.added_international_students ? 'Yes' : 'No'"
          ></v-switch>
          <template v-if="data.added_international_students">
            <div class="tw-text-md mx-2">
              <v-text-field
                v-model="data.international_students_count"
                label="How many international students would you accept?"
                placeholder="Doesn’t matter / Specific number"
              ></v-text-field>
            </div>
          </template>
        </template>

        <div class="tw-text-md mx-2">
          Would you like to request a formal solution proposal to your posting?
          If yes, mentors will provide you a salary fee as well as a budget and
          timeline for the solution required.
        </div>
        <v-switch
          class="mx-2"
          v-model="data.provide_solution"
          :label="data.provide_solution ? 'Yes' : 'No'"
        ></v-switch>

        <!--学术团队佣金区间-->
        <template v-if="!isCollegeHire">
          <v-row>
            <v-col cols="12" class="py-0">
              {{
                data.provide_solution
                  ? 'Budget for Team lead'
                  : 'Budget for Team lead'
              }}
            </v-col>
            <template v-if="data.provide_solution">
              <!--v-col cols="12" sm="6" md="4" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Minimum Budget"
                  rules="required"
                >
                  <v-text-field
                    v-model="data.cost_min"
                    label="Minimum Budget"
                    prepend-inner-icon="mdi-currency-usd"
                    suffix=".00"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col-->
              <v-col cols="12" class="py-0">
                <v-row>
                  <v-col cols="12" sm="8" class="py-0">
                    <v-text-field
                      v-model="data.cost_min"
                      label="Maximum Budget for Team lead"
                      prepend-inner-icon="mdi-currency-usd"
                      hint="The suggested pay for the team lead ranges from $1000-1500/ per month"
                      suffix=".00"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--移除付费20%提示-->
                <!--              <p>-->
                <!--                Beeznests will charge 20% on top of the student’s wages and Team-->
                <!--                Lead salary.-->
                <!--              </p>-->
              </v-col>
              <v-col cols="12" class="py-0">
                <v-row>
                  <v-col cols="12" class="mb-0 pb-0">
                    <span>
                      Budget for Project Fee (any additional expenses to carry
                      out a solution for your project) This information will
                      help the Team Lead create a strategy and breakdown costs
                      for the solution proposal. (i.e. project fee may be the
                      total budget for Ad expenses for a marketing project)
                    </span>
                  </v-col>
                  <v-col cols="12" sm="8" md="5" class="py-0">
                    <v-text-field
                      v-model="data.actual_cost_max"
                      label="Maximum Budget for Project Fee"
                      prepend-inner-icon="mdi-currency-usd"
                      suffix=".00"
                    ></v-text-field>
                  </v-col>
                  <!--                <v-col cols="12" sm="8" md="4" class="py-0">-->
                  <!--                  <v-text-field-->
                  <!--                    v-model="data.actual_cost_max"-->
                  <!--                    label="Maximum actual project cost"-->
                  <!--                    prepend-inner-icon="mdi-currency-usd"-->
                  <!--                    suffix=".00"-->
                  <!--                    :error-messages="errors"-->
                  <!--                  ></v-text-field>-->
                  <!--                </v-col>-->
                </v-row>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="data.cost_min"
                  label="Budget for Team lead"
                  prepend-inner-icon="mdi-currency-usd"
                  hint="Depending on the size and scope of the project, the suggested minimum project fee is at least $500 for Team Leads. Students will work for free. Higher project fees will attract more Team Leads to apply. Please note you only need to pay per project, not per month."
                  suffix=".00"
                ></v-text-field>
              </v-col>
              <!--移除付费20%提示-->
              <!--            <v-col cols="12" class="py-0 -tw-mt-4">-->
              <!--              <p>-->
              <!--                Beeznests will charge 20% on top of the student’s wages and Team-->
              <!--                Lead salary.-->
              <!--              </p>-->
              <!--            </v-col>-->
            </template>
          </v-row>
        </template>
        <v-row v-else>
          <v-col cols="12" class="py-0">
            {{
              data.provide_solution
                ? 'Budget for Team Lead'
                : 'Wage for Team Lead'
            }}
          </v-col>
          <!-- 添加 College 的支持-->

          <template v-if="data.provide_solution">
            <!--v-col cols="12" sm="6" md="4" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="Minimum Budget"
                rules="required"
              >
                <v-text-field
                  v-model="data.cost_min"
                  label="Minimum Budget"
                  prepend-inner-icon="mdi-currency-usd"
                  suffix=".00"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col-->
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" sm="8" class="py-0">
                  <v-text-field
                    v-model="data.cost_min"
                    label="Budget for Team Lead"
                    prepend-inner-icon="mdi-currency-usd"
                    suffix=".00"
                    :error-messages="errors"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!--移除付费20%提示-->
              <!--              <p>-->
              <!--                Beeznests will charge 20% on top of the student’s wages and Team-->
              <!--                Lead salary.-->
              <!--              </p>-->
            </v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" class="mb-0 pb-0">
                  <span>
                    Budget for Project Fee (any additional expenses to carry out
                    a solution for your project) This information will help the
                    Team Lead create a strategy and breakdown costs for the
                    solution proposal. (i.e. project fee may be the total budget
                    for Ad expenses for a marketing project)
                  </span>
                </v-col>
                <v-col cols="12" sm="8" md="5" class="py-0">
                  <v-text-field
                    v-model="data.actual_cost_max"
                    label="Maximum Budget for Project Fee"
                    prepend-inner-icon="mdi-currency-usd"
                    suffix=".00"
                  ></v-text-field>
                </v-col>
                <!--                <v-col cols="12" sm="8" md="4" class="py-0">-->
                <!--                  <v-text-field-->
                <!--                    v-model="data.actual_cost_max"-->
                <!--                    label="Maximum actual project cost"-->
                <!--                    prepend-inner-icon="mdi-currency-usd"-->
                <!--                    suffix=".00"-->
                <!--                    :error-messages="errors"-->
                <!--                  ></v-text-field>-->
                <!--                </v-col>-->
              </v-row>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="data.cost_min"
                label="Wage for Team Lead"
                prepend-inner-icon="mdi-currency-usd"
                suffix=".00"
                :error-messages="errors"
              ></v-text-field>
            </v-col>
            <!--移除付费20%提示-->
            <!--            <v-col cols="12" class="py-0 -tw-mt-4">-->
            <!--              <p>-->
            <!--                Beeznests will charge 20% on top of the student’s wages and Team-->
            <!--                Lead salary.-->
            <!--              </p>-->
            <!--            </v-col>-->
          </template>
        </v-row>
        <!--        <v-row>-->
        <!--          &lt;!&ndash; 学术团队项目 是否申请了政府补助&ndash;&gt;-->
        <!--          <v-col cols="12" sm="6" md="6">-->
        <!--            <div class="tw-text-md mx-2">-->
        <!--              Are you applying for government wage subsidy for this project?-->
        <!--            </div>-->
        <!--            <v-switch-->
        <!--              class="mx-2"-->
        <!--              v-model="data.is_government_subsidy"-->
        <!--              :label="data.is_government_subsidy ? 'Yes' : 'No'"-->
        <!--            ></v-switch>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <!--是否申请政府补助之后的问题-->
        <v-row>
          <v-col
            cols="12"
            sm="10"
            md="8"
            v-if="data.is_government_subsidy && data.provide_solution"
          >
            <v-text-field
              label="Student Wage per hour  (min $15/hr to be eligible for wage subsidy)"
              v-model="data.students_budget_each_hour"
              prepend-inner-icon="mdi-currency-usd"
              suffix=".00/hr"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Number of Students to hire"
              v-model="data.students_count"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Hours required per week (min 10 hr to be eligible for wage subsidy)"
              suffix="/hr/week"
              type="number"
              v-model="data.students_work_time"
            ></v-text-field>
            <v-text-field
              label="Total Student Wage"
              v-model="total_students_budget"
              readonly
              prepend-inner-icon="mdi-currency-usd"
              suffix=".00"
            ></v-text-field>
            <v-text-field
              label="Total Team Lead Wage"
              readonly
              prepend-inner-icon="mdi-currency-usd"
              :value="
                data.cost_min * data.time_line > 0
                  ? data.cost_min * data.time_line
                  : null
              "
              suffix=".00"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="10"
            md="8"
            v-if="data.is_government_subsidy && !data.provide_solution"
          >
            <v-text-field
              label="Student Wage per hour  (min $15/hr to be eligible for wage subsidy)"
              v-model="data.students_budget_each_hour"
              prepend-inner-icon="mdi-currency-usd"
              suffix=".00/hr"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Number of Students to hire"
              v-model="data.students_count"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Hours required per week (min 10 hr to be eligible for wage subsidy)"
              suffix="hr/week"
              type="number"
              v-model="data.students_work_time"
            ></v-text-field>
            <v-text-field
              label="Total Student Wage"
              v-model="total_students_budget"
              readonly
              prepend-inner-icon="mdi-currency-usd"
              suffix=".00"
            ></v-text-field>
            <v-text-field
              label="Total Team Lead Wage"
              suffix=".00"
              type="number"
              prepend-inner-icon="mdi-currency-usd"
              readonly
              persistent-hint
              v-model="total_team_lead_budget"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="!is_project">
        <div class="tw-text-md mx-2">
          After the mentorship program, are you interested in applying for
          external projects as an Academic Team with your mentees?
        </div>
        <v-switch
          class="mx-2"
          v-if="!is_project"
          v-model="data.work_with_academic_team"
          :label="data.work_with_academic_team ? 'Yes' : 'No'"
        ></v-switch>
        <v-text-field
          v-model="data.like_to_receive"
          v-if="data.work_with_academic_team"
          label="What type of projects would you like to receive？"
        ></v-text-field>
      </template>

      <v-switch
        v-model="data.is_online"
        class="mx-2"
        :label="data.is_online ? 'Remote' : 'Onsite'"
      ></v-switch>
      <v-sheet class="py-6" v-if="!is_project">
        <label>About the mentor (BIO):</label>
      </v-sheet>
      <froala v-model="data.about_mentor" v-if="!is_project"></froala>
      <v-sheet class="my-2">
        <label>
          <description-label
            :type="data.type"
            :is-project="is_project"
            :provide-solution="data.provide_solution"
          >
          </description-label>
        </label>
      </v-sheet>
      <froala v-model="data.content"></froala>
      <v-sheet class="my-2" v-if="contentError">
        {{ contentError }}
      </v-sheet>
      <template
        v-if="!(data.type === 'academic-team' && data.provide_solution)"
      >
        <v-sheet class="py-6">
          <label>
            <will-do-label
              :type="data.type"
              :is-project="is_project"
              :provide-solution="data.provide_solution"
            ></will-do-label>
          </label>
        </v-sheet>
        <froala v-model="data.who"></froala>
      </template>

      <v-sheet class="py-6">
        <label>
          <skill-label
            :type="data.type"
            :is-project="is_project"
            :provide-solution="data.provide_solution"
          ></skill-label>
        </label>
      </v-sheet>
      <froala v-model="data.skills"></froala>
      <template v-if="data.type !== 'academic-team' && is_project">
        <v-sheet class="py-6">
          <label>
            <work-with-label
              :type="data.type"
              :is-project="is_project"
              :provide-solution="data.provide_solution"
            ></work-with-label>
          </label>
        </v-sheet>
        <froala v-model="data.work_with"></froala>
      </template>
      <template v-if="!is_project">
        <v-sheet class="py-6">
          <label
            >Rough Agenda (Please type here, or upload a file or link to your
            outline/ Beeznests teaching plan) :</label
          >
        </v-sheet>
        <froala v-model="data.agenda"></froala>
        <v-sheet class="py-6">
          <label>Policy:</label>
        </v-sheet>
        <froala v-model="data.policy"></froala>
      </template>

      <!--项目和实习 新增加问题-->
      <template v-if="is_project && data.type !== 'academic-team'">
        <v-sheet class="py-6">
          <label
            >Why is this experience (Even if it is unpaid) valuable for
            students?
          </label>
        </v-sheet>
        <froala v-model="data.experience"></froala>
        <v-sheet class="py-6">
          <label
            >What key practical skills students will learn from you? (your
            answer will help us better promote your opportunity to our
            students）</label
          >
        </v-sheet>
        <froala v-model="data.practical_skills"></froala>
      </template>
      <!--      <v-row>-->
      <!--        <v-col>-->
      <!--          <v-text-field-->
      <!--            v-model="data.password"-->
      <!--            label="Fill in an invitation code so that students can apply for free"-->
      <!--            prepend-inner-icon="mdi-lock"-->
      <!--            :readonly="isEdit"-->
      <!--            :disabled="isEdit"-->
      <!--          ></v-text-field>-->
      <!--        </v-col>-->
      <v-sheet class="mt-3">
        <v-btn class="mr-4" @click="submit" color="primary" depressed
          >next</v-btn
        >
      </v-sheet>
    </form>
    <v-dialog
      max-width="560"
      v-model="showTypeSelectModal"
      @click:outside="changeCollegeHire(true)"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Please select the option that matches your preference best.
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-card class="mb-4">
            <v-card-text
              >The team lead (industry expert/professor) from our partnering
              university will not only be fully responsible for the quality of
              the project. However, the Team Lead will offer a solution proposal
              for your problem and delegate tasks to the students. Before the
              project begins, a training session will be provided by the team
              lead for students based on the actual skill students may need for
              your project.
            </v-card-text>
          </v-card>
          <template v-for="(item, i) in selectTypeItems">
            <v-card
              :key="`select-${i}`"
              class="mb-4"
              @click="changeCollegeHire(item.value)"
            >
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-text>{{ item.content }}</v-card-text>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>
<script>
// import { create } from '@/api/project'
import { required, email, max, min_value } from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate'
import { fetchAll } from '@/api/category'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('min_month', {
  ...min_value,
  message: '{_field_} must be more than $1000',
})

extend('cost', (value) => {
  if (value > 0 && value <= 99999) {
    return true
  }
  return 'The cost of the project must be more than 10$'
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
import DatePicker from '@/components/DatePicker'
// import Editor from '@/components/Editor'
import Froala from '@/components/Froala'
import moment from 'moment'
import SkillLabel from '@/components/Project/Label/skill'
import WillDoLabel from '@/components/Project/Label/WillDo'
import DescriptionLabel from '@/components/Project/Label/DescriptionLabel'
import WorkWithLabel from '@/components/Project/Label/WorkWithLabel'
import BigNumber from 'bignumber.js'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    Froala,
    SkillLabel,
    WillDoLabel,
    DescriptionLabel,
    WorkWithLabel,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {
          title: '',
          position: '',
          cost_min: 0,
          cost_max: 0,
          is_free: true,
          is_online: true,
          category_id: null,
          closed_at: moment().add(1, 'months').format('YYYY-MM-DD'),
          about_mentor: '',
          content: '',
          who: '',
          work_with: '',
          skills: '',
          time_line: '',
          type: 'task',
          reward: '',
          seats: null,
          password: null,
          agenda: '',
          policy: '',
          total_hours: '',
          course_hours: '',
          experience_hours: '',
          work_with_academic_team: false,
          provide_solution: false,
          like_to_receive: '',
          is_government_subsidy: false,
          students_budget: null,
          students_work_time: null,
          students_count: 0,
          students_budget_each_hour: null,
          team_lead_budget: null,
          team_budget: null,
          actual_cost_min: null,
          actual_cost_max: null,
          international_students_count: null,
          added_international_students: false,
          practical_skills: null,
          experience: null,
        }
      },
    },
  },
  data: () => ({
    categories: [],
    categoryLoading: false,
    contentError: false,
    showTypeSelectModal: false,
    isCollegeHire: false, // 默认为否
    isSelectCollegeHire: false,
    selectTypeItems: [
      {
        title: 'Hiring International students ',
        content:
          'Pay for team lead (professor from our partnering college /industry expert) only, work with as many international students as you want for free.', // The suggested pay for the team lead ranges from $1000-2000/per month',
        value: true,
      },
      // {
      //   title: 'Hiring Canadian Students',
      //   content:
      //     'Hire domestic students who have a Canadian citizenship or PR from our platform. We will help you apply for eligible grants to subsidize their wages（access 50% - 70% wage subsidy for post-secondary hires. Note not all projects and hires are eligible for grants. We will help you confirm based on your final project description and posting. The suggested pay for the team lead ranges from $1000-2000/per month.',
      //   value: false,
      // },
    ],
  }),
  watch: {
    'data.type'(newType, oldType) {
      if (newType === 'academic-team' && oldType !== 'academic-team') {
        this.showTypeSelectModal = true
      }
    },
    isCollegeHire(status) {
      this.showTypeSelectModal = false
      this.isSelectCollegeHire = true // 是否选择过了 学术团队的项目类型
      this.data.is_government_subsidy = !status
    },
  },
  computed: {
    closed_at: {
      get() {
        return moment.utc(this.data.closed_at).format('YYYY-MM-DD')
      },
      set(date) {
        this.data.closed_at = date
      },
    },
    // 是否为普通项目
    is_project() {
      return this.$route.fullPath.indexOf('mentor-program') < 0
    },
    project_type() {
      return this.$route.fullPath.indexOf('mentor-program') > -1
        ? 'mentor-program'
        : 'task'
    },
    total_students_budget() {
      return !isNaN(this.data.students_budget_each_hour) &&
        !isNaN(this.data.students_work_time) &&
        !isNaN(this.data.students_count)
        ? this.data.students_budget_each_hour *
            this.data.students_work_time *
            this.data.students_count *
            this.data.time_line *
            4
        : null
    },
    total_team_lead_budget() {
      return this.data.cost_min !== null && this.data.cost_min > 0
        ? this.data.cost_min // * this.data.time_line
        : null
    },
  },
  mounted() {
    this.$set(this.data, 'costs', [this.data.cost_min, this.data.cost_max])
    // this.closed_at = this.data.closed_at
    this.loadCategories()
  },
  methods: {
    async loadCategories() {
      try {
        this.categoryLoading = 'info'
        const { data } = await fetchAll({})
        this.categories = data
      } catch (e) {
        console.log(e)
      } finally {
        this.categoryLoading = false
      }
    },
    async submit() {
      const isInvalid = await this.$refs.observer.validate()
      if (this.data.content) {
        this.contentError = false
      }
      if (isInvalid && this.data.content) {
        const data = {
          title: this.data.title,
          position: this.data.position,
          category_id: this.data.category_id,
          cost_min:
            this.data.type === 'academic-team'
              ? this.data.cost_min
              : this.project_type === 'task' && this.data.is_free
              ? 0
              : this.data.cost_min || 0,
          cost_max:
            this.data.type === 'academic-team' && this.data.provide_solution
              ? this.data.cost_max
              : 0,
          closed_at: this.data.closed_at,
          is_online: this.data.is_online,
          about_mentor: this.data.about_mentor,
          content: this.data.content,
          who: this.data.who,
          work_with: this.data.work_with,
          skills: this.data.skills,
          time_line: this.data.time_line,
          type: this.is_project ? this.data.type : 'mentor-program',
          reward: this.data.is_free ? null : this.data.reward,
          seats: this.data.seats ? this.data.seats : 0,
          password: this.data.password,
          agenda: this.data.agenda,
          policy: this.data.policy,
          total_hours: this.data.total_hours,
          course_hours: this.data.course_hours,
          experience_hours: this.data.experience_hours,
          work_with_academic_team: this.data.work_with_academic_team,
          provide_solution: this.data.provide_solution,
          like_to_receive: this.data.like_to_receive,
          is_government_subsidy: this.data.is_government_subsidy,
          students_budget:
            this.total_students_budget === null
              ? 0
              : this.total_students_budget,
          students_work_time: this.data.students_work_time,
          students_count: this.data.students_count,
          students_budget_each_hour: this.data.students_budget_each_hour,
          team_lead_budget: this.data.team_lead_budget,
          team_budget: this.data.team_budget,
          actual_cost_min: this.data.actual_cost_min,
          actual_cost_max: this.data.actual_cost_max,
          international_students_count: this.data.international_students_count,
          added_international_students: this.data.added_international_students,
          experience: this.data.experience,
          practical_skills: this.data.practical_skills,
        }
        // 如果是
        if (
          data.type === 'academic-team' &&
          data.is_government_subsidy &&
          !data.provide_solution &&
          this.total_team_lead_budget < 0
        ) {
          this.$message(
            'The academic team budget must be greater than total student wage',
            'error'
          )
          return
        }
        if (
          data.type === 'academic-team' &&
          data.is_government_subsidy &&
          !data.provide_solution
        ) {
          data.team_lead_budget = this.total_team_lead_budget
        }
        // const { data } = await create(d)
        this.$emit('next', data)
      } else if (!this.data.content) {
        this.contentError = 'The project description can not be empty!'
      }
    },
    daysToFormat(value) {
      if (value >= 365) {
        return '1 Year'
      } else if (value === 180) {
        return '半年'
      } else if (value > 30 && value % 30 === 0) {
        return parseFloat(value / 30) + ' Months'
      } else if (value < 30 && value % 7 === 0) {
        return parseFloat(value / 7) + ' Weeks'
      } else {
        return value + 'Days'
      }
    },
    changeCollegeHire(status) {
      this.isCollegeHire = status
      this.data.is_government_subsidy = !status
      this.showTypeSelectModal = false
      this.isSelectCollegeHire = true
    },
  },
}
</script>
