<template>
  <v-card>
    <v-card-subtitle>
      <v-list-item class="pl-0">
        <router-link
          :to="{ name: 'userShow', params: { id: data.user_id } }"
          style="text-decoration: none;"
        >
          <v-list-item-avatar>
            <v-img class="elevation-6" :src="data.user.avatar"></v-img>
          </v-list-item-avatar>
        </router-link>
        <v-list-item-content>
          <v-list-item-title>
            <router-link
              :to="{ name: 'projectShow', params: { id: data.id } }"
              style="text-decoration: none;"
              >{{ data.title }}</router-link
            >
          </v-list-item-title>
        </v-list-item-content>
        <v-row align="center" justify="end">
          <strong
            v-if="
              isLoggedIn &&
              (userInfo.id === data.user.id || userInfo.id === receiver.user.id)
            "
            class="orange--text title mr-2"
            >${{ receiver.cost }}</strong
          >
          <v-chip class="ma-2" color="deep-orange" text-color="white">
            {{ receiver.status | Up }}
          </v-chip>
          <v-btn
            color="primary"
            v-if="
              (data.commission_type === 'normal' ||
                data.commission_type === 'percentage') &&
              data.is_own &&
              data.status === 'applying'
            "
            @click="checkout(receiver.id, receiver.cost)"
            >Checkout</v-btn
          >
          <v-btn
            v-if="
              isLoggedIn &&
              data.status === 'processing' &&
              receiver.status === 'processing' &&
              receiver.user.id === userInfo.id
            "
            color="primary"
            @click="complete(receiver.id)"
            >Complete</v-btn
          >
          <v-btn
            v-if="data.is_own && data.status === 'completed'"
            @click="finished"
            >Finished</v-btn
          >
          <v-btn
            v-if="
              isLoggedIn &&
              data.status === 'finished' &&
              receiver.user.id === userInfo.id
            "
            @click="review"
            >Review</v-btn
          >
        </v-row>
      </v-list-item>
    </v-card-subtitle>
    <v-card-text
      v-if="
        receiver.answer &&
        isLoggedIn &&
        (receiver.user.id === userInfo.id || data.is_own)
      "
    >
      <div
        v-for="(q, index) in receiver.answer"
        :key="`receiver-answer-${index}`"
      >
        <v-card-title>Q{{ ++index }}.{{ q.question }}</v-card-title>
        <v-card-text>{{ q.content }}</v-card-text>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          user: {},
        }
      },
    },
    receiver: {
      type: Object,
      default() {
        return {
          user: {},
        }
      },
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'isLoggedIn']),
  },
}
</script>
