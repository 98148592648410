var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-subtitle',[_c('v-list-item',{staticClass:"pl-0"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'userShow', params: { id: _vm.data.user_id } }}},[_c('v-list-item-avatar',[_c('v-img',{staticClass:"elevation-6",attrs:{"src":_vm.data.user.avatar}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'projectShow', params: { id: _vm.data.id } }}},[_vm._v(_vm._s(_vm.data.title))])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"end"}},[(
            _vm.isLoggedIn &&
            (_vm.userInfo.id === _vm.data.user.id || _vm.userInfo.id === _vm.receiver.user.id)
          )?_c('strong',{staticClass:"orange--text title mr-2"},[_vm._v("$"+_vm._s(_vm.receiver.cost))]):_vm._e(),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"deep-orange","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("Up")(_vm.receiver.status))+" ")]),(
            (_vm.data.commission_type === 'normal' ||
              _vm.data.commission_type === 'percentage') &&
            _vm.data.is_own &&
            _vm.data.status === 'applying'
          )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.checkout(_vm.receiver.id, _vm.receiver.cost)}}},[_vm._v("Checkout")]):_vm._e(),(
            _vm.isLoggedIn &&
            _vm.data.status === 'processing' &&
            _vm.receiver.status === 'processing' &&
            _vm.receiver.user.id === _vm.userInfo.id
          )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.complete(_vm.receiver.id)}}},[_vm._v("Complete")]):_vm._e(),(_vm.data.is_own && _vm.data.status === 'completed')?_c('v-btn',{on:{"click":_vm.finished}},[_vm._v("Finished")]):_vm._e(),(
            _vm.isLoggedIn &&
            _vm.data.status === 'finished' &&
            _vm.receiver.user.id === _vm.userInfo.id
          )?_c('v-btn',{on:{"click":_vm.review}},[_vm._v("Review")]):_vm._e()],1)],1)],1),(
      _vm.receiver.answer &&
      _vm.isLoggedIn &&
      (_vm.receiver.user.id === _vm.userInfo.id || _vm.data.is_own)
    )?_c('v-card-text',_vm._l((_vm.receiver.answer),function(q,index){return _c('div',{key:`receiver-answer-${index}`},[_c('v-card-title',[_vm._v("Q"+_vm._s(++index)+"."+_vm._s(q.question))]),_c('v-card-text',[_vm._v(_vm._s(q.content))])],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }