import axios from '@/plugins/axios'

export const likable = {
  reply: 'reply',
}

export const like = (id, type) => {
  return axios.post('like', {
    id,
    type,
  })
}

export const unlike = (id, type) => {
  return axios.post('like/delete', {
    id,
    type,
  })
}
