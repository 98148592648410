// import store from '@/store'
export default {
  path: '/hires',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '',
      name: 'hireIndex',
      component: () => import(/* webpackChunkName: 'hires' */'@/views/hires/index'),
      meta: {
        keepAlive: true,
      },
    },
  ],
}
