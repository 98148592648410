export default {
  path: '/',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '/mobile',
      name: 'MobileIndex',
      component: () => import(/* webpackChunkName: 'index' */'@/views/Index'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'membership',
      name: 'MemberShip',
      component: () => import(/* webpackChunkName: 'index' */'@/views/membership/company'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'sponsorship',
      name: 'Sponsorship',
      component: () => import(/* webpackChunkName: 'index' */'@/views/membership/businesses'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: 'membership-college',
      name: 'MembersPackageForCollege',
      component: () =>
        import(
          /* webpackChunkName "membership" */ '@/views/membership/college'
        ),
    },
  ],
}
