var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-2 fill-height"},[(_vm.noData || _vm.loadError)?[_c('v-card-text',{staticClass:"d-flex flex-column justify-center flex-grow-1 fill-height"},[(_vm.noData)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"warning"}},[_vm._v("No new notifications")]):_vm._e(),(_vm.loadError)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"warning"}},[_vm._v("Network Error!")]):_vm._e()],1)]:(_vm.loading)?[_c('loading',{attrs:{"loading":_vm.loading}})]:_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.notifications),function(item,index){return [(item.type === 'App\\Notifications\\RepliedNotification')?_c('notification-replied',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(
          item.type ===
          'App\\Notifications\\ProjectHasNewReceiverNotification'
        )?_c('notification-project-applied',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(item.type === 'App\\Notifications\\ReceiveSelectedNotification')?_c('notification-receive-selected',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(item.type === 'App\\Notifications\\CompleteProjectNotification')?_c('notification-complete-project',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(item.type === 'App\\Notifications\\FinishedProjectNotification')?_c('notification-finished-project',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(item.type === 'App\\Notifications\\ReviewedReceiveNotification')?_c('notification-reviewed',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(item.type === 'App\\Notifications\\FollowedNotification')?_c('notification-followed',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(
          item.type ===
          'App\\Notifications\\ChallengeReplyChangeBestNotification'
        )?_c('notification-challenge-best-replied',{key:`notification-${index}`,attrs:{"item":item}}):_vm._e(),(index < _vm.notifications.length - 1)?_c('v-divider',{key:`divider-${index}`}):_vm._e()]})],2),(!_vm.loading && !_vm.noData && _vm.pages.last_page > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.pages.last_page},model:{value:(_vm.pages.current_page),callback:function ($$v) {_vm.$set(_vm.pages, "current_page", $$v)},expression:"pages.current_page"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }