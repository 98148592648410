<template>
  <span>{{ content }}</span>
</template>
<script>
export default {
  props: {
    //  区分项目还是 mentor
    isProject: {
      type: Boolean,
      default: true,
    },
    // 是否需要提供解决方案
    provideSolution: {
      type: Boolean,
      default: false,
    },
    // 项目具体类型
    type: {
      type: String,
      default: 'task',
    },
  },
  computed: {
    content() {
      return this.isProject && this.type === 'academic-team'
        ? 'What the academic team will do'
        : this.isProject
        ? 'What you will do'
        : 'Learning objectives for the course'
    },
  },
}
</script>
