<template>
  <v-card class="mt-2" elevation="2">
    <v-list-item>
      <v-list-item-avatar size="48">
        <router-link
          :to="{
            name: 'userShow',
            params: { id: challenge.user.id },
          }"
          style="text-decoration: none;"
        >
          <v-avatar>
            <v-img :src="challenge.user.avatar" />
          </v-avatar>
        </router-link>
      </v-list-item-avatar>
      <v-list-item-content>
        <user-name
          :name="challenge.user.name"
          :type="challenge.user.type"
          :id="challenge.user.id"
          :organization="challenge.user.organization"
        >
          <template v-slot:before
            ><v-img
              :src="challenge.user.country_flag"
              max-height="24"
              max-width="24"
              contain
              class="align-self-center mr-1"
            ></v-img
          ></template>
        </user-name>
        <v-list-item-subtitle>
          <v-chip x-small color="info">{{
            challenge.user.type | userType
          }}</v-chip>
          <v-chip label x-small class="ml-1" color="info"
            ><v-icon x-small left>mdi-map-marker</v-icon
            >{{ challenge.user.country.name }}</v-chip
          >
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ challenge.created_at | diffForHumans }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      {{ challenge.title }}
    </v-card-text>
    <v-card-actions>
      <v-tooltip bottom v-if="challenge.has_meeting">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="mr-3">mdi-alarm-check</v-icon>
        </template>
        <span> Webinar Date: {{ challenge.meeting_at | format }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="challenge.closed_at">
        <template v-slot:activator="{ on, attrs }">
          <v-chip label v-bind="attrs" v-on="on">
            <v-icon left>mdi-lock-clock</v-icon>
            {{ challenge.closed_at | format }}
          </v-chip>
        </template>
        <span>Deadline {{ challenge.closed_at | format }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn text color="orange accent-4" :to="`/challenges/${challenge.id}`">
        View More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import UserName from '@/components/User/Name'
export default {
  components: {
    UserName,
  },
  props: {
    challenge: {
      type: Object,
      default() {
        return {
          user: {},
        }
      },
    },
  },
}
</script>
