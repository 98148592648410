<template>
  <v-card>
    <v-list-item>
      <v-list-item-avatar size="48">
        <router-link
          :to="{
            name: 'userIndex',
            params: { id: data.user.id },
          }"
        >
          <v-avatar>
            <v-img class="elevation-6" :src="data.user.avatar"></v-img>
          </v-avatar>
        </router-link>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="d-flex">
          <v-img
            :src="data.user.country_flag"
            max-height="24"
            max-width="24"
            contain
            class="align-self-center mr-1"
          ></v-img>
          <user-name
            :name="data.user.name"
            :type="data.user.type"
            :id="data.user.id"
            :organization="data.user.organization"
          ></user-name>
          <div class="d-flex">
            <v-icon color="orange">mdi-star</v-icon>
            <span
              class="align-self-center orange--text font-weight-bold text-h6"
              >{{ data.rate }}</span
            >
          </div>
        </div>
        <v-list-item-subtitle
          >Published {{ data.created_at | diffForHumans }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <div class="pa-0">
        <router-link
          :to="{
            name: 'projectShow',
            params: { id: data.reviewable.project_id },
          }"
          style="text-decoration: none;"
          >{{ data.reviewable.project.title }}</router-link
        >
      </div>
      {{ data.content }}
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          user: {},
        }
      },
    },
  },
}
</script>
