export default {
  path: '/links',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: '',
      name: 'linkIndex',
      component: () => import(/* webpackChunkName: 'link' */'@/views/links/index'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: 'show/:slug',
      name: 'linkShow',
      component: () => import(/* webpackChunkName: 'link' */'@/views/links/show'),
      meta: {
        fill: true,
      },
    },
  ],
}
