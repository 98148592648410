<template>
  <v-sheet v-if="!isLoggedIn" color="transparent">
    <template v-if="!isMobile">
      <v-btn text to="/auth/login">Login</v-btn>
      <v-btn text to="/auth/register">Register</v-btn>
    </template>
    <template v-else>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Sign In/Up
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn text to="/auth/login">Login</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text to="/auth/register">Register</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-sheet>
  <v-sheet v-else color="transparent">
    <v-badge
      :value="userInfo.unread_notifications_count"
      :content="userInfo.unread_notifications_count"
      color="deep-orange"
      overlap
      small
      class="mr-3"
    >
      <v-icon @click="$router.push({ name: 'notificationUnread' })"
        >mdi-bell-outline</v-icon
      >
    </v-badge>
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab text small v-bind="attrs" v-on="on">
          <v-avatar size="32">
            <v-img :src="userInfo.avatar" />
          </v-avatar>
        </v-btn>
      </template>
      <!--v-list>
        <v-list-item
          @click="
            $router.push({ name: 'userShow', params: { id: userInfo.id } })
          "
        >
          <v-list-item-title> My Profile </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push({ name: 'userSettings' })">
          <v-list-item-title> Edit Profile </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push({ name: 'portfolioCreate' })">
          <v-list-item-title> Add Portfolio </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({ name: 'userProjects', params: { id: userInfo.id } })
          "
        >
          <v-list-item-title> Posted Projects</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'userChallenges',
              params: { id: userInfo.id },
            })
          "
        >
          <v-list-item-title> Posted Discussions </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'userReceives',
              params: { id: userInfo.id },
            })
          "
        >
          <v-list-item-title> Received Projects </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'userReplies',
              params: { id: userInfo.id },
            })
          "
        >
          <v-list-item-title> My Replies </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              name: 'userReviews',
              params: { id: userInfo.id },
            })
          "
        >
          <v-list-item-title> Performance Review </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="$router.push({ name: 'organizationIndex' })"
          v-if="userInfo.is_admin"
        >
          <v-list-item-title> Organization Manage </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="$router.push({ name: 'linkIndex' })"
          v-if="userInfo.is_admin"
        >
          <v-list-item-title> Links Manage </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="$router.push({ name: 'promotionIndex' })"
          v-if="userInfo.is_admin"
        >
          <v-list-item-title> Promotions Manage </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push({ name: 'userPassword' })">
          <v-list-item-title> Change Password </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title> Logout </v-list-item-title>
        </v-list-item>
      </v-list-->
      <div class="tw-rounded-md tw-shadow-xs tw-py-1 tw-bg-white">
        <template v-for="(item, i) in userMenus">
          <div
            class="tw-block tw-px-4 tw-py-2 tw-text-xs tw-text-gray-400"
            :key="`user-menu-title-${i}`"
            v-if="i === 0"
          >
            Profile Manage
          </div>
          <div
            class="tw-block tw-px-4 tw-py-2 tw-text-xs tw-text-gray-400"
            :key="`user-menu-title-${i}`"
            v-if="i === 3"
          >
            Published
          </div>
          <div
            class="tw-block tw-px-4 tw-py-2 tw-text-xs tw-text-gray-400"
            :key="`user-menu-title-${i}`"
            v-if="i === 8"
          >
            Other
          </div>
          <router-link
            :key="`user-menu-${i}`"
            v-if="item.route && (!item.isAdmin || userInfo.is_admin)"
            :to="item.route"
            class="tw-block tw-my-2 tw-px-4 tw-py-2 tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-bg-gray-100 tw-transition tw-duration-150 tw-ease-in-out"
            >{{ item.name }}</router-link
          >
          <template v-else-if="item.name === 'Logout'">
            <div
              class="tw-border-t tw-border-gray-100"
              :key="`user-border-${i}`"
            ></div>
            <span
              :key="`user-menu-${i}`"
              class="tw-cursor-pointer tw-block tw-px-4 tw-py-2 tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-bg-gray-100 tw-transition tw-duration-150 tw-ease-in-out"
              @click="logout"
              >Logout</span
            >
          </template>
        </template>
      </div>
    </v-menu>
  </v-sheet>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'userInfo']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    userMenus() {
      const user_id = this.isLoggedIn ? this.userInfo.id : 0
      return [
        {
          name: 'My Profile',
          route: {
            name: 'NewProfile',
          },
        },
        {
          name: 'Edit Profile',
          route: {
            name: 'userSettings',
          },
        },
        {
          name: 'Add Portfolio',
          route: {
            name: 'portfolioCreate',
          },
        },
        {
          name: 'Posted Projects',
          route: {
            name: 'userProjects',
            params: {
              id: user_id,
            },
          },
        },
        // {
        //   name: 'Posted Discussions',
        //   route: {
        //     name: 'userChallenges',
        //     params: { id: user_id },
        //   },
        // },
        {
          name: 'Received Projects',
          route: {
            name: 'userReceives',
            params: { id: user_id },
          },
        },
        {
          name: 'My Replies',
          route: {
            name: 'userReplies',
            params: { id: user_id },
          },
        },
        {
          name: 'Performance Review',
          route: {
            name: 'userReviews',
            params: { id: user_id },
          },
        },
        {
          name: 'Organization Manage',
          route: {
            name: 'organizationIndex',
          },
          isAdmin: true,
        },
        {
          name: 'Links Manage',
          route: {
            name: 'linkIndex',
          },
          isAdmin: true,
        },
        {
          name: 'Promotions Manage',
          route: {
            name: 'promotionIndex',
          },
          isAdmin: true,
        },
        {
          name: 'Change Password',
          route: {
            name: 'userPassword',
          },
        },
        {
          name: 'Logout',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>
