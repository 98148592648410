<template>
  <v-card outlined flat>
    <v-toolbar flat>
      <v-toolbar-title>Skills</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-sheet class="py-1">
        <v-chip
          v-for="category in userCategories"
          :key="`user-category-${category.id}`"
          class="ma-2"
          @click="change(category)"
          >{{ category.name }}</v-chip
        >
      </v-sheet>
      <v-sheet class="px-3" v-if="!showAllCategory">
        <v-btn depressed outlined color="deep-orange accent-2" @click="showAdd"
          ><v-icon dark>mdi-plus</v-icon>Add Skill</v-btn
        >
      </v-sheet>
    </v-card-text>
    <v-card-actions v-if="showAllCategory">
      <v-spacer></v-spacer>
      <v-btn text @click="showAllCategory = !showAllCategory">cancel</v-btn>
      <v-btn
        @click="save"
        :loading="categoryLoading"
        depressed
        color="amber darken-4"
        class="white--text"
        >save</v-btn
      >
    </v-card-actions>
    <v-expand-transition>
      <v-sheet v-show="showAllCategory" class="pa-5">
        <v-sheet v-if="categories.length > 0">
          <template v-for="category in categories">
            <v-toolbar-title :key="`category-${category.id}`">
              {{ category.name }}
            </v-toolbar-title>
            <v-chip
              v-for="child in category.children"
              :key="`category--${child.id}`"
              class="ma-1"
              @click="change(child)"
              >{{ child.name }}</v-chip
            >
          </template>
        </v-sheet>
        <v-sheet v-else-if="loading">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-sheet>
      </v-sheet>
    </v-expand-transition>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { categories as settingCategories } from '@/api/setting'
import { getCategories } from '@/api/user'
import _ from 'lodash'
export default {
  computed: {
    ...mapState({
      loading: (state) => state.category.loading,
    }),
  },
  async mounted() {
    await this.getCategories()
    await this.loadUserCategories()
    this.oldCategories = _.clone(this.userCategories)
    this.categories = this.diffCategories()
  },
  data: () => ({
    showAllCategory: false,
    oldCategories: [],
    userCategories: [],
    categories: [],
    categoryLoading: false,
  }),
  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
    }),
    async loadUserCategories() {
      const { data } = await getCategories()
      this.userCategories = data
    },
    showAdd() {
      this.showAllCategory = !this.showAllCategory
    },
    async save() {
      const userCategories = _.orderBy(this.category_id(this.userCategories))
      const oldCategories = _.orderBy(this.category_id(this.oldCategories))
      if (userCategories.toString() !== oldCategories.toString()) {
        this.categoryLoading = true
        await settingCategories(userCategories)
        this.categoryLoading = false
      }
      this.showAllCategory = false
      this.$store.dispatch('auth/isLogged')
    },
    change(category) {
      const _index = this.userCategories.findIndex(
        (item) => item.id === category.id
      )
      if (_index > -1) {
        this.userCategories.splice(_index, 1)
      } else {
        this.userCategories.push(category)
      }
      this.categories = this.diffCategories()
    },
    diffCategories() {
      const data = _.cloneDeep(this.$store.state.category.data)
      return data
        .map((item) => {
          if (item.children.length > 0) {
            item.children = item.children
              .map((i) => {
                return this.userCategories.findIndex(
                  (cate) => cate.id === i.id
                ) > -1
                  ? null
                  : i
              })
              .filter((i) => i)
            return item
          } else {
            return null
          }
        })
        .filter((i) => i)
    },
    category_id(categories) {
      categories = _.cloneDeep(categories)
      return categories.map((item) => {
        if (_.isObject(item)) return item.id
        return item
      })
    },
  },
}
</script>
