import ls from '@/plugins/ls'
const state = {
  edit: null,
  saveLoading: false,
  redirectTo: ls.get('setting_first_redirect_to', {}),
}

const mutations = {
  SET(state, field) {
    state.edit = field
  },
  LOADING(state, status) {
    state.loading = status
  },
  SET_REDIRECT_TO(state, route) {
    console.log('更新设置后的跳转地址', route)
    ls.set('setting_first_redirect_to', route)
    state.redirectTo = route
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
