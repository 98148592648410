var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-subtitle',[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-avatar',{attrs:{"size":"48"}},[_c('router-link',{attrs:{"to":{
                name: 'userIndex',
                params: { id: _vm.receiver.user.id },
              }}},[_c('v-avatar',[_c('v-img',{staticClass:"elevation-6",attrs:{"src":_vm.receiver.user.avatar}})],1)],1)],1),_c('v-list-item-content',{staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticClass:"align-self-center mr-1",attrs:{"src":_vm.receiver.user.country_flag,"max-height":"24","max-width":"24","contain":""}}),_c('user-name',{attrs:{"name":_vm.receiver.user.name,"type":_vm.receiver.user.type,"id":_vm.receiver.user.id,"organization":_vm.receiver.user.organization}})],1),_c('div',{staticClass:"d-flex"},[_c('v-chip',{attrs:{"x-small":"","color":"warning"}},[_vm._v(_vm._s(parseFloat(_vm.receiver.user.counts.rate).toFixed(1)))]),_c('v-rating',{attrs:{"background-color":"orange lighten-3","color":"orange","size":16,"disabled":"","dense":"","value":parseFloat(_vm.receiver.user.counts.rate)}}),_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(_vm.receiver.user.counts.rate_count)+" reviews)")])],1)]),_c('div',{staticClass:"align-center justify-end hidden-xs-only"},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"deep-orange","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("Up")(_vm.receiver.status))+" ")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"align-center justify-end hidden-sm-and-up"},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"deep-orange","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("Up")(_vm.receiver.status))+" ")])],1)]),(
          _vm.isLoggedIn &&
          (_vm.receiver.user.id === _vm.userInfo.id || _vm.isProjectPublisher)
        )?_c('v-card-text',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","label":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.receiver.user.email)+" ")],1),(_vm.receiver.user.identityable.linkedin)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","label":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-linkedin")]),_c('a',{attrs:{"href":_vm.receiver.user.identityable.linkedin}},[_vm._v(_vm._s(_vm.receiver.user.identityable.linkedin))])],1):_vm._e(),(_vm.receiver.user.counts.portfolio > 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","label":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder-image")]),_c('router-link',{attrs:{"to":{ name: 'userPortfolios', params: { id: _vm.receiver.user.id } }}},[_vm._v(" Portfolios ")])],1):_vm._e(),_vm._l((_vm.receiver.answer),function(q,index){return _c('div',{key:`receiver-answer-${index}`},[_c('v-card-text',[_vm._v("Q"+_vm._s(++index)+"."+_vm._s(q.question))]),_c('v-card-text',[_vm._v(_vm._s(q.content))])],1)}),(_vm.isProjectPublisher || _vm.isCreate)?_c('show-cost',{attrs:{"project":_vm.project,"receiver":_vm.receiver}}):_vm._e()],2):_vm._e(),(_vm.isProjectPublisher || _vm.isCreate)?[_c('v-divider'),_c('v-card-actions',[(_vm.project.type !== 'academic-team')?_c('strong',{staticClass:"orange--text tw-text-lg"},[_vm._v("$"+_vm._s(parseInt(_vm.receiver.cost ? _vm.receiver.cost : 0)))]):_vm._e(),_c('v-spacer'),((_vm.isProjectPublisher || _vm.isCreate) && _vm.receiver.file)?_c('v-btn',{staticClass:"align-self-center",attrs:{"depressed":"","href":_vm.receiver.file,"target":"_blank"}},[_vm._v(" View Proposal ")]):_vm._e(),(
              (_vm.isProjectPublisher || _vm.isCreate) && _vm.receiver.user.resume_file
            )?_c('v-btn',{staticClass:"align-self-center",attrs:{"depressed":"","href":_vm.receiver.user.resume_file,"target":"_blank"}},[_vm._v(" View Resume ")]):_vm._e(),(
              _vm.isProjectPublisher &&
              _vm.receiver.status === 'finished' &&
              _vm.receiver.has_send_recommend
            )?_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$routerTo(_vm.receiver.has_send_recommend)}}},[_vm._v("View reference letter")]):_vm._e(),(
              _vm.receiver.status === 'applied' &&
              _vm.isProjectPublisher &&
              _vm.project.status === 'applying'
            )?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.checkout(_vm.receiver.id, _vm.receiver.cost)}}},[_vm._v("Hire")]):_vm._e(),(
              _vm.isCreate &&
              _vm.project.type === 'academic-team' &&
              _vm.receiver.status === 'processing'
            )?_c('v-btn',{attrs:{"color":"primary","depressed":"","to":{
              name: 'TeamLeadPayment',
              params: { receive_id: _vm.receiver.id },
            }}},[_vm._v("Access Student Database")]):_vm._e(),(_vm.isCreate && _vm.receiver.status === 'processing')?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.complete(_vm.receiver.id)}}},[_vm._v("Complete")]):_vm._e(),(
              _vm.isProjectPublisher &&
              (_vm.receiver.status === 'completed' ||
                _vm.receiver.status === 'processing')
            )?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.finished}},[_vm._v("Finish")]):_vm._e(),(
              _vm.isProjectPublisher &&
              (_vm.receiver.status === 'finished' ||
                _vm.receiver.status === 'processing')
            )?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.review}},[_vm._v("Review")]):_vm._e(),(
              _vm.isProjectPublisher &&
              _vm.receiver.status === 'finished' &&
              !_vm.receiver.has_send_recommend
            )?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'receiveRecommendation',
                params: { id: _vm.project.id, receive_id: _vm.receiver.id },
              })}}},[_vm._v("Send reference letter")]):_vm._e()],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }