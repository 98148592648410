<template>
  <v-row justify="center">
    <v-col md="4" lg="6">
      <v-card class="pa-4" v-if="checkOrderLoading">
        <v-toolbar-title class="text-center">{{
          orderException ? 'Error' : 'Check Order'
        }}</v-toolbar-title>
        <v-card-text class="d-flex justify-center flex-column">
          <div class="align-self-center" v-if="payInfo.is_paid">
            The order has been paid! Please back and try again.
            <v-btn depressed @click="$router.go(-1)">back</v-btn>
          </div>
          <div class="align-self-center" v-else-if="!orderException">
            <v-progress-circular
              :width="3"
              color="red"
              indeterminate
            ></v-progress-circular>
          </div>
          <template v-else>
            <div class="red--text text-center">
              The order info is exception! Please back and try again.
            </div>
            <div class="align-self-center mt-4">
              <v-btn depressed outlined @click="$router.go(-1)">back</v-btn>
            </div>
          </template>
        </v-card-text>
      </v-card>
      <v-card class="pa-4" v-else-if="paidSuccess">
        <v-toolbar-title class="text-center"
          >Deposit Successful</v-toolbar-title
        >
        <v-card-text class="d-flex justify-center flex-column">
          <v-icon size="36" color="green">mdi-check</v-icon>
          <div class="text-center">
            <v-btn
              depressed
              :to="{ name: 'projectShow', params: { id: projectId } }"
              >Back</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
      <v-card class="pa-4" v-else>
        <v-toolbar-title>Payment Method</v-toolbar-title>
        <v-card-text class="mt-2"> ${{ payInfo.amount }} </v-card-text>
        <div id="card-element" class="mt-4"></div>
        <v-alert v-if="cardError" class="mt-2">
          {{ cardError }}
        </v-alert>
        <v-card-actions class="mt-6">
          <v-btn
            depressed
            color="deep-orange"
            class="white--text"
            @click="pay"
            :loading="paidLoading"
            >Pay</v-btn
          >
          <v-btn depressed @click="$router.go(-1)">back</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { loadStripe } from '@stripe/stripe-js'
import { fetchOrderInfo, payment } from '@/api/pay'
import { mapGetters } from 'vuex'
import { apply as applyProject, checkApplyPassword } from '@/api/project'
export default {
  name: 'Home',
  data: () => ({
    stripe: null,
    cardElement: null,
    cardError: null,
    checkOrderLoading: false,
    orderException: false,
    payInfo: {},
    paidSuccess: false,
    paidLoading: false,
    projectId: 0,
    projectData: {
      questions: [],
    },
  }),
  computed: {
    no() {
      return this.$route.params.no || 0
    },
    ...mapGetters(['userInfo']),
  },
  async created() {
    this.checkOrderLoading = true
    try {
      const { data } = await fetchOrderInfo(this.$route.params.no)
      this.payInfo = data
      if (data.is_paid) return
      this.checkOrderLoading = false
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
      console.log(this.stripe, 'stripe')
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
      const elements = this.stripe.elements()
      const cardElement = elements.create('card', { style })

      cardElement.mount('#card-element')
      var _self = this
      cardElement.addEventListener('change', (event) => {
        if (event.error) {
          _self.cardError = event.error.message
        } else {
          _self.cardError = ''
        }
      })
      this.cardElement = cardElement
    } catch (e) {
      console.log(e)
      this.orderException = true
    }
  },
  methods: {
    async pay() {
      this.paidLoading = true
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
        'card',
        this.cardElement,
        {
          billing_details: {
            name: this.userInfo.name,
            email: this.userInfo.email,
          },
          metadata: { order_no: this.no },
        }
      )

      if (error) {
        this.cardError = error.message
        // 错误信息
      } else {
        // 向服务端传送 token 进行支付。
        try {
          const data = await payment(paymentMethod.id, { no: this.no })
          this.paidLoading = false
          if (data.error <= 0) {
            this.paidSuccess = true
            this.projectId = data.data.project.id
            // 如果是团队订单
            console.log('是否为团队订单', this.payInfo)
            if (this.payInfo.type === 'apply_project') {
              this.projectData = data.data.project
              await this.apply()
            }
            if (this.payInfo.type === 'user_cost') {
              this.$router.push('/')
              return
            }
            if (this.payInfo.type === 'job') {
              this.$router.push({
                name: 'jobsShow',
                params: { id: data.data.project.id },
              })
            }
          } else {
            this.cardError = data.message
          }
        } catch (e) {
          this.paidLoading = false
          if (e.response && e.response.data && e.response.data.message) {
            this.cardError = e.response.data.message
          }
        }
      }
    },
    async loadPayInfo() {
      const info = await fetchOrderInfo(this.$route.params.no)
      console.log(info)
    },
    async apply() {
      //
      if (this.projectData.questions.length <= 0) {
        await this.handleApplyProject(this.projectData.cost_min)
      } else {
        this.$store.commit('project/SET', this.projectData)
        this.$router.push({
          name: 'projectApply',
          params: { id: this.projectData.id },
        })
      }
    },
    async handleApplyProject() {
      try {
        const res = await applyProject(this.projectData.id, {
          cost: this.projectData.cost_min,
        })
        if (res.message) {
          this.$store.dispatch('message/pushMessage', {
            type: 'info',
            content: res.message,
            timeout: 3000,
          })
        }
        this.$router.push({
          name: 'projectShow',
          params: { id: this.projectData.id },
        })
      } catch (e) {
        this.$router.push({
          name: 'projectShow',
          params: { id: this.projectData.id },
        })
      }
    },
  },
}
</script>
<style lang="css">
.StripeElement {
  box-sizing: border-box;
  height: 3.0769rem;
  padding: 0.7692rem 0.9231rem;
  border: 0.0769rem solid transparent;
  border-radius: 0.3077rem;
  background-color: white;
  box-shadow: 0 0.0769rem 0.2308rem 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 0.0769rem 0.2308rem 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
