var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"center"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'userShow',
          params: { id: _vm.item.id },
        }}},[_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.item.avatar}})],1)],1)],1)],1),_c('v-card-title',{staticClass:"justify-center pt-0"},[_c('span',{staticClass:"font-weight-light text-center"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'userShow',
          params: { id: _vm.item.id },
        }}},[_vm._v(_vm._s(_vm._f("noName")(_vm.item.name)))])],1)]),_c('v-card-text',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"center"}},[_c('v-rating',{attrs:{"value":parseFloat(_vm.item.counts.rate),"color":"amber","dense":"","half-increments":"","readonly":"","size":"22"}}),_c('div',{staticClass:"grey--text ml-4"},[_vm._v(" "+_vm._s(_vm._f("toFixed")(_vm.item.counts.rate))+" ("+_vm._s(_vm.item.counts.rate_count)+") ")])],1)],1),_c('v-card-text',[_c('v-sheet',{style:(_vm.isMobile
          ? ''
          : 'height: 88px; overflow: hidden; text-overflow: ellipsis;')},[_vm._v(_vm._s(_vm.item.introduction))]),(_vm.item.type === 'student')?_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text","center-active":""}},[(_vm.categories.length > 0)?_vm._l((_vm.categories),function(category){return _c('v-chip',{key:`student-${_vm.item.id}-category-${category.id}`,on:{"click":function($event){return _vm.changeCategory(category.id)}}},[_vm._v(_vm._s(category.name))])}):_vm._e(),_c('v-chip',{attrs:{"label":""}},[_vm._v(" No Skills ")])],2):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":"","loading":_vm.isLoading(_vm.item.id),"color":_vm.isFollowed(_vm.item.id) ? 'primary' : ''},on:{"click":function($event){_vm.isFollowed(_vm.item.id) ? _vm.unfollow(_vm.item.id) : _vm.follow(_vm.item.id)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-heart-outline")])],1),_c('small',[_vm._v(_vm._s(_vm.item.followers_count))]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"orange","text":"","to":{ name: 'userShow', params: { id: _vm.item.id } }}},[_vm._v(" View More ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }