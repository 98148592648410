<template>
  <div>
    <v-toolbar-title>Resume</v-toolbar-title>
    <v-alert
      text
      icon="mdi-alert-outline"
      type="warning"
      color="yellow darken-3"
    >
      Your resume is safe with us. It will only be made viewable for
      opportunities you apply for.
    </v-alert>
    <v-list v-if="filename">
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="blue">mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a
              :href="fileLink"
              target="_blank"
              class="blue--text"
              style="text-decoration: none;"
              >{{ filename }}</a
            >
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="handleDeleteResume" :loading="deleteLoading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-btn
      color="deep-orange"
      class="white--text"
      depressed
      @click="handleSelectFile"
      :loading="uploadLoading"
      outlined
      v-else
    >
      <v-icon>mdi-upload</v-icon>
      Upload Resume
    </v-btn>
    <input
      type="file"
      ref="upload"
      style="display: none;"
      @change="handleFilePick"
    />
  </div>
</template>
<script>
const allowedType = ['docx', 'pdf', 'jpeg', 'jpg', 'png', 'doc', 'webp', 'txt']
import { uploadResume, deleteResume } from '@/api/setting'

export default {
  data: () => ({
    uploadLoading: false,
    deleteLoading: false,
  }),
  computed: {
    filename() {
      const array = this.fileLink.split('/')
      return array.length > 0 ? array[array.length - 1] : ''
    },
    fileLink: {
      get() {
        return this.$store.state.auth.userInfo.resume_file || ''
      },
      set(v) {
        const userInfo = Object.assign({}, this.$store.getters.userInfo)
        userInfo.resume_file = v
        this.$store.commit('auth/SET_USER_INFO', userInfo)
      },
    },
    is_mentor() {
      return this.$store.state.auth.userInfo.is_mentor
    },
  },
  methods: {
    handleSelectFile() {
      this.$refs.upload.click()
    },
    handleFilePick(e) {
      const file = e.target.files[0]
      const isAllowed = this.checkFile(file)
      if (!isAllowed) {
        this.$message(
          'Your resume file is not allowed, Please upload file image or pdf or word',
          'error',
          8000
        )
        return
      }
      this.uploadFile(file)
    },
    checkFile(file) {
      const nameArray = file.name.split('.')
      const ext = nameArray[nameArray.length - 1]
      return ext && allowedType.findIndex((item) => item === ext) > -1
    },
    async uploadFile(file) {
      this.uploadLoading = true
      const formData = new FormData()
      formData.append('file', file)
      try {
        const res = await uploadResume(formData)
        // this.filename = res.file_name
        this.fileLink = res.file_path
        //this.$store.dispatch('auth/isLogged')
        this.$emit('update', res.file_path)
      } catch (e) {
        if (e.response && e.response.status === 413) {
          this.$message(
            'Your resume file is too large, Please choose anther file',
            'error',
            5000
          )
          return
        }
        this.$message('Upload fail, Please retry', 'error', 5000)
      } finally {
        this.uploadLoading = false
      }
    },
    async handleDeleteResume() {
      this.deleteLoading = true
      try {
        await deleteResume()
        this.fileLink = ''
        this.$store.dispatch('auth/isLogged')
      } catch (e) {
        if (e.response) {
          this.$message('Delete resume fail,Please retry!', 'error')
        }
      } finally {
        this.deleteLoading = false
      }
    },
  },
}
</script>
