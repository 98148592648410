<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" md="4" lg="4">
      <v-card>
        <v-card-title>Oops!</v-card-title>
        <v-card-text>You do not have permission to view this page!</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            depressed
            color="orange"
            class="white--text"
            style="text-transform: none;"
            @click="logout"
            v-if="isLoggedIn"
            >Logout</v-btn
          >
          <v-btn
            depressed
            color="orange"
            class="white--text"
            style="text-transform: none;"
            :to="{ name: 'AuthLogin' }"
            v-else
            >Login</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="orange"
            class="white--text"
            style="text-transform: none;"
            :to="{ path: authBackTo }"
            >Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'authBackTo']),
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>
