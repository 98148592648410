<template>
  <v-row justify="center">
    <v-col cols="12" md="10" xl="8">
      <v-row>
        <v-col cols="12" md="10" lg="9">
          <v-stepper v-model="step" class="mb-4">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1"
                >Basic Information</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2"
                >More Information</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step step="3">Completed</v-stepper-step>
            </v-stepper-header>
          </v-stepper>
          <v-card v-if="step <= 1">
            <v-card-text>
              <create-base-form
                :data="project_info"
                :is-edit="id > 0"
                @next="goToAddQuestions"
              ></create-base-form>
            </v-card-text>
          </v-card>
          <v-card v-else-if="step <= 2">
            <add-questions
              v-model="questions"
              @back="back"
              @next="nextToPay"
              v-if="!storeLoading"
            ></add-questions>
            <v-card-text v-else>
              <Loading :loading="true"></Loading>
            </v-card-text>
          </v-card>
          <v-card v-else-if="step <= 3">
            <v-card-text>
              <v-list v-if="promotions.length > 0">
                <v-subheader
                  >Choose our customized promotion package for you</v-subheader
                >
                <v-list-item-group v-model="settings" multiple>
                  <v-list-item
                    v-for="(item, index) in promotions"
                    :key="`promotion-item-${index}`"
                    :three-line="item.name === 'Referred students'"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="`$${item.cost}`"
                        ></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
                <v-divider></v-divider>
              </v-list>
              <v-slide-y-transition class="py-0" group tag="v-list">
                <v-list-item
                  v-for="(item, index) in showCostItems"
                  :key="`cost-item-${index}`"
                  three-line
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.description
                    }}</v-list-item-subtitle></v-list-item-content
                  >
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="`$${item.cost}`"
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-slide-y-transition>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="选择"
                  ></v-list-item-action-text>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Show your status</v-list-item-title>
                  <v-list-item-subtitle
                    >Your status is visible to everyone</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="$80"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Total</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ showTotalAmountText }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="`$${showTotalAmount}`"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <!--v-alert type="success" color="info">
                <h3 class="headline">
                  Congratulations! Your new project has been successfully
                  posted.
                </h3>
                <v-divider class="my-4 white"></v-divider>
                <v-row align="center" no-gutters>
                  <v-col class="grow"></v-col>
                  <v-spacer></v-spacer>
                  <v-col class="shrink">
                    <v-btn
                      outlined
                      @click="
                        $router.push({
                          name: 'projectShow',
                          params: { id: project_id },
                        })
                      "
                    >
                      View
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="gotoPay"
                depressed
                color="primary"
                :loading="updateOrderLoading"
                >{{ totalAmount > 0 ? 'Pay' : 'Next' }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card v-if="step <= 1">
            <v-alert class="mb-0">
              Please do not include your email address in your posting.<br />
              Once you create a project/task on our platform, the students will
              be able to access your company information. <br />Additionally,
              you will be able to access the student’s resume and receive an
              email notification whenever someone applies for your opportunity.
            </v-alert>
            <v-card-actions>
              <faq-link
                class="white--text mx-2 mb-4 outlined orange"
                text="How to use our platform?"
              ></faq-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import CreateBaseForm from '@/components/Project/Create/BaseForm'
import AddQuestions from '@/components/Project/Create/AddQuestions'
import { create, fetchData, update } from '@/api/project'
import Loading from '@/components/Loading'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { fetch as fetchPromotion } from '@/api/promotion'
import { updateOrder } from '@/api/pay'
import BigNumber from 'bignumber.js'
export default {
  components: {
    Loading,
    CreateBaseForm,
    AddQuestions,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.isLoggedIn || !vm.userInfo) {
        vm.$router.push({ name: 'AuthLogin' })
      } else if (!vm.userInfo.type) {
        vm.$message('Please Complete Your Profile First')
        vm.$router.push({ name: 'userSettings' })
      }
    })
  },
  data: () => ({
    step: 1,
    title: '',
    email: '',
    select: null,
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    checkbox: null,
    categories: [],
    value1: [100, 300],
    is_free: false,
    is_online: true,
    project_id: 0,
    project_info: {
      title: '',
      position: '',
      cost_min: null,
      cost_max: null,
      is_free: true,
      is_online: true,
      category_id: null,
      closed_at: moment().add(1, 'months').format('YYYY-MM-DD'),
      content: '',
      who: '',
      work_with: '',
      skills: '',
      time_line: '',
      type: 'task',
      reward: '',
      seats: null,
      password: null,
      policy: '',
      total_hours: '',
      course_hours: '',
      experience_hours: '',
      work_with_academic_team: false,
      provide_solution: false,
    },
    questions: [],
    storeLoading: false,
    settings: [],
    costItems: [
      {
        name: 'Service & Maintenance Fee',
        cost: 2.99,
      },
      {
        name: 'Transaction Fee',
        cost: 2,
      },
    ],
    promotions: [],
    promotionItems: [],
    updateOrderLoading: false,
  }),
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    showCostItems() {
      let initItems = []
      if (
        this.project_info.cost_min > 0 &&
        !this.userInfo.can_free_hired &&
        this.project_info.type === 'task'
      ) {
        initItems.push({
          name: 'Project Fee',
          cost: this.project_info.cost_min,
        })
      }
      // 普通项目和实习项目需要收费
      if (
        !this.userInfo.can_free &&
        (this.project_info.type === 'task' ||
          this.project_info.type === 'internship')
      ) {
        initItems.push({
          name: 'Service Fee',
          description:
            'The $20 promotion fee includes an email campaign for your opportunity which will immediately reach out to our global network of over 15,000 students.',
          cost: 20.0,
        })
      }
      // if (this.userInfo.can_free) {
      //
      // } else {
      //   return this.costItems.concat(initItems).concat(this.promotionItems)
      // }
      return initItems.concat(this.promotionItems)
    },
    totalAmount() {
      let amount = 0
      this.showCostItems.forEach((item) => {
        amount += item.cost * 100
      })
      return amount / 100
    },
    showTotalAmountText() {
      // return 'Services Fee + 5% GST'
      return 'Services Fee'
    },
    showTotalAmount() {
      // const fee = new BigNumber(this.totalAmount).times(0.05)
      // return new BigNumber(this.totalAmount).plus(fee).toFixed(2)
      return new BigNumber(this.totalAmount).toFixed(2)
    },
    ...mapGetters(['isLoggedIn', 'userInfo', 'isAdmin']),
  },
  async mounted() {
    if (this.id > 0) {
      await this.initData()
    }
    if (this.step === 3 && this.promotions.length <= 0) {
      await this.loadPromotions()
    }
  },
  watch: {
    questions(newQuestions) {
      this.project_info.questions = newQuestions
    },
    step(newStep) {
      if (newStep === 3 && this.promotions.length <= 0) {
        this.loadPromotions()
      }
    },
    settings(newData, oldData) {
      const newArray = newData.map((i) => {
        return { ...this.promotions[i] }
      })

      // 先移除没有的，再添加
      this.promotionItems.forEach((item, index) => {
        if (item.id && newArray.findIndex((i) => i.id === item.id) <= -1) {
          this.promotionItems.splice(index, 1)
        }
      })
      newData.forEach((item) => {
        const data = { ...this.promotions[item] }
        if (this.promotionItems.findIndex((i) => i.id === data.id) <= -1) {
          this.promotionItems.push({
            name: `${data.name} Promotion Fee`,
            cost: data.cost,
            id: data.id,
          })
        }
      })
    },
  },
  methods: {
    // 编辑模式获取数据
    async initData() {
      try {
        const res = await fetchData(this.id, {
          includes: 'content,who,work_with,skills,policy,agenda,about_mentor',
        })
        if (res.data.cost_min > 0) {
          res.data.is_free = false
          res.data.cost_min = parseFloat(res.data.cost_min)
        }

        this.project_info = Object.assign(this.project_info, res.data)
        if (res.data.questions) {
          this.questions = res.data.questions
        }
        this.$set(
          this.project_info,
          'closed_at',
          moment(this.project_info.closed_at).format('YYYY-MM-DD')
        )
      } catch (e) {
        console.log(e)
      }
    },
    submit() {
      this.$refs.observer.validate()
      this.step = 2
    },
    clear() {
      this.name = ''
      this.email = ''
      this.select = null
      this.checkbox = null
      this.$refs.observer.reset()
    },
    back() {
      this.step--
    },
    async loadProject() {
      try {
        const res = await fetchData(this.project_id)
      } catch (e) {
        console.log(e)
      }
    },
    goToAddQuestions(data) {
      this.project_info = data
      console.log('Project Info', this.project_info)
      if (this.project_info.type === 'academic-team') {
        this.project_info.cost_max = this.project_info.cost_min
      }
      if (this.questions) {
        this.project_info.questions = Object.assign({}, this.questions)
      }
      this.step++
    },

    nextToPay() {
      // 检查 questions
      this.storeProject()
    },
    /**
     * 保存数据
     */
    async storeProject() {
      try {
        this.storeLoading = true
        // 整理数据
        const postData = Object.assign({}, this.project_info)
        const questions = []
        if (Array.isArray(postData.questions)) {
          postData.questions.map((item) => {
            if (!item.checked && item.title) {
              questions.push({
                title: item.title,
                checked: false,
              })
            } else if (item.checked && item.title) {
              questions.push({
                title: item.title,
                checked: true,
              })
            }
            return null
          })

          postData.questions = questions
        }
        if (this.id <= 0) {
          const response = await create(postData)
          if (response.data) {
            this.project_id = response.data.id
            this.project_info = Object.assign(this.project_info, response.data)
            if (this.project_info.type === 'mentor-program') {
              this.$router.push(`/mentor-program/${this.project_id}`)
              return
            }
            if (this.isAdmin) {
              this.$router.push(`/projects/${this.project_id}`)
              return
            }
            if (this.project_info.type === 'academic-team') {
              this.$router.push(`/projects/${this.project_id}`)
              return
            }
            this.step++
          }
        } else {
          const response = await update(this.id, postData)
          if (response.data) {
            this.$store.dispatch('message/pushMessage', {
              type: 'info',
              content: 'Success',
              timeout: 3000,
            })
            this.project_info = Object.assign(this.project_info, response.data)
            this.$router.push(`/projects/${this.id}`)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.storeLoading = false
      }
    },
    /**
     * 去往支付页面
     */
    async gotoPay() {
      this.updateOrderLoading = true
      const ids = this.settings.map((index) => {
        return this.promotions[index].id
      })
      try {
        const { data } = await updateOrder(this.project_info.order.id, {
          promotions: ids,
        })
        console.log(data)
        if (data.is_paid) {
          this.$router.push(`/projects/${this.project_id}`)
        } else {
          this.$router.push({ name: 'Pay', params: { no: data.no } })
        }
        //
      } catch (e) {
        this.updateOrderLoading = false
      }
    },
    async loadPromotions() {
      try {
        const { data } = await fetchPromotion({})
        this.promotions = data
        if (this.project_info.order && this.project_info.order.items) {
          this.settings = this.project_info.order.items.map((i) => {
            return this.promotions.findIndex(
              (item) => item.id === i.promotion_id
            )
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
