<template>
  <v-col cols="12">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" xl="8">
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>Basic Information</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div class="px-4">
                  <template v-if="viewUser">
                    <field-row
                      v-for="(label, index) in Object.keys(basicInfo)"
                      icon=""
                      :label="upperFirst(label)"
                      :show-hover="false"
                      :key="`info-${label}`"
                      :value="viewUser[label]"
                      :divider="index < Object.keys(basicInfo).length - 1"
                    >
                      <template v-if="label === 'avatar'">
                        <v-avatar :size="60">
                          <v-img :src="viewUser[label]" />
                        </v-avatar>
                      </template>
                      <template v-else-if="label === 'languages'">
                        <template v-if="viewUser[label].length > 0">
                          <v-chip
                            v-for="language in viewUser[label]"
                            :key="`user-show-language-${language.id}`"
                            class="mr-1"
                            label
                            outlined
                          >
                            {{ language.name }}
                          </v-chip>
                        </template>
                        <template v-else>{{ '' }}</template>
                      </template>
                      <template v-else-if="label === 'country'">
                        <v-chip label outlined v-if="basicInfo[label]">
                          <v-icon left>{{ basicInfo[label].emoji }}</v-icon>
                          {{ basicInfo[label].name }}
                        </v-chip>
                      </template>
                    </field-row>
                  </template>
                </div>
              </v-card-text>
            </v-card>
            <v-card
              class="mt-8"
              v-if="
                (viewUser.type === 'student' || viewUser.type === 'person') &&
                viewUser.categories.length > 0
              "
            >
              <v-toolbar flat>
                <v-toolbar-title>Skills</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-chip
                  v-for="category in viewUser.categories"
                  :key="`user-show-skill-${category.id}`"
                  class="mr-1"
                  label
                  outlined
                >
                  {{ category.name }}
                </v-chip>
              </v-card-text>
            </v-card>
            <v-card
              class="mt-8"
              v-if="viewUser.identityable && viewUser.identityable.id"
            >
              <v-toolbar flat>
                <v-toolbar-title
                  >{{
                    upperFirst(viewUser.type === 'person' ? '' : viewUser.type)
                  }}
                  Information</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <div class="px-4">
                  <field-row
                    v-for="(label, index) in Object.keys(moreInfo)"
                    icon=""
                    :label="upperFirst(changeLabel(label))"
                    :show-hover="false"
                    :key="`info-${label}`"
                    :value="moreInfo[label]"
                    :divider="index < Object.keys(moreInfo).length - 1"
                  >
                    <template v-if="label === 'is_profit'">
                      {{ !!moreInfo[label] ? 'Profit' : 'Non-Profit' }}
                    </template>
                  </field-row>
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mt-8" v-if="viewUser.type !== 'company'">
              <v-toolbar flat>
                <v-toolbar-title>Featured Experience</v-toolbar-title>
                <template
                  v-if="
                    experiences.length > 0 &&
                    isLoggedIn &&
                    userInfo.id === viewUser.id
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn depressed @click="showAddExperienceModal(true)">
                    <v-icon>mdi-plus</v-icon>
                    Add</v-btn
                  ></template
                >
              </v-toolbar>
              <div class="grey--text text-caption pa-4">
                As your resume will only be accessible by owners for projects
                that you apply, we highly encourage you to showcase your top 1-2
                professional experiences here. This will help increase your
                publicity and will give browsing companies a sneak peak of your
                potential!
              </div>
              <v-card-text v-if="loadExperienceLoading">
                <loading :loading="loadExperienceLoading"></loading>
              </v-card-text>
              <v-card-text v-else-if="experiences.length > 0">
                <v-list>
                  <v-list-group
                    v-for="item in experiences"
                    :key="`experience-${item.id}`"
                    v-model="item.active"
                    no-action
                    two-line
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.position_title"
                        ></v-list-item-title>
                        <v-list-item-subtitle v-text="item.organization_name">
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          v-text="`${item.date_start} - ${item.date_end}`"
                        >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <v-list-item-content>
                      <div class="pa-2">{{ item.content }}</div>
                    </v-list-item-content>
                    <v-card-actions
                      v-if="isLoggedIn && item.user_id === userInfo.id"
                    >
                      <v-btn depressed @click="editExperience(item.id)">
                        <v-icon>mdi-lead-pencil</v-icon>
                        Edit
                      </v-btn>
                      <v-btn
                        depressed
                        @click="deleteExperience(item.id)"
                        :loading="item.deleteLoading"
                      >
                        <v-icon>mdi-delete</v-icon>
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-list-group>
                </v-list>
              </v-card-text>
              <v-card-actions
                v-else-if="isLoggedIn && userInfo.id === viewUser.id"
              >
                <v-btn depressed @click="showAddExperienceModal(true)">
                  <v-icon>mdi-plus</v-icon>
                  Add</v-btn
                >
              </v-card-actions>
              <v-card-text v-else>
                Nothing
              </v-card-text>
            </v-card>
            <v-card class="mt-8" v-if="viewUser.invite_code">
              <v-toolbar flat>
                <v-toolbar-title>Invite your friends!</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <p>
                  Send this link to people you think would make awesome
                  community members!
                </p>
                <div class="">
                  <v-text-field
                    outlined
                    readonly
                    @click="copyLink"
                    @click:append="copyLink"
                    :value="viewUser.invite_link"
                    id="copy-txt"
                    append-icon="mdi-content-copy"
                  ></v-text-field>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <experience-create
      :show="experienceFormModal"
      @show="showAddExperienceModal"
      :id="editExperienceId"
      @reload="loadExperience"
    ></experience-create>
  </v-col>
</template>
<script>
import { fetchInfo } from '@/api/user'
import { mapGetters } from 'vuex'
import FieldRow from '@/components/Settings/FieldRow'
import { pick, upperFirst, startCase } from 'lodash'
import ExperienceCreate from '@/components/User/Experience/Create'
import { destroy, fetchIndex } from '@/api/experience'
import Loading from '@/components/Loading'
export default {
  components: { FieldRow, ExperienceCreate, Loading },
  computed: {
    ...mapGetters(['viewUser', 'userInfo', 'isLoggedIn']),
    basicInfo() {
      return pick(this.viewUser, this.showField.base)
    },
    moreInfo() {
      return pick(
        this.viewUser.identityable,
        this.showField[this.viewUser.type]
      )
    },
    user_id() {
      return this.$route.params.id
    },
  },
  data() {
    return {
      user: {},
      experienceFormModal: false,
      loadExperienceLoading: false,
      showField: {
        base: ['name', 'avatar', 'introduction', 'languages', 'country'],
        student: ['education', 'linkedin'],
        company: [
          'name',
          'address',
          'contact_name',
          'contact_role',
          'is_profit',
          'phone',
          'linkedin',
          'website',
        ],
        person: ['phone', 'linkedin'],
      },
      experiences: [],
      editExperienceId: 0,
    }
  },
  watch: {
    user_id() {
      this.loadExperience()
    },
  },
  mounted() {
    this.loadExperience()
  },
  methods: {
    upperFirst(label) {
      return upperFirst(startCase(label))
    },
    changeLabel(label) {
      switch (label) {
        case 'is_profit':
          return 'Profit or Non-Profit'
        case 'phone':
          return this.viewUser.type === 'company' ? 'contact_phone' : label
        case 'email':
          return 'contact_email'
        default:
          return label
      }
    },
    copyLink() {
      const copyDom = document.getElementById('copy-txt')
      copyDom.select()
      var successful = document.execCommand('copy')
      try {
        const msg = successful ? 'successful' : 'unsuccessful'
        this.$message('The Referal link is copied!')
      } catch (err) {
        console.log('unable to copy')
      }
    },
    showAddExperienceModal(isShow = true) {
      this.experienceFormModal = isShow
    },
    async loadExperience() {
      this.loadExperienceLoading = true
      try {
        const { data } = await fetchIndex({ user_id: this.user_id })
        this.experiences = data
        this.loadExperienceLoading = false
      } catch (e) {
        this.loadExperienceLoading = false
      }
    },
    editExperience(id) {
      this.editExperienceId = id
      this.experienceFormModal = true
    },
    async deleteExperience(id) {
      const index = this.experiences.findIndex((item) => item.id === id)
      this.$set(this.experiences[index], 'deleteLoading', true)
      try {
        await destroy(id)
        this.experiences.splice(index, 1)
      } catch (e) {
        this.$set(this.experiences[index], 'deleteLoading', false)
      }
    },
  },
}
</script>
