<template>
  <v-card>
    <v-card-text>
      <template v-for="(q, index) in questions">
        <v-row :key="`q-${index}`" class="px-3">
          <v-text-field
            :error="q.error"
            :error-messages="q.errorMessage"
            v-model="q.title"
          ></v-text-field>
          <v-btn
            color="error"
            icon
            depressed
            class="align-self-center"
            @click="removeQuestion(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-alert
        icon="mdi-alert-outline"
        prominent
        text
        type="warning"
        color="yellow darken-3"
        class="mt-5"
      >
        These questions will be provided to all applicants in the hiring process
        and is meant to help you further shortlist candidates and get to know
        them better.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn class="mx-3" @click="addQuestion" outlined color="orange"
        >Add questions</v-btn
      >
    </v-card-actions>
    <v-card-actions class="mt-8">
      <v-btn @click="next" color="primary" depressed>Next</v-btn>
      <v-btn @click="$emit('back')" depressed>Back</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  mounted() {
    this.questions = this.value
  },
  data: () => ({
    questions: [],
  }),
  methods: {
    // 问题相关
    addQuestion() {
      this.questions.push({
        checked: false,
        title: '',
        error: false,
        errorMessage: null,
      })
    },
    removeQuestion(index) {
      this.questions.splice(index, 1)
    },
    checkQuestions() {
      // 遍历 questions
      let isError = false
      this.questions.forEach((item, index) => {
        item.error = false
        item.errorMessage = null
        if (item.checked && !item.title) {
          item.error = true
          item.errorMessage = 'The question must be Enter'
          isError = true
        }
      })
      return !isError
    },
    next() {
      if (this.checkQuestions()) {
        this.$emit('next')
      }
    },
  },
}
</script>
