<template>
  <v-card>
    <v-card-text>
      <v-row class="mx-auto" justify="center">
        <router-link
          :to="{
            name: 'userShow',
            params: { id: item.id },
          }"
          style="text-decoration: none;"
        >
          <v-avatar>
            <v-img :src="item.avatar" />
          </v-avatar>
        </router-link>
      </v-row>
    </v-card-text>
    <v-card-title class="justify-center pt-0">
      <span class="font-weight-light text-center tw-truncate">
        <router-link
          :to="{
            name: 'userShow',
            params: { id: item.id },
          }"
          style="text-decoration: none;"
          >{{ item.name | noName }}</router-link
        ></span
      >
    </v-card-title>
    <v-card-text>
      <v-sheet
        :style="
          isMobile
            ? 'height: 180px; overflow: hidden; text-overflow: ellipsis;'
            : 'height: 92px; overflow: hidden; text-overflow: ellipsis;'
        "
        >{{ item.introduction }}</v-sheet
      >
      <div class="tw-flex tw-justify-center">
        <v-chip class="my-1 py-1" color="orange" outlined small>
          <v-icon left size="18" color="orange">mdi-email</v-icon
          >{{ item.email }}
        </v-chip>
      </div>

      <v-chip-group
        active-class="deep-purple accent-4 white--text"
        v-if="item.type === 'student'"
        center-active
      >
        <template v-if="categories.length > 0">
          <v-chip
            v-for="category in categories"
            :key="`student-${item.id}-category-${category.id}`"
            @click="changeCategory(category.id)"
            >{{ category.name }}</v-chip
          >
        </template>
        <v-chip label v-else>
          No Skills
        </v-chip>
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {
          categories: [],
        }
      },
    },
  },
  computed: {
    categories() {
      return this.item.categories || []
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>
