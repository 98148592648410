<template>
  <div>
    <template v-if="!isMobile">
      <div
        class="tw-h-screen tw-flex tw-items-center login-bg tw-bg-right-bottom tw-bg-contain"
      >
        <div
          class="tw-container tw-mx-auto tw-flex tw-flex-row tw-justify-between"
          style="max-width: 1024px;"
        >
          <div
            class="tw-border tw-rounded tw-relative tw-bg-white tw-p-6 tw-w-5/12 tw-self-center"
          >
            <div
              class="tw-absolute tw-w-20 tw-h-20 tw--mt-32 tw--mr-12"
              style="right: 0; top: 0;"
            >
              <img src="@/assets/images/honeycomb2.png" />
            </div>
            <h2 class="tw-text-4xl tw-mb-4">Create Account</h2>
            <div>
              <div
                class="tw-border tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-t"
                :class="{ active: emailFocusIn }"
              >
                <label for="email" class="tw-text-sm text-gray-1"
                  >Email Address<span class="tw-text-red-500">*</span></label
                >
                <input
                  id="email"
                  type="text"
                  class="tw-outline-none"
                  @focusin="emailFocusIn = true"
                  @focusout="emailFocusIn = false"
                  v-model="email"
                />
              </div>
              <div
                class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2"
                :class="{ active: passwordFocusIn }"
              >
                <label for="password" class="tw-text-sm text-gray-1"
                  >Password<span class="tw-text-red-500">*</span></label
                >
                <input
                  id="password"
                  type="password"
                  class="tw-outline-none"
                  @focusin="passwordFocusIn = true"
                  @focusout="passwordFocusIn = false"
                  v-model="password"
                />
              </div>
              <div
                class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2"
                :class="{ active: confirmPasswordFocusIn }"
              >
                <label for="confirm-password" class="tw-text-sm text-gray-1"
                  >Confirm Password<span class="tw-text-red-500">*</span></label
                >
                <input
                  id="confirm-password"
                  type="password"
                  class="tw-outline-none"
                  @focusin="confirmPasswordFocusIn = true"
                  @focusout="confirmPasswordFocusIn = false"
                  v-model="password_confirmation"
                />
              </div>
              <div
                class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-b"
                :class="{ active: codeFocusIn }"
              >
                <label for="code" class="tw-text-sm text-gray-1">Code</label>
                <input
                  id="code"
                  type="text"
                  class="tw-outline-none"
                  @focusin="codeFocusIn = true"
                  @focusout="codeFocusIn = false"
                  v-model="code"
                />
              </div>
            </div>
            <div class="tw-mt-2 text-gray-1 tw-text-sm">
              <label class="tw-block tw-text-gray-500">
                <input
                  class="tw-mr-1 leading-tight"
                  type="checkbox"
                  v-model="isAgree"
                />
                <span
                  >Check here to indicate that you have read and agree to
                  the<router-link to="/blog/6" class="tw-text-sm text-primary">
                    Beeznests Terms and Conditions</router-link
                  ></span
                >
              </label>
            </div>
            <div v-if="hasError" class="tw-text-red-500 tw-pt-2 tw-text-sm">
              {{ errorMessage }}
            </div>
            <div class="tw-flex tw-justify-between tw-py-3 tw-text-sm">
              <div class="text-gray-1">
                Already registered?
                <router-link to="/new-login" class="text-primary"
                  >Sign In</router-link
                >
              </div>
            </div>
            <button
              @click="submitRegister"
              class="tw-block tw-uppercase tw-py-3 tw-w-full tw-rounded tw-outline-none focus:tw-outline-none"
              :class="!loginLoading ? 'bg-orange-2' : 'tw-bg-gray-400'"
              :disabled="loginLoading"
            >
              <i class="fa fa-spinner fa-spin" v-if="loginLoading"></i>
              <i class="fa fa-arrow-circle-o-right" v-else></i
              ><span class="tw-font-bold tw-ml-1">Register</span>
            </button>
            <div class="text-gray-1 tw-text-sm tw-py-2">
              By register, you agree to ours Terms of Use and Privacy Policy
            </div>
          </div>
          <div class="tw-w-5/12">
            <img src="@/assets/images/login.png" />
          </div>
        </div>
        <div
          class="tw-absolute tw-w-20 tw-h-20 tw-mb-8 tw-ml-8"
          style="left: 0; bottom: 0;"
        >
          <img src="@/assets/images/honeycomb.png" />
        </div>
      </div>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="tw-flex tw-items-center fill-height">
            <div
              class="tw-border tw-rounded tw-relative tw-bg-white tw-p-3 tw-self-center tw-mx-4"
            >
              <h2 class="tw-text-4xl tw-mb-4">Create Account</h2>
              <div>
                <div
                  class="tw-border tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-t"
                  :class="{ active: emailFocusIn }"
                >
                  <label for="email" class="tw-text-sm text-gray-1"
                    >Email Address<span class="tw-text-red-500">*</span></label
                  >
                  <input
                    id="email"
                    type="text"
                    class="tw-outline-none"
                    @focusin="emailFocusIn = true"
                    @focusout="emailFocusIn = false"
                    v-model="email"
                  />
                </div>
                <div
                  class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2"
                  :class="{ active: passwordFocusIn }"
                >
                  <label for="password" class="tw-text-sm text-gray-1"
                    >Password<span class="tw-text-red-500">*</span></label
                  >
                  <input
                    id="password"
                    type="password"
                    class="tw-outline-none"
                    @focusin="passwordFocusIn = true"
                    @focusout="passwordFocusIn = false"
                    v-model="password"
                  />
                </div>
                <div
                  class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2"
                  :class="{ active: confirmPasswordFocusIn }"
                >
                  <label for="confirm-password" class="tw-text-sm text-gray-1"
                    >Confirm Password<span class="tw-text-red-500"
                      >*</span
                    ></label
                  >
                  <input
                    id="confirm-password"
                    type="password"
                    class="tw-outline-none"
                    @focusin="confirmPasswordFocusIn = true"
                    @focusout="confirmPasswordFocusIn = false"
                    v-model="password_confirmation"
                  />
                </div>
                <div
                  class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-b"
                  :class="{ active: codeFocusIn }"
                >
                  <label for="code" class="tw-text-sm text-gray-1">Code</label>
                  <input
                    id="code"
                    type="text"
                    class="tw-outline-none"
                    @focusin="codeFocusIn = true"
                    @focusout="codeFocusIn = false"
                    v-model="code"
                  />
                </div>
              </div>
              <div class="tw-mt-2 text-gray-1 tw-text-sm">
                <label class="tw-block tw-text-gray-500">
                  <input
                    class="tw-mr-1 leading-tight"
                    type="checkbox"
                    v-model="isAgree"
                  />
                  <span
                    >Check here to indicate that you have read and agree to
                    the<router-link
                      to="/blog/6"
                      class="tw-text-sm text-primary"
                    >
                      Beeznests Terms and Conditions</router-link
                    ></span
                  >
                </label>
              </div>
              <div v-if="hasError" class="tw-text-red-500 tw-pt-2 tw-text-sm">
                {{ errorMessage }}
              </div>
              <div class="tw-flex tw-justify-between tw-py-3 tw-text-sm">
                <div class="text-gray-1">
                  Already registered?
                  <router-link to="/new-login" class="text-primary"
                    >Sign In</router-link
                  >
                </div>
              </div>
              <button
                @click="submitRegister"
                class="tw-block tw-uppercase tw-py-3 tw-w-full tw-rounded tw-outline-none focus:tw-outline-none"
                :class="!loginLoading ? 'bg-orange-2' : 'tw-bg-gray-400'"
                :disabled="loginLoading"
              >
                <i class="fa fa-spinner fa-spin" v-if="loginLoading"></i>
                <i class="fa fa-arrow-circle-o-right" v-else></i
                ><span class="tw-font-bold tw-ml-1">Register</span>
              </button>
              <div class="text-gray-1 tw-text-sm tw-py-2">
                By register, you agree to ours Terms of Use and Privacy Policy
              </div>
            </div>
          </div>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ls from '@/plugins/ls'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'

export default {
  components: { MobileDrawer, MobileHeader },
  mounted() {
    document.documentElement.style.fontSize = '14px'
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  data: () => ({
    emailFocusIn: false,
    passwordFocusIn: false,
    codeFocusIn: false,
    email: null,
    password: null,
    code: null,
    hasError: false,
    errorMessage: null,
    isAgree: false,
    password_confirmation: null,
    confirmPasswordFocusIn: false,
  }),
  computed: {
    ...mapGetters(['authBackTo', 'authRedirectTo', 'loginLoading', 'isMobile']),
  },
  methods: {
    emailFocus() {
      this.emailFocusIn = true
    },
    passwordFocus() {
      this.passwordFocusIn = true
    },
    async submitRegister() {
      if (!this.email) {
        this.hasError = true
        this.errorMessage = 'The email field is required.'
        return
      }
      if (!this.password) {
        this.hasError = true
        this.errorMessage = 'The password field is required.'
        return
      }
      if (!this.isAgree) {
        this.hasError = true
        this.errorMessage =
          'Please read and agree to Beeznests Terms and Conditions'
        return
      }
      if (this.password !== this.password_confirmation) {
        this.hasError = true
        this.errorMessage = 'Confirm password does not match'
        return
      }
      try {
        const isRegistered = await this.$store.dispatch('auth/register', {
          email: this.email,
          password: this.password,
          code: this.code,
        })
        if (isRegistered) {
          console.log('注册后的跳转页', this.authRedirectTo)
          this.$router.push(this.authRedirectTo)
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.hasError = true
          this.errorMessage = e.response.data.message
        }
      }
    },
    created() {
      if (this.$route.query.code) {
        ls.set('invite_code', this.$route.query.code)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.border-color-primary {
  border-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-5 {
  background-color: #fff9f1;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
.text-gray-2 {
  color: #031226;
}
.bg-main {
  background-color: #f5f9ff;
}
.bg-profile {
  background-image: url('~@/assets/images/profile-top.png');
}
.bg-blue-1 {
  background-color: #e1f6ff;
}
.text-blue-1 {
  color: #03a9f4;
}
.bg-blue-2 {
  background-color: #f0eff9;
}
.text-blue-2 {
  color: #1600db;
}
.bee-image-container {
}
.active {
  border-left-color: #fecc17;
}
.login-bg {
  background-color: #fef6df;
  background-image: url('~@/assets/images/login-bg.png');
}
</style>
