<template>
  <div>
    <section
      class="tw-py-10 tw--mx-4 md:tw-pt-20 tw-px-6 mx:tw-px-0 tw-testimonial"
      style="background-image: linear-gradient(135deg, #fecc17, #ff9800);"
    >
      <blockquote
        class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-12 tw-max-w-2xl"
      >
        <p class="tw-mb-6 tw-text-4xl">
          Members Package For College
        </p>
        <p class="tw-text-2xl">Who we are?</p>
        <p class="tw-mb-6 tw-text-lg">
          Beeznests Connection is a student freelance platform that helps
          students get more work experience to advance their careers. Our
          mission is to remotely connect companies and hiring individuals with
          students from our partner universities to bridge the gap between the
          academic and business community.
        </p>
        <p class="tw-text-2xl">Why work with us?</p>
        <ul class="tw-mb-6 tw-text-lg text-left pl-0">
          <li>
            - Get access to tons of global project opportunities for you
            students for free
          </li>
          <li>
            - Get opportunities to get trained and work with industry experts on
            real projects
          </li>
          <li>
            - College referral job board, get access to exclusive paid hiring
            opportunities for your students
          </li>
          <li>
            - Get discounts on opportunities we offer such as bootcamp and super
            bootcamp (regular bootcamp + soft skill training to prepare for work
            in Canada)
          </li>
        </ul>
      </blockquote>
    </section>
    <section class="tw-py-10 md:tw-py-0 md:tw-mb-20 tw-px-6 md:tw-px-0">
      <div
        class="tw-container md:tw-flex md:tw-justify-center tw-mx-auto tw-mb-10 md:tw-mb-10"
      >
        <div
          v-for="item in items"
          :key="item.name"
          class="tw-relative tw-pb-cta tw-shadow-lg tw-overflow-hidden tw-bg-white tw-rounded-lg tw-w-card tw-mr-10 tw-mb-10 md:tw-mb-0"
        >
          <div class="tw-pt-8 tw-pb-10 tw-px-6 md:tw-px-10">
            <h4
              class="text-center tw-uppercase tw-text-xl tw-tracking-wide tw-mb-3"
            >
              {{ item.name }}
            </h4>
            <p class="tw-align-text-top tw-mb-4 text-center">
              <sup class="tw-font-light tw-text-50 tw-text-2xl">$</sup>
              <span class="tw-font-light tw-text-90 tw-text-price">{{
                item.price
              }}</span>
              <span
                class="tw-font-light tw-text-90 tw-text-xl tw-line-through"
                v-if="item.oldPrice > 0"
                >${{ item.oldPrice }}</span
              ><span class="tw-text-base">/Term</span>
            </p>
            <ul class="tw-list-reset tw-leading-normal tw-text-80 tw-pl-0">
              <li
                v-for="(i, index) in item.items"
                :key="`${item.name}-${index}`"
                class="tw-flex tw-items-start tw-py-2"
              >
                <i
                  class="fa fa-check-circle tw-mr-1"
                  aria-hidden="true"
                  style="color: #ff9800; padding-top: 2px;"
                ></i>
                <div>
                  <div class="tw-font-medium">{{ i.title }}</div>
                  <div>{{ i.content }}</div>
                </div>
              </li>
            </ul>
          </div>
          <router-link
            :to="{ name: 'Payment', params: { amount: item.price } }"
            class="tw-absolute tw-bottom-0 tw-inset-x-0 tw-flex tw-items-center tw-justify-center tw-no-underline tw-h-cta tw-block tw-bg-10 hover:tw-bg-20 tw-text-center tw-text-primary tw-uppercase tw-tracking-wide tw-font-bold"
          >
            Get Started
          </router-link>
        </div>
      </div>
      <div class="tw-max-w-3xl tw-mx-auto">
        <p class="tw-text-center text-90">
          If you are interested in becoming a Silver or Platinum Sponsor, please
          email us directly at sponsorship@beeznests.com to confirm whether we
          can accommodate your customized requirement of choice
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        name: 'Basic',
        price: 1500,
        oldPrice: null,
        items: [
          {
            title: 'Sign up for free',
            content:
              'Your students will save $20 and sign up for free using a special code and can access all the paid/unpaid projects, internships.',
          },
          {
            title: 'Academic Team',
            content:
              'Professors can sign up as a team lead; College refers students to participate in Academic Team projects.',
          },
          {
            title: 'Regular Bootcamp',
            content: '10% discount',
          },
          {
            title: 'Super Bootcamp',
            content: '10% discount for group tickets purchase',
          },
          {
            title: 'Certificate of Completion',
            content:
              'Printed or PDF certificate can have your school name and logo alongside the Beeznests logo after students complete any academic team project and bootcamp',
          },
          {
            title: 'Promotion',
            content:
              '2 events or opportunities on our social media platform will be promoted each term.',
          },
        ],
      },
      {
        name: 'Premium',
        price: 2500,
        oldPrice: 5000,
        items: [
          {
            title: 'Sign up for free',
            content:
              'Your students will save $20 and sign up for free using a special code and can access all the paid/unpaid projects, internships.',
          },
          {
            title: 'Academic Team',
            content:
              'Professors can sign up as a team lead; College refers students to participate in Academic Team projects.',
          },
          {
            title: 'Regular Bootcamp Discount',
            content:
              'Get a 40% discount for each bootcamp per seat, which is $300 per seat after discount. Get a group ticket rate: $2500 for 15 students, which is $166 per seat.',
          },
          {
            title: 'Access to Customized Bootcamps',
            content: '',
          },
          {
            title: 'Super Bootcamp Discount',
            content: '40% discount for group tickets purchase',
          },
          {
            title: 'Certificate of Completion',
            content:
              'Printed or PDF certificate can have your school name and logo alongside the Beeznests logo after students complete any academic team project and bootcamp',
          },
          {
            title: 'Student Promotion',
            content:
              'Special student promotions on Beeznests social media, also mentioning your school name.',
          },
          {
            title: 'Customized Badge',
            content:
              'Students who complete bootcamp, super bootcamp or Academic Team will get a special badge which will associate them with your college and mention they have completed a bootcamp or Academic Team. This will improve their chances of getting hired.',
          },
          {
            title: 'Logo Placement',
            content:
              'We will put your logo on our website and monthly newsletters that will directly reach thousands of students.',
          },
          {
            title: 'Guest Speaker',
            content:
              'Access to our mentors for up to 3 guest speaker opportunities at your university, per term.',
          },
          {
            title: 'College Referral Job Board',
            content:
              'Promote your students directly to hiring companies for full time job opportunities through our website.',
          },
          {
            title: 'College hiring option',
            content:
              'Get companies to line up projects for your school to take on.',
          },
          {
            title: 'Promotion',
            content:
              '5 events/opportunities on our social media platform will be promoted each term.',
          },
          {
            title: null,
            content:
              '5 Direct Listing of upcoming events and opportunities on our website and newsletter.',
          },
          {
            title: null,
            content:
              'Reserved three-week Ad space on our website for 3 different opportunities each term.',
          },
          {
            title: null,
            content:
              'Promote your events or opportunities on our monthly newsletter which is read by 15,000 students globally.',
          },
        ],
      },
      {
        name: 'Enterprise',
        price: 6000,
        oldPrice: 9000,
        items: [
          {
            title: 'Free Bootcamp',
            content:
              'Access to 2 regular customized one month bootcamps, for free',
          },
          {
            title: 'Sign up for free',
            content:
              'Your students will save $20 and sign up for free using a special code and can access all the paid/unpaid projects, internships.',
          },
          {
            title: 'Guest Speaker',
            content:
              'Access to our mentors for up to 3 guest speaker opportunities at your university, per term.',
          },
          {
            title: 'College Referral Job Board',
            content:
              'Promote your students directly to hiring companies for full time job opportunities through our website.',
          },
          {
            title: 'College hiring option',
            content:
              'Get companies to line up projects for your school to take on.',
          },
          {
            title: 'Academic Team',
            content:
              'Professors can sign up as a team lead; College refers students to participate in Academic Team projects.',
          },
          {
            title: 'Regular Bootcamp Discount',
            content:
              'Get a 40% discount for each bootcamp per seat, which is $300 per seat after discount. Get a group ticket rate: $2500 for 15 students, which is $166 per seat.',
          },
          {
            title: 'Access to Customized Bootcamps',
            content: '',
          },
          {
            title: 'Super Bootcamp Discount',
            content: '40% discount for group tickets purchase',
          },
          {
            title: 'Certificate of Completion',
            content:
              'Printed or PDF certificate can have your school name and logo alongside the Beeznests logo after students complete any academic team project and bootcamp',
          },
          {
            title: 'Student Promotion',
            content:
              'Special student promotions on Beeznests social media, also mentioning your school name.',
          },
          {
            title: 'Customized Badge',
            content:
              'Students who complete bootcamp, super bootcamp or Academic Team will get a special badge which will associate them with your college and mention they have completed a bootcamp or Academic Team. This will improve their chances of getting hired.',
          },
          {
            title: 'Logo Placement',
            content:
              'We will put your logo on our website and monthly newsletters that will directly reach thousands of students.',
          },
          {
            title: 'Promotion',
            content:
              '5 events/opportunities on our social media platform will be promoted each term.',
          },
          {
            title: '',
            content:
              '5 Direct Listing of upcoming events and opportunities on our website and newsletter.',
          },
          {
            title: '',
            content:
              'Reserved three-week Ad space on our website for 3 different opportunities each term.',
          },
          {
            title: '',
            content:
              'Promote your events or opportunities on our monthly newsletter which is read by 15,000 students globally.',
          },
        ],
      },
    ],
  }),
}
</script>
<style lang="scss" scoped>
.tw-text-price {
  font-size: 2.5rem;
}
.tw-text-60 {
  color: #4a90e2;
}
.tw-text-50 {
  color: #8a97a2;
}
.tw-text-90 {
  color: #202c3a;
}
.tw-container {
  max-width: 960px;
}
.tw-w-card {
  width: 22.5rem;
}
.tw-testimonial {
  padding-bottom: 190px;
  margin-bottom: -190px;
}
.tw-pl-0 {
  padding-left: 0 !important;
}
.tw-h-cta {
  height: 4.5rem;
}
.tw-bg-10 {
  background-color: #f7f9fb;
  &:hover {
    background-color: rgba(255, 152, 0, 0.75);
  }
}
.tw-pb-cta {
  padding-bottom: 4.5rem;
}
</style>
