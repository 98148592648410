import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/assets/scss/variables.scss'
Vue.use(Vuetify)
import '@mdi/js'
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#FF9800',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg', // default - only for display purposes
  },
})
