<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-sheet class="pa-4"> </v-sheet>
    </v-col>
    <v-col cols="12" md="10" lg="8">
      <div class="py-4 d-flex justify-space-between">
        <v-toolbar-title>
          {{ data.title }}
        </v-toolbar-title>
        <div v-if="isLoggedIn && userInfo.id === data.user_id">
          <v-btn
            icon
            color="error"
            class="white--text"
            :to="{ name: 'portfolioEdit', params: { id: id } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            class="white--text"
            @click="deletePortfolio(id)"
            :loading="deleteLoading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        style="position: relative;"
        :style="{ height: containerHeight + 'px' }"
      >
        <template v-for="(image, index) in images">
          <div
            v-if="image.url !== ''"
            :key="`image-preview-${index}`"
            :style="{
              position: 'absolute',
              left: image.left + 'px',
              top: image.top + 'px',
              width: image.width + 'px',
              height: image.height + 'px',
            }"
          >
            <v-img
              :src="data.attachments[index].url"
              :width="image.width"
              :height="image.height"
              contain
            ></v-img>
          </div>
        </template>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { fetchData, destroy } from '@/api/portfolio'
import justifiedLayout from 'justified-layout'
import { mapGetters } from 'vuex'
export default {
  computed: {
    id() {
      return this.$route.params.id
    },
    ...mapGetters(['isLoggedIn', 'userInfo']),
  },
  data: () => ({
    data: {
      title: null,
      attachments: [],
    },
    images: [],
    containerHeight: 0,
    containerWidth: 1280,
    deleteLoading: false,
  }),
  created() {
    this.containerWidth = this.$vuetify.breakpoint.width * 0.666667
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        const { data } = await fetchData(this.id)
        this.data = data
        const { containerHeight, boxes } = justifiedLayout(data.attachments, {
          containerWidth: this.containerWidth,
          containerPadding: {
            top: 0,
            right: 50,
            bottom: 8,
            left: 0,
          },
          boxSpacing: {
            horizontal: 18,
            vertical: 18,
          },
          showWidows: true,
          targetRowHeight: 180,
        })
        this.containerHeight = containerHeight
        this.images = boxes
      } catch (e) {
        console.log(e)
      }
    },
    async deletePortfolio(id) {
      try {
        this.deleteLoading = true
        await destroy(id)
        this.deleteLoading = false
        this.$router.push({ name: 'userPortfolioIndex' })
      } catch (e) {
        this.deleteLoading = false
      }
    },
  },
}
</script>
