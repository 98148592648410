<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8">
      <v-card>
        <v-card-title>
          Apply Project
        </v-card-title>

        <v-card-text>
          <div v-if="data.type === 'academic-team'" class="subtitle-1 pl-4">
            <div class="py-4">
              Please ensure all Proposed Project Fee is below the company’s
              Budget Project Fee
            </div>

            <v-card tile max-width="500">
              <v-list-item two-line>
                <v-list-item-content v-if="data.is_government_subsidy">
                  <v-list-item-title
                    >Company’s Budget for Team Lead:
                    <span class="orange--text tw-text-xl"
                      >${{ data.cost_min }}</span
                    ></v-list-item-title
                  >
                  <!--v-list-item-content
                    >It is the entire budget for all wages paid out to the
                    students and Team Lead throughout the duration of the
                    project.</v-list-item-content
                  -->
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title
                    >Company’s Budget for team lead:
                    <span class="orange--text tw-text-xl"
                      >${{ data.cost_min }}</span
                    ></v-list-item-title
                  >
                  <v-list-item-content
                    >Revenue sharing with students is
                    optional.</v-list-item-content
                  >
                </v-list-item-content>
              </v-list-item>
              <!-- 只有需要解决方案时显示此项 -->
              <v-list-item two-line v-if="data.provide_solution">
                <v-list-item-content>
                  <v-list-item-title
                    >Company’s Budget for Project Fee:
                    <span class="orange--text tw-text-xl"
                      >${{ data.actual_cost_max }}</span
                    ></v-list-item-title
                  >
                  <v-list-item-content
                    >It is the entire budget for all additional expenses to
                    carry out a solution for the project. (i.e. project fee may
                    be the total budget for Ad expenses for a marketing
                    project)</v-list-item-content
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
          <v-form ref="form" v-model="form">
            <v-row>
              <v-col
                cols="12"
                v-if="data.questions && data.questions.length > 0"
                sm="8"
              >
                <template v-for="(q, index) in answer">
                  <v-textarea
                    :label="`${q.question}`"
                    :key="`question-${index}`"
                    :prefix="`*`"
                    :rules="[rules.requiredAnswer]"
                    v-model="q.content"
                    rows="1"
                  >
                  </v-textarea>
                </template>
              </v-col>
              <!-- 不是学术团队填写的内容-->
              <template v-if="data.type !== 'academic-team'">
                <v-col cols="12" v-if="data.cost_max > 0" sm="8">
                  <v-text-field
                    v-model="cost"
                    :label="
                      data.type === 'academic-team' && data.provide_solution
                        ? 'Total Wage for Academic team'
                        : 'Cost'
                    "
                    :rules="[
                      rules.required,
                      rules.cost(data.cost_min, data.cost_max),
                    ]"
                    :hint="`Must be between $${data.cost_min} - $${data.cost_max}`"
                  >
                    <v-icon slot="prepend" color="red">mdi-currency-usd</v-icon>
                  </v-text-field>
                </v-col>
              </template>
              <!-- 学术团队项目 -->
              <template v-if="data.type === 'academic-team'">
                <!-- 四种情况 是否申请补助 是否需要解决方案 -->
                <!-- 1.需要解决方案 未申请补助 -->
                <template
                  v-if="data.provide_solution && !data.is_government_subsidy"
                >
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="project_budget"
                      label="Proposed Project Fee"
                      :rules="[rules.required]"
                      persistent-hint
                      hint="any additional expenses to carry out a solution for the project"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="cost"
                      :label="'Budget for team lead'"
                      :rules="[rules.required]"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8" class="pl-12">
                    <v-text-field
                      v-model="students_count"
                      label="How many International students will work in this project?"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <!--                  <v-col cols="12" sm="8" class="pl-12">-->
                  <!--                    <v-text-field-->
                  <!--                      v-model="canada_students_count"-->
                  <!--                      label="How many Canadian students will work on this project?"-->
                  <!--                      type="number"-->
                  <!--                    >-->
                  <!--                    </v-text-field>-->
                  <!--                  </v-col>-->
                </template>
                <!-- 2. 需要解决方案 申请了政府补助 -->
                <template
                  v-if="data.provide_solution && data.is_government_subsidy"
                >
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="project_budget"
                      label="Proposed Project Fee"
                      :rules="[rules.required]"
                      persistent-hint
                      hint="any additional expenses to carry out a solution for the project"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      disabled
                      :value="data.students_budget"
                      label="Total Student Wage"
                      persistent-hint
                      hint="field is automated"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="cost"
                      :rules="[rules.required]"
                      label="Total Team Lead Wage"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                </template>
                <!-- 3. 申请了政府补助 不需要解决方案-->
                <template
                  v-if="!data.provide_solution && data.is_government_subsidy"
                >
                  <v-col cols="12" sm="8">
                    <v-text-field
                      disabled
                      :value="data.students_budget"
                      label="Total Student Wage"
                      persistent-hint
                      hint="field is automated"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      disabled
                      :value="data.team_lead_budget"
                      label="Total Team Lead Wage"
                    >
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                </template>
                <!-- 4. 没有申请政府补助 不需要解决方案-->
                <template
                  v-if="!data.provide_solution && !data.is_government_subsidy"
                >
                  <v-col cols="12" sm="8">
                    <v-text-field v-model="cost" label="Wage for team lead">
                      <v-icon slot="prepend" color="red"
                        >mdi-currency-usd</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" class="pl-12">
                    <v-text-field
                      v-model="students_count"
                      label="How many International students will work in this project?"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <!--                  <v-col cols="12" sm="8" class="pl-12">-->
                  <!--                    <v-text-field-->
                  <!--                      v-model="canada_students_count"-->
                  <!--                      label="How many Canadian students will work on this project?"-->
                  <!--                      type="number"-->
                  <!--                    >-->
                  <!--                    </v-text-field>-->
                  <!--                  </v-col>-->
                </template>
              </template>
              <!--              <v-col cols="12" class="py-0 pl-10">-->
              <!--                <template-->
              <!--                  v-if="-->
              <!--                    !data.is_government_subsidy || data.type !== 'academic-team'-->
              <!--                  "-->
              <!--                  >Your budget for Academic Teams/ Team lead includes all wages-->
              <!--                  paid out while your budget for the project fee includes all-->
              <!--                  additional expenses needed to complete the project. They are-->
              <!--                  completely separate. </template-->
              <!--                >All costs are negotiable, and you will have the final say.-->
              <!--                <br />The Team Lead Wage is negotiable. After submitting your-->
              <!--                solution proposal, if you are the selected candidate, ensure-->
              <!--                both parties have agreed on the final website price.-->
              <!--              </v-col>-->
              <v-col
                cols="12"
                sm="8"
                v-if="data.type === 'academic-team' && data.provide_solution"
              >
                <v-sheet class="upload-btn">
                  <v-text-field
                    v-model="file_url"
                    prepend-inner-icon="mdi-paperclip"
                    label="Upload your solution proposal"
                    :disabled="true"
                  ></v-text-field>
                  <v-btn @click="fileUpload" :loading="uploading" elevation="1">
                    <v-icon>mdi-upload</v-icon>Upload
                  </v-btn>
                  <v-btn
                    text
                    class="tw-ml-4"
                    color="primary"
                    :href="
                      data.is_government_subsidy
                        ? 'https://docs.google.com/document/d/1MTSp1O8LYxAptZ1WB9dtiv8tblGx98CdUJJzt3XTN2M'
                        : 'https://docs.google.com/document/d/1kcdwHsoy0a2gTB9af8JVVzDN6sL3hbo8B-7vDm9c2Io'
                    "
                    target="_blank"
                    >Download the solution proposal template</v-btn
                  ><span
                    >Please download the solution proposal template to complete.
                    There is important information on there that you should note
                    with regards to the job posting when applying.
                  </span>
                  <input
                    ref="file"
                    type="file"
                    class="file-input"
                    @change="handleUpload"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!form || isLoading || uploading"
            :loading="isLoading"
            @click="apply"
            >Apply</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="$router.go(-1)">Back</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { apply as applyProject, fetchData } from '@/api/project'
import { get } from 'lodash'
import axios from '@/plugins/axios'
const uploadUrl = process.env.VUE_APP_BASE_URL + 'upload'
export default {
  computed: {
    id() {
      return this.$route.params.id || 0
    },
  },
  watch: {
    'data.questions'(questions) {
      if (Array.isArray(questions)) {
        questions.forEach((item, index) => {
          this.$set(this.answer, index, {
            question: item.title,
            required: item.checked,
            content: '',
          })
        })
      }
    },
  },
  data: () => ({
    form: false,
    isLoading: false,
    cost: null,
    answer: [],
    file: null,
    file_url: null,
    rules: {
      required: (v) => !!v || 'This field is required',
      requiredAnswer: (v) => !!v || 'Please answer the questions',
      cost: (min, max) => (value) =>
        (value >= parseFloat(min) && value <= parseFloat(max)) ||
        `Must be $${min} - $${max}`,
    },
    data: {
      questions: [],
      id: 0,
      students_budget: null,
    },
    uploading: false,
    team_lead_budget: null,
    total_budget: null,
    project_budget: null,
    students_count: null,
    canada_students_count: null,
  }),
  async mounted() {
    const data = this.$store.state.project.data

    if (get(data, 'id', -1) === this.id) {
      this.data = this.$store.state.project.data
    } else {
      await this.loadData()
    }
    console.log(this.data)
  },
  methods: {
    async loadData() {
      const { data } = await fetchData(this.id)
      this.data = data
    },
    async apply() {
      this.isLoading = true
      try {
        const applyData = {
          cost: parseInt(this.cost),
          answer: this.answer,
          password: this.$store.state.project.password,
          project_budget: parseInt(this.project_budget),
          students_budget: this.data.students_budget,
          students_count:
            this.students_count === null ? 0 : this.students_count,
          canada_students_count:
            this.canada_students_count === null
              ? 0
              : this.canada_students_count,
        }
        console.log(this.data)
        if (
          this.file_url &&
          this.data.type === 'academic-team' &&
          this.data.provide_solution
        ) {
          applyData.file = this.file_url
        }
        const res = await applyProject(this.data.id, applyData)
        if (res.message) {
          this.$store.dispatch('message/pushMessage', {
            type: 'info',
            content: res.message,
            timeout: 3000,
          })
          this.$router.push(`/projects/${this.id}`)
        }
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    fileUpload() {
      this.$refs['file'].click()
    },
    // 上传文件
    async handleUpload(e) {
      const file = e.target.files[0]
      this.uploading = true
      try {
        const data = new FormData()
        data.append('upload_file', file)
        const res = await axios.post('upload_file', data)
        this.file_url = res.file_path
        this.uploading = false
      } catch (e) {
        console.log(e)
        this.uploading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.file-input {
  width: 0;
  height: 0;
}
</style>
