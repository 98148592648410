<template>
  <v-row justify="center" class="mx-0">
    <v-col cols="12" md="9" lg="8">
      <v-row>
        <v-col cols="12" md="6" align-self="center">
          <h3 class="text-h6 text-md-h4 text-center">One-time Projects</h3>
          <div class="text--secondary mt-5">
            🐝 Students - Gain experience, FAST. Also, it’s easier to complete
            than internships!
          </div>
          <div class="text--secondary mt-2">
            🍯 Companies - Post a one-time project and receive help immediately
            from our network!
          </div>
          <div class="mt-10 d-flex flex-wrap justify-center justify-md-start">
            <v-btn
              class="white--text mt-4 mx-2"
              color="orange"
              depressed
              :to="{ name: 'projectCreate' }"
              >Post a project</v-btn
            >
            <v-btn
              depressed
              color="orange"
              class="white--text mt-4 mx-2"
              :to="{ name: 'projectIndex' }"
              >View projects</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-img src="~@/assets/images/index_1.jpeg" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  computed: {
    isOneLine() {
      return this.$vuetify.breakpoint.width > 500
    },
  },
}
</script>
