import axios from '@/plugins/axios'
export const create = (data) => {
  return axios.post('organizations', data)
}

export const show = (id, params) => {
  return axios.get(`organizations/${id}`, { params })
}

export const fetch = (params) => {
  return axios.get('organizations/', { params })
}

export const destroy = (id) => {
  return axios.delete(`organizations/${id}`)
}

export const update = (id, data) => {
  return axios.put(`organizations/${id}`, data)
}
