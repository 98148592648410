<template>
  <v-card>
    <v-list-item>
      <v-list-item-avatar size="48">
        <router-link
          :to="{
            name: 'userShow',
            params: { id: item.user.id },
          }"
          style="text-decoration: none;"
        >
          <v-avatar>
            <v-img :src="item.user.avatar" />
          </v-avatar>
        </router-link>
      </v-list-item-avatar>
      <v-list-item-content class="flex-grow-1 flex-shrink-0">
        <user-name
          :name="item.user.name"
          :type="item.user.type"
          :id="item.user.id"
          :organization="item.user.organization"
        >
          <template v-slot:before
            ><v-img
              :src="item.user.country_flag"
              max-height="24"
              max-width="24"
              contain
              class="align-self-center mr-1"
            ></v-img
          ></template>
        </user-name>
        <v-list-item-subtitle>
          <v-chip x-small color="info">{{ item.user.type | userType }}</v-chip>
          <v-chip label x-small class="ml-1" color="info"
            ><v-icon x-small left>mdi-map-marker</v-icon
            >{{ item.user.country.name }}</v-chip
          >
        </v-list-item-subtitle>
        <v-list-item-subtitle>{{
          item.created_at | diffForHumans
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <div class="d-flex justify-end align-self-center mr-1">
        <v-chip color="orange" class="white--text">{{
          item | projectStatus
        }}</v-chip>
      </div>
    </v-list-item>
    <v-card-text>
      <div class="d-flex flex-nowrap">
        <div class="primary--text mr-1">Project Position:</div>
        <div class="max-line-1">{{ item.position }}</div>
      </div>
      <div class="d-flex flex-nowrap">
        <div class="primary--text mr-1 text-nowrap">Project Name:</div>
        <div class="max-line-1">{{ item.title }}</div>
      </div>
      <v-sheet
        class="pt-4"
        style="height: 5.125rem; overflow: hidden;"
        v-html="item.summary"
      ></v-sheet>
      <v-row>
        <v-col class="d-flex flex-column text-center">
          <span class="primary--text mr-1">Deadline</span>
          {{ item.closed_at | format }}
        </v-col>
        <v-col class="d-flex flex-column text-center">
          <span class="primary--text mr-1">Reward</span>
          <template v-if="item.cost_min <= 0"> Free</template>
          <template v-else>${{ item.cost_min }}</template>
        </v-col>
        <v-col class="d-flex flex-column text-center">
          <span class="primary--text mr-1">Type </span
          >{{ item.is_online ? 'Remote' : 'Onsite' }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-chip v-if="item.category" @click="changeCategory(item.category.id)">
        {{ item.category.name }}
      </v-chip>
      <v-btn class="ml-auto" text color="primary" :to="`/projects/${item.id}`">
        View More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import UserName from '@/components/User/Name'
export default {
  components: {
    UserName,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          user: {},
        }
      },
    },
  },
  methods: {
    changeCategory(id) {
      this.$emit('change-category', id)
    },
  },
}
</script>
