import Vue from 'vue'
import App from './App.vue'
import './assets/scss/base.scss'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import moment from 'moment'
import _ from 'lodash'

import UserName from '@/components/User/Name'
Vue.component('user-name', UserName)
import FaqLink from '@/components/FaqLink'
Vue.component('faq-link', FaqLink)
Vue.config.productionTip = false
Vue.filter('diffForHumans', function (value) {
  if (!value) return ''
  return moment(value).fromNow()
})
Vue.filter('format', function (value) {
  if (!value) return null
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('formatDate', function (value) {
  if (!value) return null
  return moment(value).format('YYYY-MM-DD')
})

Vue.filter('formatMonth', function (value) {
  if (!value) return null
  return moment(value).format('MMMM, YYYY')
})

Vue.filter('toFixed', function (value) {
  return parseFloat(value).toFixed(1)
})

Vue.filter('projectStatus', function (value) {
  if (value.is_closed) {
    return 'Unavailable'
  }
  switch (value.status) {
    case 'applying':
      return 'Available'
    case 'completed':
      return 'Completed'
    default:
      return 'Unavailable'
  }
})
Vue.filter('userType', function (value) {
  switch (value) {
    case 'person':
      return 'Individual'
    case 'company':
      return 'Company'
    case 'student':
      return 'Student'
    default:
      return value
  }
})
Vue.filter('Up', function (value) {
  return _.upperFirst(value)
})
Vue.filter('addHour', function (value) {
  return _.endsWith(value, 'hour') ||
    _.endsWith(value, 'Hour') ||
    _.endsWith(value, 'Year')
    ? value
    : value + '/Hour'
})
Vue.filter('noName', function (value) {
  if (!value) return 'Anonymous'
  return value
})

Vue.filter('replaceEmpty', function (value) {
  if (value) return value.replace('<p><br></p>', '')
  return value
})

Vue.prototype.$replaceEmpty = (content) => {
  if (content) return content.replace('<p><br></p>', '')
  return content
}

Vue.prototype.$message = (content, type = 'info', timeout = 3000) => {
  store.dispatch('message/pushMessage', {
    type,
    content,
    timeout,
  })
}

Vue.prototype.$routerTo = (link) => {
  if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
    try {
      window.location.href = link
    } catch (e) {
      console.log(e)
    }
  } else {
    router.push(link)
  }
}
Vue.prototype.$linkToFaq = () => {
  window.location.href =
    'https://docs.google.com/document/d/1DEAnsa14maa3GHIryE-rXDZfeIzAjc4NMFobED-B9Ts/edit?usp=sharing'
}

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
