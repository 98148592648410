export default {
  path: '/bootcamp',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: 'create',
      name: 'bootcampCreate',
      component: () => import(/* webpackChunkName: 'bootcamp' */'@/views/bootcamp/create'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: ':id',
      name: 'bootcampShow',
      component: () => import(/* webpackChunkName: 'bootcamp' */'@/views/bootcamp/show'),
    },
  ],
}
