export default {
  path: '/organizations',
  component: () => import('@/views/layouts/root'),
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      name: 'organizationIndex',
      component: () => import(/* webpackChunkName: 'organization' */'@/views/organization/index'),
    },
    {
      path: 'create',
      name: 'organizationCreate',
      component: () => import(/* webpackChunkName: 'organization' */'@/views/organization/create'),
      meta: {
        auth: true,
      },
    },
    {
      path: 'edit/:id',
      name: 'organizationEdit',
      component: () => import(/* webpackChunkName: 'organization' */'@/views/organization/create'),
    },
    {
      path: ':id',
      name: 'organizationShow',
      component: () => import(/* webpackChunkName: 'organization' */'@/views/organization/show'),
    },
  ],
}
