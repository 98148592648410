<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        readonly
        v-model="dateFormatted"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" @input="menu = false"> </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Deadline',
    },
    value: {
      type: String,
      default() {
        return new Date().toISOString().substr(0, 10)
      },
    },
  },
  data: () => ({
    menu: false,
  }),

  computed: {
    date: {
      get() {
        return this.parseDate(this.value)
      },
      set(newDate) {
        this.dateFormatted = this.formatDate(newDate)
      },
    },
    dateFormatted: {
      get() {
        return this.formatDate(this.date)
      },
      set(newDate) {
        this.$emit('input', newDate)
      },
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      return date
    },
    parseDate(date) {
      if (!date) return null
      return date
    },
  },
}
</script>
