<template>
  <v-row>
    <v-col>
      <v-card>
        <v-list-item>
          <v-list-item-avatar size="48">
            <router-link
              :to="{
                name: 'userShow',
                params: { id: data.user.id },
              }"
            >
              <v-avatar>
                <v-img class="elevation-6" :src="data.user.avatar"></v-img>
              </v-avatar>
            </router-link>
          </v-list-item-avatar>
          <v-list-item-content>
            <user-name
              :name="data.user.name"
              :type="data.user.type"
              :id="data.user.id"
              :organization="data.user.organization"
            ></user-name>
            <v-list-item-subtitle>
              <v-chip x-small color="info">{{
                data.user.type | userType
              }}</v-chip>
            </v-list-item-subtitle>
            <v-list-item-title class="grey--text text-caption">{{
              data.created_at | diffForHumans
            }}</v-list-item-title>
          </v-list-item-content>
          <v-row align="center" justify="end">
            <v-btn
              icon
              :loading="isLoading(data.user.id)"
              @click="
                isFollowed(data.user.id)
                  ? unfollow(data.user.id)
                  : follow(data.user.id)
              "
              :color="isFollowed(data.user.id) ? 'primary' : ''"
            >
              <v-icon class="mr-1">mdi-account-heart-outline</v-icon>
            </v-btn>
          </v-row>
        </v-list-item>
        <v-card-text v-if="data.content === ''">
          <v-alert type="info" text
            >Viewable only to the publisher
            <template v-if="!isLoggedIn"
              >, If you are the publisher of the this company challenge, please
              <router-link :to="{ name: 'AuthLogin' }">click here</router-link>
              to login</template
            ></v-alert
          >
        </v-card-text>
        <v-card-text v-else v-html="data.content"> </v-card-text>
        <v-chip
          v-if="data.is_best"
          color="deep-orange"
          class="ml-3"
          label
          outlined
        >
          <v-icon left>
            mdi-image-filter-vintage
          </v-icon>
          The Best
        </v-chip>
        <v-card-actions>
          <v-btn
            icon
            @click="data.is_liked ? unlikeReply(data.id) : likeReply(data.id)"
            :loading="loading"
            :color="data.is_liked ? 'primary' : ''"
          >
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <span class="text-body-2 grey--text mr-2">{{
            data.likes_count
          }}</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="isChallengeCreate && !data.is_best">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="checkBest"
                :loading="checkBestLoading"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Select the best solution</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { likable, like, unlike } from '@/api/like'
import { mapActions, mapGetters } from 'vuex'
import { checkReplyToBest } from '@/api/challenge'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    challengeUserId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userInfo']),
    isChallengeCreate() {
      return !!(
        this.isLoggedIn &&
        (this.challengeUserId === this.userInfo.id || this.userInfo.id <= 2)
      )
    },
  },
  data: () => ({
    loading: false,
    checkBestLoading: false,
  }),
  methods: {
    async likeReply(id) {
      try {
        this.loading = true
        const { message, status } = await like(id, likable.reply)
        this.$message(message)
        if (status === 'liked') {
          this.$emit('update', { id, type: status })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async unlikeReply(id) {
      try {
        this.loading = true
        const { status } = await unlike(id, likable.reply)
        // this.$message(message)
        if (status === 'unliked') {
          this.$emit('update', { id, type: status })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    isFollowed(id) {
      const index = this.$store.state.follow.data.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.data[index].followed
      return false
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    ...mapActions({
      follow: 'follow/follow',
      unfollow: 'follow/unfollow',
    }),
    async checkBest() {
      this.checkBestLoading = true
      try {
        const { message } = await checkReplyToBest(this.data.id)
        if (message) {
          this.$message(message)
          this.$emit('best')
        }
        this.checkBestLoading = false
      } catch (e) {
        this.checkBestLoading = false
      }
    },
  },
}
</script>
