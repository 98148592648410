<template>
  <header class="tw-bg-white tw-py-1 tw-font-rubik">
    <div class="tw-container tw-mx-auto">
      <div class="tw-flex tw-flex-row tw-justify-space-between">
        <router-link
          :to="{ name: 'Index' }"
          class="logo-image tw-flex tw-items-center"
        >
          <img src="@/assets/images/logo.png" style="max-height: 58px;" />
        </router-link>
        <div class="tw-flex tw-flex-grow"></div>
        <nav
          class="nav tw-text-black tw-text-sm tw-flex tw-flex-row tw-items-center tw-flex-shrink tw-flex-grow-0 tw-mr-4 tw-space-x-4"
        >
          <router-link to="/new-projects" class="tw-flex"
            ><span>PROJECTS</span>
          </router-link>
          <!--          <router-link to="/new-projects/academic-team" class="tw-flex"-->
          <!--            ><span>ACADEMIC TEAM</span>-->
          <!--          </router-link>-->
          <router-link to="/jobs" class="tw-flex">
            <span class="tw-uppercase">Internship/full-time job</span>
          </router-link>
          <router-link to="/new-network" class="tw-flex">
            <span>NETWORK</span>
          </router-link>
          <!--          <router-link to="/mentor-programs" class="tw-flex">-->
          <!--            <span>BOOTCAMP</span>-->
          <!--          </router-link>-->

          <!--          <router-link to="/new-links" class="tw-flex">-->
          <!--            <span>EXTERNAL OPPORTUNITIES</span></router-link-->
          <!--          >-->
          <!--router-link to="/new-blog" class="tw-flex"
            ><span>BLOG</span></router-link
          -->
        </nav>
        <nav
          class="auth tw-items-center tw-flex tw-flex-shrink tw-flex-grow-0 tw-text-sm tw-font-bold"
          v-if="!isLoggedIn"
        >
          <router-link :to="{ name: 'NewLogin' }" class="tw-p-4">
            <i class="fa fa-sign-in tw-mr-1"></i>LOG IN</router-link
          >
          <router-link
            :to="{ name: 'NewRegister' }"
            style="color: #fff;"
            class="tw-px-3 tw-py-3 tw-bg-black tw-text-white tw-rounded tw-ml-4 tw-flex tw-place-content-center tw-place-items-center"
          >
            <img
              src="@/assets/images/register.svg"
              class="tw-mr-1"
            />REGISTER</router-link
          >
        </nav>
        <nav
          class="tw-items-center tw-flex tw-flex-shrink tw-flex-grow-0 tw-ml-10"
          v-else
        >
          <router-link
            :to="{ name: 'notificationIndex' }"
            class="tw-mr-4"
            :class="
              userInfo.unread_notifications_count > 0
                ? 'tw-text-orange-400'
                : 'tw-text-gray-600'
            "
          >
            <i class="fa fa-bell" aria-hidden="true"></i>
            <span
              class="tw-text-xs"
              v-if="userInfo.unread_notifications_count > 0"
              >{{ userInfo.unread_notifications_count }}</span
            >
          </router-link>
          <div class="tw-relative">
            <span @click="navHidden = !navHidden" class="tw-cursor-pointer">
              <img
                :src="userInfo.avatar"
                class="tw-w-10 tw-h-10 tw-rounded-full"
                :alt="userInfo.name"
              />
            </span>
            <div
              :style="navHidden ? 'display: none;' : 'display: block;'"
              class="tw-absolute tw-z-50 tw-w-48 tw-rounded-md tw-shadow-lg tw-origin-top-right tw-right-0"
            >
              <div class="tw-rounded-md tw-shadow-xs tw-py-1 tw-bg-white">
                <template v-for="(item, i) in userMenus">
                  <div
                    class="tw-block tw-px-4 tw-py-2 tw-text-xs tw-text-gray-400"
                    :key="`user-menu-title-${i}`"
                    v-if="i === 0"
                  >
                    Profile Manage
                  </div>
                  <div
                    class="tw-block tw-px-4 tw-py-2 tw-text-xs tw-text-gray-400"
                    :key="`user-menu-title-${i}`"
                    v-if="i === 3"
                  >
                    Published
                  </div>
                  <div
                    class="tw-block tw-px-4 tw-py-2 tw-text-xs tw-text-gray-400"
                    :key="`user-menu-title-${i}`"
                    v-if="i === 8"
                  >
                    Other
                  </div>

                  <router-link
                    :key="`user-menu-${i}`"
                    v-if="item.route && (!item.isAdmin || userInfo.is_admin)"
                    :to="item.route"
                    class="tw-block tw-my-2 tw-px-4 tw-py-2 tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-bg-gray-100 tw-transition tw-duration-150 tw-ease-in-out"
                    >{{ item.name }}</router-link
                  >
                  <template v-else-if="item.name === 'Logout'">
                    <div
                      class="tw-border-t tw-border-gray-100"
                      :key="`user-border-${i}`"
                    ></div>
                    <span
                      :key="`user-menu-${i}`"
                      class="tw-cursor-pointer tw-block tw-px-4 tw-py-2 tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-bg-gray-100 tw-transition tw-duration-150 tw-ease-in-out"
                      @click="logout"
                      >Logout</span
                    >
                  </template>
                </template>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div
      class="tw-bg-white tw-border-t tw-mt-1 tw-mb-4"
      style="box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08); border-color: #ddd;"
    >
      <div class="tw-container tw-mx-auto">
        <nav
          class="tw-text-gray-600 tw-flex tw-flex-row tw-justify-between tw-space-x-4 tw-mx-4 tw-text-sm"
        >
          <div
            v-for="(item, index) in categories"
            :key="`nav-category-${index}`"
            @mouseover="setShow(index)"
            @mouseleave="setHide(index)"
          >
            <router-link
              :to="{
                name: routerName,
                query: { category_id: item.id },
              }"
              tag="button"
              :disabled="routerName === 'NewNetwork'"
              class="tw-relative tw-flex-grow hover:tw-text-orange-400"
            >
              <div
                class="tw-py-4 border-color-primary-hover tw-text-base tw-border-b-2 tw-border-transparent tw-font-rubik"
                style="
                  color: #8a92ab;
                  font-weight: normal;
                  font-stretch: normal;
                  letter-spacing: 0;
                "
              >
                {{ item.name }}
              </div>
              <div
                class="tw-absolute tw-z-50 tw-rounded-md tw-shadow-lg"
                :style="`display: ${showArray[index] ? 'block' : 'none'}; ${
                  index === 0 || index === 1 || index === 3
                    ? 'left: -10px;'
                    : index === 2
                    ? 'transform: translateX(-10%);'
                    : index === 4
                    ? 'transform: translateX(-50%);'
                    : 'transform: translateX(-70%);'
                }`"
                style="z-index: 99;"
                v-if="item.children.length > 0"
                :ref="`dropdown-${index}`"
              >
                <div class="tw-rounded-md tw-shadow-xs tw-p-4 tw-bg-white">
                  <div class="tw-grid" style="grid-template-columns: 1fr 1fr;">
                    <router-link
                      v-for="(sub, i) in item.children"
                      :key="`nav-category-${index}-sub-${i}`"
                      :to="{
                        name: routerName,
                        query: { category_id: sub.id },
                      }"
                      class="tw-text-base tw-mx-2 tw-my-2 tw-px-4 tw-py-2 tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-bg-gray-100 tw-transition tw-duration-150 tw-ease-in-out"
                      style="
                        white-space: nowrap;
                        color: #8a92ab;
                        font-weight: normal;
                        font-stretch: normal;
                        letter-spacing: 0;
                      "
                      >{{ sub.name }}</router-link
                    >
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'userInfo']),
    ...mapState({
      categories: (state) =>
        state.category.data.filter((i) => i.parent_id === 0),
    }),
    userMenus() {
      const user_id = this.isLoggedIn ? this.userInfo.id : 0
      return [
        {
          name: 'My Profile',
          route: {
            name: 'NewProfile',
          },
        },
        {
          name: 'Edit Profile',
          route: {
            name: 'userSettings',
          },
        },
        {
          name: 'Add Portfolio',
          route: {
            name: 'portfolioCreate',
          },
        },
        {
          name: 'Posted Projects',
          route: {
            name: 'userProjects',
            params: {
              id: user_id,
            },
          },
        },
        // {
        //   name: 'Posted Discussions',
        //   route: {
        //     name: 'userChallenges',
        //     params: { id: user_id },
        //   },
        // },
        {
          name: 'Received Projects',
          route: {
            name: 'userReceives',
            params: { id: user_id },
          },
        },
        // {
        //   name: 'My Replies',
        //   route: {
        //     name: 'userReplies',
        //     params: { id: user_id },
        //   },
        // },
        {
          name: 'Performance Review',
          route: {
            name: 'userReviews',
            params: { id: user_id },
          },
        },
        {
          name: 'Organization Manage',
          route: {
            name: 'organizationIndex',
          },
          isAdmin: true,
        },
        {
          name: 'Links Manage',
          route: {
            name: 'linkIndex',
          },
          isAdmin: true,
        },
        {
          name: 'Promotions Manage',
          route: {
            name: 'promotionIndex',
          },
          isAdmin: true,
        },
        {
          name: 'Change Password',
          route: {
            name: 'userPassword',
          },
        },
        {
          name: 'Subscribe New Project',
          route: {
            name: 'settingSubscribe',
          },
        },
        {
          name: 'Logout',
        },
      ]
    },
    routerName() {
      if (this.$route.name === 'NewNetwork') {
        return 'NewNetwork'
      } else {
        return 'NewProjects'
      }
    },
  },
  data: () => ({
    showCategory: false,
    showArray: [],
    navHidden: true,
    isSubscribeNewProject: false,
  }),
  mounted() {
    if (this.categories.length <= 0) {
      this.$store.dispatch('category/getCategories').then(() => {
        this.setCategories()
      })
    } else {
      this.setCategories()
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        location.reload()
      })
    },
    setCategories() {
      this.categories.forEach((i, index) => {
        this.$set(this.showArray, index, false)
      })
    },
    setShow(index) {
      this.$set(this.showArray, index, true)
    },
    setHide(index) {
      this.$set(this.showArray, index, false)
    },
  },
}
</script>
<style lang="scss" scoped>
.nav {
  .router-link-active {
    span {
      font-weight: 500;
      position: relative;
      &:after {
        content: '';
        border-bottom: 2px solid #000;
        width: 38%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
.tw-container {
  max-width: 1150px !important;
}
.text-primary {
  color: #ff9800;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}

.border-color-2 {
  border-color: #fecc17;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.text-gray-1 {
  color: #8a92ab;
}
.border-color-primary-hover {
  &:hover {
    border-color: #ff9800;
  }
}
</style>
