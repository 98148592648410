<template>
  <v-list-item-title class="d-flex" style="overflow: visible;">
    <slot name="before"></slot>
    <router-link
      :to="{
        name: 'userShow',
        params: { id: id },
      }"
      class="align-self-center"
      style="text-decoration: none;"
    >
      {{ name }}
    </router-link>
    <template v-if="organization && organization.id">
      <user-badge :organization="organization"></user-badge>
    </template>
  </v-list-item-title>
</template>
<script>
import UserBadge from '@/components/User/Badge'
export default {
  name: 'UserName',
  components: {
    UserBadge,
  },
  props: ['name', 'type', 'id', 'organization'],
  mounted() {
    // console.log(this.organization)
  },
}
</script>
