<template>
  <v-card outlined flat>
    <v-toolbar flat>
      <v-toolbar-title>Verify Your Email Address</v-toolbar-title>
    </v-toolbar>
    <template v-if="(id && hash && !verify_error) || checkLoading">
      <loading :loading="true"></loading>
    </template>
    <template v-else>
      <v-card-text>
        <v-alert color="blue" text>
          A {{ isSend ? 'new ' : '' }} fresh verification link has been sent to
          your email address.
        </v-alert>
        <v-sheet
          >Before proceeding, please check your email for a verification
          link.</v-sheet
        >
      </v-card-text>
      <v-card-actions>
        <v-sheet class="pa-2"
          >If you did not receive the email<v-btn
            text
            style="text-transform: none;"
            @click="resend"
            :loading="loading"
            >click here to request another</v-btn
          ></v-sheet
        >
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
import axios from '@/plugins/axios'
import moment from 'moment'
import loading from '@/components/Loading'
import { mapGetters } from 'vuex'
export default {
  components: {
    loading,
  },
  data: () => ({
    loading: false,
    isSend: false,
    verify_error: false,
    checkLoading: false,
  }),
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    hash() {
      return this.$route.params.hash || null
    },
    ...mapGetters(['emailVerify', 'isLoggedIn']),
  },
  async mounted() {
    this.checkLoading = true
    try {
      await this.$store.dispatch('auth/isLogged')
      console.log(this.isLoggedIn)
      if (!this.isLoggedIn) {
        this.$router.push({ name: 'AuthLogin' })
      }
    } catch (e) {
      console.log('Error ?', e)
    }

    this.checkLoading = false
    if (this.emailVerify) {
      this.$router.push(this.$store.state.auth.redirectTo)
    }
    if (this.id && this.hash) {
      this.verify()
    }
  },
  methods: {
    async verify() {
      if (this.loading) return
      try {
        this.loading = true
        const { status_code } = await axios.get(
          `email/verify/${this.id}/${this.hash}`,
          {
            params: {
              expires: this.$route.query.expires,
              signature: this.$route.query.signature,
            },
          }
        )
        if (status_code === 204) {
          this.$store.commit('auth/SET_EMAIL_VERIFY', moment())
          this.$router.push({ name: 'userSettings' })
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.verify_error = true
        }
      } finally {
        this.loading = false
      }
    },
    async resend() {
      if (this.loading) return
      try {
        this.loading = true
        const { status_code } = await axios.post('email/resend')
        if (status_code === 202) {
          this.isSend = true
        }
        if (status.code === 204) {
          // 已经验证了
          this.$store.commit('auth/SET_EMAIL_VERIFY', moment())
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
