<template>
  <div>
    <div
      class="tw-container tw-flex tw-flex-row tw-justify-between tw-mt-32 tw-mx-auto"
    >
      <div style="width: 271px;">
        <div style="width: 102px; height: 59px;">
          <img src="@/assets/images/logo.png" />
        </div>
        <div class="tw-mt-4 tw-text-gray-1">
          A student exclusive freelance platform where anyone can outsource
          remote tasks to students.
        </div>
        <div class="tw-flex tw-flex-row tw-my-4 text-gray-1 tw-w-full">
          <a
            href="https://www.linkedin.com/company/beeznests"
            target="_blank"
            class="tw-border tw-mr-2 tw-p-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-rounded-full"
          >
            <i class="fa fa-linkedin"></i>
          </a>
          <a
            href="https://www.facebook.com/beeznests"
            target="_blank"
            class="tw-border tw-mr-2 tw-p-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-rounded-full"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a
            href="https://twitter.com/beeznests"
            target="_blank"
            class="tw-border tw-mr-2 tw-p-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-rounded-full"
          >
            <i class="fa fa-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/beeznests/"
            class="tw-border tw-mr-2 tw-p-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-rounded-full"
          >
            <i class="fa fa-instagram"></i>
          </a>
          <a
            href="mailto:ourhoneycomb@beeznests.com"
            class="tw-border tw-mr-2 tw-p-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center tw-rounded-full"
          >
            <i class="fa fa-envelope"></i>
          </a>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-flex-grow-0">
        <strong class="tw-mb-4">Our Links</strong>

        <a
          class="tw-my-2 text-gray-1"
          href="https://docs.google.com/document/d/1kmmaQLHh9ZEanH6yhjygx3Jl4ZN67or32Vju0X_qNHI/edit"
          >Faqs</a
        >
        <router-link
          :to="{ name: 'articleShow', params: { id: 17 } }"
          class="tw-my-2 text-gray-1"
        >
          How it Works</router-link
        >
        <router-link :to="{ name: 'NewRegister' }" class="tw-my-2 text-gray-1"
          >Register</router-link
        >
        <router-link :to="{ name: 'NewBlog' }" class="tw-my-2 text-gray-1"
          >Blog</router-link
        >
      </div>
      <div class="tw-flex tw-flex-col tw-flex-grow-0">
        <strong class="tw-mb-4">Our Services</strong>
        <router-link :to="{ name: 'NewOur' }" class="tw-my-2 text-gray-1"
          >About Us</router-link
        >
        <!--        <router-link :to="{ name: 'Sponsorship' }" class="tw-my-2 text-gray-1"-->
        <!--          >Sponsorship Package</router-link-->
        <!--        >-->
        <router-link
          :to="{ name: 'PaymentUserCost' }"
          class="tw-my-2 text-gray-1"
          >Membership Package for Student</router-link
        >
        <router-link
          :to="{ name: 'MembersPackageForCollege' }"
          class="tw-my-2 text-gray-1"
          >Membership Package for College</router-link
        >

        <a href="mailto:jin.liu@beeznests.com" class="tw-my-2 text-gray-1">
          Contact Us
        </a>
      </div>
      <div class="tw-flex tw-flex-col tw-flex-grow-0" style="width: 271px;">
        <strong class="tw-mb-4">Newsletter</strong>
        <span class="tw-text-gray-1"
          >Don’t miss out on any upcoming events or opportunities.</span
        >
        <div class="tw-flex tw-flex-col tw-justify-center tw-flex-1">
          <form class="tw-w-full tw-max-w-sm">
            <div
              class="tw-flex tw-items-center tw-border-2 tw-border-yellow-300 tw-rounded"
            >
              <input
                class="tw-appearance-none tw-bg-transparent tw-border-none tw-w-full tw-text-gray-700 tw-mr-3 tw-py-1 tw-px-2 tw-leading-tight focus:tw-outline-none"
                type="email"
                placeholder="Enter your email address"
                aria-label="Email"
                required
                v-model="subscribeEmail"
              />
              <button
                class="tw-uppercase tw-outline-none focus:tw-outline-none tw-flex-shrink-0 tw-bg-yellow-300 hover:tw-bg-yellow-200 tw-border-yellow-300 hover:tw-border-yellow-200 active:tw-border-yellow-200 focus:tw-border-yellow-200 tw-text-sm tw-border-4 tw-text-white tw-py-2 tw-px-4"
                type="button"
                :disabled="subscribeLoading"
                @click="subscribe"
              >
                <i class="fa fa-spinner fa-spin" v-if="subscribeLoading"></i>
                Subscribe
              </button>
            </div>
            <div
              v-if="subscribeMessage"
              :class="{ 'tw-text-red-500': subscribeError }"
            >
              {{ subscribeMessage }}
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="tw-container tw-mx-auto">
      <div class="tw-py-6 tw-flex tw-justify-between tw-border-t text-gray-1">
        <span>© 2020 Beeznests Connection Ltd. All rights reserved.</span>
        <div class="tw-flex tw-flex-grow-0 tw-divide-x tw-divide-gray-400">
          <div class="tw-text-center tw-px-3">
            <router-link to="/blog/6" class="text-gray-2"
              >Terms of Use</router-link
            >
          </div>
          <div class="tw-text-center tw-px-3">
            <router-link to="/blog/7" class="text-gray-2"
              >Privacy Policy</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { subscribe } from '@/api'

export default {
  data: () => ({
    subscribeLoading: false,
    subscribeMessage: null,
    subscribeError: false,
    subscribeEmail: null,
  }),
  methods: {
    async subscribe() {
      this.subscribeLoading = true
      this.subscribeError = false
      this.subscribeMessage = null
      try {
        await subscribe(this.subscribeEmail)
        this.subscribeMessage = "Thanks for joining Beeznests! You're awesome."
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.subscribeMessage = e.response.data.message
        }
        this.subscribeError = true
      } finally {
        this.subscribeLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tw-container {
  max-width: 1024px !important;
}

.text-primary {
  color: #ff9800;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}

.text-gray-1 {
  color: #8a92ab;
}
</style>
