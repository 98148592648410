import axios from '@/plugins/axios'

export const fetchIndex = (params) => {
  return axios.get('projects', {
    params,
  })
}

export const fetchData = (id, params) => {
  return axios.get(`projects/${id}`, {
    params,
  })
}

export const fetchReceiver = (id, params) => {
  return axios.get(`projects/${id}/receiver`, {
    params,
  })
}

export const paymentUrl = (id) => {
  return axios.get(`projects/payment/${id}`)
}

export const create = (data) => {
  return axios.post('projects', data)
}

export const update = (id, data) => {
  return axios.put(`projects/${id}`, data)
}

export const closed = (id) => {
  return axios.post(`projects/${id}/closed`)
}

export const apply = (id, data) => {
  return axios.post(`receive/${id}`, data)
}

export const complete = (id) => {
  return axios.post(`receive/${id}/complete`)
}

export const finished = (id) => {
  return axios.post(`receive/${id}/finished`)
}

export const review = (data) => {
  return axios.post(`review`, data)
}

export const destroy = (id) => {
  return axios.delete(`projects/${id}`)
}

export const getOrder = (id) => {
  return axios.get(`projects/${id}/order`)
}

export const fetchReceiveData = (id, params) => {
  return axios.get(`receive/${id}`, {
    params,
  })
}

export const sendRecommendation = (id, data) => {
  return axios.post(`receive/send_recommendation/${id}`, data)
}

export const checkApplyPassword = (id, data) => {
  return axios.post(`receive/check_password/${id}`, data)
}
