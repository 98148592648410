<template>
  <v-row justify="center" no-gutters>
    <div class="tw-w-full">
      <section
        class="tw-py-10 md:tw-pt-20 tw-px-6 mx:tw-px-0 tw-testimonial tw-w-full"
        style="background-image: linear-gradient(135deg, #fecc17, #ff9800);"
      >
        <!--blockquote
        class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-8 tw-max-w-3xl"
      >

        <p class="tw-mb-6 tw-text-lg">
          Our mission is to help companies get projects done at a low and
          affordable price. We provide a simple and convenient platform where
          you can hire, pay, and leave performance reviews for students directly
          on our platform. Focus on hiring the right student, and let us
          automate everything else.
        </p>
      </blockquote-->
        <blockquote
          class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-8 tw-max-w-3xl"
        >
          <p class="tw-text-4xl">Membership Package for Students</p>
          <p class="tw-text-2xl">Who are we?</p>
          <p class="tw-mb-6 tw-text-lg">
            Beeznests Connection is a remote student freelance platform that
            helps students get more Canadian or global work experience to
            advance their careers.
          </p>
        </blockquote>
        <blockquote
          class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-12 tw-max-w-3xl"
        >
          <p class="tw-text-2xl">Why work with us?</p>
          <p class="tw-mb-6 tw-text-lg">
            Access to all the remote projects opportunities
            <br />
            Access to referral students database for special paid
            project/internship/full-time job.
            <br />
            Create your own project and be your own project manager
            <br />
          </p>
        </blockquote>
        <!--        <blockquote-->
        <!--          class="tw-text-center tw-text-white tw-text-xl md:tw-text-xl tw-mx-auto tw-leading-tight md:tw-mb-12 tw-max-w-3xl"-->
        <!--        >-->
        <!--          The one-time member fee of $20 or $800 (after you pay the one-time-->
        <!--          membership fee, you will be able to apply for any projects/internships-->
        <!--          on our website in the future)-->
        <!--        </blockquote>-->
      </section>
      <section class="tw-py-10 md:tw-py-0 md:tw-mb-20 tw-px-6 md:tw-px-0">
        <div
          class="tw-container md:tw-flex md:tw-justify-center tw-mx-auto tw-mb-10 md:tw-mb-10"
        >
          <div
            v-for="item in items"
            :key="item.name"
            class="tw-relative tw-pb-cta tw-shadow-lg tw-overflow-hidden tw-bg-white tw-rounded-lg tw-w-card tw-mx-auto md:tw-mr-10 tw-mb-10 md:tw-mb-0"
          >
            <div class="tw-pt-8 tw-pb-10 tw-px-6 md:tw-px-10">
              <h4
                class="text-center tw-uppercase tw-text-xl tw-tracking-wide tw-mb-3"
              >
                {{ item.name }}
              </h4>
              <p class="tw-align-text-top tw-mb-4 text-center">
                <sup
                  class="tw-font-light tw-text-50 tw-text-2xl"
                  v-if="item.price > 0"
                  >$</sup
                >
                <span class="tw-font-light tw-text-90 tw-text-price">{{
                  item.price > 0 ? item.price : 'free'
                }}</span>
                <span class="tw-text-base">{{ item.description }}</span>
              </p>
              <!--              <p>-->
              <!--                <span class="tw-block tw-text-left">{{-->
              <!--                  item.description-->
              <!--                }}</span>-->
              <!--              </p>-->
              <ul class="tw-list-reset tw-leading-normal tw-text-80 tw-pl-0">
                <li
                  class="tw-flex tw-items-start tw-py-2"
                  style="color: rgb(255, 152, 0);"
                >
                  Benefits:
                </li>
                <li
                  v-for="(i, index) in item.items"
                  :key="`${item.name}-${index}`"
                  class="tw-flex tw-items-start tw-py-2"
                  style="color: rgb(138, 146, 171);"
                >
                  <template v-if="i === 'R'">
                    <span style="color: rgb(255, 152, 0);">Requirements:</span>
                  </template>
                  <template v-else>
                    <i
                      class="fa fa-check-circle tw-mr-1"
                      aria-hidden="true"
                      style="color: #ff9800; padding-top: 2px;"
                    ></i>
                    {{ i }}
                  </template>
                </li>
              </ul>
            </div>
            <div
              class="tw-cursor-pointer tw-absolute tw-bottom-0 tw-inset-x-0 tw-flex tw-items-center tw-justify-center tw-no-underline tw-h-cta tw-block tw-bg-10 hover:tw-bg-20 tw-text-center tw-text-primary tw-uppercase tw-tracking-wide tw-font-bold"
              @click="pay(item.price)"
            >
              {{ item.price > 0 ? 'Pay' : 'Join Us' }}
            </div>
          </div>
        </div>
        <div class="tw-max-w-3xl tw-mx-auto">
          <p class="tw-text-center text-90 tw-max-w-3xl">
            For any inquiries regarding Business Memberships, please email
            ourhoneycomb@beeznests.com
          </p>
        </div>
      </section>
    </div>
  </v-row>
</template>
<script>
import { getPaymentUserCostNo, setFreeStudent } from '@/api/setting'

export default {
  data: () => ({
    items: [
      {
        name: 'Basic',
        price: 0,
        oldPrice: 0,
        description: null,
        items: [
          'Able to apply for all the volunteer based projects for professional experience',
          // 'Free discussion posting',
          'Get email alerts for all the new global projects',
          'All the project/internship opportunities are remote, you can gain some global working experience from your home',
          //'Pay $4.99  service fee for each job posting',
          'Post your own project, get support from our community and create your own professional experience',
        ],
      },
      {
        name: 'Premium',
        price: 20,
        oldPrice: 300,
        description: 'one time fee',
        items: [
          'Access to Referred Student Database, Beeznests will promote your resume directly to hiring companies for all paid job opportunities.',
          // 'Free discussion posting',
          'Access to the exclusive job offers with the sponsorship opportunities',
          'Guaranteed interview opportunities with hiring companies',
          'Able to apply for exclusive internships, part time/ full time job opportunities on the website',
          'Get email alerts for all the new job opportunities',
          'Special Linkedin Student Spotlight promotion post',
        ],
      },
    ],
    cost: null,
  }),
  methods: {
    pay(cost) {
      this.paidLoading = true
      this.cost = cost
      if (cost > 0) {
        this.getOrderNo()
      } else {
        // 设置为不付费
        this.setFree()
      }
    },
    async setFree() {
      try {
        await setFreeStudent()
        this.$router.push('/')
      } catch {
        this.paidLoading = false
      }
    },
    async getOrderNo() {
      try {
        const { data } = await getPaymentUserCostNo(this.cost)
        if (!data.closed && !data.is_paid) {
          this.$router.push({
            name: 'Pay',
            params: {
              no: data.no,
            },
          })
        } else {
          this.$store.dispatch('message/pushMessage', {
            type: 'error',
            content: 'You have already paid the membership fee',
            timeout: 3000,
          })
          this.paidLoading = false
        }
      } catch {
        this.paidLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tw-text-price {
  font-size: 2.5rem;
}
.tw-text-60 {
  color: #4a90e2;
}
.tw-text-50 {
  color: #8a97a2;
}
.tw-text-90 {
  color: #202c3a;
}
.tw-container {
  max-width: 960px;
}
.tw-w-card {
  width: 22.5rem;
}
.tw-testimonial {
  padding-bottom: 190px;
  margin-bottom: -190px;
}
.tw-pl-0 {
  padding-left: 0 !important;
}
.tw-h-cta {
  height: 4.5rem;
}
.tw-bg-10 {
  background-color: #f7f9fb;
  &:hover {
    background-color: rgba(255, 152, 0, 0.75);
  }
}
.tw-bg-20 {
  background-color: #eef1f4;
}
.tw-pb-cta {
  padding-bottom: 4.5rem;
}
</style>
