<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-subtitle>
          <v-list-item class="px-0">
            <v-list-item-avatar size="48">
              <router-link
                :to="{
                  name: 'userIndex',
                  params: { id: user.id },
                }"
              >
                <v-avatar>
                  <v-img class="elevation-6" :src="user.avatar"></v-img>
                </v-avatar>
              </router-link>
            </v-list-item-avatar>
            <v-list-item-content style="overflow: visible;">
              <div class="d-flex">
                <v-img
                  :src="user.country_flag"
                  max-height="24"
                  max-width="24"
                  contain
                  class="align-self-center mr-1"
                ></v-img>
                <user-name
                  :name="user.name"
                  :type="user.type"
                  :id="user.id"
                  :organization="user.organization"
                ></user-name>
              </div>
              <div class="d-flex">
                <v-chip x-small color="warning">{{
                  parseFloat(user.counts.rate).toFixed(1)
                }}</v-chip>
                <v-rating
                  background-color="orange lighten-3"
                  color="orange"
                  :size="16"
                  disabled
                  dense
                  :value="parseFloat(user.counts.rate)"
                ></v-rating>
                <span class="grey--text"
                  >({{ user.counts.rate_count }} reviews)</span
                >
              </div>
            </v-list-item-content>
            <div class="align-center justify-end hidden-xs-only">
              <v-chip class="ma-2" color="deep-orange" text-color="white">
                Applied
              </v-chip>
            </div>
          </v-list-item>
        </v-card-subtitle>
        <v-card-text v-if="isPublisher">
          <v-btn
            depressed
            :href="user.resume_file"
            class="align-self-center"
            target="_blank"
          >
            View Resume
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    user: Object,
    isPublisher: Boolean,
  },
}
</script>
