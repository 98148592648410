<template>
  <div>
    <v-autocomplete
      v-model="newValue"
      :items="languages"
      chips
      label="Languages"
      item-text="name"
      item-value="id"
      multiple
      @change="handleChange"
      :loading="loading"
      :error-messages="errors"
      data-vv-name="languages"
    ></v-autocomplete>
  </div>
</template>
<script>
import { languages } from '@/api/setting'
import _ from 'lodash'
export default {
  props: ['value', 'errors'],
  data: () => ({
    languages: [],
    newValue: null,
    loading: false,
  }),
  async mounted() {
    await this.loadLanguages()
    this.newValue = _.cloneDeep(this.value)
  },
  methods: {
    async loadLanguages() {
      this.loading = true
      const { data } = await languages()
      this.languages = data
      this.loading = false
    },
    handleChange() {
      const v = this.newValue.map((item) => {
        const index = this.languages.findIndex((i) => i.id === item)
        return this.languages[index]
      })
      this.$emit('input', v)
    },
  },
}
</script>
