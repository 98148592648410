var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-4"})],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-form',[_c('v-text-field',{ref:"container",attrs:{"label":"Title"},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('div',{staticStyle:{"position":"relative"},style:({ height: _vm.containerHeight + 'px' })},[_c('v-fade-transition',{attrs:{"group":"","name":"list"}},[_vm._l((_vm.images),function(image,index){return [(image.url !== '')?_c('div',{key:`image-preview-${index}`,style:({
                position: 'absolute',
                left: image.left + 'px',
                top: image.top + 'px',
                width: image.width + 'px',
                height: image.height + 'px',
              })},[_c('v-img',{attrs:{"src":image.url,"width":image.width,"height":image.height,"contain":""}}),_c('v-progress-linear',{attrs:{"color":_vm.uploadProgressColor(_vm.uploadFiles[index].id),"buffer-value":"0","value":_vm.uploadFiles[index].progress,"stream":""}}),_c('v-btn',{staticStyle:{"position":"absolute","top":"-1em","right":"-1em"},attrs:{"icon":"","loading":_vm.uploadFiles[index].status === 'deleting'},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_c('div',{key:`image-preview-${index}`,staticStyle:{"position":"absolute"},style:({
                left: image.left + 'px',
                top: image.top + 'px',
                width: image.width + 'px',
                height: image.height + 'px',
              })},[_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"upload-btn pa-8 elevation-4"},[_c('v-icon',{staticClass:"icon",attrs:{"size":"36"}},[_vm._v("mdi-image-plus")]),_c('input',{staticClass:"file-input",attrs:{"type":"file","accept":"image/*, video/*","multiple":""},on:{"change":_vm.handleChange}})],1)],1)]})],2)],1),_c('v-sheet',{staticClass:"py-6"},[_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.submitLoading,"color":"deep-orange"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.id > 0 ? 'Save' : 'Submit'))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }