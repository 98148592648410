<template>
  <div>
    <div class="tw-grid tw-grid-cols-4 tw-gap-4">
      <template v-for="item in items">
        <div
          class="tw-flex tw-flex-col text-center tw-bg-gray-100 tw-rounded tw-py-3 tw-px-1"
          v-if="item.isShow"
          :key="item.name"
        >
          <strong class="orange--text tw-text-lg"
            ><template v-if="item.isMoney">$</template
            >{{ parseInt(item.value) }}</strong
          >
          {{ item.name }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: ['project', 'receiver'],
  computed: {
    isAcademicTeam() {
      return this.project.type === 'academic-team'
    },
    items() {
      return [
        {
          name: 'Budget for the project',
          value: this.receiver.project_budget,
          isShow: this.isAcademicTeam && this.project.provide_solution,
          isMoney: true,
        },
        {
          name: 'Wage for hiring team lead',
          value: this.receiver.total_cost,
          isShow: this.isAcademicTeam && !this.project.is_government_subsidy,
          isMoney: true,
        },
        {
          name: 'Wage for hiring students',
          value: this.receiver.students_fee,
          isShow: this.isAcademicTeam && this.project.is_government_subsidy,
          isMoney: true,
        },
        {
          name: 'Wage for hiring team lead',
          value: this.receiver.lead_fee,
          isShow: this.isAcademicTeam && this.project.is_government_subsidy,
          isMoney: true,
        },
        {
          name: 'Total Cost',
          value: this.receiver.total_cost,
          isShow: this.isAcademicTeam && this.project.is_government_subsidy,
          isMoney: true,
        },
        {
          name: 'Hired Candian students',
          value: this.receiver.canada_students_count,
          isShow: this.isAcademicTeam && !this.project.is_government_subsidy,
          isMoney: false,
        },
        {
          name: 'Hired International students',
          value: this.receiver.students_count,
          isShow: this.isAcademicTeam && !this.project.is_government_subsidy,
          isMoney: false,
        },
      ]
    },
  },
}
</script>
