var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"center"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'userShow',
          params: { id: _vm.item.id },
        }}},[_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.item.avatar}})],1)],1)],1)],1),_c('v-card-title',{staticClass:"justify-center pt-0"},[_c('span',{staticClass:"font-weight-light text-center tw-truncate"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'userShow',
          params: { id: _vm.item.id },
        }}},[_vm._v(_vm._s(_vm._f("noName")(_vm.item.name)))])],1)]),_c('v-card-text',[_c('v-sheet',{style:(_vm.isMobile
          ? 'height: 180px; overflow: hidden; text-overflow: ellipsis;'
          : 'height: 92px; overflow: hidden; text-overflow: ellipsis;')},[_vm._v(_vm._s(_vm.item.introduction))]),_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('v-chip',{staticClass:"my-1 py-1",attrs:{"color":"orange","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":"","size":"18","color":"orange"}},[_vm._v("mdi-email")]),_vm._v(_vm._s(_vm.item.email)+" ")],1)],1),(_vm.item.type === 'student')?_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text","center-active":""}},[(_vm.categories.length > 0)?_vm._l((_vm.categories),function(category){return _c('v-chip',{key:`student-${_vm.item.id}-category-${category.id}`,on:{"click":function($event){return _vm.changeCategory(category.id)}}},[_vm._v(_vm._s(category.name))])}):_c('v-chip',{attrs:{"label":""}},[_vm._v(" No Skills ")])],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }