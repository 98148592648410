<template>
  <div>
    <section
      class="tw-py-10 tw--mx-4 md:tw-pt-20 tw-px-6 mx:tw-px-0 tw-testimonial"
      style="background-image: linear-gradient(135deg, #fecc17, #ff9800);"
    >
      <!--blockquote
        class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-8 tw-max-w-3xl"
      >

        <p class="tw-mb-6 tw-text-lg">
          Our mission is to help companies get projects done at a low and
          affordable price. We provide a simple and convenient platform where
          you can hire, pay, and leave performance reviews for students directly
          on our platform. Focus on hiring the right student, and let us
          automate everything else.
        </p>
      </blockquote-->
      <blockquote
        class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-8 tw-max-w-3xl"
      >
        <p class="tw-text-4xl">UPGRADE YOUR BUSINESS MEMBERSHIP</p>
        <p class="tw-text-2xl">Who we are?</p>
        <p class="tw-mb-6 tw-text-lg">
          Beeznests Connection Ltd is the first ever “student exclusive
          freelance platform“ to connect businesses with students all around the
          world. Our mission is to help companies get remote projects done at a
          low and affordable price. All company projects and jobs posted will be
          completed by our partnering universities’ students. Get your tasks
          completed with academic support.
        </p>
      </blockquote>
      <blockquote
        class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-12 tw-max-w-3xl"
      >
        <p class="tw-text-2xl">Why work with us?</p>
        <p class="tw-mb-6 tw-text-lg">
          Our international platform is the perfect opportunity for businesses
          and individuals to reach out to young talent around the world. We have
          over 8,000 diverse students on our platform, and we provide a simple
          and convenient way of hiring, paying for projects, and leaving
          performance reviews for students directly on our platform. Focus on
          hiring the right student, and let us automate everything else
        </p>
      </blockquote>
    </section>
    <section class="tw-py-10 md:tw-py-0 md:tw-mb-20 tw-px-6 md:tw-px-0">
      <div
        class="tw-container md:tw-flex md:tw-justify-center tw-mx-auto tw-mb-10 md:tw-mb-10"
      >
        <div
          v-for="item in items"
          :key="item.name"
          class="tw-relative tw-pb-cta tw-shadow-lg tw-overflow-hidden tw-bg-white tw-rounded-lg tw-w-card tw-mr-10 tw-mb-10 md:tw-mb-0"
        >
          <div class="tw-pt-8 tw-pb-10 tw-px-6 md:tw-px-10">
            <h4
              class="text-center tw-uppercase tw-text-xl tw-tracking-wide tw-mb-3"
            >
              {{ item.name }}
            </h4>
            <p class="tw-align-text-top tw-mb-4 text-center">
              <sup class="tw-font-light tw-text-50 tw-text-2xl">$</sup>
              <span class="tw-font-light tw-text-90 tw-text-price">{{
                item.price
              }}</span>
              <template v-if="item.name !== 'Basic'">
                <span
                  class="tw-font-light tw-text-90 tw-text-xl tw-line-through"
                  >${{ item.oldPrice }}</span
                ></template
              ><span class="tw-text-base">/Year</span>
            </p>
            <p>
              <span class="tw-block tw-text-left">{{ item.description }}</span>
            </p>
            <ul class="tw-list-reset tw-leading-normal tw-text-80 tw-pl-0">
              <li
                class="tw-flex tw-items-start tw-py-2"
                style="color: rgb(255, 152, 0);"
              >
                Benefits:
              </li>
              <li
                v-for="(i, index) in item.items"
                :key="`${item.name}-${index}`"
                class="tw-flex tw-items-start tw-py-2"
                style="color: rgb(138, 146, 171);"
              >
                <template v-if="i === 'R'">
                  <span style="color: rgb(255, 152, 0);">Requirements:</span>
                </template>
                <template v-else>
                  <i
                    class="fa fa-check-circle tw-mr-1"
                    aria-hidden="true"
                    style="color: #ff9800; padding-top: 2px;"
                  ></i>
                  <div>
                    <div class="tw-font-medium">{{ i.title }}</div>
                    <div>{{ i.content }}</div>
                  </div>
                </template>
              </li>
            </ul>
          </div>
          <router-link
            :to="{ name: 'Payment', params: { amount: item.price } }"
            class="tw-absolute tw-bottom-0 tw-inset-x-0 tw-flex tw-items-center tw-justify-center tw-no-underline tw-h-cta tw-block tw-bg-10 hover:tw-bg-20 tw-text-center tw-text-primary tw-uppercase tw-tracking-wide tw-font-bold"
          >
            Get Started
          </router-link>
        </div>
      </div>
      <div class="tw-max-w-3xl tw-mx-auto">
        <p class="tw-text-center text-90 tw-max-w-3xl">
          For any inquiries regarding Business Memberships, please email
          ourhoneycomb@beeznests.com
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        name: 'Basic',
        price: 295,
        oldPrice: 0,
        description: null,
        // 'Enjoy our Beeznests platform as a company for no cost, such services include:',
        items: [
          {
            title: 'Post for free',
            content:
              'You can post as many hiring opportunities as you want for free on our website.',
          },
          {
            title: 'Free promotion',
            content:
              'We will promote your hiring opportunities to all our students through direct email campaigns and social media channels.',
          },
          {
            title: 'Access to Job Referral Board (New Feature) for free',
            content:
              'Access to the best students (referred by our partner college) for internship or your part time/full time job quickly.',
          },
        ],
      },
      {
        name: 'Bee Gold',
        price: 495,
        oldPrice: 1050,
        description: null, //'Upgrade to Premium for more:',
        items: [
          {
            title: 'Post for free',
            content:
              'You can post as many hiring opportunities as you want for free on our website.',
          },
          {
            title: 'Free promotion',
            content:
              'We will promote your hiring opportunities to all our students through direct email campaigns and social media channels.',
          },
          {
            title: 'Digital Marketing Project',
            content:
              'The instructors and students from our partner college will work as a team on your marketing project for 6 weeks for free. At least twice a year',
          },
          {
            title: 'Referral Program',
            content:
              'The best tech students referred from our partner college will work on your internship for 4 weeks, for free. At least twice a year',
          },
          {
            title: 'Access to Job Referral Board (New Feature) for free',
            content:
              'Hire students who have been only strongly recommended by our partner colleges or by Beeznests. These students have either completed some professional training or have completed their school program already and have been highly recommended by their school. All the candidates here are ready to work and are looking for paid positions.',
          },
          {
            title: 'Government Grants',
            content:
              'When you hire one of our students, we will help you access government grants.\n' +
              '*Receive a 100% wage subsidy (up to $10,000) to hire a student\n',
          },
          {
            title: 'Prestige Level',
            content:
              "After every successful hiring, we will give you a new prestige level. Every level will boost your company's awareness of more than 20,000 people.",
          },
          {
            title: 'Logo Placement',
            content:
              'We will put your logo on our website and monthly newsletters that will directly reach thousands of students.',
          },
          {
            title: 'Bee Mentor',
            content:
              'Every 3 months, your employees will have a chance to join our BeeMentor sessions, to learn and explore new topics, in the business area.',
          },
          {
            title: 'Social Media Promotion',
            content:
              'We will advertise your company in our social media panels to more than 20,000 active users.',
          },
          {
            title: 'Keep Updated',
            content:
              'Whenever we receive any new government funding grants and college hiring opportunities, we will let you know. For any college hiring opportunities, you have the priority to participate.',
          },
        ],
      },
      {
        name: 'Bee Diamond',
        price: 1695,
        oldPrice: 2500,
        description: null, //'Upgrade to Enterprise for much more:',
        items: [
          {
            title: 'Academic Team project',
            content:
              'Our team leads and students from our partner college will work as a team on any of your projects for 2 months for free. ',
          },
          {
            title: 'Company Promotion',
            content:
              'We will advertise your company info in our website ad section and in our social media panels for more than 20,000 active users.',
          },
          {
            title: 'Post for free',
            content:
              'You can post as many hiring opportunities as you want for free on our website.',
          },
          {
            title: 'Free promotion',
            content:
              'We will promote your hiring opportunities to all our students through direct email campaigns and social media channels.',
          },
          {
            title: 'Digital Marketing Project',
            content:
              'The instructors and students from our partner college will work as a team on your marketing project for 6 weeks for free. At least twice a year',
          },
          {
            title: 'Referral Program',
            content:
              'The best tech students referred from our partner college will work on your internship for 4 weeks, for free. At least twice a year',
          },
          {
            title: 'Access to Job Referral Board (New Feature) for free',
            content:
              'Hire students who have been only strongly recommended by our partner colleges or by Beeznests. These students have either completed some professional training or have completed their school program already and have been highly recommended by their school. All the candidates here are ready to work and are looking for paid positions.',
          },
          {
            title: 'Government Grants',
            content:
              'When you hire one of our students, we will help you access government grants.\n' +
              '*Receive a 100% wage subsidy (up to $10,000) to hire a student\n',
          },
          {
            title: 'Prestige Level',
            content:
              "After every successful hiring, we will give you a new prestige level. Every level will boost your company's awareness of more than 20,000 people.",
          },
          {
            title: 'Logo Placement',
            content:
              'We will put your logo on our website and monthly newsletters that will directly reach thousands of students.',
          },
          {
            title: 'Bee Mentor',
            content:
              'Every 3 months, your employees will have a chance to join our BeeMentor sessions, to learn and explore new topics, in the business area.',
          },
          {
            title: 'Social Media Promotion',
            content:
              'We will advertise your company in our social media panels to more than 20,000 active users.',
          },
          {
            title: 'Keep Updated',
            content:
              'Whenever we receive any new government funding grants and college hiring opportunities, we will let you know. For any college hiring opportunities, you have the priority to participate.',
          },
        ],
      },
    ],
  }),
}
</script>
<style lang="scss" scoped>
.tw-text-price {
  font-size: 2.5rem;
}
.tw-text-60 {
  color: #4a90e2;
}
.tw-text-50 {
  color: #8a97a2;
}
.tw-text-90 {
  color: #202c3a;
}
.tw-container {
  max-width: 960px;
}
.tw-w-card {
  width: 22.5rem;
}
.tw-testimonial {
  padding-bottom: 190px;
  margin-bottom: -190px;
}
.tw-pl-0 {
  padding-left: 0 !important;
}
.tw-h-cta {
  height: 4.5rem;
}
.tw-bg-10 {
  background-color: #f7f9fb;
  &:hover {
    background-color: rgba(255, 152, 0, 0.75);
  }
}
.tw-bg-20 {
  background-color: #eef1f4;
}
.tw-pb-cta {
  padding-bottom: 4.5rem;
}
</style>
