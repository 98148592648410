<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title"> Beeznests </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.name" link>
        <v-list-item-content @click="$router.push(item.route)">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  computed: {
    drawer: {
      get() {
        return this.$store.getters.drawer
      },
      set(status) {
        this.$store.commit('device/SET_DRAWER', status)
      },
    },
  },
  data: () => ({
    items: [
      {
        name: 'Projects',
        route: {
          name: 'NewProjects',
        },
      },
      {
        name: 'Network',
        route: {
          name: 'NewNetwork',
        },
      },
      {
        name: 'Bootcamp',
        route: '/mentor-programs',
      },
      {
        name: 'Internship/full-time job',
        route: '/jobs',
      },
      // {
      //   name: 'discussions',
      //   route: {
      //     name: 'NewDiscussion',
      //   },
      // },

      // {
      //   name: 'External Opportunities',
      //   route: {
      //     name: 'NewLinks',
      //   },
      // },
    ],
  }),
}
</script>
