<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" lg="10" xl="8" class="mt-5">
      <v-row justify="center">
        <v-col cols="12" md="9">
          <v-card>
            <v-card-title>
              <h4>
                {{ data.position }}
              </h4>
              <v-list-item class="px-0 overflow-hidden">
                <v-list-item-avatar size="48">
                  <router-link
                    :to="{
                      name: 'userIndex',
                      params: { id: data.user.id },
                    }"
                    style="text-decoration: none;"
                  >
                    <v-avatar size="48">
                      <v-img
                        class="elevation-6"
                        :src="data.user.avatar"
                      ></v-img>
                    </v-avatar>
                  </router-link>
                </v-list-item-avatar>
                <v-list-item-content
                  class="flex-shrink-1"
                  style="overflow: hidden;"
                >
                  <user-name
                    :name="data.user.name"
                    :type="data.user.type"
                    :id="data.user.id"
                    :organization="data.user.organization"
                  >
                    <template v-slot:before
                      ><v-img
                        :src="data.user.country_flag"
                        max-height="24"
                        max-width="24"
                        contain
                        class="align-self-center mr-1"
                      ></v-img
                    ></template>
                  </user-name>
                </v-list-item-content>
              </v-list-item>
            </v-card-title>
            <div></div>
            <template v-if="data.about_mentor">
              <v-card-subtitle>
                <span class="tw-text-xl tw-font-medium"
                  >About company:</span
                ></v-card-subtitle
              >
              <v-card-text v-html="data.about_mentor"> </v-card-text>
            </template>
            <template v-if="data.content">
              <v-card-subtitle
                ><span class="tw-text-xl tw-font-medium"
                  >What you will do with the mentor:</span
                ></v-card-subtitle
              >
              <v-card-text v-html="$replaceEmpty(data.content)"> </v-card-text>
            </template>
            <template v-if="data.work_with">
              <v-card-subtitle
                ><span class="tw-text-xl tw-font-medium"
                  >The requirements for students:</span
                ></v-card-subtitle
              >
              <v-card-text v-html="$replaceEmpty(data.work_with)">
              </v-card-text>
            </template>
            <v-card-text>
              <v-sheet>
                <span class="primary--text mr-1">Type: </span
                >{{ data.is_online ? 'Remote' : 'Online' }}</v-sheet
              >
              <v-sheet class="pt-n4" v-if="data.time_line">
                <span class="primary--text mr-1">Duration of the project:</span>
                {{ data.time_line }} months
              </v-sheet>
              <v-sheet class="pt-n4">
                <span class="primary--text mr-1">Email:</span
                >ourhoneycomb@gmail.com
              </v-sheet>
              <v-alert text color="blue" class="my-4"
                >If interested, email for more information.
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { fetchData } from '@/api/project'

export default {
  computed: {
    id() {
      return this.$route.params.id || 0
    },
  },
  data: () => ({
    data: {},
    loading: false,
  }),
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        let includes =
          'is_applied,is_own,content,who,work_with,skills,about_mentor,policy,agenda'
        const { data } = await fetchData(this.id, {
          includes,
        })
        this.data = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
