<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-sheet class="pa-4"> </v-sheet>
    </v-col>
    <v-col cols="12" md="10" lg="8">
      <v-form>
        <v-text-field
          label="Title"
          v-model="data.title"
          ref="container"
        ></v-text-field>
        <div
          style="position: relative;"
          :style="{ height: containerHeight + 'px' }"
        >
          <v-fade-transition group name="list">
            <template v-for="(image, index) in images">
              <div
                v-if="image.url !== ''"
                :key="`image-preview-${index}`"
                :style="{
                  position: 'absolute',
                  left: image.left + 'px',
                  top: image.top + 'px',
                  width: image.width + 'px',
                  height: image.height + 'px',
                }"
              >
                <v-img
                  :src="image.url"
                  :width="image.width"
                  :height="image.height"
                  contain
                ></v-img>

                <v-progress-linear
                  :color="uploadProgressColor(uploadFiles[index].id)"
                  buffer-value="0"
                  :value="uploadFiles[index].progress"
                  stream
                ></v-progress-linear>
                <v-btn
                  icon
                  :loading="uploadFiles[index].status === 'deleting'"
                  style="position: absolute; top: -1em; right: -1em;"
                  @click="removeFile(index)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <div
                v-else
                :style="{
                  left: image.left + 'px',
                  top: image.top + 'px',
                  width: image.width + 'px',
                  height: image.height + 'px',
                }"
                style="position: absolute;"
                :key="`image-preview-${index}`"
              >
                <v-sheet class="upload-btn pa-8 elevation-4" v-ripple>
                  <v-icon size="36" class="icon">mdi-image-plus</v-icon>
                  <input
                    type="file"
                    class="file-input"
                    accept="image/*, video/*"
                    multiple
                    @change="handleChange"
                  />
                </v-sheet>
              </div>
            </template>
          </v-fade-transition>
        </div>
        <v-sheet class="py-6">
          <v-btn
            :loading="submitLoading"
            @click="submit"
            color="deep-orange"
            class="white--text"
            >{{ id > 0 ? 'Save' : 'Submit' }}</v-btn
          >
        </v-sheet>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import justifiedLayout from 'justified-layout'
import { clone as _clone } from 'lodash'
const uploadStatusMap = {
  waiting: 'Waiting',
  uploading: 'Uploading',
  fail: 'Fail',
  complete: 'Complete',
}
import {
  upload,
  add,
  remove,
  data as uploadFilesData,
  getStatus,
  pushCompleteData,
  init,
} from '@/utils/upload'
import { create, fetchData, updatePortfolio } from '@/api/portfolio'
export default {
  data: () => ({
    data: {
      title: null,
      attachments: [],
    },
    files: [],
    containerHeight: 120,
    containerWidth: 1280,
    uploadFiles: uploadFilesData,
    submitLoading: false,
  }),
  computed: {
    images() {
      const boxes = this.counterSize()
      boxes.map((item, index) => {
        item.url = this.files[index] ? this.files[index].url : ''
        return item
      })
      return boxes
    },
    id() {
      return this.$route.params.id || 0
    },
  },
  mounted() {
    //
    this.containerWidth = this.$refs.container.$el.offsetWidth
    if (this.id > 0) {
      this.initData()
    }
  },
  methods: {
    async initData() {
      const { data } = await fetchData(this.id)
      this.data.title = data.title
      data.attachments.forEach((item) => {
        this.files.push({
          width: item.width,
          height: item.height,
          url: item.url,
        })
      })
      pushCompleteData(data.attachments)
      this.counterSize()
    },
    handleChange(e) {
      const files = e.target.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const imageType = /^image\//
        if (!imageType.test(file.type)) {
          const uploadId = add(file)
          upload(uploadId)
          continue
        }
        const img = new Image()
        img.file = file
        const reader = new FileReader()
        const _this = this
        reader.onload = (function (aImg) {
          return function (e) {
            aImg.src = e.target.result
            aImg.onload = function () {
              const uploadId = add(file)
              _this.files.push({
                width: aImg.width,
                height: aImg.height,
                url: e.target.result,
                uploadId,
              })
              upload(uploadId)
              _this.counterSize()
            }
          }
        })(img)
        reader.readAsDataURL(file)
      }
    },
    async removeFile(index) {
      await remove(this.uploadFiles[index].id)
      this.uploadFiles = uploadFilesData
      console.log('files', this.uploadFiles, 'data', uploadFilesData)
      this.files.splice(index, 1)
    },
    counterSize() {
      const images = _clone(this.files)
      images.push({
        width: 180,
        height: 180,
      })
      const { containerHeight, boxes } = justifiedLayout(images, {
        containerWidth: this.containerWidth,
        containerPadding: {
          top: 0,
          right: 0,
          bottom: 8,
          left: 0,
        },
        boxSpacing: {
          horizontal: 18,
          vertical: 18,
        },
        showWidows: true,
        targetRowHeight: 180,
      })
      this.containerHeight = containerHeight
      return boxes
    },
    uploadProgressColor(id) {
      const status = getStatus(id)
      switch (status) {
        case 'fail':
          return 'red'
        case 'complete':
          return 'blue'
        default:
          return 'orange'
      }
    },
    async submit() {
      const postData = Object.assign({}, this.data)
      let attachments = []
      const isUploading = this.uploadFiles.some(
        (item) => item.status === 'waiting' || item.status === 'uploading'
      )
      const hasError = this.uploadFiles.some((item) => item.status === 'fail')
      if (hasError) {
        this.$message('Please remove the failed files', 'error')
        return
      }
      if (isUploading) {
        this.$message('Please wait for the file upload to complete', 'error')
        return
      } else {
        attachments = this.uploadFiles.map((item) => item.fileId)
      }
      if (attachments.length <= 0) {
        this.$message('Please wait for the file upload to complete', 'error')
        return
      }
      postData.attachments = attachments
      this.submitLoading = true
      let portfolioId = 0
      if (this.id > 0) {
        try {
          await updatePortfolio(this.id, postData)
        } catch (e) {
          this.submitLoading = false
          return
        }
        portfolioId = this.id
      } else {
        try {
          const { data } = await create(postData)
          if (data && data.id) {
            portfolioId = data.id
          }
        } catch (e) {
          this.submitLoading = false
          return
        }
      }
      this.submitLoading = false
      this.$router.push({
        name: 'portfolioShow',
        params: {
          id: portfolioId,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-btn {
  height: 120px;
  width: 120px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
