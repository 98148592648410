const state = {
  message: '',
}

const mutations = {
  SET(state, message) {
    state.message = message
  },
  CLEAR(state) {
    state.message = ''
  },
}

const actions = {
  get({ commit, state }, clear = true) {},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
