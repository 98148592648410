<template>
  <v-col cols="12">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" xl="8">
            <template v-if="data.length > 0">
              <item
                v-for="(item, index) in data"
                :item="item"
                :key="`${item.id}`"
                :class="{ 'mt-5': index > 0 }"
              ></item>
            </template>
            <v-alert type="error" v-if="!loading && loadError" class="mt-5">
              Load data error.
            </v-alert>
            <v-alert type="info" v-if="!loading && noData" class="mt-5">
              Nothing found here.
            </v-alert>
            <v-skeleton-loader
              ref="skeleton"
              type="article, article, article, article, article"
              class="mx-auto mt-5"
              v-if="loading"
            ></v-skeleton-loader>
            <v-sheet v-intersect="onIntersect" v-if="!noMoreData"></v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import { fetchIndex } from '@/api/project'
import Item from '@/components/Project/Item'
export default {
  components: {
    Item,
  },
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    noData() {
      return this.data.length <= 0
    },
  },
  data: () => ({
    loading: false,
    loadError: false,
    pages: {
      current_page: 1,
      last_page: 1,
      per_page: 5,
    },
    data: [],
    noMoreData: false,
  }),
  created() {
    this.loadData(true)
  },
  methods: {
    async loadData(reset = false) {
      try {
        this.loading = true
        const requestData = {
          user_id: this.id,
        }
        requestData.page = ++this.pages.current_page
        requestData.per_page = this.pages.per_page
        if (reset) {
          requestData.page = 1
        }
        requestData.includes = 'summary'
        const { data, meta } = await fetchIndex(requestData)
        this.data = reset ? data : this.data.concat(data)
        this.pages = meta
        if (requestData.page === meta.last_page) this.noMoreData = true
      } catch (e) {
        this.loadError = true
      } finally {
        this.loading = false
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting && !this.noMoreData && !this.loading) {
        this.loadData()
      }
    },
  },
}
</script>
