<template>
  <v-row class="fill-height" justify="center">
    <v-col align-self="center" class="mx-auto d-flex justify-center">
      <v-progress-circular
        indeterminate
        color="deep-orange lighten-2"
        v-if="loading"
      ></v-progress-circular>
      <v-sheet v-else>
        {{ noData ? noData : '暂无相关内容' }}
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    noData: {
      type: String,
      default: null,
    },
  },
}
</script>
