<template>
  <v-list-item>
    <v-list-item-avatar>
      <router-link
        :to="{ name: 'userShow', params: { id: item.data.user_id } }"
      >
        <v-avatar>
          <v-img :src="item.data.user_avatar"></v-img>
        </v-avatar>
      </router-link>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <span class="grey--text mr-2">{{ item.data.user_name }}</span>
        <span class="mr-2">Replied for </span>
        <router-link
          :to="{ name: 'challengeShow', params: { id: item.data.id } }"
          >{{ item.data.title }}</router-link
        >
      </v-list-item-title>
      <small class="ml-auto grey--text">
        {{ item.created_at | diffForHumans }}
      </small>
      <v-list-item-subtitle v-html="item.data.content"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: {
    item: {
      data: {},
    },
  },
}
</script>
