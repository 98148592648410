<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" lg="10">
      <v-row no-gutters class="mt-8">
        <v-col cols="3" class="d-none d-sm-flex">
          <v-btn
            rounded
            color="deep-orange"
            class="white--text"
            to="/challenges/create"
            >Post a New Discussion</v-btn
          >
        </v-col>
        <!--v-col cols="12" sm="8" md="4" class="ml-auto">
          <v-select
            label="Solo field"
            small-chips
            flat
            v-model="category"
            :items="allCategories"
            chips
            multiple
            dense
            outlined
            rounded
            item-value="id"
            item-text="name"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ category.length - 1 }} others)</span
              >
            </template>
          </v-select>
        </v-col-->
        <v-col cols="12" md="4 " class="ml-auto">
          <v-text-field
            label="Search Challenge"
            solo
            dense
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            v-model="title"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-none d-md-block" md="3" xl="2">
          <v-card class="pa-2 mt-2">
            <v-card-text>
              Need help making good innovative decisions? Double check your
              assumptions and get another perspective on your current problem or
              situation!
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="9" xl="10" class="pa-3 pt-0 pa-md-4 pt-md-0">
          <loading
            v-if="loading || data.length <= 0"
            :loading="loading"
          ></loading>
          <v-row justify="space-between" v-else>
            <v-col
              v-for="item in data"
              :key="`challenge-${item.id}`"
              cols="12"
              xl="6"
              class="mx-0"
            >
              <challenge-item :challenge="item"></challenge-item>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="pages.last_page > 1">
        <v-col cols="12">
          <v-pagination
            v-model="pages.current_page"
            :length="pages.last_page"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ChallengeItem from '@/components/Challenge/Item'
import { fetchIndex } from '@/api/challenge'
import { debounce } from 'lodash'
import Loading from '@/components/Loading'
export default {
  components: {
    ChallengeItem,
    Loading,
  },
  data() {
    return {
      items2: [],
      model: 1,
      data: [],
      loading: false,
      title: null,
      category: null,
      pages: {
        current_page: 1,
        last_page: 1,
      },
    }
  },
  mounted() {
    this.loadIndex()
    this.debounceLoadIndex = debounce(this.loadIndex, 800)
  },
  watch: {
    title() {
      this.debounceLoadIndex(true)
    },
    'pages.current_page'() {
      this.loadIndex()
    },
  },
  methods: {
    async loadIndex(reset = false) {
      try {
        this.loading = true
        const requestParams = {}
        if (this.category) {
          requestParams.category_id = this.category
        }
        if (reset) {
          this.pages = {
            current_page: 1,
            last_page: 1,
          }
          this.data = []
        }
        if (this.title) {
          requestParams.title = this.title
        }
        requestParams.page = this.pages.current_page
        const { data, meta } = await fetchIndex(requestParams)
        this.data = data
        this.pages = meta
      } catch (e) {
        this.$store.dispatch('message/pushMessage', {
          type: 'error',
          content: e.response.data.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
