var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"48"}},[_c('router-link',{attrs:{"to":{
          name: 'userIndex',
          params: { id: _vm.data.user.id },
        }}},[_c('v-avatar',[_c('v-img',{staticClass:"elevation-6",attrs:{"src":_vm.data.user.avatar}})],1)],1)],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticClass:"align-self-center mr-1",attrs:{"src":_vm.data.user.country_flag,"max-height":"24","max-width":"24","contain":""}}),_c('user-name',{attrs:{"name":_vm.data.user.name,"type":_vm.data.user.type,"id":_vm.data.user.id,"organization":_vm.data.user.organization}}),_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-star")]),_c('span',{staticClass:"align-self-center orange--text font-weight-bold text-h6"},[_vm._v(_vm._s(_vm.data.rate))])],1)],1),_c('v-list-item-subtitle',[_vm._v("Published "+_vm._s(_vm._f("diffForHumans")(_vm.data.created_at)))])],1)],1),_c('v-card-text',[_c('div',{staticClass:"pa-0"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'projectShow',
          params: { id: _vm.data.reviewable.project_id },
        }}},[_vm._v(_vm._s(_vm.data.reviewable.project.title))])],1),_vm._v(" "+_vm._s(_vm.data.content)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }