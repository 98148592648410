<template>
  <div>
    <section
      class="tw-py-10 tw--mx-4 md:tw-pt-20 tw-px-6 mx:tw-px-0 tw-testimonial"
      style="background-image: linear-gradient(135deg, #fecc17, #ff9800);"
    >
      <blockquote
        class="tw-text-center tw-text-white tw-text-xl md:tw-text-3xl tw-mx-auto tw-leading-tight md:tw-mb-12 tw-max-w-2xl"
      >
        <p class="tw-mb-6 tw-text-4xl">
          SPONSORSHIPS
        </p>
        <p class="tw-mb-6 tw-text-lg">
          For a special discounted price, experience all the benefits and
          promotions included in our 1 year sponsorship package below. Offer
          available to only our first 5 sponsors.
        </p>
      </blockquote>
    </section>
    <section class="tw-py-10 md:tw-py-0 md:tw-mb-20 tw-px-6 md:tw-px-0">
      <div
        class="tw-container md:tw-flex md:tw-justify-center tw-mx-auto tw-mb-10 md:tw-mb-10"
      >
        <div
          v-for="item in items"
          :key="item.name"
          class="tw-relative tw-pb-cta tw-shadow-lg tw-overflow-hidden tw-bg-white tw-rounded-lg tw-w-card tw-mr-10 tw-mb-10 md:tw-mb-0"
        >
          <div class="tw-pt-8 tw-pb-10 tw-px-6 md:tw-px-10">
            <h4
              class="text-center tw-uppercase tw-text-xl tw-tracking-wide tw-mb-3"
            >
              {{ item.name }}
            </h4>
            <p
              class="tw-align-text-top tw-mb-4 text-center"
              v-if="item.name !== 'Basic'"
            >
              <sup class="tw-font-light tw-text-50 tw-text-2xl">$</sup>
              <span class="tw-font-light tw-text-90 tw-text-price">{{
                item.price
              }}</span>
              <span class="tw-font-light tw-text-90 tw-text-xl tw-line-through"
                >${{ item.oldPrice }}</span
              ><span class="tw-text-base">/Year</span>
            </p>
            <p class="tw-align-text-top tw-mb-4" v-if="item.name === 'Basic'">
              <span class="tw-font-light tw-text-90 tw-text-price">Free</span>
            </p>
            <ul class="tw-list-reset tw-leading-normal tw-text-80 tw-pl-0">
              <li
                v-for="(i, index) in item.items"
                :key="`${item.name}-${index}`"
                class="tw-flex tw-items-start tw-py-2"
              >
                <i
                  class="fa fa-check-circle tw-mr-1"
                  aria-hidden="true"
                  style="color: #ff9800; padding-top: 2px;"
                ></i
                >{{ i }}
              </li>
            </ul>
          </div>
          <router-link
            :to="{ name: 'Payment', params: { amount: item.price } }"
            class="tw-absolute tw-bottom-0 tw-inset-x-0 tw-flex tw-items-center tw-justify-center tw-no-underline tw-h-cta tw-block tw-bg-10 hover:tw-bg-20 tw-text-center tw-text-primary tw-uppercase tw-tracking-wide tw-font-bold"
          >
            Get Started
          </router-link>
        </div>
      </div>
      <div class="tw-max-w-3xl tw-mx-auto">
        <p class="tw-text-center text-90">
          If you are interested in becoming a Silver or Platinum Sponsor, please
          email us directly at sponsorship@beeznests.com to confirm whether we
          can accommodate your customized requirement of choice
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        name: 'Bronze',
        price: 1000,
        oldPrice: 1500,
        items: [
          'Customized badge that will directly associate your university with your students on our platform.',
          '20 free postings of project/task for all associated professors and faculty members of the university',
          'Logo placement on our website and biweekly newsletters that directly reaches out to 8000+ students',
          'Promotion of  3 events/opportunities on our social media platform within the year',
          'Opportunity to be a co-host or key speaker for any 1 future business events we create among startups',
          'Complimentary tickets to any future business events/meeting we create',
        ],
      },
      {
        name: 'Silver',
        price: 1500,
        oldPrice: 2200,
        items: [
          'Customized badge that will directly associate your university with your students on our platform.',
          'Total free postings of project/task for all associated professors, faculty members, and students of the university',
          'Logo placement on our website and biweekly newsletters that directly reaches out to 8000+ students',
          'Promotion of  25 events/opportunities on our social media platform within the year',
          'Unlimited Direct Listing of upcoming events and opportunities  on our website and newsletter',
          'Opportunity to be a co-host or  key speaker for any 2-3 future business events we create',
          'Complimentary tickets to any future business events/meeting we create',
          'Reserved three-week Ad space on website for 3 different opportunities within the year',
          '20% discount for all training session including resume workshops and language training',
          'Customized requirement',
        ],
      },
      {
        name: 'Platinum',
        price: 2500,
        oldPrice: 3300,
        items: [
          'Customized badge that will directly associate your university with your students on our platform.',
          'Total free postings of project/task for all associated professors, faculty members, and students of the university',
          'Logo placement on our website and biweekly newsletters that directly reaches out to 8000+ students',
          'Unlimited Promotion of events/opportunities on our social media platform within the year',
          'Unlimited Direct Listing of upcoming events and opportunity on our website and newsletter',
          'Opportunity to be a co-host or key speaker for any 5 future business events we create',
          'Complimentary tickets to any future business events/meeting we create',
          'Reserved three-week Ad space on website for 6 different opportunities within the year',
          '50% discount for all training session including resume workshops and language training',
          'Downloadable reference letter template provided for convenience',
          'Customized requirement',
        ],
      },
    ],
  }),
}
</script>
<style lang="scss" scoped>
.tw-text-price {
  font-size: 2.5rem;
}
.tw-text-60 {
  color: #4a90e2;
}
.tw-text-50 {
  color: #8a97a2;
}
.tw-text-90 {
  color: #202c3a;
}
.tw-container {
  max-width: 960px;
}
.tw-w-card {
  width: 22.5rem;
}
.tw-testimonial {
  padding-bottom: 190px;
  margin-bottom: -190px;
}
.tw-pl-0 {
  padding-left: 0 !important;
}
.tw-h-cta {
  height: 4.5rem;
}
.tw-bg-10 {
  background-color: #f7f9fb;
  &:hover {
    background-color: rgba(255, 152, 0, 0.75);
  }
}
.tw-pb-cta {
  padding-bottom: 4.5rem;
}
</style>
