<template>
  <div>
    <template v-if="!isMobile">
      <common-header></common-header>
      <div style="margin-top: 40px; width: 787px;" class="tw-mx-auto">
        <h3 style="color: #ff9800;" class="tw-text-4xl tw-text-center">
          Welcome To Our
        </h3>
        <div class="tw-text-4xl tw-text-center">
          Beeznests' External Opportunity Page!
        </div>
        <div
          class="tw-text-center tw-text-sm"
          style="color: #8a92ab; max-width: 746px; margin-top: 16px;"
        >
          <div class="tw-mb-1">
            Can’t find a one-time project that interests you? No worries, we
            will try our best to help you find whatever you are looking for!
          </div>
          <p class="tw-mb-1">
            Check out these external job links here. All opportunities are
            remote and will be updated weekly to include current, available
            jobs.
          </p>
          <p>
            To apply for these opportunities, please do so externally through
            the associated websites. Please also note that, unlike our one-time
            projects, we are unable to moderate or answer any questions
            regarding the hiring process as these are all external opportunities
            we currently have no involvement with. Wishing you all the best in
            your job search!
          </p>
        </div>
        <div class="tw-flex tw-justify-center" style="margin-top: 40px;">
          <img src="@/assets/images/links.png" style="max-width: 373px;" />
        </div>
        <div
          style="margin-top: 50px;"
          class="tw-flex tw-justify-center tw-uppercase"
        >
          <span
            style="
              padding: 16px 48px;
              background-color: #ff9800;
              box-shadow: 0 3px 6px 0 rgba(255, 152, 0, 0.47);
              border-radius: 4px;
            "
            class="tw-text-2xl tw-font-bold tw-text-white"
            >{{ month }}</span
          >
        </div>
      </div>
      <div
        class="tw-mx-auto tw-flex tw-justify-between"
        style="max-width: 1100px;"
      >
        <div style="width: 787px;">
          <div
            v-for="(item, i) in data"
            :key="`week-${i}`"
            style="margin-top: 30px;"
          >
            <template v-if="i < weeks">
              <h3 style="color: #ff9800;" class="tw-text-3xl">
                <span
                  class="tw-border-b-2 tw-pb-1"
                  style="border-color: #ff9800;"
                  >Week {{ i + 1 }}</span
                >
              </h3>
              <div
                v-for="(link, index) in item"
                :key="`links-${i}-${index}`"
                class="tw-grid tw-grid-cols-2"
                style="margin-top: 17px;"
              >
                <div
                  class="tw-inline-flex tw-items-center"
                  style="max-width: 90%;"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                    viewBox="0 0 27 18"
                    class="tw-mr-1"
                  >
                    <g transform="translate(-417 -1384)">
                      <g
                        transform="translate(417 1384)"
                        fill="#031226"
                        stroke="#fff"
                        stroke-width="1.5"
                      >
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <g
                        transform="translate(426 1384)"
                        fill="#ff9800"
                        stroke="#fff"
                        stroke-width="1.5"
                      >
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                    </g>
                  </svg>
                  <p class="tw-truncate tw-font-bold">
                    {{ link.name }}
                  </p>
                </div>
                <div
                  class="tw-p-1 hover-color tw-border tw-rounded tw-text-sm tw-inline-flex tw-items-center tw-overflow-hidden"
                  style="
                    border-color: #ddd;
                    background-color: #f6f8f9;
                    color: #8a92ab;
                    max-width: 100%;
                  "
                >
                  <i
                    class="fa fa-link tw-mr-1"
                    style="transform: rotate(90deg);"
                  ></i>
                  <a
                    :href="link.link"
                    style="color: #8a92ab;"
                    class="tw-inline-block tw-truncate"
                    >{{ link.link }}</a
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--右侧-->
        <div class="" style="max-width: 300px; width: 300px;">
          <div class="">
            <div class="tw-mt-4">
              <img
                src="https://api.beeznests.com/storage/portfolios/202010/24/93_1603542218_fMHlOXSgCc.png"
              />
            </div>
            <div class="bg-orange-4 tw-rounded tw-border tw-mt-6 tw-pt-4">
              <h2 class="tw-text-2xl tw-font-bold tw-text-center text-mb-4">
                Invite Friends
              </h2>
              <div
                class="tw-mt-4 tw-flex tw-items-center tw-border tw-py-2 tw-mx-6 tw-rounded tw-bg-white"
              >
                <input
                  class="tw-appearance-none tw-bg-transparent tw-border-none tw-w-full tw-text-gray-700 tw-mr-3 tw-py-1 tw-px-2 tw-leading-tight tw-outline-none"
                  type="text"
                  placeholder="Email Address"
                  v-model="inviteMail"
                />
                <span class="flex-shrink-0 text-sm tw-mr-4">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
              <div
                class="tw-my-1 tw-mx-6"
                v-if="inviteMessage"
                :class="{ 'tw-text-red-500': inviteError }"
              >
                {{ inviteMessage }}
              </div>
              <div class="tw-my-4 tw-mx-6">
                <button
                  @click="invite"
                  class="tw-shadow tw-outline-none focus:tw-outline-none tw-w-full tw-py-2 tw-rounded bg-orange-2 tw-uppercase disabled:tw-opacity-75"
                  :disabled="inviteLoading"
                >
                  <i class="fa fa-spin fa-spinner" v-if="inviteLoading"></i>
                  Invite now
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--右侧结束-->
      </div>

      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div style="margin-top: 40px;" class="tw-mx-4">
            <h3 style="color: #ff9800;" class="tw-text-4xl tw-text-center">
              Welcome To Our
            </h3>
            <div class="tw-text-4xl tw-text-center">
              Beeznests' External Opportunity Page!
            </div>
            <div
              class="tw-text-center tw-text-sm"
              style="color: #8a92ab; max-width: 746px; margin-top: 16px;"
            >
              <div class="tw-mb-1">
                Can’t find a one-time project that interests you? No worries, we
                will try our best to help you find whatever you are looking for!
              </div>
              <p class="tw-mb-1">
                Check out these external job links here. All opportunities are
                remote and will be updated weekly to include current, available
                jobs.
              </p>
              <p>
                To apply for these opportunities, please do so externally
                through the associated websites. Please also note that, unlike
                our one-time projects, we are unable to moderate or answer any
                questions regarding the hiring process as these are all external
                opportunities we currently have no involvement with. Wishing you
                all the best in your job search!
              </p>
            </div>
            <div class="tw-flex tw-justify-center" style="margin-top: 40px;">
              <img src="@/assets/images/links.png" />
            </div>
            <div
              style="margin-top: 50px;"
              class="tw-flex tw-justify-center tw-uppercase"
            >
              <span
                style="
                  padding: 16px 48px;
                  background-color: #ff9800;
                  box-shadow: 0 3px 6px 0 rgba(255, 152, 0, 0.47);
                  border-radius: 4px;
                "
                class="tw-text-2xl tw-font-bold tw-text-white"
                >{{ month }}</span
              >
            </div>
          </div>
          <div class="tw-mx-4">
            <div
              v-for="(item, i) in data"
              :key="`week-${i}`"
              style="margin-top: 30px;"
            >
              <template v-if="i < weeks">
                <h3 style="color: #ff9800;" class="tw-text-3xl">
                  <span
                    class="tw-border-b-2 tw-pb-1"
                    style="border-color: #ff9800;"
                    >Week {{ i + 1 }}</span
                  >
                </h3>
                <div
                  v-for="(link, index) in item"
                  :key="`links-${i}-${index}`"
                  class="tw-flex tw-flex-col"
                  style="margin-top: 17px;"
                >
                  <div
                    class="tw-inline-flex tw-items-center"
                    style="max-width: 90%;"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="12"
                      viewBox="0 0 27 18"
                      class="tw-mr-1"
                    >
                      <g transform="translate(-417 -1384)">
                        <g
                          transform="translate(417 1384)"
                          fill="#031226"
                          stroke="#fff"
                          stroke-width="1.5"
                        >
                          <circle cx="9" cy="9" r="9" stroke="none" />
                          <circle cx="9" cy="9" r="8.25" fill="none" />
                        </g>
                        <g
                          transform="translate(426 1384)"
                          fill="#ff9800"
                          stroke="#fff"
                          stroke-width="1.5"
                        >
                          <circle cx="9" cy="9" r="9" stroke="none" />
                          <circle cx="9" cy="9" r="8.25" fill="none" />
                        </g>
                      </g>
                    </svg>
                    <div class="tw-truncate tw-font-bold">
                      {{ link.name }}
                    </div>
                  </div>
                  <div
                    class="tw-p-1 hover-color tw-border tw-rounded tw-text-sm tw-inline-flex tw-items-center tw-overflow-hidden"
                    style="
                      border-color: #ddd;
                      background-color: #f6f8f9;
                      color: #8a92ab;
                      max-width: 100%;
                    "
                  >
                    <i
                      class="fa fa-link tw-mr-1"
                      style="transform: rotate(90deg);"
                    ></i>
                    <a
                      :href="link.link"
                      style="color: #8a92ab;"
                      class="tw-inline-block tw-truncate"
                      >{{ link.link }}</a
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
          <mobile-footer> </mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import CommonHeader from './common/header'
import CommonFooter from './common/footer'
import { fetch, fetchAll } from '@/api/link'
import moment from 'moment'
import { inviteFriend } from '@/api/user'
import { mapGetters } from 'vuex'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
export default {
  components: {
    MobileDrawer,
    MobileFooter,
    CommonHeader,
    CommonFooter,
    MobileHeader,
  },
  computed: {
    month() {
      return moment().format('MMMM YYYY')
    },
    weeks() {
      return Math.ceil(moment().format('DD') / 7)
    },
    ...mapGetters(['isMobile']),
  },
  data: () => ({
    data: [],
    inviteMail: null,
    inviteError: false,
    inviteLoading: false,
    inviteMessage: null,
  }),
  mounted() {
    document.documentElement.style.fontSize = '14px'
    this.loadData()
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  methods: {
    async loadData() {
      const { data } = await fetchAll()
      this.data = data
    },
    async invite() {
      this.inviteLoading = true
      this.inviteError = false
      try {
        await inviteFriend(this.inviteMail)
        this.inviteMessage = 'Successful!'
      } catch (e) {
        this.inviteError = true
        if (e.response && e.response.data && e.response.data.message) {
          this.inviteMessage = e.response.data.message
        } else {
          this.inviteMessage = 'Server Error!'
        }
      } finally {
        this.inviteLoading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.hover-color {
  &:hover {
    color: #ff9800 !important;
    a {
      color: #ff9800 !important;
    }
  }
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-2 {
  background-color: #fecc17;
}
</style>
