<template>
  <v-card>
    <v-card-title>
      <router-link
        :to="{ name: 'challengeShow', params: { id: data.challenge.id } }"
        style="text-decoration: none;"
        >{{ data.challenge.title }}</router-link
      >
    </v-card-title>
    <v-card-text v-html="data.content"> </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          user: {},
        }
      },
    },
  },
}
</script>
