import axios from '@/plugins/axios'
export const create = (data) => {
  return axios.post('portfolio', data)
}

export const fetchData = (id, params) => {
  return axios.get(`portfolio/${id}`, { params })
}

export const fetchIndex = (params) => {
  return axios.get('portfolio/', { params })
}

export const fetchUserIndex = (id, params) => {
  return axios.get(`user/${id}/portfolio/`, { params })
}

export const destroy = (id) => {
  return axios.delete(`portfolio/${id}`)
}

export const updatePortfolio = (id, data) => {
  return axios.put(`portfolio/${id}`, data)
}
