// import store from '@/store'
export default {
  path: '/projects',
  component: () => import('@/views/layouts/root'),
  children: [
    {
      path: 'review/:id',
      name: 'receiveReview',
      component: () => import(/* webpackChunkName: 'review' */'@/views/projects/review'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: 'recommendation/:id/receiver/:receive_id',
      name: 'receiveRecommendation',
      component: () => import(/* webpackChunkName: 'review' */'@/views/projects/recommendation'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: '',
      redirect: { name: 'NewProjects' },
      // name: 'projectIndex',
      // component: () => import('@/views/projects/index'),
      // meta: {
      //   keepAlive: true,
      // },
    },
    {
      path: 'create',
      name: 'projectCreate',
      component: () => import(/* webpackChunkName: 'projects' */'@/views/projects/create'),
      meta: {
        auth: true,
        settings: true,
        notPayCost: true,
      },
    },
    {
      path: ':id/edit',
      name: 'projectEdit',
      component: () => import(/* webpackChunkName: 'projects' */'@/views/projects/create'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: ':id/apply',
      name: 'projectApply',
      component: () => import(/* webpackChunkName: 'projects' */'@/views/projects/answer'),
      meta: {
        auth: true,
        settings: true,
      },
    },
    {
      path: ':id',
      name: 'projectShow',
      component: () => import(/* webpackChunkName: 'projects' */'@/views/projects/show'),
    },
  ],
}
