<template>
  <v-card :loading="loginLoading ? 'warning' : false">
    <v-toolbar color="deep-orange" dark flat>
      <v-toolbar-title>Login</v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="mr-1">Don't have an account yet?</span>
      <router-link
        to="/auth/register"
        class="white--text"
        style="text-decoration: none;"
      >
        Register
      </router-link>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="valid" ref="loginForm">
        <v-text-field
          label="Email"
          name="login"
          prepend-icon="mdi-email"
          v-model="email"
          :rules="emailRules"
          type="text"
        ></v-text-field>

        <v-text-field
          id="password"
          label="Password"
          name="password"
          v-model="password"
          :rules="passwordRules"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>
        <div class="d-flex justify-space-between">
          <v-checkbox
            v-model="remember"
            label="Remember me?"
            required
          ></v-checkbox>
          <v-btn text class="align-self-center">Forgot Password?</v-btn>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="pl-5 pb-3">
      <v-btn color="primary" @click="loginSubmit" depressed>Login</v-btn>
      <v-btn text :to="authBackTo">back</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
  name: 'Login',
  computed: {
    ...mapGetters(['authBackTo', 'loginLoading', 'authRedirectTo']),
  },
  data: () => ({
    valid: false,
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) =>
        !!(v || '').match(emailReg) || 'Email must be a valid email address.',
    ],
    passwordRules: [
      (v) => !!v || 'Password is required',
      // (v) => (v && v.length >= 8) || 'Password must be at least 8 characters.',
    ],
    email: null,
    password: null,
    remember: false,
  }),
  methods: {
    async loginSubmit() {
      // 进行表单校验后 使用 store 事件登录
      if (this.$refs.loginForm.validate()) {
        const loginData = {
          email: this.email,
          password: this.password,
          remember: this.remember,
        }
        if (await this.$store.dispatch('auth/login', loginData)) {
          this.$router.push(this.authRedirectTo)
        }
      }
    },
  },
}
</script>
