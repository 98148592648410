<template>
  <div>
    <template v-if="!isMobile">
      <common-header></common-header>
      <div class="tw-container tw-mx-auto" style="max-width: 900px;">
        <div class="tw-mt-8">
          <img src="@/assets/images/beeznests_team.png" />
        </div>
      </div>

      <div
        class="tw-mt-32 tw-pb-32 tw-pt-24 tw-flex tw-items-center tw-flex-col"
        style="background-color: #fffbef; position: relative;"
      >
        <div
          class="honeycomb tw-w-20 tw-h-20 tw--mt-8 tw-ml-8"
          style="position: absolute; left: 0; top: 0;"
        >
          <img src="@/assets/images/honeycomb.png" />
        </div>
        <div
          class="honeycomb tw-w-20 tw-h-20 tw-mb-4 tw-mr-8"
          style="position: absolute; right: 0; bottom: 0;"
        >
          <img src="@/assets/images/honeycomb2.png" />
        </div>
        <h3 class="tw-text-4xl tw-font-normal tw-pb-4">
          Our <span class="text-primary">Beeznests</span> Story
        </h3>
        <div class="tw-mt-10 tw-max-w-lg">
          <img src="@/assets/images/index-story.png" />
        </div>
        <div class="tw-max-w-lg tw-text-center tw-text-gray-500 tw-mt-8">
          Our company mission is to bridge the gap between students and
          companies by overcoming a geographical boundary. By creating a
          platform that solely focuses on remote tasks/ projects, we provide
          convenience and limitless opportunities for everyone. We would like to
          thank all the students and their overwhelming support throughout this
          journey.
        </div>
        <div class="tw-mt-10">
          <a
            href="#"
            class="tw-px-8 tw-py-4 bg-orange-2 tw-rounded tw-uppercase"
            >view more</a
          >
        </div>
      </div>
      <!--div class="tw-container tw-mx-auto" style="max-width: 770px;">
      <div class="tw-pt-6">
        <h3 class="tw-text-center tw-text-4xl tw-mt-8 tw-mb-6">
          Core <span class="text-primary">Team</span>
        </h3>
        <div class="tw-flex tw-flex-row tw-flex-wrap tw-justify-between">
          <div
            class="tw-flex tw-items-center tw-flex-col"
            v-for="i in 5"
            :key="`core-team-${i}`"
          >
            <img src="@/assets/images/our-avatar.png" class="tw-w-32 tw-h-32" />
            <h3 class="tw-text-xl tw-my-1">Mark JK Parker</h3>
            <span class="text-gray-1 tw-text-sm">Project Manager</span>
            <div
              class="tw-flex tw-flex-row tw-justify-between tw-mt-1 text-gray-1 tw-w-full tw-text-xs"
            >
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-linkedin"></i>
              </div>
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-facebook"></i>
              </div>
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-twitter"></i>
              </div>
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-mail-forward"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-pt-8">
        <h3 class="tw-text-center tw-text-4xl tw-mt-8 tw-mb-6">
          Ambassador <span class="text-primary">Team</span>
        </h3>
        <div class="tw-flex tw-flex-row tw-flex-wrap tw-justify-between">
          <div
            class="tw-flex tw-items-center tw-flex-col"
            v-for="i in 5"
            :key="`core-team-${i}`"
          >
            <img src="@/assets/images/our-avatar.png" class="tw-w-32 tw-h-32" />
            <h3 class="tw-text-xl tw-my-1">Mark JK Parker</h3>
            <span class="text-gray-1 tw-text-sm">Project Manager</span>
            <div
              class="tw-flex tw-flex-row tw-justify-between tw-mt-1 text-gray-1 tw-w-full tw-text-xs"
            >
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-linkedin"></i>
              </div>
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-facebook"></i>
              </div>
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-twitter"></i>
              </div>
              <div
                class="tw-border tw-p-1 tw-h-6 tw-w-6 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              >
                <i class="fa fa-mail-forward"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div-->
      <common-footer></common-footer>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="tw-mx-4">
            <div class="tw-mt-8">
              <img src="@/assets/images/beeznests_team.png" />
            </div>
          </div>
          <div
            class="tw-mt-12 tw-pb-32 tw-pt-24 tw-flex tw-items-center tw-flex-col"
            style="background-color: #fffbef; position: relative;"
          >
            <div
              class="honeycomb tw-w-10 tw-h-10 tw--mt-4 tw-ml-8"
              style="position: absolute; left: 0; top: 0;"
            >
              <img src="@/assets/images/honeycomb.png" />
            </div>
            <div
              class="honeycomb tw-w-10 tw-h-10 tw-mb-4 tw-mr-8"
              style="position: absolute; right: 0; bottom: 0;"
            >
              <img src="@/assets/images/honeycomb2.png" />
            </div>
            <h3 class="tw-text-4xl tw-font-normal tw-pb-4">
              Our <span class="text-primary">Beeznests</span> Story
            </h3>
            <div class="tw-mt-10 tw-max-w-lg">
              <img src="@/assets/images/index-story.png" />
            </div>
            <div
              class="tw-max-w-lg tw-text-center tw-text-gray-500 tw-mt-8 tw-mx-4"
            >
              Our company mission is to bridge the gap between students and
              companies by overcoming a geographical boundary. By creating a
              platform that solely focuses on remote tasks/ projects, we provide
              convenience and limitless opportunities for everyone. We would
              like to thank all the students and their overwhelming support
              throughout this journey.
            </div>
            <div class="tw-mt-10">
              <a
                href="#"
                class="tw-px-8 tw-py-4 bg-orange-2 tw-rounded tw-uppercase"
                >view more</a
              >
            </div>
          </div>
          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import CommonHeader from './common/header'
import CommonFooter from './common/footer'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import { mapGetters } from 'vuex'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
export default {
  components: {
    MobileDrawer,
    MobileFooter,
    MobileHeader,
    CommonFooter,
    CommonHeader,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.border-color-primary {
  border-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-5 {
  background-color: #fff9f1;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
.text-gray-2 {
  color: #031226;
}
.bg-main {
  background-color: #f5f9ff;
}
.bg-profile {
  background-image: url('~@/assets/images/profile-top.png');
}
.bg-blue-1 {
  background-color: #e1f6ff;
}
.text-blue-1 {
  color: #03a9f4;
}
.bg-blue-2 {
  background-color: #f0eff9;
}
.text-blue-2 {
  color: #1600db;
}
.bee-image-container {
}
</style>
