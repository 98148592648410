<template>
  <div>
    <template v-if="!isMobile">
      <common-header></common-header>
      <div class="tw-container tw-mx-auto" style="max-width: 1150px">
        <div class="tw-mx-10">
          <i class="fa fa-arrow-up"></i>
          Reminder: Find candidates by job category, contact them directly
          through our network for your project or internship.
        </div>
      </div>
      <div class="tw-container tw-mx-auto tw-mt-6">
        <div class="tw-flex tw-justify-center">
          <div class="tw-mr-12 tw--mt-2" style="max-width: 278px; width: 278px">
            <div
              class="tw-border tw-p-3 tw-rounded-lg tw-m-2 tw-shadow-md border-color-gray"
            >
              <h3
                class="tw-font-bold tw-border-l-4 tw-pl-1 border-color-2 tw-mt-4"
              >
                Network Categories
              </h3>
              <div class="tw-flex tw-justify-between tw-mt-4">
                <span
                  class="tw-font-bold tw-cursor-pointer"
                  :class="type === 'student' ? 'text-orange-2' : 'text-gray-1'"
                  @click="type = 'student'"
                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i
                  >Students</span
                >
                <span
                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"
                  >{{ 28000 + counts.student }}</span
                >
              </div>
              <div class="tw-flex tw-justify-between tw-mt-4">
                <span
                  class="tw-font-bold tw-cursor-pointer"
                  :class="type === 'company' ? 'text-orange-2' : 'text-gray-1'"
                  @click="type = 'company'"
                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i
                  >Companies</span
                >
                <span
                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"
                  >{{ 200 + counts.company }}</span
                >
              </div>
              <div class="tw-flex tw-justify-between tw-mt-4">
                <span
                  class="tw-font-bold tw-cursor-pointer"
                  :class="type === 'person' ? 'text-orange-2' : 'text-gray-1'"
                  @click="type = 'person'"
                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i
                  >Individual</span
                >
                <span
                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"
                  >{{ 200 + counts.person }}</span
                >
              </div>
              <!--              <div class="tw-flex tw-justify-between tw-mt-4">-->
              <!--                <span-->
              <!--                  class="tw-font-bold tw-cursor-pointer"-->
              <!--                  :class="type === 'mentor' ? 'text-orange-2' : 'text-gray-1'"-->
              <!--                  @click="type = 'mentor'"-->
              <!--                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i-->
              <!--                  >Beeznests Mentor</span-->
              <!--                >-->
              <!--                <span-->
              <!--                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"-->
              <!--                  >{{ counts.mentor }}</span-->
              <!--                >-->
              <!--              </div>-->
              <!--div class="tw-font-bold tw-mt-4 tw-ml-2 tw-text-sm">
              SHOW MORE<i class="tw-ml-1 fa fa-chevron-down"></i>
            </div-->
              <div
                class="tw-border-t tw-border-gray-400 tw--mx-3 tw-mt-6 border-color-gray"
              ></div>
              <div class="tw-mt-4">
                <h3 class="tw-font-bold tw-border-l-4 tw-pl-1 border-color-2">
                  Featured Student of the week
                </h3>
                <div class="tw-flex tw-flex-col tw-mt-2">
                  <div
                    class="tw-flex tw-mt-4 tw-ml-2 tw-overflow-hidden"
                    v-for="(item, i) in featureds"
                    :key="`top-${i}`"
                  >
                    <div
                      class="tw-rounded"
                      style="
                        min-width: 42px;
                        min-height: 42px;
                        width: 42px;
                        height: 42px;
                      "
                    >
                      <img :src="item.avatar" class="tw-rounded" />
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-justify-between tw-ml-2 tw-w-auto"
                      style="max-width: 94px"
                    >
                      <span
                        class="tw-font-bold tw-text-sm tw-leading-none tw-truncate"
                        >{{ item.name | noName }}{{ item.name | noName
                        }}{{ item.name | noName }}</span
                      >
                      <span class="text-gray-1 tw-text-xs tw-leading-none"
                        >UI Designer</span
                      >
                      <div class="tw-inline-block tw-text-xs tw-leading-none">
                        <i
                          class="fa fa-star text-primary"
                          v-for="index in 4"
                          :key="`top-${i}-star-${index}`"
                        ></i>
                        <i
                          class="fa fa-star-half-o text-primary"
                          aria-hidden="true"
                        ></i>
                        <span
                          class="text-primary tw-text-xs tw-font-bold tw-align-middle"
                          >({{ item.counts.rate | toFixed }})</span
                        >
                      </div>
                    </div>
                    <div
                      class="tw-self-center tw-ml-auto tw-flex tw-flex-1"
                      style="min-width: 66px"
                    >
                      <button
                        class="tw-outline-none focus:tw-outline-none tw-cursor-pointer tw-ml-auto bg-gray-2 tw-text-xs text-gray-1 tw-self-center tw-px-1 tw-rounded-full"
                        @click="
                          isFollowed(item.id)
                            ? unfollow(item.id)
                            : follow(item.id)
                        "
                        :disabled="isLoading(item.id)"
                      >
                        {{ isFollowed(item.id) ? 'UNFOLLOW' : 'FOLLOW'
                        }}{{ isLoading(item.id) ? '...' : null }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tw-border-t tw-border-gray-400 tw--mx-3 tw-mt-6 border-color-gray"
              ></div>
              <div class="tw-mt-4">
                <h3 class="tw-font-bold tw-border-l-4 tw-pl-1 border-color-2">
                  Discussion Forum
                </h3>
                <ul class="tw-mt-2 tw-ml-2">
                  <li>
                    <i
                      class="fa fa-hand-o-right text-primary"
                      aria-hidden="true"
                    ></i>
                    <a href="#" class="tw-ml-2 text-gray-1 tw-text-sm"
                      >Learn how to expand your network?</a
                    >
                  </li>
                  <li>
                    <i
                      class="fa fa-hand-o-right text-primary"
                      aria-hidden="true"
                    ></i>
                    <a href="#" class="tw-ml-2 text-gray-1 tw-text-sm"
                      >How to make good resume?</a
                    >
                  </li>
                  <li>
                    <i
                      class="fa fa-hand-o-right text-primary"
                      aria-hidden="true"
                    ></i>
                    <a href="#" class="tw-ml-2 text-gray-1 tw-text-sm"
                      >Learn how to expand your network?</a
                    >
                  </li>
                </ul>
                <div class="tw-my-6 tw-ml-2">
                  <a
                    href="#"
                    class="border-color-2 tw-shadow-md bg-orange-2 tw-text-sm tw-px-4 tw-py-2 tw-rounded tw-uppercase"
                  >
                    discussion forum<i
                      class="fa fa-arrow-circle-o-right tw-ml-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div style="width: 800px; max-width: 800px">
            <div>
              <div class="tw-flex tw-flex-row tw-flex-wrap tw--mt-6 tw--mr-4">
                <div
                  class="tw-mt-6 tw-mr-4"
                  v-for="(item, i) in data"
                  :key="`student-${i}`"
                  style="max-width: 186px; width: 186px"
                >
                  <div
                    class="tw-border tw-pt-4 tw-rounded-lg tw-text-center"
                    style="border-color: #ddd"
                  >
                    <div class="tw-flex tw-justify-center tw-relative">
                      <div
                        class="tw-w-20 tw-h-20 tw-bg-yellow-100 tw-rounded-full tw-border-2 tw-shadow tw-border-white tw-overflow-hidden"
                      >
                        <img :src="item.avatar" />
                      </div>
                      <div
                        class="tw-absolute tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-border-2 tw-shadow tw-border-white tw-overflow-hidden"
                        style="
                          transform: translateX(14px);
                          left: 50%;
                          bottom: 4px;
                          display: inline-flex;
                        "
                      >
                        <img :src="item.country_flag" />
                      </div>
                    </div>
                    <h1
                      class="tw-font-bold tw-items-center tw-flex tw-justify-center tw-text-xl tw-my-1 tw-mx-2 tw-max-w-full"
                    >
                      <span class="tw-block tw-truncate">{{
                        item.name | noName
                      }}</span>
                      <!--                      <img-->
                      <!--                        v-if="item.organization && item.organization.badge"-->
                      <!--                        class="tw-w-6 tw-h-6"-->
                      <!--                        :alt="item.organization.description"-->
                      <!--                        :title="item.organization.description"-->
                      <!--                        :src="`https://cdn.jsdelivr.net/gh/beeznests/static@master/dist/img/badge_${item.organization.badge}.png`"-->
                      <!--                      />-->
                      <div class="tw-text-sm">
                        <user-badge
                          :organization="item.organization"
                        ></user-badge>
                      </div>
                    </h1>
                    <div
                      class="text-gray-1 tw-text-sm tw-h-4 text-w-full"
                      style="overflow: hidden"
                    >
                      <template
                        v-if="item.categories && item.categories.length > 0"
                      >
                        {{ item.categories[0].name }}
                      </template>
                      <template v-else> No Skills </template>
                    </div>
                    <div
                      class="tw-my-1 tw-inline-block tw-text-xs tw-flex tw-items-center tw-justify-center"
                    >
                      <i
                        class="fa fa-star text-primary"
                        v-for="index in 4"
                        :key="`student-${i}-star-${index}`"
                      ></i>
                      <i
                        class="fa fa-star-half-o text-primary"
                        aria-hidden="true"
                      ></i>
                      <span
                        class="text-primary tw-text-xs tw-font-bold tw-align-middle"
                        >({{ item.counts.rate | toFixed }})</span
                      >
                    </div>
                    <div
                      class="text-gray-1 tw-px-4 tw-leading-tight tw-overflow-hidden tw-flex tw-justify-center tw-text-center tw-h-12 tw-text-xs"
                      style="height: 45px"
                    >
                      {{ item.is_mentor ? item.about_me : item.introduction }}
                    </div>
                    <div class="tw-mt-4">
                      <router-link
                        :to="{ name: 'NewProfile', params: { id: item.id } }"
                        class="tw-text-xs tw-block border-color-2 bg-gray-2 hover:tw-bg-yellow-300 hover:tw-bg-orange-300 tw-px-4 tw-py-2 tw-rounded-b tw-uppercase"
                      >
                        View Details
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="tw-flex tw-justify-center tw-mt-4">
                <div class="tw-my-6">
                  <button
                    @click="loadMore"
                    class="tw-px-4 tw-py-2 tw-rounded tw-uppercase tw-outline-none focus:tw-outline-none"
                    :class="
                      loading || noMoreData
                        ? 'tw-bg-gray-100 tw-border-gray-100'
                        : 'border-color-2 bg-orange-2'
                    "
                    :disabled="loading || noMoreData"
                  >
                    {{
                      noMoreData
                        ? 'no more data'
                        : loading
                        ? 'loading'
                        : 'load more'
                    }}<i
                      class="tw-ml-1 fa fa-arrow-circle-o-right"
                      aria-hidden="true"
                      v-if="!loading"
                    ></i>
                    <i
                      class="tw-ml-1 fa fa-spinner fa-spin"
                      aria-hidden="true"
                      v-else
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <common-footer></common-footer>
    </template>
    <template v-if="isMobile">
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <!-- 分类 -->
          <div class="tw-mx-4 tw-mt-6">
            <div
              class="tw-border tw-p-3 tw-rounded-lg tw-shadow-md border-color-gray"
            >
              <h3
                class="tw-font-bold tw-border-l-4 tw-pl-1 border-color-2 tw-mt-4"
              >
                Network Categories
              </h3>
              <div class="tw-flex tw-justify-between tw-mt-4">
                <span
                  class="tw-font-bold tw-cursor-pointer"
                  :class="type === 'student' ? 'text-orange-2' : 'text-gray-1'"
                  @click="type = 'student'"
                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i
                  >Students</span
                >
                <span
                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"
                  >{{ 8000 + counts.student }}</span
                >
              </div>
              <div class="tw-flex tw-justify-between tw-mt-4">
                <span
                  class="tw-font-bold tw-cursor-pointer"
                  :class="type === 'company' ? 'text-orange-2' : 'text-gray-1'"
                  @click="type = 'company'"
                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i
                  >Companies</span
                >
                <span
                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"
                  >{{ 200 + counts.company }}</span
                >
              </div>
              <div class="tw-flex tw-justify-between tw-mt-4">
                <span
                  class="tw-font-bold tw-cursor-pointer"
                  :class="type === 'person' ? 'text-orange-2' : 'text-gray-1'"
                  @click="type = 'person'"
                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i
                  >Individual</span
                >
                <span
                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"
                  >{{ 200 + counts.person }}</span
                >
              </div>
              <!--              <div class="tw-flex tw-justify-between tw-mt-4">-->
              <!--                <span-->
              <!--                  class="tw-font-bold tw-cursor-pointer"-->
              <!--                  :class="type === 'mentor' ? 'text-orange-2' : 'text-gray-1'"-->
              <!--                  @click="type = 'mentor'"-->
              <!--                  ><i class="fa fa-users tw-mr-1" aria-hidden="true"></i-->
              <!--                  >Beeznests Mentor</span-->
              <!--                >-->
              <!--                <span-->
              <!--                  class="bg-orange-3 tw-px-1 text-primary tw-rounded tw-text-xs tw-leading-loose"-->
              <!--                  >{{ counts.mentor }}</span-->
              <!--                >-->
              <!--              </div>-->
              <!--div class="tw-font-bold tw-mt-4 tw-ml-2 tw-text-sm">
              SHOW MORE<i class="tw-ml-1 fa fa-chevron-down"></i>
            </div-->
              <div
                class="tw-border-t tw-border-gray-400 tw--mx-3 tw-mt-6 border-color-gray"
              ></div>
              <div class="tw-mt-4">
                <h3 class="tw-font-bold tw-border-l-4 tw-pl-1 border-color-2">
                  Featured Student of the week
                </h3>
                <div class="tw-flex tw-flex-col tw-mt-2">
                  <div
                    class="tw-flex tw-mt-4 tw-ml-2 tw-overflow-hidden"
                    v-for="(item, i) in featureds"
                    :key="`top-${i}`"
                  >
                    <div
                      class="tw-rounded"
                      style="
                        min-width: 42px;
                        min-height: 42px;
                        width: 42px;
                        height: 42px;
                      "
                    >
                      <img :src="item.avatar" class="tw-rounded" />
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-justify-between tw-ml-2 tw-w-auto"
                      style="max-width: 94px"
                    >
                      <span
                        class="tw-font-bold tw-text-sm tw-leading-none tw-truncate"
                        >{{ item.name | noName }}{{ item.name | noName
                        }}{{ item.name | noName }}</span
                      >
                      <span class="text-gray-1 tw-text-xs tw-leading-none"
                        >UI Designer</span
                      >
                      <div class="tw-inline-block tw-text-xs tw-leading-none">
                        <i
                          class="fa fa-star text-primary"
                          v-for="index in 4"
                          :key="`top-${i}-star-${index}`"
                        ></i>
                        <i
                          class="fa fa-star-half-o text-primary"
                          aria-hidden="true"
                        ></i>
                        <span
                          class="text-primary tw-text-xs tw-font-bold tw-align-middle"
                          >({{ item.counts.rate | toFixed }})</span
                        >
                      </div>
                    </div>
                    <div
                      class="tw-self-center tw-ml-auto tw-flex tw-flex-1"
                      style="min-width: 66px"
                    >
                      <button
                        class="tw-outline-none focus:tw-outline-none tw-cursor-pointer tw-ml-auto bg-gray-2 tw-text-xs text-gray-1 tw-self-center tw-px-1 tw-rounded-full"
                        @click="
                          isFollowed(item.id)
                            ? unfollow(item.id)
                            : follow(item.id)
                        "
                        :disabled="isLoading(item.id)"
                      >
                        {{ isFollowed(item.id) ? 'UNFOLLOW' : 'FOLLOW'
                        }}{{ isLoading(item.id) ? '...' : null }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tw-border-t tw-border-gray-400 tw--mx-3 tw-mt-6 border-color-gray"
              ></div>
              <div class="tw-mt-4">
                <h3 class="tw-font-bold tw-border-l-4 tw-pl-1 border-color-2">
                  Discussion Forum
                </h3>
                <ul class="tw-mt-2 tw-ml-2">
                  <li>
                    <i
                      class="fa fa-hand-o-right text-primary"
                      aria-hidden="true"
                    ></i>
                    <a href="#" class="tw-ml-2 text-gray-1 tw-text-sm"
                      >Learn how to expand your network?</a
                    >
                  </li>
                  <li>
                    <i
                      class="fa fa-hand-o-right text-primary"
                      aria-hidden="true"
                    ></i>
                    <a href="#" class="tw-ml-2 text-gray-1 tw-text-sm"
                      >How to make good resume?</a
                    >
                  </li>
                  <li>
                    <i
                      class="fa fa-hand-o-right text-primary"
                      aria-hidden="true"
                    ></i>
                    <a href="#" class="tw-ml-2 text-gray-1 tw-text-sm"
                      >Learn how to expand your network?</a
                    >
                  </li>
                </ul>
                <div class="tw-my-6 tw-ml-2">
                  <a
                    href="#"
                    class="border-color-2 tw-shadow-md bg-orange-2 tw-text-sm tw-px-4 tw-py-2 tw-rounded tw-uppercase"
                  >
                    discussion forum<i
                      class="fa fa-arrow-circle-o-right tw-ml-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- 正文 -->
          <div class="tw-flex tw-flex-col tw-mx-4">
            <div
              class="tw-mt-6"
              v-for="(item, i) in data"
              :key="`student-${i}`"
            >
              <div
                class="tw-border tw-pt-4 tw-rounded-lg tw-text-center tw-overflow-hidden"
                style="border-color: #ddd"
              >
                <div class="tw-flex tw-justify-center tw-relative">
                  <div
                    class="tw-w-20 tw-h-20 tw-bg-yellow-100 tw-rounded-full tw-border-2 tw-shadow tw-border-white tw-overflow-hidden"
                  >
                    <img :src="item.avatar" />
                  </div>
                  <div
                    class="tw-absolute tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-border-2 tw-shadow tw-border-white tw-overflow-hidden"
                    style="transform: translateX(14px); left: 50%; bottom: 4px"
                  >
                    <img :src="item.country_flag" />
                  </div>
                </div>
                <h1
                  class="tw-font-bold tw-items-center tw-flex tw-justify-center tw-text-xl tw-my-1 tw-mx-2 tw-max-w-full"
                >
                  <span class="tw-block tw-truncate">{{
                    item.name | noName
                  }}</span>
                  <!--                  <img-->
                  <!--                    v-if="item.organization && item.organization.badge"-->
                  <!--                    class="tw-w-6 tw-h-6"-->
                  <!--                    :alt="item.organization.description"-->
                  <!--                    :title="item.organization.description"-->
                  <!--                    :src="`https://cdn.jsdelivr.net/gh/beeznests/static@master/dist/img/badge_${item.organization.badge}.png`"-->
                  <!--                  />-->
                  <user-badge :organization="item.organization"></user-badge>
                </h1>
                <div
                  class="text-gray-1 tw-text-sm tw-h-4 text-w-full"
                  style="overflow: hidden"
                >
                  <template
                    v-if="item.categories && item.categories.length > 0"
                  >
                    {{ item.categories[0].name }}
                  </template>
                  <template v-else> No Skills </template>
                </div>
                <div
                  class="tw-my-1 tw-inline-block tw-text-xs tw-flex tw-items-center tw-justify-center"
                >
                  <i
                    class="fa fa-star text-primary"
                    v-for="index in 4"
                    :key="`student-${i}-star-${index}`"
                  ></i>
                  <i
                    class="fa fa-star-half-o text-primary"
                    aria-hidden="true"
                  ></i>
                  <span
                    class="text-primary tw-text-xs tw-font-bold tw-align-middle"
                    >({{ item.counts.rate | toFixed }})</span
                  >
                </div>
                <div
                  class="text-gray-1 tw-px-4 tw-leading-tight tw-overflow-hidden tw-flex tw-justify-center tw-text-center tw-text-xs"
                >
                  {{ item.is_mentor ? item.about_me : item.introduction }}
                </div>
                <div class="tw-mt-4">
                  <router-link
                    :to="{ name: 'NewProfile', params: { id: item.id } }"
                    class="tw-text-xs tw-block border-color-2 bg-gray-2 hover:tw-bg-yellow-300 hover:tw-bg-orange-300 tw-px-4 tw-py-2 tw-rounded-b tw-uppercase"
                  >
                    View Details
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-justify-center tw-mt-4">
            <div class="tw-my-6">
              <button
                @click="loadMore"
                class="tw-px-4 tw-py-2 tw-rounded tw-uppercase tw-outline-none focus:tw-outline-none"
                :class="
                  loading || noMoreData
                    ? 'tw-bg-gray-100 tw-border-gray-100'
                    : 'border-color-2 bg-orange-2'
                "
                :disabled="loading || noMoreData"
              >
                {{
                  noMoreData
                    ? 'no more data'
                    : loading
                    ? 'loading'
                    : 'load more'
                }}<i
                  class="tw-ml-1 fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                  v-if="!loading"
                ></i>
                <i
                  class="tw-ml-1 fa fa-spinner fa-spin"
                  aria-hidden="true"
                  v-else
                ></i>
              </button>
            </div>
          </div>
          <mobile-footer></mobile-footer>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import CommonHeader from './common/header'
import CommonFooter from './common/footer'
import { fetchIndex } from '@/api/student'
import { mapActions, mapGetters } from 'vuex'
import MobileFooter from '@/views/new-pages/common/MobileFooter'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'
import UserBadge from '@/components/User/Badge'
export default {
  components: {
    MobileDrawer,
    MobileFooter,
    CommonHeader,
    CommonFooter,
    MobileHeader,
    UserBadge,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  data: () => ({
    showCategory: false,
    data: [],
    page: 1,
    last_page: 1,
    noMoreData: false,
    loading: false,
    categories: [],
    category_id: 0,
    keyword: '',
    featureds: [],
    type: 'student',
    counts: {
      company: 0,
      student: 0,
      person: 0,
      mentor: 0,
    },
  }),
  watch: {
    type() {
      this.loadData(true)
    },
    '$route.query.category_id'(category_id) {
      this.category_id = '' + category_id
      this.loadData(true)
    },
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
    this.loadData(true)
  },
  destroyed() {
    document.documentElement.style.fontSize = '16px'
  },
  methods: {
    async loadData(reset = false) {
      if (reset) {
        this.page = 1
        this.data = []
        this.noMoreData = false
      }
      this.loading = true
      const requestData = {}
      requestData.type = this.type
      if (this.category_id > 0) {
        requestData.category_id = this.category_id
      }
      requestData.page = this.page
      const res = await fetchIndex(requestData)
      this.data = reset ? res.data : this.data.concat(res.data)
      this.noMoreData = res.meta.last_page === this.page
      this.loading = false
      if (res.featured) {
        this.featureds = res.featured
      }
      if (res.counts) {
        this.counts = res.counts
      }
      if (res.featured_followed) {
        this.$store.dispatch('follow/update', res.featured_followed)
      }
    },
    async loadMore() {
      if (this.noMoreData) {
        return
      }
      this.page += 1
      await this.loadData()
    },
    isFollowed(id) {
      const index = this.$store.state.follow.data.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.data[index].followed
      return false
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    ...mapActions({
      follow: 'follow/follow',
      unfollow: 'follow/unfollow',
    }),
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.border-color-gray {
  border-color: #ddd;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
</style>
