<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8">
      <v-card class="pa-4" v-if="data.id">
        <v-card-text v-html="data.content" class="content"></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from '@/plugins/axios'
export default {
  data: () => ({
    data: {},
  }),
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const { data } = await axios.get('articles/links')
      if (data.id) {
        this.data = data
      }
    },
  },
}
</script>
<style lang="scss">
.content {
  text-indent: 2em;
}
</style>
