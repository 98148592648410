import axios from '@/plugins/axios'
export const create = (data) => {
  return axios.post('promotions', data)
}

export const show = (id, params) => {
  return axios.get(`promotions/${id}`, { params })
}

export const fetch = (params) => {
  return axios.get('promotions/', { params })
}

export const destroy = (id) => {
  return axios.delete(`promotions/${id}`)
}

export const update = (id, data) => {
  return axios.put(`promotions/${id}`, data)
}
