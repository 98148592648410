<template>
  <v-card>
    <v-card-text>
      <v-row class="mx-auto" justify="center">
        <router-link
          :to="{
            name: 'userShow',
            params: { id: item.id },
          }"
          style="text-decoration: none;"
        >
          <v-avatar>
            <v-img :src="item.avatar" />
          </v-avatar>
        </router-link>
      </v-row>
    </v-card-text>
    <v-card-title class="justify-center pt-0">
      <span class="font-weight-light text-center">
        <router-link
          :to="{
            name: 'userShow',
            params: { id: item.id },
          }"
          style="text-decoration: none;"
          >{{ item.name | noName }}</router-link
        ></span
      >
    </v-card-title>
    <v-card-text>
      <v-row align="center" class="mx-0" justify="center">
        <v-rating
          :value="parseFloat(item.counts.rate)"
          color="amber"
          dense
          half-increments
          readonly
          size="22"
        ></v-rating>

        <div class="grey--text ml-4">
          {{ item.counts.rate | toFixed }} ({{ item.counts.rate_count }})
        </div>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-sheet
        :style="
          isMobile
            ? ''
            : 'height: 88px; overflow: hidden; text-overflow: ellipsis;'
        "
        >{{ item.introduction }}</v-sheet
      >
      <v-chip-group
        active-class="deep-purple accent-4 white--text"
        v-if="item.type === 'student'"
        center-active
      >
        <template v-if="categories.length > 0">
          <v-chip
            v-for="category in categories"
            :key="`student-${item.id}-category-${category.id}`"
            @click="changeCategory(category.id)"
            >{{ category.name }}</v-chip
          >
        </template>
        <v-chip label>
          No Skills
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <v-card-actions>
      <v-btn
        icon
        :loading="isLoading(item.id)"
        @click="isFollowed(item.id) ? unfollow(item.id) : follow(item.id)"
        :color="isFollowed(item.id) ? 'primary' : ''"
      >
        <v-icon class="mr-1">mdi-account-heart-outline</v-icon> </v-btn
      ><small>{{ item.followers_count }}</small>
      <v-btn
        class="ml-auto"
        color="orange"
        text
        :to="{ name: 'userShow', params: { id: item.id } }"
      >
        View More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {
          categories: [],
        }
      },
    },
  },
  computed: {
    categories() {
      return this.item.categories || []
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    isFollowed(id) {
      const index = this.$store.state.follow.data.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.data[index].followed
      return false
    },
    isLoading(id) {
      const index = this.$store.state.follow.loading.findIndex(
        (item) => item.id === id
      )
      if (index > -1) return this.$store.state.follow.loading[index].status
      return false
    },
    ...mapActions({
      follow: 'follow/follow',
      unfollow: 'follow/unfollow',
    }),
  },
}
</script>
