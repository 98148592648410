<template>
  <div>
    <template v-if="!isMobile">
      <div
        class="tw-h-screen tw-flex tw-items-center login-bg tw-bg-right-bottom tw-bg-contain"
      >
        <div
          class="tw-container tw-mx-auto tw-flex tw-flex-row tw-justify-between"
          style="max-width: 1024px;"
        >
          <div
            class="tw-border tw-rounded tw-relative tw-bg-white tw-p-6 tw-w-5/12 tw-self-center"
          >
            <div
              class="tw-absolute tw-w-20 tw-h-20 tw--mt-32 tw--mr-12"
              style="right: 0; top: 0;"
            >
              <img src="@/assets/images/honeycomb2.png" />
            </div>
            <h2 class="tw-text-4xl tw-mb-4">Welcome Back</h2>
            <div>
              <div
                class="tw-border tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-t"
                :class="{ active: emailFocusIn }"
              >
                <label for="email" class="tw-text-sm text-gray-1"
                  >Email Address</label
                >
                <input
                  id="email"
                  type="text"
                  class="tw-outline-none"
                  required
                  @focusin="emailFocusIn = true"
                  @focusout="emailFocusIn = false"
                  v-model="email"
                />
              </div>
              <div
                class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-b"
                :class="{ active: passwordFocusIn }"
              >
                <label for="password" class="tw-text-sm text-gray-1"
                  >Password</label
                >
                <input
                  id="password"
                  type="password"
                  required
                  class="tw-outline-none"
                  @focusin="passwordFocusIn = true"
                  @focusout="passwordFocusIn = false"
                  v-model="password"
                />
              </div>
            </div>
            <div v-if="hasError" class="tw-text-red-400 tw-pt-2 tw-text-sm">
              {{ errorMessage }}
            </div>
            <div class="tw-flex tw-justify-between tw-py-3 tw-text-sm">
              <div class="text-gray-1">
                Don’t have an a account yet?
                <router-link to="/new-register" class="text-primary"
                  >Sign Up</router-link
                >
              </div>
              <router-link :to="{ name: 'ForgotPassword' }" class="text-primary"
                >Forget password?</router-link
              >
            </div>
            <button
              @click="loginSubmit"
              class="tw-block tw-uppercase tw-py-3 tw-w-full tw-rounded tw-outline-none focus:tw-outline-none"
              :class="!loginLoading ? 'bg-orange-2' : 'tw-bg-gray-400'"
              :disabled="loginLoading"
            >
              <i class="fa fa-spinner fa-spin" v-if="loginLoading"></i>
              <i class="fa fa-arrow-circle-o-right" v-else></i
              ><span class="tw-font-bold tw-ml-1">Login</span>
            </button>
            <div class="text-gray-1 tw-text-sm tw-py-2">
              By logging in, you agree to ours Terms of Use and Privacy Policy
            </div>
          </div>
          <div class="tw-w-5/12">
            <img src="@/assets/images/login.png" />
          </div>
        </div>
        <div
          class="tw-absolute tw-w-20 tw-h-20 tw-mb-8 tw-ml-8"
          style="left: 0; bottom: 0;"
        >
          <img src="@/assets/images/honeycomb.png" />
        </div>
      </div>
    </template>
    <template v-else>
      <v-app>
        <mobile-drawer></mobile-drawer>
        <mobile-header></mobile-header>
        <v-main>
          <div class="login-bg">
            <div class="tw-pt-12 tw-pb-12">
              <div class="tw-mx-6">
                <img src="@/assets/images/login.png" />
              </div>
              <div
                class="tw-border tw-mt-6 tw-rounded tw-relative tw-bg-white tw-p-6 tw-mx-4 tw-self-center"
              >
                <div
                  class="tw-absolute tw-w-10 tw-h-10"
                  style="right: 0; top: 0;"
                >
                  <img src="@/assets/images/honeycomb2.png" />
                </div>
                <h2 class="tw-text-4xl tw-mb-4">Welcome Back</h2>
                <div>
                  <div
                    class="tw-border tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-t"
                    :class="{ active: emailFocusIn }"
                  >
                    <label for="email" class="tw-text-sm text-gray-1"
                      >Email Address</label
                    >
                    <input
                      id="email"
                      type="text"
                      class="tw-outline-none"
                      required
                      @focusin="emailFocusIn = true"
                      @focusout="emailFocusIn = false"
                      v-model="email"
                    />
                  </div>
                  <div
                    class="tw-border tw-border-t-0 tw-border-l-4 tw-flex tw-flex-col tw-p-2 tw-rounded-b"
                    :class="{ active: passwordFocusIn }"
                  >
                    <label for="password" class="tw-text-sm text-gray-1"
                      >Password</label
                    >
                    <input
                      id="password"
                      type="password"
                      required
                      class="tw-outline-none"
                      @focusin="passwordFocusIn = true"
                      @focusout="passwordFocusIn = false"
                      v-model="password"
                    />
                  </div>
                </div>
                <div v-if="hasError" class="tw-text-red-400 tw-pt-2 tw-text-sm">
                  {{ errorMessage }}
                </div>
                <div class="tw-flex tw-justify-between tw-py-3 tw-text-sm">
                  <div class="text-gray-1">
                    Don’t have an a account yet?
                    <router-link to="/new-register" class="text-primary"
                      >Sign Up</router-link
                    >
                  </div>
                  <router-link
                    :to="{ name: 'ForgotPassword' }"
                    class="text-primary"
                    >Forget password?</router-link
                  >
                </div>
                <button
                  @click="loginSubmit"
                  class="tw-block tw-uppercase tw-py-3 tw-w-full tw-rounded tw-outline-none focus:tw-outline-none"
                  :class="!loginLoading ? 'bg-orange-2' : 'tw-bg-gray-400'"
                  :disabled="loginLoading"
                >
                  <i class="fa fa-spinner fa-spin" v-if="loginLoading"></i>
                  <i class="fa fa-arrow-circle-o-right" v-else></i
                  ><span class="tw-font-bold tw-ml-1">Login</span>
                </button>
                <div class="text-gray-1 tw-text-sm tw-py-2">
                  By logging in, you agree to ours Terms of Use and Privacy
                  Policy
                </div>
              </div>
            </div>
          </div>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MobileHeader from '@/views/new-pages/common/MobileHeader'
import MobileDrawer from '@/views/new-pages/common/MobileDrawer'

export default {
  components: { MobileDrawer, MobileHeader },
  computed: {
    ...mapGetters(['authBackTo', 'loginLoading', 'authRedirectTo', 'isMobile']),
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
  },
  destroyed() {
    // document.documentElement.style.fontSize = '16px'
  },
  data: () => ({
    emailFocusIn: false,
    passwordFocusIn: false,
    email: null,
    password: null,
    hasError: false,
    errorMessage: null,
  }),
  methods: {
    emailFocus() {
      this.emailFocusIn = true
    },
    passwordFocus() {
      this.passwordFocusIn = true
    },
    async loginSubmit() {
      // 进行表单校验后 使用 store 事件登录
      try {
        const loginData = {
          email: this.email,
          password: this.password,
          remember: this.remember,
        }
        if (await this.$store.dispatch('auth/login', loginData)) {
          this.$router.push(this.authRedirectTo)
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.hasError = true
          this.errorMessage = e.response.data.message
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.text-primary {
  color: #ff9800 !important;
}
.text-orange-2 {
  color: #fecc17;
}
.bg-primary {
  background-color: #ff9800;
}
.border-color-primary {
  border-color: #ff9800;
}
.bg-orange-2 {
  background-color: #fecc17;
}
.bg-top {
  background-color: #fed43a;
}
.border-color-2 {
  border-color: #fecc17;
}
.bg-orange-3 {
  background-color: #fff0d9;
}
.bg-orange-4 {
  background-color: #fffbef;
}
.bg-orange-5 {
  background-color: #fff9f1;
}
.logo-image {
  width: 147px;
  height: 64px;
  img {
    height: 64px;
    width: auto;
  }
}
.bg-gray-1 {
  background-color: #8a92ab;
}
.bg-gray-2 {
  background-color: #eff3f9;
}
.text-gray-1 {
  color: #8a92ab;
}
.text-gray-2 {
  color: #031226;
}
.bg-main {
  background-color: #f5f9ff;
}
.bg-profile {
  background-image: url('~@/assets/images/profile-top.png');
}
.bg-blue-1 {
  background-color: #e1f6ff;
}
.text-blue-1 {
  color: #03a9f4;
}
.bg-blue-2 {
  background-color: #f0eff9;
}
.text-blue-2 {
  color: #1600db;
}
.bee-image-container {
}
.active {
  border-left-color: #fecc17;
}
.login-bg {
  background-color: #fef6df;
  background-image: url('~@/assets/images/login-bg.png');
}
</style>
