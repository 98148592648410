<template>
  <router-view></router-view>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isMobile']),
    pathName() {
      return window.location.pathname
    },
  },
  created() {
    this.$store.dispatch('auth/isLogged')
    this.$store.dispatch('device/setMobile')
    // this.gotoMobile()
  },
  mounted() {
    document.documentElement.style.fontSize = '14px'
  },
  methods: {
    gotoMobile() {
      if (
        (this.$route.name === 'Index' || this.pathName === '/') &&
        this.isMobile
      ) {
        this.$router.replace({ name: 'MobileIndex' })
      }
    },
  },
}
</script>
