<template>
  <v-card class="pa-2 fill-height">
    <template v-if="noData || loadError">
      <v-card-text
        class="d-flex flex-column justify-center flex-grow-1 fill-height"
      >
        <v-alert type="warning" class="mb-0" v-if="noData"
          >No new notifications</v-alert
        >
        <v-alert type="warning" class="mb-0" v-if="loadError"
          >Network Error!</v-alert
        >
      </v-card-text>
    </template>
    <template v-else-if="loading">
      <loading :loading="loading"></loading>
    </template>
    <v-list three-line v-else>
      <template v-for="(item, index) in notifications">
        <notification-replied
          :item="item"
          :key="`notification-${index}`"
          v-if="item.type === 'App\\Notifications\\RepliedNotification'"
        ></notification-replied>
        <notification-project-applied
          :item="item"
          :key="`notification-${index}`"
          v-if="
            item.type ===
            'App\\Notifications\\ProjectHasNewReceiverNotification'
          "
        ></notification-project-applied>
        <notification-receive-selected
          :item="item"
          :key="`notification-${index}`"
          v-if="item.type === 'App\\Notifications\\ReceiveSelectedNotification'"
        ></notification-receive-selected>
        <notification-complete-project
          :item="item"
          :key="`notification-${index}`"
          v-if="item.type === 'App\\Notifications\\CompleteProjectNotification'"
        ></notification-complete-project>
        <notification-finished-project
          :item="item"
          :key="`notification-${index}`"
          v-if="item.type === 'App\\Notifications\\FinishedProjectNotification'"
        ></notification-finished-project>
        <notification-reviewed
          :item="item"
          :key="`notification-${index}`"
          v-if="item.type === 'App\\Notifications\\ReviewedReceiveNotification'"
        ></notification-reviewed>
        <notification-followed
          :item="item"
          :key="`notification-${index}`"
          v-if="item.type === 'App\\Notifications\\FollowedNotification'"
        ></notification-followed>
        <notification-challenge-best-replied
          :item="item"
          :key="`notification-${index}`"
          v-if="
            item.type ===
            'App\\Notifications\\ChallengeReplyChangeBestNotification'
          "
        ></notification-challenge-best-replied>
        <v-divider
          v-if="index < notifications.length - 1"
          :key="`divider-${index}`"
        ></v-divider>
      </template>
    </v-list>
    <div class="text-center" v-if="!loading && !noData && pages.last_page > 1">
      <v-pagination
        v-model="pages.current_page"
        :length="pages.last_page"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { fetchAll } from '@/api/notification'
import NotificationReplied from '@/components/Notification/Replied'
import NotificationProjectApplied from '@/components/Notification/ProjectApplied'
import NotificationReceiveSelected from '@/components/Notification/ReceiveSelected'
import NotificationCompleteProject from '@/components/Notification/ProjectComplete'
import NotificationFinishedProject from '@/components/Notification/ProjectFinished'
import NotificationReviewed from '@/components/Notification/Reviewed'
import NotificationFollowed from '@/components/Notification/Followed'
import NotificationChallengeBestReplied from '@/components/Notification/ChallengeBestReplied'
import Loading from '@/components/Loading'
export default {
  components: {
    Loading,
    NotificationReplied,
    NotificationProjectApplied,
    NotificationReceiveSelected,
    NotificationCompleteProject,
    NotificationReviewed,
    NotificationFinishedProject,
    NotificationFollowed,
    NotificationChallengeBestReplied,
  },
  data: () => ({
    notifications: [],
    noData: false,
    loading: false,
    loadError: false,
    pages: {
      current_page: 1,
      last_page: 1,
    },
  }),
  watch: {
    'pages.current_page'() {
      this.loadData()
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const requestData = {
        page: this.pages.current_page,
      }
      try {
        this.loading = true
        const { data, meta } = await fetchAll(requestData)
        if (requestData.page === 1 && data.length <= 0) {
          this.noData = true
        }
        this.notifications = data

        this.loading = false
        this.pages = meta
        this.loadError = false
      } catch (e) {
        console.log(e)
        this.loadError = true
        this.loading = false
      }
    },
  },
}
</script>
