<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <!--v-toolbar-title>Settings</v-toolbar-title-->
        <v-alert
          v-if="alertMessage"
          icon="mdi-alert-outline"
          prominent
          text
          type="warning"
          color="yellow darken-3"
          class="mt-2"
        >
          {{ alertMessage }}
        </v-alert>
        <!--        <v-alert-->
        <!--          v-if="userInfo.is_mentor && !alertMessage && userInfo.type"-->
        <!--          icon="mdi-alert-outline"-->
        <!--          prominent-->
        <!--          text-->
        <!--          type="warning"-->
        <!--          color="yellow darken-3"-->
        <!--          class="mt-2"-->
        <!--        >-->
        <!--          Please complete your profile as detailed as possible. Your profile-->
        <!--          plays a large role in why students are companies wish to work with-->
        <!--          you.-->
        <!--        </v-alert>-->
        <v-card outlined flat class="mt-10">
          <v-toolbar flat v-if="!userInfo.type">
            <v-toolbar-title>Select your Status</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <template v-if="!userInfo.type">
              <v-row>
                <v-col>
                  <v-alert type="warning" text>
                    <template v-slot:prepend><i></i></template>
                    Once you select your status, you can’t change it!</v-alert
                  >
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="4" md="3" class="text-center">
                  <v-btn
                    color="info"
                    outlined
                    @click="check('student')"
                    :loading="btnLoading.student"
                    >Students</v-btn
                  >
                  <div>Current Students</div>
                </v-col>
                <v-col cols="12" sm="4" md="3" class="text-center">
                  <v-btn
                    color="info"
                    outlined
                    @click="check('company')"
                    :loading="btnLoading.company"
                    >Company</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="4" md="3" class="text-center">
                  <v-btn
                    color="info"
                    outlined
                    @click="check('person')"
                    :loading="btnLoading.person"
                    >Individual
                  </v-btn>
                  <div>
                    Graduated Students, Immigrants, Industry Experts/ Professors
                  </div>
                </v-col>
              </v-row>
            </template>
            <template
              v-else-if="!isSelectMentor && userInfo.type !== 'student'"
            >
              <v-toolbar flat>
                <v-toolbar-title>Enter the code</v-toolbar-title>
              </v-toolbar>
              <div class="ma-4">
                <v-text-field
                  label="Your code"
                  v-model="userCode"
                  :error-messages="mentorCodeError"
                  hint="If you’re a Beeznests mentor or college team lead,  please enter your designated code. Skip this step otherwise."
                  persistent-hint
                ></v-text-field>
              </div>
              <v-card-actions>
                <v-btn @click="verifyMentorCode">Next</v-btn>
                <v-btn @click="isSelectMentor = true">Skip</v-btn>
              </v-card-actions>
            </template>
            <ValidationObserver ref="observer" v-else>
              <form>
                <v-text-field
                  label="Email"
                  disabled
                  v-model="userInfo.email"
                ></v-text-field>
                <field-row
                  label="Avatar"
                  @update="showEdit('avatar')"
                  :divider="false"
                >
                  <v-avatar :size="60">
                    <v-img :src="userInfo.avatar" />
                  </v-avatar>
                </field-row>
                <template
                  v-if="
                    userInfo.is_mentor &&
                    (userInfo.type === 'person' || userInfo.type === 'company')
                  "
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required|max:255"
                  >
                    <v-text-field
                      label="*Name"
                      v-model="userInfo.name"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <template v-if="userInfo.is_mentor">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="About me"
                    rules="required|max:1024"
                  >
                    <v-text-field
                      label="*About me"
                      v-model="userInfo.about_me"
                      :error-messages="errors"
                      hint="Tell us what you hope to offer students as a Beeznests mentor or College team lead"
                      persistent-hint
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Working Experience"
                    rules="required|max:1024"
                  >
                    <v-text-field
                      label="*Working Experience"
                      v-model="userInfo.experience"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <template v-if="userInfo.type === 'company'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Company Name"
                    rules="required|max:255"
                  >
                    <v-text-field
                      label="*Company Name"
                      v-model="userInfo.identityable.name"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <template
                  v-else-if="
                    !userInfo.is_mentor ||
                    (userInfo.is_mentor && userInfo.type !== 'person')
                  "
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required|max:255"
                  >
                    <v-text-field
                      label="*Name"
                      v-model="userInfo.name"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Introduction"
                  :counter="255"
                  rules="required|max:255"
                  v-if="!(userInfo.type === 'person' && userInfo.is_mentor)"
                >
                  <v-textarea
                    label="*Introduction"
                    v-model="userInfo.introduction"
                    rows="1"
                    :error-messages="errors"
                    show-hint
                    persistent-hint
                    :hint="
                      userInfo.type === 'company'
                        ? 'Tell us something about your company'
                        : 'Tell us something about yourself and what do you want to contribute to our community'
                    "
                  ></v-textarea>
                </ValidationProvider>
                <languages v-model="userInfo.languages"></languages>
                <country v-model="userInfo.country"></country>
                <template v-if="userInfo.type === 'student'">
                  <v-select
                    label="Education"
                    v-model="userInfo.identityable.education"
                    :items="[
                      'High schooler',
                      'Undergraduate',
                      'Graduated',
                      'Master',
                      'PHD',
                    ]"
                  ></v-select>
                  <v-text-field
                    label="LinkedIn"
                    v-model="userInfo.identityable.linkedin"
                  ></v-text-field>
                  <resume @update="changeResume"></resume>
                  <new-skills @update="changeSkills"></new-skills>
                </template>
                <template v-else-if="userInfo.type === 'company'">
                  <v-text-field
                    label="Company Address"
                    v-model="userInfo.identityable.address"
                  ></v-text-field>
                  <v-col cols="12" class="pa-0">
                    <header>Is Profit</header>
                  </v-col>
                  <v-switch
                    v-model="userInfo.identityable.is_profit"
                    :label="`${
                      userInfo.identityable.is_profit ? 'Profit' : 'Non-Profit'
                    }`"
                  ></v-switch>
                  <v-select
                    v-model="userInfo.identityable.employees"
                    label="Company Employees"
                    :items="['0-20', '20-50', '50-100', '100-200', '200+']"
                  ></v-select>
                  <v-text-field
                    label="Linkedin"
                    v-model="userInfo.identityable.linkedin"
                  ></v-text-field>
                  <v-text-field
                    label="Website"
                    v-model="userInfo.identityable.website"
                  ></v-text-field>
                  <resume
                    @update="changeResume"
                    v-if="userInfo.is_mentor"
                  ></resume>
                  <new-skills
                    @update="changeSkills"
                    v-if="userInfo.is_mentor"
                  ></new-skills>
                </template>
                <template v-else-if="userInfo.type === 'person'">
                  <v-text-field
                    label="LinkedIn"
                    v-model="userInfo.identityable.linkedin"
                  ></v-text-field>
                  <resume @update="changeResume"></resume>
                  <new-skills @update="changeSkills"></new-skills>
                </template>
                <v-card-actions class="mt-6 ml-n2">
                  <v-btn
                    depressed
                    color="deep-orange"
                    class="white--text"
                    @click="save"
                    :loading="saveLoading"
                    >Save</v-btn
                  >
                  <v-btn depressed v-if="showBack" @click="back">Back</v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
        <v-dialog v-model="showAvatarModal" width="500">
          <avatar
            @hide="showAvatarModal = false"
            @update="changeAvatar"
            :value="userInfo.avatar"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { required, email, max } from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate'
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
import { mapGetters } from 'vuex'
import Languages from '@/components/Settings/Languages'
import Country from '@/components/Settings/Country'
import Resume from '@/components/Settings/Resume'
import FieldRow from '@/components/Settings/FieldRow'
import Avatar from '@/components/Settings/Avatar'
import { updateSetting, verifyMentorCode } from '@/api/setting'
import NewSkills from '@/components/Settings/NewSkills'
import { get } from 'lodash'
export default {
  components: {
    Resume,
    Country,
    Languages,
    ValidationProvider,
    ValidationObserver,
    FieldRow,
    Avatar,
    NewSkills,
  },
  computed: {
    userInfo: {
      get() {
        return this.$store.state.auth.userInfo
      },
      set() {},
    },
    alertMessage() {
      const message = this.$store.state.alert.message
      this.$store.commit('alert/CLEAR')
      return message
    },
  },
  data: () => ({
    btnLoading: {
      student: false,
      company: false,
      person: false,
    },
    showBack: false,
    saveLoading: false,
    showAvatarModal: false,
    isSelectMentor: false, // 是否选择了是不是 mentor (导师)
    userCode: null,
    verifyMentorCodeLoading: false,
    mentorCodeError: null,
  }),
  mounted() {
    this.isSelectMentor = !!this.userInfo.type
  },
  methods: {
    check(type) {
      this.showBack = true
      this.userInfo.type = type
      if (type === 'student') {
        this.isSelectMentor = true
      }
    },
    back() {
      this.userInfo.type = null
      this.isSelectMentor = false
    },
    async save() {
      const isValidate = await this.$refs.observer.validate()
      // 如果是个人 并且是导师
      if (this.userInfo.is_mentor && this.userInfo.type === 'person') {
        this.userInfo.introduction = this.userInfo.about_me
      }
      if (!isValidate && !this.userInfo.introduction) {
        this.$message('Please fill out the introduction', 'error')
        return
      }
      if (
        !isValidate &&
        !this.userInfo.name &&
        this.userInfo.type !== 'company'
      ) {
        this.$message('Please fill out the name', 'error')
        return
      }
      // 检查简历是否上传
      if (!this.userInfo.resume_file && this.userInfo.type !== 'company') {
        this.$message('Please upload your resume', 'error')
        return
      }
      // 检查导师建立是否上传
      if (!this.userInfo.resume_file && this.userInfo.is_mentor) {
        this.$message('Please upload your resume', 'error')
        return
      }
      // 强制上传头像
      if (this.userInfo.default_avatar === this.userInfo.avatar) {
        this.$message('Please upload your avatar', 'error')
        return
      }
      // 保存信息
      const postData = Object.assign({ categories: [] }, this.userInfo)
      // 判断
      if (
        (!postData.categories || postData.categories.length <= 0) &&
        this.userInfo.type !== 'company'
      ) {
        this.$message('Please select the skills', 'error')
        return
      } else {
        postData.categories = postData.categories.map((item) => item.id)
      }
      if (postData.languages && postData.languages.length > 0) {
        postData.languages = postData.languages.map((item) => item.id)
      }
      this.saveLoading = true
      try {
        const res = await updateSetting(postData)
        if (res.status_code === 201) {
          this.$message('Your profile is updated!')
        }
        this.saveLoading = false
        await this.$store.dispatch('auth/isLogged')
        // 检查是否需要支付费用
        if (
          this.$store.getters.userInfo &&
          this.$store.getters.userInfo.is_need_to_pay_cost
        ) {
          this.$router.push({
            name: 'PaymentUserCost',
          })
          return
        }
        if (this.$store.state.setting.redirectTo.fullPath) {
          const redirectTo = this.$store.state.setting.redirectTo
          this.$store.commit('setting/SET_REDIRECT_TO', {})
          this.$router.push(redirectTo)
        } else {
          this.$router.push({ name: 'nextSettings' })
        }
      } catch (e) {
        console.log('error', e)
        this.saveLoading = false
      }
    },
    showEdit(field) {
      this.showAvatarModal = true
    },
    changeAvatar(avatar) {
      this.userInfo.avatar = avatar
    },
    changeSkills(skills) {
      this.userInfo.categories = skills
    },
    changeResume(file) {
      this.userInfo.resume_file = file
    },
    // 验证 mentor code 是否正确
    async verifyMentorCode() {
      if (this.verifyMentorCodeLoading) return
      this.verifyMentorCodeLoading = true
      try {
        const { data } = await verifyMentorCode(this.userCode)
        this.userInfo.organization_id = data.id
        this.isSelectMentor = true
        this.userInfo.is_mentor = true
      } catch (e) {
        if (get(e, 'response.data.message', null)) {
          this.mentorCodeError = e.response.data.message
        }
      } finally {
        this.verifyMentorCodeLoading = false
      }
    },
  },
}
</script>
