<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="4" class="mt-12">
        <v-card>
          <v-card-title>
            Subscribe setting
          </v-card-title>
          <v-card-text>
            <v-input>
              Subscribe to receive email notifications about new projects
            </v-input>
            <v-switch
              v-model="isSubscribe"
              :label="`${isSubscribe ? 'Subscribe' : 'Unsubscribe'}`"
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settingSubscribe } from '@/api/setting'

export default {
  data: () => ({
    isSubscribe: undefined,
  }),
  watch: {
    isSubscribe(newState) {
      newState !== this.initSubscribe && this.settingSubscribe()
    },
    '$store.getters.userInfo'(newState) {
      this.initSubscribe = newState.is_subscribe_email
      this.isSubscribe = this.initSubscribe
    },
  },
  mounted() {},
  methods: {
    settingSubscribe() {
      settingSubscribe().then(() => {
        this.initSubscribe = this.isSubscribe
      })
    },
  },
}
</script>
