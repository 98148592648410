<template>
  <v-row justify="center">
    <v-col cols="12" md="10" xl="8">
      <v-row>
        <v-col cols="12" md="9">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <v-form v-model="form" v-if="!loading">
                <v-text-field
                  label="Title"
                  :rules="[rules.required]"
                  v-model="title"
                ></v-text-field>
                <v-sheet class="my-3">
                  <label class="mt-5">Content</label>
                  <v-btn
                    text
                    style="text-decoration: none; text-transform: none;"
                    @click="challengeDialog = true"
                    >Description For Business Challenge</v-btn
                  >
                </v-sheet>
                <froala v-model="content"></froala>
                <v-text-field
                  label="Webinar Link"
                  class="mt-6"
                  v-model="meeting"
                  :rules="meetingRules"
                ></v-text-field>
                <v-scroll-y-transition>
                  <v-row v-if="meeting">
                    <v-col>
                      <date-picker
                        v-model="meeting_date"
                        label="Webinar Date"
                      ></date-picker>
                    </v-col>
                    <v-col>
                      <time-picker
                        v-model="meeting_time"
                        label="Webinar Time"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
                <date-picker v-model="closed_at"></date-picker>
              </v-form>
              <loading v-else :loading="loading"></loading>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="!form"
                :loading="submitLoading"
                @click="submit"
                color="info"
                depressed
                >{{ id > 0 ? 'Update' : 'Submit' }}</v-btn
              >
              <v-btn @click="$router.go(-1)" depressed>Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="challengeDialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Description</v-toolbar-title>
        </v-toolbar>
        <v-list subheader>
          <v-list-item
            v-for="(desc, index) in descriptions"
            :key="`challenge-description-${index}`"
          >
            <v-list-item-content>
              {{ index + 1 }}. {{ desc }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { fetchData, createOrUpdate } from '@/api/challenge'
import loading from '@/components/Loading'
import Froala from '@/components/Froala'
import DatePicker from '@/components/DatePicker'
import TimePicker from '@/components/TimePicker'
import { urlRule } from '@/utils/rules'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    loading,
    Froala,
    DatePicker,
    TimePicker,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.isLoggedIn || !vm.userInfo) {
        vm.$router.push({ name: 'AuthLogin' })
      } else if (!vm.userInfo.type) {
        vm.$message('Please Complete Your Profile First')
        vm.$router.push({ name: 'userSettings' })
      }
    })
  },
  data: (vm) => ({
    form: false,
    title: '',
    meeting: '',
    content: '',
    closed_at: '',
    meeting_date: new Date().toISOString().substr(0, 10),
    meeting_time: '09:00',
    loading: false,
    submitLoading: false,
    rules: {
      required: (v) => !!v || 'This field is required!',
    },
    meetingRules: [
      (v) => !v || v.match(urlRule) || 'The webinar link is invalid a url',
    ],
    challengeDialog: false,
    descriptions: [
      'Basic background information about the company. What do you do and why?',
      'What is the current challenge you are facing and what is the ultimate goal? Please include as much information as possible including the 5 W’s (Who, What, Where, When, Why)',
      'What current tactics are you using to address the issue and what tactics have you already attempted',
      'Is there a method in mind you wish to look more into and would like our students to research?',
      'Please include any resources or links that students can access to learn more about your company (Website, LinkedIn, Facebook, other social media platforms).',
      'A deadline/date for when you would like receive proposals',
      'Do you require a webinar/interview after students submit proposal ? Yes, no',
      'What would you offer to students if it is not paid ( optional )',
    ],
  }),
  computed: {
    id() {
      return this.$route.params.id || 0
    },
    ...mapGetters(['isLoggedIn', 'userInfo']),
  },
  async mounted() {
    if (this.id > 0) {
      await this.initData()
    }
  },
  methods: {
    async initData() {
      try {
        this.loading = true
        const { data } = await fetchData(this.id)
        this.title = data.title
        this.content = data.content
        this.meeting = data.meeting
        this.meeting_time = data.meeting_time
        this.meeting_date = data.meeting_date
        this.closed_at = moment(data.closed_at).format('Y-M-D')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async submit() {
      if (this.submitLoading) return
      try {
        this.submitLoading = true
        const postData = {
          title: this.title,
          content: this.content,
          meeting_at: this.meeting_date + ' ' + this.meeting_time,
          closed_at: this.closed_at,
          meeting: this.meeting,
        }
        const { data } = await createOrUpdate(postData, this.id)

        this.$message(
          `${
            this.id > 0
              ? 'Update Successfully'
              : 'Your Company Challenge Has Been Successfully Posted'
          }`
        )
        this.$router.push({
          name: 'challengeShow',
          params: { id: data.id },
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.submitLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
